<template>
  <div class="window">
    <div class="form">
      <div class="item short require">
        <p class="title">姓名：</p>
        <input v-model="form.name" class="input" maxlength="100"/>
      </div>
      <div class="item short require">
        <p class="title">年龄：</p>
        <input v-model="form.age" class="input" maxlength="100"/>
      </div>
      <div class="item short require">
        <p class="title">性别：</p>
        <div class="radios flex-cc">
          <div class="radio flex-cc" @click="changeSex('男')">
            <p class="circle" v-if="form.sex !== '男'"></p>
            <img v-else src="@/assets/images/activity/selected.png" class="img" />
            <p>男</p>
          </div>
          <div class="radio flex-cc" @click="changeSex('女')">
            <p class="circle" v-if="form.sex !== '女'"></p>
            <img v-else src="@/assets/images/activity/selected.png" class="img" />
            <p>女</p>
          </div>
        </div>
      </div>
      <div class="item short require">
        <p class="title">学历：</p>
        <input v-model="form.edu" class="input" maxlength="100"/>
      </div>
      <div class="item short require">
        <p class="title">专业领域：</p>
        <input v-model="form.area" class="input" maxlength="100"/>
      </div>
      <div class="item short require">
        <p class="title">工作经验：</p>
        <input v-model="form.exp" class="input" maxlength="100"/>
      </div>
      <div class="item short require" v-if="!userInfo.id">
        <p class="title">联系方式：</p>
        <el-input @input="handlePhone" v-model="form.phone" class="e-input" maxlength="11" />
      </div>
      <div class="item short c-code" v-if="!userInfo.id">
        <el-input @input="handleCaptcha" v-model="form.code" class="e-input e-code" maxlength="6" />
        <button class="b-code" :class="{'disable-code': !showCode}" @click="getPhoneCode()">验证码</button>
      </div>
      <div class="item long require">
        <p class="title">请简要描述您的科技成果或项目：</p>
        <textarea v-model="form.des" class="textarea"  maxlength="2000" />
      </div>
      <div class="item long require">
        <p class="title">上传您的科技成果或项目附件：</p>
        <el-upload
          ref="fileUpload"
          action="#"
          :accept="'.pdf,.doc,.docx,.png,.jpg,.gif,.jfif,.jpeg,.ppt'"
          :limit="1"
          :disabled="fileDisable"
          list-type="text"
          :file-list="files"
          :on-exceed="handleExceedFile"
          :before-upload="beforeUploadFile"
          :http-request="handleUploadFile"
          :on-change="loadJsonFromFile_2" style="width: 100px;height: 32px;">
          <!-- 设定高度防止抖动 -->
          <template #default>
            <div class="file-upload" v-if="files.length === 0 && !fileDisable">
              <img src="@/assets/images/incubator/upload-icon.png"/>
              <p>上传文件</p>
            </div>
            <div class="file-upload" v-else-if="fileDisable">
              <p>上传文件中</p>
            </div>
            <div class="flex-cc file-upload-name" v-else>
              <p class="text-ol-ell">{{files[0].name}}</p>
              <div class="delete-button" @click.stop="deleteFile">
                X
              </div>
            </div>
          </template>
        </el-upload>
      </div>
      <div class="item short require">
        <p class="title">期望工作城市：</p>
        <input v-model="form.city" class="input" maxlength="100"/>
      </div>
      <div class="item short require">
        <p class="title">期望从事产业：</p>
        <input v-model="form.indus" class="input" maxlength="100"/>
      </div>
      <div class="item short require">
        <p class="title">期望薪资待遇：</p>
        <input v-model="form.wage" class="input" maxlength="100"/>
      </div>
      <div class="item long" >
        <p class="tip">注意事项：<br/>请确保填写的信息真实有效。提交申请后，我们将在3个工作日内与您联系。</p>
      </div>
      <div class="item long">
        <button class="submit" @click="submit" v-loading="loading">确认提交</button>
      </div>
      <div class="item long" >
        <p class="line">科企岛人才工作站期待您的加入，我们将根据您的信息和需求，为您提供个性化的服务和解决方案。让我们携手共创美好的未来！</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { uploadTypeFile, cosByUploadType} from "@/api/upload";
import { bucketMap,fileStaticPath,requestVersion } from "@/utils/consts";
import { randomFilename } from "@/utils/time";
import { getPhoneCode } from "@/api/user";
import { activityRegister } from '@/api/activity';


export default {
  emits:['closeWindow'],
  data() {
    return {
      fileDisable: false,
      files: [],
      count: 60,
      showCode: true, // 是否显示倒计时
      timer: null,
      loading: false,
      userInfo: {},
      form:{
        name: '',
        age: '',
        sex: '男',
        edu: '',
        area: '',
        exp: '',
        phone: '',
        code: '',
        des: '',
        file: '',
        city: '',
        indus: '',
        wage: '',
      },
    }
  },
  mounted(){
    this.userInfo = JSON.parse(localStorage.getItem('userInfos')).memberInfo || {}
  },
  methods:{
    async submit(){
      if(!this.form.name){
        ElMessage.warning('请输入姓名!')
        return
      } else if(!this.form.age){
        ElMessage.warning('请输入年龄!')
        return
      } else if(!this.form.edu){
        ElMessage.warning('请输入学历!')
        return
      } else if(!this.form.area){
        ElMessage.warning('请输入专业领域!')
        return
      } else if(!this.form.exp){
        ElMessage.warning('请输入工作经验!')
        return
      } else if(!this.form.phone && !this.userInfo.id){
        ElMessage.warning('请输入联系方式!')
        return
      } else if(!this.form.code && !this.userInfo.id){
        ElMessage.warning('请输入验证码!')
        return
      } else if(this.files.length === 0){
        ElMessage.warning('请上传科技成果或项目附件!')
        return
      } else if(!this.form.des){
        ElMessage.warning('请输入您的科技成果或项目!')
        return
      } else if(!this.form.city){
        ElMessage.warning('请输入您期望的工作城市!')
        return
      } else if(!this.form.indus){
        ElMessage.warning('请输入您期望的从事产业!')
        return
      } else if(!this.form.wage){
        ElMessage.warning('请输入您期望的薪资待遇!')
        return
      }
      this.loading = true
      if(!this.userInfo.id){
        const params = {
          machineCode: "",
          version: requestVersion,
          canal: 1,
          type: 0,
          code: this.form.code,
          phone: this.form.phone,
        }
        this.$store.dispatch("user/login", params).then(async () => {
          let userInfo = localStorage.getItem('userInfos')
          userInfo = JSON.parse(userInfo).memberInfo
          const { organizationType } = userInfo
          // 没有身份的跳去选择角色，助手除外 或者未选择行业前不打开窗口
          if (organizationType){
            this.$store.dispatch("user/updateVipInfo")
          }
          this.userInfo = userInfo
          try {
            const result = await this.directUploadFile(this.files[0])
            // 路径+/年/月/日/时间戳/文件原名称(文件名称+类型)
            let path = result.Location.slice(result.Location.indexOf("/"))
            this.form.file = 'https://' + fileStaticPath + '/' + path.slice(0,path.indexOf('/',12) + 1) + this.files[0].name
            this.axiosForm()
          } catch (error) {
            ElMessage.warning('上传文件或登录失败，请稍后再试')
            console.log(error,'上传文件错误');
            this.loading = false
            return
          }
        })
      }
      else this.axiosForm()
    },
    async axiosForm(){
      try {
        const res =  await activityRegister({
          activityName: '人才工作站',
          activityType: '32',
          code: '123',
          isCreateMember: false,
          contactWay: this.userInfo.phone,
          jsonStr: JSON.stringify({
            '年龄': this.form.age,
            '性别': this.form.sex,
            '学历': this.form.edu,
            '专业领域': this.form.area,
            '工作经验': this.form.exp,
            '联系方式': this.userInfo.phone,
            '科技成果或项目': this.form.des,
            '附件': this.form.file,
            '期望工作城市': this.form.city,
            '期望从事产业': this.form.indus,
            '期望薪资待遇': this.form.wage,
          }),
          name: this.form.name,
        })
        if(res.code === "Sx200"){
          ElMessage.success('提交成功')
          this.clearForm()
          this.$emit('closeWindow')
          this.loading = false
        }
      } catch (error) {
        ElMessage.warning('提交错误，请稍后再试!')
        console.log(error,'提交错误');
        this.loading = false
        return
      }
    },
    getPhoneCode() {
      const TIME_COUNT = 60;
      const reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (reg.test(this.form.phone) == false) {
        ElMessage.warning("您输入的手机号码格式不正确，请重新输入");
        return;
      }
      getPhoneCode({ phone: this.form.phone })
        .then(() => {
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.showCode = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
          ElMessage.success("发送成功");
        })
        .catch((err) => {
          ElMessage.error({ message: err.message });
        });
    },
    handleExceedFile(){ // 文件超出个数限制时的钩子
      ElMessage.warning('最多只能上传1个文件～')
    },
    beforeUploadFile(file){
      // 上传文件
      if(file.size > 50 * 1024 * 1024){
        // 大小判断 50M
        ElMessage.warning('上传文件大小过大，不能超过50M!')
        return false
      }
    },
    deleteFile(){ // 删除文件
      this.form.file = ''
      this.files = []
    },
    async handleUploadFile(val){  // 进行上传
      if(val.file.size === 0){ // 空文件判断
        ElMessage.warning('请勿上传空文件!')
        this.deleteFile() // 提示后删除
        return
      }
      if(val.file.name.length > 70){    // 如果超过70个字符
        ElMessage.warning('文件名称过长,请修改!')
        this.deleteFile() //提示后删除
        return
      }
      if(!this.userInfo.id){
        // 未登录
        this.files = [val.file]
        return
      }
      let result;
      result = await this.directUploadFile(val.file)
      // 路径+/年/月/日/时间戳/文件原名称(文件名称+类型)
      let path = result.Location.slice(result.Location.indexOf("/"))
      this.form.file = 'https://' + fileStaticPath + '/' + path.slice(0,path.indexOf('/',12) + 1) + this.files[0].name
    },
    directUploadFile(file){ // 暂用video的桶
      let self = this
      let cos = cosByUploadType(uploadTypeFile)
      let bucket = bucketMap.file
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket, /* 必须 */
            Region: "ap-shanghai",     /* 存储桶所在地域，必须字段 */
            Key: randomFilename()+'/' + file.name,              /* 必须 */
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              self.$refs['fileUpload'].uploadFiles.map((item) => {
                if (item.raw.uid === file.uid) {
                  if(item.percentage !== 100){  // 已经上传完成不再操作
                    item.percentage = parseInt(progressData.percent * 100)
                    if(item.percentage === 100) {
                      ElMessage.success('上传文件成功!')
                      self.fileDisable = false
                    }
                    else  self.fileDisable = true
                  }
                }
              })
            }
          },
          function (err, data) {
            if (err) {
              console.log('fileError:',err,err.code);
              if(err.message.includes('getAuthorization')){
                // ! 获取授权错误
                ElMessage.warning('网络波动，请稍后再试!')
              }
              self.fileDisable = false
              reject(err)
            } else {
              resolve(data)
            }
          }
        );
      })
    },
    loadJsonFromFile_2(file,fileList){
      this.files = fileList
    },
    handlePhone(e){
      const pattern = new RegExp("^[0-9]*$")
      this.form.phone = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    handleCaptcha(e) {  // 验证码处理，只输入数字
      const pattern = new RegExp("^[0-9]*$")
      this.form.code = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    changeSex(type){
      this.form.sex = type
    },
    clearForm(){
      this.files = []
      this.form = {
        name: '',
        age: '',
        sex: '男',
        edu: '',
        area: '',
        exp: '',
        phone: '',
        code: '',
        des: '',
        file: '',
        city: '',
        indus: '',
        wage: '',
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.window{
  max-height: 50vh;
  overflow-y: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  .form{
    width: 624px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 33px;
    .require{
      .title{
        padding-left: 8px;
        position: relative;
        &::before{
          position: absolute;
          content: "*";
          font-size: 14px;
          color: #ED0900;
          left: 0;
          top: 2px;
        }
      }
    }
    .short{
      width: 290px;
    }
    .long{
      width: 100%;
    }
    .c-code{
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      .b-code{
        width: 65px;
        height: 32px;
        background: $--color-theme;
        cursor: pointer;
        border-radius: 0px 4px 4px 0px;
        margin-bottom: 4px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        box-sizing: border-box;
      }
      .disable-code{
          background: #2282ff8a !important;
          cursor: not-allowed !important;
        }
    }
    .item{
      margin-bottom: 20px;
      :deep(.el-upload-list){
        display: none !important;
      }
      .file-upload{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #D3D3D3;
        width: 100px;
        height: 32px;
        cursor: pointer;
        position: relative;
        .delete-button{
          z-index: 20;
          position: absolute;
          right: 0px;
          top: 0px;
          width: 14px;
          cursor: pointer;
          height: 14px;
          font-size: 14px;
          color: #ffffff;
          line-height: 14px;
          text-align: center;
          background-color: #000;
        }
        p{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 14px;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 4px;
        }
      }
      .file-upload-name{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #D3D3D3;
        width: 290px;
        height: 32px;
        cursor: pointer;
        position: relative;
        .delete-button{
          z-index: 20;
          position: absolute;
          right: 0px;
          top: 0px;
          width: 14px;
          cursor: pointer;
          height: 14px;
          font-size: 14px;
          color: #ffffff;
          line-height: 14px;
          text-align: center;
          background-color: #000;
        }
        p{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 16px;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 4px;
        }
      }
      .submit{
        width: 150px;
        height: 32px;
        background: $--color-theme;
        border-radius: 3px;
        margin: 0 auto;
        cursor: pointer;
        display: block;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
      }
      .line{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 16px;
        padding-bottom: 33px;
        margin-top: 113px;
      }
      .tip{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 16px;
        padding: 34px 0 31px;
      }
      .title{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 14px;
        margin-bottom: 8px;
      }
      .e-code{
        width: 89px;
        :deep(.el-input__inner){
          height: 32px;
          width: 89px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .e-input{
        :deep(.el-input__inner){
          height: 32px;
        }
      }
      .input{
        background-color: transparent;
        font-size: 14px;
        width: 100%;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #D3D3D3;
        box-sizing: border-box;
        padding-left: 10px;
        padding-right: 10px;
      }
      .textarea{
        background-color: transparent;
        font-size: 14px;
        width: 100%;
        height: 91px;
        border-radius: 4px;
        border: 1px solid #D3D3D3;
        box-sizing: border-box;
        padding: 10px;
        resize: none;
      }
      .radios{
        width: 100%;
        height: 32px;
        .radio{
          width: 100px;
          cursor: pointer;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 14px;
          .circle{
            width: 14px;
            height: 14px;
            border: 1px solid #D3D3D3;
            border-radius: 50%;
            box-sizing: border-box;
            margin-right: 4px;
          }
          .img{
            margin-right: 4px;
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}
</style>