<template>
  <div class="content">
    <div class="wrap">
      <div class="banner-tool">
        <el-carousel height="390px" style="width: 493px;" indicator-position="outside" arrow="never" :autoplay="true" :interval="300000" v-if="list1.length > 0" class="animate__animated animate__fadeIn" ref="Carousel">
          <el-carousel-item v-for="item in list1" :key="item.id">
            <div class="news-b">
              <div class="thumb" @click="toDetail(item.id)">
                <Image :src="item.thumb" :imgId="item.id" ></Image>
                <a class="a-title text-otl-ell" :href="`/detail?id=${item.id}`" target="_blank" @click.stop>{{ item.title }}</a>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <img class="carousel-left" @click="carouselPre" src="@/assets/images/G60/home/ca-left.png"/>
        <img class="carousel-right" @click="carouselNext" src="@/assets/images/G60/home/ca-right.png"/>
      </div>
      <div class="right">
        <p class="title-p">G60热点速报</p>
        <img class="title-code" src="@/assets/images/G60/home/hot-code.png" />
        <div v-for="(item,index) in list2" :key="item.id" class="card animate__animated animate__fadeIn" :class="{'no-padding-bottom': index === list2?.length - 1}" @click="toHref(item.href)">
          <a @click.stop class="a-title text-otl-ell" :href="item.href" target="_blank">{{ item.title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getlistHomepage } from "@/api/G60";
import { routerWindow } from "@/utils/tool";

export default{
  data(){
    return{
      // G60走廊频道ID 329066093805569
      // G60外部链接ID 329066133651457
      list1:[],
      list2:[],
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    carouselPre(){
      this.$refs.Carousel.prev()
    },
    carouselNext(){
      this.$refs.Carousel.next()
    },
    toHref(href){
      if(!href) return
      window.open(href, "_blank")
    },
    toDetail(id){
      if(!id) return
      routerWindow(this.$router,'/detail',{id})
    },
    getList(){
      getlistHomepage(1,10,329066093805569).then(res=>{
        this.list1 = res.data.result
      })
      getlistHomepage(1,7,329066133651457).then(res=>{
        this.list2 = res.data.result.map(item=>{
          item.href = item.summary.replace('**kqd-str**','') // 获取外部的链接
          return item
        })
      })
    },
  }
}

</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  .title{
    font-size: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 30px;
    margin-bottom: 20px;
    a{
      color: #111111;
      &:hover{
        color: $--G60-theme !important;
      }
      &:visited{
        color: #111111;
      }
    }
  }
  .wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .banner-tool{
    width: 493px;
    height: 400px;
    background-color: #ffffff;
    position: relative;
    :deep(.el-carousel__indicator--horizontal) {
      .el-carousel__button{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #CDCDCD;
      }
    }
    :deep(.el-carousel__indicators) {
      bottom: 112px !important;
      position: absolute;
      width: 100%;
      left: 0;
    }
    .carousel-left{
      position: absolute;
      width: 22px;
      height: 22px;
      left: 37px;
      top: 130px;
      cursor: pointer;
    }
    .carousel-right{
      position: absolute;
      width: 22px;
      height: 22px;
      right: 37px;
      top: 130px;
      cursor: pointer;
    }
  }
  .news-b{
    display: flex;
    position: relative;
    width: 493px;
    .thumb{
      width: 493px;
      height: 290px;
      cursor: pointer;
    }
    .a-title{
      width: 100%;
      padding: 0 20px;
      background-color: #FFFFFF;
      box-sizing: border-box;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #111111;
      line-height: 28px;
      height: 56px;
      margin-top: 20px;
      cursor: pointer;
      &:hover{
        color: $--G60-theme !important;
      }
      &:visited{
        color: #111111;
      }
    }
  }
  .right{
    width: 708px;
    background: #FFFFFF;
    padding-bottom: 28px;
    box-sizing: border-box;
    padding-left: 42px;
    padding-right: 20px;
    padding-top:  20px;
    position: relative;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .title-p{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 26px;
      color: #111111;
      line-height: 37px;
      margin-bottom: 10px;
    }
    .title-code{
      width: 54px;
      height: 54px;
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .hot{
      width: 100%;
      height: 106px;
      margin-bottom: 10px;
    }
    .no-padding-bottom{
      margin-bottom: 0 !important;
    }
    .card{
      width: 100%;
      box-sizing: border-box;
      padding-left: 21px;
      position: relative;
      cursor: pointer;
      &:hover{
        .a-title{
          color: $--G60-theme !important;
        }
      }
      &::before{
        content: '';
        box-sizing: border-box;
        border-radius: 50%;
        position: absolute;
        left: 0px;
        top: 9px;
        width: 6px;
        height: 6px;
        background: #C9C9C9;
      }
      .a-title{
        width: 90%;
        max-height: 48px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 24px;
        cursor: pointer;
        &:hover{
          color: $--G60-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .time{
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
      }
    }
  }
}
</style>