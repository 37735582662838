<template>
  <div class="content">
    <div class="title-line">
      <p class="title m-r"><a @click.stop href="/news?id=329089112145921" target="_blank">创新前沿</a></p>
      <p class="title"><a @click.stop href="/news?id=329089217003521" target="_blank">产学研用</a></p>
    </div>
    <div class="b-content">
      <div class="left">
        <div v-for="item in list1" :key="item.id" class="card animate__animated animate__fadeIn" @click="toDetail(item.id)">
          <div class="img">
            <Image :src="item.thumb" :imgId="item.id" />
          </div>
          <div class="info">
            <a @click.stop class="a-title text-ol-ell" :href="`/detail?id=${item.id}`" target="_blank">{{ item.title }}</a>
            <p class="word text-othl-ell">{{ checkSummary(item.summary) }}</p>
            <p class="time">{{ item.publishTime }}</p>
          </div>
        </div>
      </div>
      <div class="right">
        <div v-for="item in list2" :key="item.id" class="card animate__animated animate__fadeIn" @click="toDetail(item.id)">
          <div class="img">
            <Image :src="item.thumb" :imgId="item.id" />
          </div>
          <div class="bottom">
              <a @click.stop class="a-title text-otl-ell" :href="`/detail?id=${item.id}`" target="_blank">{{ item.title }}</a>
            <!-- <p class="word text-ol-ell">{{ checkSummary(item.summary) }}</p> -->
            <div class="time">
              <!-- <img src="@/assets/images/G60/home/clock.png" /> -->
              <p>{{ item.publishTime.slice(0,10) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getlistHomepage } from "@/api/G60";
import { routerWindow } from "../../../../utils/tool";


export default{
  data(){
    return{
      // G60创新前沿频道ID 329089112145921
      // G60产学研用频道ID 329089217003521
      list1: [],
      list2: [],
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    toDetail(id){
      if(!id) return
      routerWindow(this.$router,'/detail',{id})
    },
    getList(){
      getlistHomepage(1,3,329089112145921).then(res=>{
        this.list1 = res.data.result
      })
      getlistHomepage(1,4,329089217003521).then(res=>{
        this.list2 = res.data.result
      })
    },
    checkSummary(word){
      if(!word) return word
      else if(word.indexOf('**kqd-img**') !== -1) return ''
      return word.replace('**kqd-str**','')
    },
  },
}
</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  .title-line{
    display: flex;
    .m-r{
      margin-right: 482px;
    }
    a{
      color: #111111;
      &:hover{
        color: $--G60-theme !important;
      }
      &:visited{
        color: #111111;
      }
    }
  }
  .title{
    font-size: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 30px;
    padding-bottom: 35px;
  }
  .b-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left{
      width: 555px;
      height: 531px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .card{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &:hover{
          .a-title{
            color: $--G60-theme !important;
          }
        }
        .img{
          width: 221px;
          height: 157px;
          cursor: pointer;
        }
        .info{
          width: 316px;
          .a-title{
            display: block;
            width: 100%;
            margin-bottom: 12px;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 22px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #111111;
            }
          }
          .word{
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #111111;
            line-height: 24px;
            height: 72px;
            margin-bottom: 7px;
          }
          .time{
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 24px;
          }
        }
      }
    }
    .right{
      width: 610px;
      height: 531px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .card{
        width: 298px;
        margin-bottom: 20px;
        cursor: pointer;
        background-color: #ffffff;
        &:hover{
          .a-title{
            color: $--G60-theme !important;
          }
        }
        .img{
          width: 100%;
          height: 176px;
          cursor: pointer;
          z-index: 20;
          position: relative;
        }
        .bottom{
          position: relative;
          z-index: 40;
          width: 100%;
          background-color: #ffffff;
          border-radius: 4px;
          margin-top: -12px;
        }
        .time{
          width: 100%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          padding-left: 6px;
          padding-bottom: 16px;
          img{
            width: 12px;
            height: 12px;
            margin-right: 3px;
          }
        }
        .a-title{
          display: block;
          width: 100%;
          box-sizing: border-box;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #111111;
          line-height: 19px;
          cursor: pointer;
          padding: 16px 6px 15px;
          &:hover{
            color: $--G60-theme !important;
          }
          &:visited{
            color: #111111;
          }
        }
        .word{
          width: 100%;
          box-sizing: border-box;
          font-size: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #98A3B7;
          line-height: 24px;
        }
      }
    }
  }
}
</style>