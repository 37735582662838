<template>
  <div>
    <div class="container">
      <div class="container contact-container">
        <div class="top-bg" :style="selected ? ' height: calc(100vh - 450px);' : ''">
          <!-- 选择身份弹窗 -->
          <div class="selection" v-if="selected">
            <div class="selection-top">
              <p>选择身份</p>
            </div>
            <div class="selection-content">
              <div class="selection-item bg-blue" @click="inputForm(2)">
                <p>我是普通用户</p>
              </div>
              <div class="selection-item bg-blue" @click="inputForm(1)">
                <p>我是科研院所工作者</p>
              </div>
              <!-- 资本类 -->
              <div class="selection-item bg-blue" @click="inputForm(3)">
                <p>我是资本类企业人员</p>
              </div>
            </div>
          </div>

          <div class="school" v-if="choose == 1">
            <div class="selection-top">
              <p>构建工作台—{{ labType?.name || '产学研' }}</p>
            </div>
            <el-form :rules="rulesSchool" ref="ruleSchool" :model="schoolForm" label-width="180px" class="school-content">
              <el-form-item prop="name" label="实验室名称">
                <el-input v-model="schoolForm.name" placeholder="请输入实验室名称" clearable :maxlength="40"
                  show-word-limit></el-input>
              </el-form-item>

              <el-form-item prop="belong" label="所属单位">
                <el-input v-model="schoolForm.belong" placeholder="请填写所属单位的信息" clearable :maxlength="40"
                  show-word-limit></el-input>
              </el-form-item>

              <el-form-item prop="createMan" label="姓名">
                <el-input v-model="schoolForm.createMan" clearable placeholder="请输入您的中文名字" :maxlength="10"
                  show-word-limit></el-input>
              </el-form-item>

              <el-form-item prop="label" label="请输入您的科研方向">
                <!-- 1.7 防止重复 -->
                <el-select style="width: 100%;background: #F3F3F3!important;" v-model="schoolForm.label" multiple
                  filterable allow-create :filter-method="findLabel" default-first-option placeholder="输入单个方向后按enter键确认">
                </el-select>
              </el-form-item>

              <el-form-item prop="educationId" label="学历">
                <el-select v-model="schoolForm.educationId" placeholder="请选择学历">
                  <el-option v-for="item in educationList" :key="item.id" :label="item.val" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="positionId" label="职称">
                <el-select v-model="schoolForm.positionId" placeholder="请选择职称">
                  <el-option v-for="item in jobtitlelist" :key="item.id" :label="item.val" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="email" label="联系邮箱">
                <el-input v-model="schoolForm.email" clearable placeholder="请输入联系邮箱"></el-input>
              </el-form-item>
              <!-- 1.7 删除 -->
              <!-- <el-form-item prop="techScaleId" label="实验室规模">
                <el-select v-model="schoolForm.techScaleId" placeholder="请输入实验室规模">
                  <el-option
                      v-for="item in humanList"
                      :key="item.id"
                      :label="item.val"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->

              <el-form-item prop="addressAreaNames" label="请选择实验室地址">
                <el-cascader ref="cascaderAddr" :props="props" placeholder="请选择实验室地址"
                  v-model="schoolForm.addressAreaNames" @visible-change="visibleChange" @change="visibleChange"
                  @expand-change="visibleChange"></el-cascader>
              </el-form-item>
              <!-- 1.8.4 自我介绍 -->
              <el-form-item label="请填写自我介绍">
                <el-input v-model="schoolForm.orgIntroduction" type="textarea" :maxlength="400" show-word-limit
                  placeholder="请输入自我介绍，最少10个字，最多不超过400字" resize="none" rows="9" />
              </el-form-item>
              <el-form-item label="上传实验室铭牌或教师职工工牌">
                <el-upload ref="upload1" action="#" :limit="9" :accept="'image/*'" list-type="picture-card"
                  :on-exceed="handleExceed" :http-request="handleUpload" :on-change="loadJsonFromFile"
                  :before-upload="beforeUpload">
                  <template #default>
                    <i class="el-icon-plus"></i>
                  </template>
                  <template #file="{ file }">
                    <el-progress v-if="file.percentage != 100" type="circle" :percentage="file.percentage"></el-progress>
                    <div v-if="file.percentage == 100" style="position: relative;width: 100%; height: 100%">
                      <img v-if="file.raw.type[0] == 'i'" class="el-upload-list__item-thumbnail" :src="file.url" alt=""
                        @click="showDiaFile(file)" />
                      <div @click="deleteVideo(file)" class="icon-delete">
                        <i class="el-icon-delete" style="fontSize: 16px;color: #fff;"></i>
                      </div>
                    </div>
                  </template>
                </el-upload>
              </el-form-item>

              <el-dialog v-model="dialogVisible">
                <img :src="dialogImageUrl" alt="">
              </el-dialog>

              <div class="window-btn" @click="submitLab" v-loading="btnDisabled">
                <div>
                  <div class="btn-text">
                    确定提交
                  </div>
                </div>
              </div>
            </el-form>
          </div>
          <!-- <div class="company company-big" v-if="choose==2 && !companyType">
            <div class="selection-top-new">
              <div class="first-step">第一步选择企业类型</div>
            </div>
            <div class="selection-content-company-type" v-loading="loadingTypes">
              <div class="selection-item-company-type" @click="selectCompanyType(item)" v-for="(item,index) in companyTypes" :key="item.key" :class="{'back1': index===0,'back2': index===1,'back3': index===2,'back4': index===3}">
                <div class="type-container">
                  <div class="type-name">{{item.name}}</div>
                  <div class="type-describe">{{item.des}}</div>
                </div>
              </div>
              <div class="selection-item-company-type" @click="selectCompanyType(item)" v-for="(item) in companyTypes" :key="item.key" >
                <div class="type-container">
                  <img :src="staticPath + item.image" alt="">
                </div>
              </div>
            </div>
          </div> -->
          <div class="company" v-if="choose == 2" v-loading="loading || !companyType" style="min-height: 450px;">
            <div class="selection-top">
              <p>{{ showImgUpload ? "完善企业信息" : "完善个人信息" }}</p>
            </div>
            <el-form class="school-content">
              <el-form-item v-for="(item, index) in newCompanyform" :key="index">
                <div class="new-company-form">
                  <p v-if="!(item.type === 'input' && (item.name === '企业关键词' || item.name === '企业关键字'))">
                    {{ item.name }}:
                    <span class="empty-remind"
                      v-if="item.val?.length === 0 && item.type !== 'qidaoAddress' && item.type !== 'multiMedia' && submitClicked && item.notNull === 1">{{ item.nullTip }}</span>
                    <span class="empty-remind"
                      v-else-if="item.type === 'qidaoAddress' && (!isNaN(item.key) || item.name === '企业地址' || item.name === '园区地址' || item.name === '单位地址' || item.name === '地址') && location?.length === 0 && submitClicked">{{ item.nullTip }}</span>
                    <span class="empty-remind"
                      v-else-if="item.type === 'qidaoAddress' && isNaN(item.key) && item.val?.length === 0 && submitClicked && item.name !== '企业地址' && item.name !== '园区地址' && item.name !== '单位地址' && item.name !== '地址'">{{ item.nullTip }}</span>
                    <!-- <span class="empty-remind" v-else-if="item.type === 'multiMedia' && uploadimgs?.length === 0 && submitClicked">{{item.nullTip}}</span> -->
                    <!-- 新的选择字段 -->
                    <!-- <span class="empty-remind" v-else-if="item.type === 'qidaoPicker' && qidaoPicker?.length === 0">{{item.nullTip}}</span> -->
                  </p>
                  <p v-else>
                    <span v-if="!isNaN(item.key)">{{ directionLabel }}:</span>
                    <span v-else>{{ item.title }}</span>
                    <span class="empty-remind"
                      v-if="companyLabels?.length === 0 && submitClicked && !isNaN(item.key)">{{ item.nullTip }}</span>
                    <span class="empty-remind"
                      v-if="item.val?.length === 0 && submitClicked && isNaN(item.key)">{{ item.nullTip }}</span>
                  </p>
                  <!-- 媒体文件提示 -->
                  <div v-if="item.type === 'multiMedia'" class="">
                    <div class="tip">营业执照、身份证、工作证、名片等都可以</div>
                    <div class="tip-small">仅作为认证信息，将不会对外展示</div>
                  </div>
                  <div
                    v-if="(item.type === 'input' && item.name !== '企业关键词' && item.name !== '企业关键字') || (isNaN(item.key) && item.type === 'input')">
                    <el-form-item prop="name">
                      <el-input v-model="item.val" :placeholder="item.nullTip" :maxlength="item.limit"
                        show-word-limit></el-input>
                    </el-form-item>
                  </div>
                  <div v-if="item.type === 'inputNumber'">
                    <el-form-item prop="name">
                      <el-input @input="handelNumber(index)" v-model="item.val" :placeholder="item.nullTip"
                        :maxlength="item.limit" show-word-limit></el-input>
                    </el-form-item>
                  </div>
                  <!-- qidaoDict 单选 -->
                  <div v-if="item.type === 'qidaoDict'" class="dict-selections">
                    <div v-for="(jtem, i) in item.selections" :key="jtem.id" class="dict-selection"
                      :class="{ 'dict-active': jtem.id === item.val }" @click="chooseDict(index, i)">
                      {{ jtem.val }}
                    </div>
                  </div>
                  <div
                    v-if="item.type === 'qidaoAddress' && (!isNaN(item.key) || item.name === '企业地址' || item.name === '园区地址' || item.name === '单位地址' || item.name === '地址')">
                    <el-form-item>
                      <el-cascader ref="cascaderAddr" :props="props" style="width: 100%;background: #F3F3F3!important;"
                        :placeholder="item.nullTip" @visible-change="visibleChange" @change="visibleChange"
                        @expand-change="visibleChange" v-model="location"></el-cascader>
                    </el-form-item>
                  </div>
                  <div
                    v-if="item.type === 'input' && (item.name === '企业关键词' || item.name === '企业关键字') && !isNaN(item.key)">
                    <el-form-item prop="label">
                      <!-- 1.7 防止重复 -->
                      <el-select style="width: 100%;background: #F3F3F3!important;" class="width360"
                        v-model="companyLabels" multiple filterable allow-create :filter-method="findLabel"
                        default-first-option :placeholder="item.nullTip">
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-if="item.type === 'textarea' && !isNaN(item.key)">
                    <el-form-item prop="summary">
                      <el-input :rows="9" v-model="item.val" type="textarea" :minlength="item.min" :maxlength="item.limit"
                        :placeholder="item.nullTip" show-word-limit resize="none"></el-input>
                    </el-form-item>
                  </div>
                  <div v-if="item.type === 'industrys'">
                    <!-- 行业多选 -->
                    <MultiSelection :selections="multiIndustry" :placeholder="item.nullTip" :max="10" :ref="'industrys'"
                      :value="userIndustryIds?.join(',')"></MultiSelection>
                  </div>
                  <div v-if="item.type === 'organization'">
                    <!-- 研究机构 -->
                    <el-form-item prop="industryIds">
                      <el-select style="width:100%;" v-model="item.val" clearable placeholder="请选择研究机构">
                        <!-- :disabled="isDisabled(index)" -->
                        <el-option v-for="(item) in organizations" :key="item.id" :label="item.val" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-if="item.type === 'select'">
                    <!-- select选项 -->
                    <el-form-item prop="industryIds">
                      <el-select style="width:100%;" v-model="item.val" clearable :placeholder="item.subTitle">
                        <el-option v-for="(jtem) in item.selections" :key="jtem.id" :label="jtem.val" :value="jtem.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <!-- 园区类型 -->
                  <div v-if="item.type === 'gardenType'">
                    <el-form-item prop="industryIds">
                      <el-select style="width:100%;" v-model="item.val" clearable placeholder="请选择园区类型">
                        <!-- :disabled="isDisabled(index)" -->
                        <el-option v-for="(item) in gardenType" :key="item.id" :label="item.val" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <!-- 园区科研人员数 -->
                  <div v-if="item.type === 'gardenNumber'">
                    <el-form-item prop="industryIds">
                      <el-select style="width:100%;" v-model="item.val" clearable placeholder="请选择园区内科研人员数">
                        <!-- :disabled="isDisabled(index)" -->
                        <el-option v-for="(item) in gardenNumber" :key="item.id" :label="item.val" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <!-- 是否选项 -->
                  <div v-if="item.type === 'twoWaySelect'">
                    <el-form-item prop="industryIds">
                      <el-select style="width:100%;" v-model="item.val" clearable :placeholder="item.subTitle">
                        <el-option v-for="(jtem) in item.selections" :key="jtem.id" :label="jtem.val" :value="jtem.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-if="item.type === 'multiMedia'">
                    <el-upload ref="upload1" action="#" :limit="9" :accept="'image/*'" list-type="picture-card"
                      :on-exceed="handleExceed" :http-request="handleUpload" :on-change="loadJsonFromFile"
                      :before-upload="beforeUpload">
                      <template #default>
                        <i class="el-icon-plus"></i>
                      </template>
                      <template #file="{ file }">
                        <el-progress v-if="file.percentage != 100" type="circle"
                          :percentage="file.percentage"></el-progress>
                        <div v-if="file.percentage == 100" style="position: relative;width: 100%; height: 100%">
                          <img v-if="file.raw.type[0] == 'i'" class="el-upload-list__item-thumbnail" :src="file.url" alt=""
                            @click="showDiaFile(file)" />
                          <div @click="deleteVideo(file)" class="icon-delete">
                            <i class="el-icon-delete" style="fontSize: 16px;color: #fff;"></i>
                          </div>
                        </div>
                      </template>
                    </el-upload>
                    <el-dialog v-model="dialogVisible">
                      <img :src="dialogImageUrl" style="border:2px solid black;" alt="">
                    </el-dialog>
                  </div>
                  <!-- 附加内容 -->
                  <div v-if="item.type === 'textarea' && isNaN(item.key)">
                    <el-form-item prop="summary">
                      <el-input :rows="9" v-model="item.val" type="textarea" :minlength="item.min" :maxlength="item.limit"
                        :placeholder="item.nullTip" show-word-limit resize="none"></el-input>
                    </el-form-item>
                  </div>
                  <!-- 附加行业 -->
                  <div v-if="item.type == 'qidaoIndustry'">
                    <el-select v-model="item.val" :placeholder="item.nullTip" style="width: 100%;">
                      <el-option v-for="item in industrylist" :key="item.id" :label="item.val" :value="item.id">
                      </el-option>
                    </el-select>
                  </div>
                  <!-- 附加地址 -->
                  <div
                    v-if="item.type === 'qidaoAddress' && isNaN(item.key) && item.name !== '企业地址' && item.name !== '园区地址' && item.name !== '单位地址' && item.name !== '地址'">
                    <el-form-item>
                      <el-cascader :ref="'AddrExtra' + item.id" :props="props"
                        style="width: 100%;background: #F3F3F3!important;" :placeholder="item.nullTip"
                        @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange"
                        v-model="item.val"></el-cascader>
                    </el-form-item>
                  </div>
                </div>
                <!-- 附加时间 -->
                <div v-if="item.type === 'picker'">
                  <el-date-picker v-model="item.val" :clearable="false" type="date" :editable="false"
                    :disabledDate="disabledDate" :placeholder="item.nullTip" class="input-css">
                  </el-date-picker>
                </div>
                <!-- 自定义字段 -->
                <div v-if="index === 0 && showImgUpload" style="margin-top: 15px;">
                  <p>
                    姓名：
                    <span class="empty-remind" v-if="staffName?.length === 0 && submitClicked">请输入姓名</span>
                  </p>
                  <el-input v-model="staffName" :placeholder="'请输入姓名'" :maxlength="10" show-word-limit></el-input>
                </div>
                <div v-if="index === 0 && showImgUpload" style="margin-top: 15px;">
                  <p>
                    部门：
                    <span class="empty-remind" v-if="(departmentId + '')?.length === 0 && submitClicked">请选择部门</span>
                  </p>
                  <el-select style="width:100%;" v-model="departmentId" clearable placeholder="请选择部门"
                    :disabled="departmentSelections?.length === 0">
                    <el-option v-for="(item) in departmentSelections" :key="item.id" :label="item.val" :value="item.id">
                    </el-option>
                  </el-select>
                </div>
                <div v-if="index === 0 && showImgUpload" style="margin-top: 15px;">
                  <p>
                    职位：
                    <span class="empty-remind" v-if="(positionId + '')?.length === 0 && submitClicked">请选择职位</span>
                  </p>
                  <el-select style="width:100%;" v-model="positionId" clearable placeholder="请选择职位"
                    :disabled="positionSelections?.length === 0">
                    <el-option v-for="(item) in positionSelections" :key="item.id" :label="item.val" :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-form>
            <div class="window-btn-company-new">
              <el-button :loading="btnDisabled" :disabled="btnDisabled" @click="newSubmitCompany">确认提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { memberDetails, memberIsPerfect } from "@/api/user";
import { getprovince, getSelect, getcity, getarea } from "@/api/demand";
import { bindLab,createOrg,autoCompanyAdd } from "@/api/user";
import { mapGetters } from 'vuex'
import { uploadimg } from "@/api/upload";
import { uploadTypeImage, uploadTypeVideo, cosByUploadType } from "@/api/upload";
import { bucketMap, personalTypeId } from "@/utils/consts";
import { randomFilename } from "@/utils/time";
import { getSelectFatherSonByType } from "@/api/config";
import { ElMessage } from "element-plus";
import { staticPath, deleteList } from "@/utils/consts";
import { getOrganizationTemplate } from "@/api/organization"
import { getOrganizationTypes } from "@/api/G60";
import { deepClone } from "@/utils/tool"
import MultiSelection from '@/components/MultiSelection/MultiSelection';
import { getG60LocalInfo,getG60Id } from "@/utils/G60/login";


const labelRule = function (rule, value, callback) {
  if (value?.length > 5) {
    callback(new Error('标签不能超过5个'))
  } else {
    callback()
  }
}

export default {
  name: "selection",
  components: {
    MultiSelection
  },
  data() {
    return {
      labTypes: [], // 产学研身份类型
      resUserInfo: {},  // 接口获取用户信息
      staffName: '',  // 员工名字
      positionId: '', // 职位Id
      positionSelections: [], // 职位选项
      departmentId: '', // 部门Id
      departmentSelections: [], // 部门选项
      userIndustryIds: [],  // 用户提交的行业
      capitalType: null,  // 资本类企业
      hasCompany: false,  // 后台是否创建了公司
      gardenNumber: [],  // 科研人员数量
      gardenType: [],  // 园区类型
      organizations: [], // 企业研究机构
      templateVersion: -1,  // 模板版本
      submitClicked: false,
      companyLabels: [],
      loadingTypes: false,
      staticPath,
      typeBackgroundImg: [],
      formCompanyType: '',
      showImgUpload: true,  // 是否展示上传图片
      newCompanyform: [],
      companyTypes: [
      ], //资本投资,政策服务,普通类型,园区招商
      companyType: false,
      licenseImgs: [],
      btnDisabled: false,
      directionLabel: '',
      loading: false,
      // firmTypeCur: -1,
      firmTypeList: [],
      ElMessage: ElMessage,
      location: [],
      extraLocation: [],
      schoolLocation: [],
      username: '',
      email: '',
      industryRemark: '',
      selected: false,
      choose: 0,
      schoolForm: {
        "addressAreaId": 1,
        "addressAreaNames": '',
        "addressAreaName": "",
        "addressCityId": 1,
        "addressCityName": "",
        "addressProvinceId": 1,
        "addressProvinceName": "",
        "belong": "",
        "createMan": "",
        "educationId": "",
        "email": "",
        "label": [],   //是数组，记得分隔
        "license": "",
        "name": "",
        "phone": "1",
        "positionId": "",
        "qualifications": [
          1,
          2,
          3
        ],
        "salesmanId": "",
        "scaleId": 100,
        "signTime": "",
        "summary": "",
        "unionId": '',
        "orgIntroduction": null,
        "verifyStatus": 0
      },
      companyform: {
        "addressAreaId": 1,
        "addressAreaName": "",
        "addressCityId": 1,
        "addressCityName": "",
        "addressProvinceId": 1,
        "addressProvinceName": "",
        "memberId": "",
        "label": [],
        "license": [],
        "name": "",
        "companyType": '',
        "summary": ''
      },
      industrylist: [],
      multiIndustry: [],  // 行业多选
      educationList: [],
      jobtitlelist: [],
      humanList: [],
      dialogVisible: false,
      dialogImageUrl: '',
      uploadimgs: [],
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then(response => {
                const cities = []
                for (let item of response.data) {
                  cities.push({
                    value: item.provinceCode,
                    label: item.provinceName,
                    leaf: false
                  })
                }
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getcity(node.value).then(response => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.cityCode,
                    label: item.cityName,
                    leaf: false
                  })
                }
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.value).then(response => {
                const areas = []
                for (let item of response.data) {
                  areas.push({
                    value: item.areaCode,
                    label: item.areaName,
                    leaf: true
                  })
                }
                resolve(areas);
              });
            }
          }, 100);
        }
      },
      rulesSchool: {
        name: [
          { required: true, message: '请输入实验室名称', trigger: 'change' },
        ],
        belong: [
          { required: true, message: '请填写所属单位的信息', trigger: 'change' }
        ],
        createMan: [
          { required: true, message: '请输入您的中文名字', trigger: 'change' },
          { pattern: /[^0-9]+/, message: '姓名不可以是纯数字', trigger: 'change' }
        ],
        label: [
          { required: true, message: '请输入您的科研方向', trigger: 'change' },
          { required: true, validator: labelRule }
        ],
        educationId: [
          { required: true, message: '请选择学历', trigger: 'change' }
        ],
        positionId: [
          { required: true, message: '请选择职称', trigger: 'change' }
        ],
        email: [
          { required: true, message: '请输入联系邮箱', trigger: 'change' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        addressAreaNames: [
          { required: true, message: '请选择实验室地址', trigger: 'change' }
        ],
      },
    }
  },
  async beforeCreate() {
    let userInfos = getG60LocalInfo()
    if (!userInfos) this.$router.push('/login')  // 未登录去登录
    let res = {}
    try {
      res = await memberDetails(userInfos.memberInfo.id)
      this.userIndustryIds = res.data.industryIds
      this.resUserInfo = res.data // 保存用户信息
      this.schoolForm.createMan = res.data.name
      this.staffName = res.data.name
    } catch (error) {
      location.reload()
    }
    if (!res || !(res.data.organizationId == null || (res.data.organizationId && !res.data.areaCode))) {  // 如果已经有组织，或无返回值
      this.$router.push('/')  // 返回主页
      if (!['/spread', '/enterpriseUser', '/capital-island'].includes(this.$route.path)) {
        let name = res.data.organizationType === 1 ? '数字科创中心' : ''
        if (!name) name = res.data.labTypeName || '产学研'
        ElMessage.warning(`您已经创建了${name}`)
      }
    }
  },
  watch: {
    async $route(val) {  // 修改路由
      if (val.path !== '/selection') return
      if (val.query.type == 1) {
        this.inputForm(1)
      }
      else if (val.query.type == 2 || val.query.type == 3) {
        this.companyType = false  // 企业类型选择
        let userInfo = getG60LocalInfo()?.memberInfo
        if (!userInfo) {
          this.$router.push('/')
          return
        }
        let res = ''
        if (userInfo.organizationId) res = await memberIsPerfect(userInfo.id)  // 判断是否完善企业信息
        if (res.data) {
          this.$store.dispatch("popup/setPopup", {
            type: 13, // 修改数字科创
            parameter: {},
          });
          return
        }
        else if (res.data === false) {
          this.hasCompany = true
        }
        this.inputForm(val.query.type)  // 企业类型
      }
      else { // 返回则重置
        this.selected = true
        this.choose = 0
        this.companyType = false  // 企业类型选择
      }
    }
  },
  beforeUnmount() {
    // 页面关闭前埋点 TODO_Track: choose === 1 e_create_organization_write_Laboratory_back choose === 2 e_create_organization_write_business_back
  },
  async created() {
    if (this.$route.query.choose) { //从广告那里跳转过来的       注意一下使用企业新接口要改
      this.selected = false
      this.choose = this.$route.query.choose
    }
    this.licenseImgs = []
    if (this.$route.query) {
      if (this.$route.query.type === '1') {
        this.inputForm(1)
        this.getLabTypes()
      }
      else if (this.$route.query.type === '2') {
        let userInfo = getG60LocalInfo()
        if (!userInfo) {
          this.$router.push('/')
          return
        }
        let res = ''
        if (userInfo.organizationId) res = await memberIsPerfect(userInfo.id)  // 判断是否完善企业信息
        if (res.data) {
          this.$store.dispatch("popup/setPopup", {
            type: 13, // 修改数字科创
            parameter: {},
          });
          return
        }
        else if (res.data === false) {
          this.hasCompany = true
        }
        this.inputForm(2)
      }
      else if (this.$route.query.type === '3') {
        // 资本岛选项
        this.inputForm(3)
      }
    }
    this.$store.commit('message/SET_ACTIVENAV', '官网首页')
    this.$store.commit('message/SET_BG', false)
    await this.InitSelect();
    if (this.selected) {
      // this.$message({
      //   message: '请先选择组织',
      //   type: 'warning',
      //   offset: 100
      // });
    }
    this.getFirmType()
  },
  computed: {
    labType() {
      if (this.$route.query.type !== '1' || this.labTypes?.length === 0) return null
      return this.$route.query.addType ? this.labTypes.find(type => type.id + '' === this.$route.query.addType + '') : this.labTypes[0]
    },
    ...mapGetters(['id'])
  },
  methods: {
    visibleChange() {
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    getLabTypes() {
      getOrganizationTypes(0).then(res => {
        this.labTypes = res.data.list
      })
    },
    chooseDict(index, i) { // 单选
      this.newCompanyform[index].val = this.newCompanyform[index].selections[i].id
    },
    handelNumber(index) {  // 数字处理
      let pattern = new RegExp("^[0-9]|-$")
      this.newCompanyform[index].val = Array.from(this.newCompanyform[index].val).filter(v => pattern.test(v)).join("")
    },
    // isDisabled(index){  // 研究机构禁用判断
    //   if(this.newCompanyform?.length === 0) return false
    //   let choose0 = false
    //   this.newCompanyform[4].val.find((item)=>{
    //     if(item === 0){
    //       choose0 = true
    //       return
    //     }
    //   })
    //   if(choose0 && index !== 0){
    //     return true
    //   }
    //   else if(this.newCompanyform[4].val?.length > 0 && !choose0 && index === 0){
    //     return true
    //   }
    //   return false
    // },
    // 搜索方法
    findLabel(val) {
      let flag = false  // 判断是否存在
      let i = -1
      if (this.choose === 1) {  // 如果是创建实验室
        for (let index = 0; index < this.schoolForm.label?.length; index++) {
          if (this.schoolForm.label[index] === val) {
            flag = true
            i = index
            break
          }
        }
        if (flag) {
          this.schoolForm.label[i] += ' '// 在后方加上空格
        }
      }
      else { // 企业方向存在 this.companyLabels 中
        for (let index = 0; index < this.companyLabels?.length; index++) {
          if (this.companyLabels[index] === val) {
            flag = true
            i = index
            break
          }
        }
        if (flag) {
          this.companyLabels[i] += ' ' // 在后方加上空格
        }
      }
    },
    disabledDate(date) {
      return date.getTime() < Date.now() - 8.64e7;
    },
    async selectCompanyType(item) {            // 0: 资本投资 1: 政策 2: 技术需求 3: 园区
      this.loading = true
      // this.directionLabel = this.firmTypeList[type].child[1].val
      switch (item.key) {
        case 0:
          this.directionLabel = '请输入资本投资方向'
          break;
        case 1:
          this.directionLabel = '请输入政策服务方向'
          break;
        case 2: // 线上技术需求 key 为4
          this.directionLabel = '请输入技术需求方向'
          break;
        case 3:
          this.directionLabel = '请输入园区投资方向'
          break;
        default:
          break;
      }
      this.formCompanyType = item.key
      if (item.id == personalTypeId) this.showImgUpload = false  // 个人类型不展示图片上传
      this.companyType = true
      // 新版的企业入驻
      let res = await getOrganizationTemplate(item.id)             // 后端新添加的字段获取
      this.templateVersion = res.data.version // 填入模板版本
      let extraParams = []
      extraParams = res.data.params ? res.data.params.map((item, index) => {
        if (item.type === 'input') {
          let isName = false  // 是否名称输入
          if (item.additionCheck?.fieldName === 'name') {
            isName = true
          }
          if (['园区数量', '园区内企业数量', '研究经费', '研发经费', '科研人员数', '园区企业数量'].indexOf(item.name) !== -1 || item.additionType === 'number') {
            return {
              'isName': isName,
              'notNull': item.notNull,
              'key': index,
              'name': item.name,
              'title': item.title,
              'type': 'inputNumber',
              'nullTip': item.subTitle,
              'id': item.id,
              'val': '',
              'limit': 40
            }
          }
          return {
            'isName': isName,
            'notNull': item.notNull,
            'key': index,
            'name': item.name,
            'title': item.title,
            'type': item.type,
            'nullTip': item.subTitle,
            'id': item.id,
            'val': '',
            'limit': item.additionCheck.maxlength ? item.additionCheck.maxlength : 40
          }
        }
        else if (item.type === 'qidaoIndustry') {
          return {
            'notNull': item.notNull,
            'key': index,
            'name': item.name,
            'title': item.title,
            'type': "industrys",
            'nullTip': item.subTitle,
            'id': item.id,
            'val': this.userIndustryIds,
            'limit': ''
          }
        }
        else if (item.name === '企业研究机构') {
          return {
            'notNull': item.notNull,
            'additionType': item.additionType,
            'key': index,
            'name': item.name,
            'nullTip': item.subTitle,
            'limit': 40,
            'id': item.id,
            'val': '',
            'type': 'organization',
            'subTitle': item.subTitle
          }
        }
        else if (item.name === '园区类型') {
          return {
            'notNull': item.notNull,
            'additionType': item.additionType,
            'key': index,
            'name': item.name,
            'nullTip': item.subTitle,
            'limit': 40,
            'id': item.id,
            'val': '',
            'type': 'gardenType',
            'subTitle': item.subTitle
          }
        }
        else if (item.name === '园区内科员人员数量' || item.name === '园区内科研人员数量') {
          return {
            'notNull': item.notNull,
            'additionType': item.additionType,
            'key': index,
            'name': '园区内科研人员数量',
            'nullTip': item.subTitle,
            'limit': 40,
            'id': item.id,
            'val': '',
            'type': 'gardenNumber',
            'subTitle': item.subTitle
          }
        }
        else if (['是否引入高新企业', '是否需要引入高新企业', '是否需要引入技术专家', '是否需要碳中和培训服务'].indexOf(item.name) !== -1) {
          return {
            'notNull': item.notNull,
            'additionType': item.additionType,
            'key': index,
            'name': item.name,
            'nullTip': item.nullTip,
            'limit': 40,
            'id': item.id,
            'val': '',
            'type': 'twoWaySelect',
            'selections': [],
            'subTitle': item.subTitle
          }
        }
        else {
          return {
            'additionType': item.additionType,
            'notNull': item.notNull,
            'key': item.key,
            'name': item.name,
            'title': item.title,
            'type': item.type,
            'nullTip': item.nullTip,
            'id': item.id,
            'val': '',
            'subTitle': item.subTitle
          }
        }
      }) : []
      this.showImgUpload && extraParams.push({
        'key': extraParams?.length,
        'name': '上传企业营业执照或其他认证信息',
        'nullTip': '请上传企业认证信息',
        'val': '',
        'type': 'multiMedia',
        'subTitle': '请上传企业认证信息'
      })
      extraParams.forEach(async (item) => {
        if (item.name === '企业研究机构' && item.additionType) {
          let requestdata = {
            "limit": 100,
            "offset": 1,
            "type": item.additionType
          }
          let res = await getSelect(requestdata);
          this.organizations = res.data.result
          return
        }
        else if (item.name === '园区类型' && item.additionType) {
          let requestdata = {
            "limit": 100,
            "offset": 1,
            "type": item.additionType
          }
          let res = await getSelect(requestdata);
          this.gardenType = res.data.result
          return
        }
        else if ((item.name === '园区内科员人员数量' || item.name === '园区内科研人员数量') && item.additionType) {
          let requestdata = {
            "limit": 100,
            "offset": 1,
            "type": item.additionType
          }
          let res = await getSelect(requestdata);
          this.gardenNumber = res.data.result
          return
        }
        else if (['是否引入高新企业', '是否需要引入高新企业', '是否需要引入技术专家', '是否需要碳中和培训服务'].indexOf(item.name) !== -1 && item.additionType) {
          let requestdata = {
            "limit": 100,
            "offset": 1,
            "type": item.additionType
          }
          let res = await getSelect(requestdata);
          item.selections = res.data.result
          return
        }
        else if (item.type === 'select' && item.additionType) { // 其他select选项
          let requestdata = {
            "limit": 100,
            "offset": 1,
            "type": item.additionType
          }
          let res = await getSelect(requestdata);
          item.selections = res.data.result
          return
        }
      })
      for (let i = 0; i < extraParams?.length; i++) {
        if (extraParams[i].additionType && (extraParams[i].type === 'qidaoDict')) { // qidaoDict选项获取
          let requestdata = {
            "limit": 100,
            "offset": 1,
            "type": extraParams[i].additionType
          }
          let res = await getSelect(requestdata);
          extraParams[i].selections = res.data.result
        }
      }
      this.newCompanyform = []
      // this.newCompanyform = this.formOrder(this.newCompanyform)
      this.newCompanyform = [...this.newCompanyform, ...extraParams]
      this.$nextTick(() => {
        // 防止崩溃
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      })
      this.loading = false
    },
    formOrder(arr) {
      let newArr = arr
      for (let index = 0; index < newArr?.length; index++) {
        let temp = {}
        if (newArr[index].type === 'input' && newArr[index].name === '企业名称' && index !== 0) {
          temp = newArr[index]
          newArr[index] = newArr[0]
          newArr[0] = temp
        }
        else if (newArr[index].type === 'qidaoAddress' && index !== 1) {
          temp = newArr[index]
          newArr[index] = newArr[1]
          newArr[1] = temp
        }
        else if (newArr[index].type === 'input' && (newArr[index].name === '企业关键词' || newArr[index].name === '企业关键字') && index !== 2) {
          temp = newArr[index]
          newArr[index] = newArr[2]
          newArr[2] = temp
        }
        else if (newArr[index].type === 'textarea' && index !== 3) {
          temp = newArr[index]
          newArr[index] = newArr[3]
          newArr[3] = temp
        }
        else if (newArr[index].type === 'multiMedia' && index !== 4) {
          temp = newArr[index]
          newArr[index] = newArr[4]
          newArr[4] = temp
        }
      }
      return newArr
    },
    handleExceed() { //文件超出个数限制时的钩子
      ElMessage.warning('最多只能上传9张图片～')
    },
    async handleUpload(val) {
      var result;
      if (val.file.type.split('/')[0] === 'image') { // 上传的是图片
        result = await this.directUpload(val.file, uploadTypeImage)
        this.btnDisabled = false
        let temp = result.Location.slice(result.Location.indexOf("/"))
        if (!this.licenseImgs) {
          this.licenseImgs = [temp]
        } else {
          this.licenseImgs.push(...this.licenseImgs, temp)
          this.licenseImgs = Array.from(new Set(this.licenseImgs))
        }
      }
    },
    directUpload(file, uploadType, thumb = false) {
      var self = this
      let cosMap = {
        image: cosByUploadType(uploadTypeImage),
        video: cosByUploadType(uploadTypeVideo)
      }
      let cos = uploadType === uploadTypeImage ? cosMap.image : cosMap.video
      let bucket = uploadType === uploadTypeImage ? bucketMap.image : bucketMap.video
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket, /* 必须 */
            Region: "ap-shanghai",     /* 存储桶所在地域，必须字段 */
            Key: randomFilename(),              /* 必须 */
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              self.btnDisabled = true
              if (thumb) { // 带封面的上传的组件
                self.$refs['upload'].uploadFiles.map((item) => {
                  if (item.raw.uid === file.uid) {
                    item.percentage = parseInt(progressData.percent * 100)
                  }
                })
              } else {
                self.$refs['upload1'].uploadFiles.map((item) => {
                  if (item.raw.uid === file.uid) {
                    item.percentage = parseInt(progressData.percent * 100)
                  }
                })
              }
            }
          },
          function (err, data) {
            if (err) {
              reject(err)
            } else {
              resolve(data)
            }
          }
        );
      })
    },
    async getFirmType() {
      let res = await getSelectFatherSonByType(23)
      this.firmTypeList = res.data
    },
    beforeUpload(file) {
      if (file.type.split('/')[0] === 'video') {
        ElMessage.warning('只能上传图片!')
        this.uploadimgs.pop()
      }
    },
    async loadJsonFromFile(file, fileList) {
      this.uploadimgs = fileList
      console.log('load json from file:', file, fileList);
    },
    showDiaFile(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    deleteVideo(file) {
      let arr = this.$refs['upload1'].uploadFiles
      let index = arr.indexOf(file)
      this.$refs['upload1'].uploadFiles.splice(index, 1);
      this.licenseImgs = this.licenseImgs.filter((item, i) => { return i !== index }) // 也要删除腾讯云直传的名称🌟
      let that = this
      this.btnDisabled = true
      setTimeout(function () {
        that.btnDisabled = false
      }, 1000)
    },
    async uploadmedia() {
      let ans = []
      for (let item in this.uploadimgs) {
        let fd = new FormData()
        fd.append('images', this.uploadimgs[item].raw)
        let res = await uploadimg(fd)
        ans.push(res.data[0])
      }
      return ans;
    },
    async inputForm(item) {
      this.selected = false;
      this.choose = item === 1 ? 1 : 2; // 实验室或企业判断
      this.$router.push({ path: '/selection', query: { type: item, addType: this.$route.query.addType ? this.$route.query.addType : '', point: this.$route.query.point ? this.$route.query.point : '-1' } }) // 修改路由
      if (item === 2 || item === 3) {          // 新版企业入驻 , 上方图片读入也应该在这里面
        this.loadingTypes = true
        try {
          let resCompanyType = await getOrganizationTypes(1) //0 : 实验室 1 : 公司
          this.companyTypes = resCompanyType.data.list.map((item, index) => {         // 获取企业类型
            let params = {
              'name': item.name,
              'id': item.id,
              'image': item.image,  // 选择图
              'des': '',
              'key': index,
              // 'iconImg': staticPath+item.image            // 这个是app端的图片
            }
            switch (item.name) {
              case "资本投资类":
                params.des = '创建资本投资类的企业空间'
                break;
              case "政策服务类":
                params.des = '创建政策服务类的企业空间'
                break;
              case "技术需求类":
                // params.name = '普通类型'
                params.des = '创建技术需求类的企业空间'
                break;
              case "园区招商类":
                params.des = '创建园区招商类的企业空间'
                break;
              default:
                break;
            }
            return params
          })
          let temp = this.companyTypes[2]
          this.companyTypes[2] = this.companyTypes[3]
          this.companyTypes[3] = temp
          this.typeBackgroundImg = this.companyTypes.map((item) => {
            let style = {}
            switch (item.name) {
              case '资本投资类':
                style = {
                  backImg: 'url(' + require('../../assets/images/enterprise-invest.png') + ')',
                  hoverImg: 'url(' + require('../../assets/images/enterprise-invest-hover.png') + ')',
                }
                break;
              case '政策服务类':
                style = {
                  backImg: 'url(' + require('../../assets/images/enterprise-policy.png') + ')',
                  hoverImg: 'url(' + require('../../assets/images/enterprise-policy-hover.png') + ')',
                }
                break;
              case '技术需求类':
                style = {
                  backImg: 'url(' + require('../../assets/images/enterprise-normal.png') + ')',
                  hoverImg: 'url(' + require('../../assets/images/enterprise-normal-hover.png') + ')',
                }
                break;
              case '园区招商类':
                style = {
                  backImg: 'url(' + require('../../assets/images/enterprise-garden.png') + ')',
                  hoverImg: 'url(' + require('../../assets/images/enterprise-garden-hover.png') + ')',
                }
                break;
              default:
                break;
            }
            this.companyTypes = this.companyTypes.filter((type) => {
              return type.name != '普通类型' && type.id !== 500603
            })
            return style
          })
          this.filterCapital(item)  // 资本类型处理
          if (item === 2 && this.$route.query.addType) {
            // 类型选择
            this.companyTypes.find((item) => {
              if (item.id + '' === this.$route.query.addType) {
                this.selectCompanyType(item)
                return true
              }
            })
          }
        } catch (error) {
          console.error('input form error:', error)
        } finally {
          this.loadingTypes = false
        }
      }
      else if (item === 1) {
        this.$nextTick(() => {
          // 防止崩溃
          const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
          Array.from($el).map((item) => item.removeAttribute('aria-owns'));
        })
      }
    },
    filterCapital(item) {  // 1.10.17 资本类型处理
      let i = -1
      let capitalType = null
      this.companyTypes.find((j, index) => {
        if (j.name.indexOf('资本') !== -1) {
          i = index
          return true
        }
      })
      if (i !== -1) { // 删除资本
        capitalType = deepClone(this.companyTypes[i])
        this.capitalType = capitalType  // 保存
        this.companyTypes.splice(i, 1)
      }
      if (item === 3 && capitalType) { // 选择资本类
        this.selectCompanyType(capitalType)
      }
    },
    async InitSelect() {
      // 获取行业list
      // 一共71选项
      let requestdata = {
        "limit": 100,
        "offset": 1,
        "type": 6
      }
      let res = await getSelect(requestdata);
      this.industrylist = res.data.result;
      let newList = []  // 过滤部分选项
      this.industrylist.forEach((item) => {
        if (deleteList.indexOf(item.val) === -1) {
          newList.push(item)
        }
      })
      this.industrylist = newList
      res = await getSelectFatherSonByType(6)
      this.multiIndustry = res.data
      // 获取学历list
      requestdata.type = 4;
      let res1 = await getSelect(requestdata);
      this.educationList = res1.data.result;

      // 获取职称list
      requestdata.type = 10;
      let res2 = await getSelect(requestdata);
      this.jobtitlelist = res2.data.result;

      // 获取人员规模list
      requestdata.type = 1;
      let res3 = await getSelect(requestdata);
      this.humanList = res3.data.result;

      res3 = await getSelectFatherSonByType(90)
      this.positionSelections = res3.data
      res3 = await getSelectFatherSonByType(91)
      this.departmentSelections = res3.data
    },
    async submitLab() {
      if (this.schoolForm.orgIntroduction?.length > 0 && this.schoolForm.orgIntroduction?.length < 10) {
        ElMessage.warning('自我介绍最少输入10个字符!')
        return
      }
      let formValid = true;
      if (this.licenseImgs?.length == 0) {
        ElMessage.warning('请上传企业营业执照或其他认证信息')
        return
      }
      this.$refs['ruleSchool'].validate((valid) => {
        if (!valid) {
          formValid = false;
          return Promise.resolve(false);
        }
      });
      if (!formValid) {
        return false
      }
      // 去除头尾空格后去重
      let arr = this.schoolForm.label.map(item => item.trim())
      let mySet = new Set(arr)
      if (mySet.size != arr?.length) {
        this.schoolForm.label = [...mySet]
        ElMessage.warning('科研方向有重复值,已经去除重复值,请查看后提交')
        return
      }
      Date.prototype.Format = function (fmt) {
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "H+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          "S": this.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1?.length));
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1?.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k])?.length)));
        return fmt;
      }
      this.schoolForm.qualifications = await this.uploadmedia();
      if (this.schoolForm.qualifications?.length === 0) {
        ElMessage.warning('请上传实验室铭牌或教师职工工牌')
        return
      }
      this.loading = true
      this.schoolLocation = this.schoolForm.addressAreaNames
      this.schoolForm.addressAreaId = parseInt(this.schoolLocation[2]);
      this.schoolForm.addressCityId = parseInt(this.schoolLocation[1]);
      this.schoolForm.addressProvinceId = parseInt(this.schoolLocation[0]);
      this.schoolForm.addressAreaName = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[2]
      this.schoolForm.addressCityName = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[1]
      this.schoolForm.addressProvinceName = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[0]
      this.schoolForm.salesmanId = this.id;
      this.schoolForm.signTime = new Date().Format("yyyy-MM-dd HH:mm:ss");
      let userInfos = getG60LocalInfo()
      this.schoolForm.phone = userInfos.memberInfo.phone
      this.schoolForm.unionId = userInfos.memberInfo.phone
      let imgs = JSON.stringify(this.licenseImgs)
      imgs = imgs.replace('[', '')
      imgs = imgs.replace(']', '')
      imgs = imgs.replace(/"/g, '')
      this.schoolForm.license = imgs
      this.loading = true
      this.btnDisabled = true   // 按钮加载
      if (this.labType.id) {
        // 类型ID
        this.schoolForm.labTypeId = this.labType.id
      }
      try {
        await bindLab({...this.schoolForm,createSource: 'G60'})
        this.btnDisabled = false
        this.loading = false
        this.ElMessage.success("创建成功！")
        setTimeout(() => {
          this.$router.push('/user');
        }, 1000)
      } catch (err) {
        this.loading = false
        this.btnDisabled = false  // 按钮加载取消
      }
    },
    async newSubmitCompany() {       // 新的企业入驻方法
      this.submitClicked = false
      let params = {
        "addressAreaId": '',
        "addressAreaName": "",
        "addressCityId": '',
        "addressCityName": "",
        "addressProvinceId": '',
        "addressProvinceName": "",
        "memberId": "",
        "label": [],
        "license": [],
        "name": "",
        "summary": '',
        "params": [],
        "researchFund": '',
        "productName": '',
        "organization": [],
        "industryList": [],
        "scientificResearchCount": '',
        "orgIntroduction": null, // 1.8.4 自我介绍
        "version": -1,  // 模板版本
        "typeId": -1  // 组织机构类型ID 及企业类型 1.7
      }
      if (this.templateVersion !== -1) {
        params.version = this.templateVersion
      }
      params.memberId = getG60Id()
      this.companyTypes.find((item) => {  // 获取企业类型ID
        if (item.key === this.formCompanyType) {
          params.typeId = item.id
          return
        }
      })
      if (params.typeId === -1) {
        params.typeId = this.capitalType.id // 资本岛
      }
      try {
        this.newCompanyform.forEach((item, index) => {
          switch (item.type) {
            case 'qidaoDict': // 单选参数
              params.params.push({
                "parameterId": item.id,
                "val": JSON.stringify(Number(item.val))
              })
              break;
            case 'select':
              params.params.push({
                "parameterId": item.id,
                "val": JSON.stringify(Number(item.val))
              })
              break
            case 'inputNumber':
              if (item.val?.length === 0 && item.notNull === 1) {
                throw new Error('请填写' + item.name + '!')
              }
              else if (item.val?.length === 0 && item.notNull === 0) {
                break
              }
              if (item.name === '研究经费') {
                params.params.push({
                  "parameterId": item.id,
                  "val": JSON.stringify(Number(item.val))
                })
              }
              else if (item.name === '园区内企业数量') {
                params.params.push({
                  "parameterId": item.id,
                  "val": JSON.stringify(Number(item.val))
                })
              }
              else {
                params.params.push({
                  "parameterId": item.id,
                  "val": JSON.stringify(Number(item.val))
                })
              }
              break;
            case 'input':
              if (item.val?.length === 0 && item.notNull === 1) {
                throw new Error('请填写' + item.name + '!')
              }
              else if (item.val?.length === 0 && item.notNull === 0) {
                break
              }
              if (isNaN(item.key)) {
                params.params.push({
                  'parameterId': item.id,
                  'val': item.val
                })
                break;
              }
              if (item.name === '企业名称' || item.name === "姓名") {
                if (/^\d+$/.test(item.val)) {
                  throw new Error(`${item.name}不能为纯数字！`)
                }
                params.name = item.val
                params.params.push({
                  "parameterId": item.id,
                  "val": item.val
                })
              }
              else if (item.name === '企业研发产品名称' || item.name === '企业产品研发名称' || item.name === '企业产品名称') {
                if (/^\d+$/.test(item.val)) {
                  throw new Error('企业产品名称不能为纯数字！')
                }
                params.params.push({
                  "parameterId": item.id,
                  "val": item.val
                })
              }
              else if (item.name === '技术人员研究方向') {
                params.name = item.val
              }
              else if (item.name === '科研人员数') {
                params.memberNum = item.val
              }
              else if (item.name === '园区名称') {
                if (/^\d+$/.test(item.val)) {
                  throw new Error('园区名称不能为纯数字！')
                }
                params.name = item.val
                params.params.push({
                  "parameterId": item.id,
                  "val": item.val
                })
              }
              else if (item.name === '主导产业') {
                params.params.push({
                  "parameterId": item.id,
                  "val": item.val
                })
              }
              else if (item.name === '单位名称') {
                if (/^\d+$/.test(item.val)) {
                  throw new Error('单位名称不能为纯数字！')
                }
                params.name = item.val
                params.params.push({
                  "parameterId": item.id,
                  "val": item.val
                })
              }
              else if (item.name === "企业方向") {
                item.val = this.companyLabels
                if (item.val?.length === 0) {
                  throw new Error('请填写企业方向！')
                }
                else if (item.val?.length > 5) {
                  throw new Error('方向标签不能超过5个！')
                }
                let lengthFlag = false
                item.val.find((item) => {
                  if (item?.length > 40) {
                    lengthFlag = true
                    return
                  }
                })
                if (lengthFlag) {
                  throw new Error('方向标签每个最长为40字符！')
                }
                // 去除头尾空格后去重
                let arr = item.val.map(jtem => jtem.trim())
                let mySet = new Set(arr)
                if (mySet.size != arr?.length) {
                  item.val = [...mySet]
                  this.companyLabels = [...mySet] // 同时修改 this.companyLabels
                  throw new Error('企业方向有重复值,已经去除重复值,请查看后提交')
                }
                params.label = [...item.val]
              }
              else { // 其余输入
                if (item.notNull === 1 && item.val?.length === 0) {
                  throw new Error(`${item.name}不能为空！`)
                }
                if (item.isName === true) { // 名称输入
                  params.name = item.val
                }
                params.params.push({
                  "parameterId": item.id,
                  "val": item.val
                })
              }
              break;
            case 'industrys':
              item.val = this.$refs.industrys?.getResult()?.map(item => item[1]) // 多选行业
              if (item.val?.length === 0 && item.notNull === 1) {
                throw new Error('请选择' + item.name + '!')
              }
              else if (item.val?.length === 0 && item.notNull === 0) {
                break
              }
              params.params.push({
                "parameterId": item.id,
                "val": item.val.join(',')
              })
              break;
            case 'gardenNumber':
              if (item.val?.length === 0 && item.notNull === 1) {
                throw new Error('请选择' + item.name + '!')
              }
              else if (item.val?.length === 0 && item.notNull === 0) {
                break
              }
              params.params.push({
                "parameterId": item.id,
                "val": item.val
              })
              break;
            case 'gardenType':
              if (item.val?.length === 0 && item.notNull === 1) {
                throw new Error('请选择' + item.name + '!')
              }
              else if (item.val?.length === 0 && item.notNull === 0) {
                break
              }
              params.params.push({
                "parameterId": item.id,
                "val": item.val
              })
              break;
            case 'twoWaySelect':
              if (item.val?.length === 0 && item.notNull === 1) {
                throw new Error('请选择' + item.name + '!')
              }
              else if (item.val?.length === 0 && item.notNull === 0) {
                break
              }
              params.params.push({
                "parameterId": item.id,
                "val": item.val
              })
              break;
            case 'organization':
              if (item.val?.length === 0 && item.notNull === 1) {
                throw new Error('请选择' + item.name + '!')
              }
              else if (item.val?.length === 0 && item.notNull === 0) {
                break
              }
              params.params.push({
                "parameterId": item.id,
                "val": JSON.stringify(item.val)
              })
              break;
            case 'qidaoAddress':
              if (isNaN(item.key) && item.name !== '企业地址' && item.name !== '园区地址' && item.name !== '单位地址' && item.name !== "地址") {
                if (item.val?.length === 0) {
                  throw new Error('请选择附加地址！')
                }
                else {
                  let val = {
                    'addressAreaId': item.val[2],
                    'addressCityId': item.val[1],
                    'addressProvinceId': item.val[0],
                    'addressAreaName': this.$refs[`AddrExtra${item.id}`].getCheckedNodes()[0].pathLabels[2],
                    'addressCityName': this.$refs[`AddrExtra${item.id}`].getCheckedNodes()[0].pathLabels[1],
                    'addressProvinceName': this.$refs[`AddrExtra${item.id}`].getCheckedNodes()[0].pathLabels[0],
                  }
                  let temp = {
                    'parameterId': item.id,
                    'val': JSON.stringify(val)
                  }
                  params.params.push(temp)
                  break
                }
              }
              if (this.location?.length === 0) {
                throw new Error('请选择企业地址！')
              }
              else {
                params.addressAreaId = JSON.stringify(parseInt(this.location[2]));
                params.addressCityId = JSON.stringify(parseInt(this.location[1]));
                params.addressProvinceId = JSON.stringify(parseInt(this.location[0]));
                params.addressAreaName = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[2]
                params.addressCityName = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[1]
                params.addressProvinceName = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[0]
                let val = {
                  'addressAreaId': params.addressAreaId,
                  'addressCityId': params.addressCityId,
                  'addressProvinceId': params.addressProvinceId,
                  'addressAreaName': params.addressAreaName,
                  'addressCityName': params.addressCityName,
                  'addressProvinceName': params.addressProvinceName,
                  'addressDetail': params.addressProvinceName + '-' + params.addressCityName + '-' + params.addressAreaName
                }
                params.params.push({
                  "parameterId": item.id,
                  "val": JSON.stringify(val)
                })
              }
              break;
            case 'textarea':
              if (isNaN(item.key)) {
                if (item.val?.length === 0) {
                  throw new Error('请填写附加内容！')
                }
                else if (item.val?.length < 10) {
                  throw new Error('附加内容不能少于10个字符！')
                }
                params.params.push({
                  'parameterId': item.id,
                  'val': item.val
                })
              }
              else if (item.key === 4) {
                if (item.val?.length === 0) {
                  throw new Error('请填写企业介绍！')
                }
                else if (item.val?.length < 10) {
                  throw new Error('企业介绍不能少于10个字符！')
                }
                params.summary = item.val
              }
              break;
            case 'multiMedia': {
              if (this.licenseImgs?.length === 0) {
                throw new Error('请上传企业营业执照或其他认证信息')
              }
              let imgs = JSON.stringify(this.licenseImgs)
              imgs = imgs.replace('[', '')
              imgs = imgs.replace(']', '')
              imgs = imgs.replace(/"/g, '')
              params.license = imgs.split(',')
              break;
            }
            case 'qidaoIndustry': {
              if (item.val?.length === 0 && item.notNull === 1) {
                throw new Error('请选择' + item.name + '!')
              }
              else if (item.val?.length === 0 && item.notNull === 0) {
                break
              }
              params.params.push({
                'parameterId': item.id,
                'val': item.val.toString()
              })
              break;
            }
            case 'picker': {
              if (item.val?.length === 0 && item.notNull === 1) {
                throw new Error('请选择' + item.name + '!')
              }
              else if (item.val?.length === 0 && item.notNull === 0) {
                break
              }
              Date.prototype.Format = function (fmt) {
                var o = {
                  "M+": this.getMonth() + 1, // 月份
                  "d+": this.getDate(), // 日
                  "h+": this.getHours(), // 小时
                  "m+": this.getMinutes(), // 分
                  "s+": this.getSeconds(), // 秒
                  "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
                  "S": this.getMilliseconds() // 毫秒
                };
                if (/(y+)/.test(fmt))
                  fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1?.length));
                for (var k in o)
                  if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1?.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k])?.length)));
                return fmt;
              }
              if (typeof (item.val) == "object") {
                params.params.push({
                  'parameterId': item.id,
                  'val': item.val.Format("yyyy-MM-dd")
                })
              }
              else {
                params.params.push({
                  'parameterId': item.id,
                  'val': item.val.slice(0, 10)
                })
              }
              break;
            }
            default:
              break;
          }
          if (index === 0 && this.showImgUpload) {
            if (this.staffName?.length === 0) {
              throw new Error('请输入您的姓名!')
            }
            else if (!((this.positionId + '')?.length > 0)) {
              throw new Error('请选择您的职位!')
            }
            else if (!((this.departmentId + '')?.length > 0)) {
              throw new Error('请选择您的职位!')
            }
          }
        })
      } catch (error) {
        ElMessage.warning(error.message)
        this.submitClicked = true
        return
      }
      this.loading = true
      try {
        params.params.map((item) => { // 转为字符串
          if (typeof (item.val) !== 'string') {
            item.val = JSON.stringify(item.val)
          }
          return item
        })
        if (!this.showImgUpload) {  // 如果不展示图片上传，上传默认字段
          params.license = ["keqidao"]
          params.companyPositionId = ''
          params.companyDepartmentId = ''
          params.companyStaffName = ''
        }
        else {
          params.companyPositionId = this.positionId
          params.companyDepartmentId = this.departmentId
          params.companyStaffName = this.staffName
        }

        // console.log('text final params:',params);
        if (this.hasCompany) {  // 根据是否有公司情况分别使用接口
          params.id = this.$route.query.id
          params.industryIds = params.industryList
          await autoCompanyAdd(params)
        }
        else {
          await createOrg({...params,createSource: 'G60'});
        }
        this.loading = false
        this.ElMessage.success("创建成功！")
        setTimeout(() => {
          this.$router.push('/user');
        }, 1000)
        this.btnDisabled = false
      } catch (e) {
        this.loading = false
        this.btnDisabled = false
      }
    }
  },

}
</script>
  
<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
}

.contact-container {
  display: flex;
  flex-direction: column;

  .top-bg {
    padding: 60px 0;
    padding-top: 89px;
    width: 100%;
    background: #EAEAEA;
    display: flex;
    justify-content: center;
    align-items: center;

    .selection {
      padding: 30px 78px 0px 78px;
      background: white;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}

.selection-top {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selection-top p {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}

.selection-content {
  padding: 30px 0 60px 0;
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.selection-content-company-type {
  padding: 40px 0;
  padding-top: 0;
  margin-top: 89px;
  width: 881px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.selection-item {
  width: 360px;
  height: 77px;
  background:$--G60-theme;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgb(208, 207, 207);
}

.back1 {
  background: #FFFFFF;
  background-position: 60px 40px;
  background-repeat: no-repeat;
  background-size: 88px 93px;
  background-image: url('../../assets/images/enterprise-invest.png')
}

.back1:hover {
  background-image: url('../../assets/images/enterprise-invest-hover.png')
}

.back2 {
  background: #FFFFFF;
  background-position: 60px 40px;
  background-repeat: no-repeat;
  background-size: 88px 93px;
  background-image: url('../../assets/images/enterprise-policy.png');
  margin-left: 80px;
}

.back2:hover {
  background-image: url('../../assets/images/enterprise-policy-hover.png')
}

.back3 {
  background: #FFFFFF;
  background-position: 60px 40px;
  background-repeat: no-repeat;
  background-size: 88px 93px;
  background-image: url('../../assets/images/enterprise-normal.png')
}

.back3:hover {
  background-image: url('../../assets/images/enterprise-normal-hover.png')
}

.back4 {
  background: #FFFFFF;
  background-position: 60px 40px;
  background-repeat: no-repeat;
  background-size: 88px 93px;
  background-image: url('../../assets/images/enterprise-garden.png');
  margin-left: 80px;
}

.back4:hover {
  background-image: url('../../assets/images/enterprise-garden-hover.png')
}

.selection-item-company-type {
  -webkit-touch-callout: none;
  /*系统默认菜单被禁用*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -khtml-user-select: none;
  /*早期浏览器*/
  -moz-user-select: none;
  /*火狐*/
  -ms-user-select: none;
  /*IE10*/
  user-select: none;
  width: 428px;
  height: 128px;
  margin-bottom: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  color: black;
  font-size: 28px;

  // background-image: var(--backImg);
  .type-container {
    margin: 0 20px;

    img {
      width: 428px;
      height: 128px;
      border-radius: 14px;
      object-fit: cover;
    }

    img:hover {
      box-shadow: 0 0 2px$--G60-theme;
    }

    .type-name {
      font-size: 30px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #EF9B63;
    }

    .type-describe {
      margin-top: 15px;
      font-size: 24px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #ABB3C2;
    }
  }
}

// .selection-item-company-type:hover{
//   background-color: #E89157;
//   // background-image: var(--hoverImg);
//   .type-container{
//     .type-name{
//       color: #FFFFFF;
//     }
//     .type-describe{
//       color: #FFFFFF;
//     }
//   }
// }
.bg-blue {
  background: #25A5F4;
}

.selection-item p {
  color: white;
  font-size: 28px;
}


/* 科研人员表单 */
.option {
  span {
    color: #333;
    font-size: 20px;
    background: #F7F8F9;
    padding: 3px 20px;
    border-radius: 17px;
    margin-right: 20px;
    display: inline-block;
    margin-bottom: 12px;
    cursor: pointer;
  }

  .active {
    color: #fff;
    background: linear-gradient(180deg, #FF9957, #EA650B);
  }
}

.school {
  width: 1000px;
  padding-top: 20px;
  background: white;
  border-radius: 20px;
  overflow: hidden;
}

.school-content {
  width: 100%;
  padding: 50px 180px 30px 115px;
  box-sizing: border-box;

  .item-form {
    margin-bottom: 45px;
  }

  .empty-remind {
    font-size: 16px;
    line-height: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #E71B00;
    padding-left: 20px;
    bottom: 4px;
  }

  .new-company-form {

    // margin-bottom: 10px;
    p {
      display: flex;
      align-items: center;
    }
  }

  p {
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    margin-bottom: 12px;
  }
}

.input-css {
  /*background: #F3F3F3 !important;*/
  width: 100% !important;
}

// .input-css >>> .el-input__inner {
//   background: #F3F3F3 !important;
//   width: 100% !important;
//   border: 0 !important;
// }
.window-btn-company-new {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .el-button {
    cursor: pointer;
    width: 392px;
    height: 68px;
    background:$--G60-theme;
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #FFFFFF;
  }
}

.dict-selections {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  .dict-selection {
    color: #333;
    font-size: 16px;
    background: #F7F8F9;
    padding: 3px 16px;
    border-radius: 20px;
    margin-right: 20px;
    display: inline-block;
    cursor: pointer;
  }

  .dict-active {
    color: #FFFFFF !important;
    background:$--G60-theme !important;
  }
}

.tip {
  font-size: 16px;
  font-weight: bold;
  margin-top: -10px;
}

.tip-small {
  font-size: 14px;
}

.window-btn-company {
  padding-top: 30px;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .el-button {
    cursor: pointer;
    width: 392px;
    height: 68px;
    background: linear-gradient(0deg, #F15F00 0%, #F77B00 100%);
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #FFFFFF;
  }
}

.window-btn {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;

  p {
    margin-bottom: 0;
  }
}

.window-btn div {
  width: 288px;
  height: 68px;
  background:$--G60-theme;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-text {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }
}


/* 企业 */
.company {
  width: 1000px;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .selection-top-new {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 0;

    .first-step {
      font-size: 24px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #ABB3C2;
    }
  }

  .selection-top-new p {
    font-size: 36px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #797979;
  }
}

.company-big {
  width: 1300px;
  background-color: rgba(0, 0, 0, 0);
}

.width360 {
  width: 360px;
}

.icon-delete {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 3px 10px 0 10px;
  top: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
}
</style>