<template>
  <div class="table-0">
    <img src="@/assets/images/G60/forum/circle-1.png" class="circle"/>
    <div class="title">
      下午 科创服务专题沙龙
    </div>
    <div class="semi-part">
      分会场一：“数智化人工智能赋能”科创服务专题沙龙
      <p class="tip">地点：1F路演大厅</p>
    </div>
    <div class="t light">
      <div class="left">
        13:30-14:00
      </div>
      <div class="right">
        <p>参会嘉宾签到</p>
      </div>
    </div>
    <div class="t dark">
      <div class="left">
        14:00-14:10
      </div>
      <div class="right">
        <p>主持人开场</p>
        <p style="margin-top: 20px;">主办方致辞</p>
        <p class="tip">张超 科企岛数字科创平台 联席CEO</p>
      </div>
    </div>
    <div class="t light">
      <div class="left">
        14:10-16:30
      </div>
      <div class="right">
        <p>参会嘉宾主题分享</p>
        <div class="line">
          <p class="tip line-l">分享嘉宾：</p>
          <p class="tip">乔颖异     科企岛（上海）网络技术有限公司COO</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">李悦     东华大学计算机科学与技术学院副教授</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">陈静     上海银行松江新桥支行行长</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">李泰国     恒旨燃生物科技CEO</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">张贵杰     鲲澎（中国）有限公司市场总监</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">王宝栋     杭州未来科技城-梦想小镇沪杭创新中心 副总经理</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">朱宏芳     中慧道企业管理 总经理</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">宋庆勋     G60云智天地市场总经理</p>
        </div>
      </div>
    </div>
    <div class="t dark">
      <div class="left">
        15:30
      </div>
      <div class="right">
        <p>自由交流茶歇</p>
      </div>
    </div>
    <div class="t light">
      <div class="left">
        17:00
      </div>
      <div class="right">
        <p>合影留念、活动落幕</p>
      </div>
    </div>
    <div class="semi-part" style="margin-top: 19px;">
      分会场二：“区域产学研赋能硬科技” 科创服务专题沙龙
      <p class="tip">地点：2F党建中心</p>
    </div>
    <div class="t light">
      <div class="left">
        13:30-14:00
      </div>
      <div class="right">
        <p>参会嘉宾签到</p>
      </div>
    </div>
    <div class="t dark">
      <div class="left">
        14:00-14:10
      </div>
      <div class="right">
        <p>主持人开场</p>
        <p style="margin-top: 20px;">主办方致辞</p>
        <p class="tip">张雪 科企岛数字科创平台执行总裁</p>
      </div>
    </div>
    <div class="t light">
      <div class="left">
        14:10-16:30
      </div>
      <div class="right">
        <p>参会嘉宾主题分享</p>
        <div class="line">
          <p class="tip line-l">分享嘉宾：</p>
          <p class="tip">谭荣夏子     梦想小镇沪杭创新中心运营负责人</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">王延平     中国医药技术协会膳食纤维协会会长</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">郝道欣     东华大学科研处</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">池方     杭州银行上海小微信贷中心副总经理</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">鞠锟     翔悦密封材料有限公司副总经理</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">乔颖异     科企岛（上海）网络技术有限公司COO</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">李泰国     恒旨燃生物科技CEO</p>
        </div>
        <div class="line">
          <p class="tip line-l"></p>
          <p class="tip">朱宏芳     中慧道企业管理 总经理</p>
        </div>
      </div>
    </div>
    <div class="t dark">
      <div class="left">
        15:30
      </div>
      <div class="right">
        <p>自由交流茶歇</p>
      </div>
    </div>
    <div class="t light">
      <div class="left">
        17:00
      </div>
      <div class="right">
        <p>合影留念、活动落幕</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-0{
  width: 100%;
  padding-top: 20px;
  position: relative;
  .semi-part{
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    padding-left: 53px;
    background: linear-gradient( 90deg, #1F4099 0%, #121288 100%);
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 50px;
    text-align: justify;
    font-style: normal;
    .tip{
      margin-top: -10px;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 20px;
    }
  }
  .circle{
    position: absolute;
    left: 26px;
    top: 0;
    width: 70px;
    height: 70px;
    z-index: 1;
  }
  .title{
    width: 100%;
    height: 73px;
    background: linear-gradient( 90deg, #42B4F5 0%, #2354E3 50%, #0AC896 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 28px;
    text-align: justify;
    font-style: normal;
  }
  .light{
    background: linear-gradient( 90deg, #0765C0 0%, #121288 100%);
  }
  .dark{
    background: linear-gradient( 90deg, #1F4099 0%, #121288 100%);
  }
  .t{
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px 20px 54px;
    display: flex;
    justify-content: space-between;
    .left{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
      text-align: justify;
      font-style: normal;
    }
    .right{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 18px;
      font-style: normal;
      width: 320px;
      .line{
        display: flex;
        justify-content: flex-start;
        .line-l{
          width: 60px;
          flex-shrink: 0;
        }
      }
      .tip{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #DDDDDD !important;
        line-height: 16px;
        font-style: normal;
        margin-top: 10px;
      }
    }
  }
}
</style>