<template>
  <div class="container">
    <div class="top-part">
      <div class="logo-line">
        <img src="@/assets/images/incubator/new/logo.png" style="width: 178px;height: 56px;margin-right: 84px;"/>
        <img src="@/assets/images/incubator/new/logo-1.png" style="width: 236px;height: 50px;"/>
      </div>
      <div class="line">
        <p class="p-0">蓝珊瑚硬科技孵化</p>
      </div>
      <p class="p-1">一/站/式/科/研/成/果/转/化/平/台</p>
      <p class="p-2">从科研到市场，提供全生命周期服务，连接科技、资本与产业</p>
      <div class="button-line">
        <button @click="scrollToSmooth('Form')">立即入驻</button>
      </div>
    </div>
    <p class="p-3">蓝珊瑚全生命周期服务</p>
    <div class="options-line">
      <div class="in-line">
        <div class="option" :class="{'active': optionIndex === 0}" @mouseenter="optionIndex=0">
          <div class="icon">
            <img src="@/assets/images/incubator/new/option-0.png" style="width: 43px;height: 51px;margin-bottom: 2px;"/>
          </div>
          <p>项目入驻</p>
        </div>
        <div class="option" :class="{'active': optionIndex === 1}" @mouseenter="optionIndex=1">
          <div class="icon">
            <img src="@/assets/images/incubator/new/option-1.png" style="width: 43px;height: 50px;margin-bottom: 2px;"/>
          </div>
          <p>科研孵化</p>
        </div>
        <div class="option" :class="{'active': optionIndex === 2}" @mouseenter="optionIndex=2">
          <div class="icon">
            <img src="@/assets/images/incubator/new/option-2.png" style="width: 43px;height: 48px;margin-bottom: 5px;"/>
          </div>
          <p>市场加速</p>
        </div>
        <div class="option" :class="{'active': optionIndex === 3}" @mouseenter="optionIndex=3">
          <div class="icon">
            <img src="@/assets/images/incubator/new/option-3.png" style="width: 43px;height: 48px;margin-bottom: 5px;"/>
          </div>
          <p>科创服务</p>
        </div>
        <div class="option" :class="{'active': optionIndex === 4}" @mouseenter="optionIndex=4">
          <div class="icon">
            <img src="@/assets/images/incubator/new/option-4.png" style="width: 43px;height: 48px;margin-bottom: 5px;"/>
          </div>
          <p>产业落地</p>
        </div>
      </div>
      <div class="dark"></div>
    </div>
    <div class="part-0" v-if="optionIndex === 0">
      <div class="inner">
        <div class="content" :class="{'active': contentIndex === 0}" @mouseenter="contentIndex=0">
          <img src="@/assets/images/incubator/new/pic-0.png" style="width: 154px;height: 203px;margin-top: -22px;"/>
          <p class="p-title">项目评估</p>
          <p class="text">从技术创新性、市场潜力、团队能力、预期效益等多个角度出发，对项目进行全面而深入的评估。</p>
        </div>
        <div class="content" :class="{'active': contentIndex === 1}" @mouseenter="contentIndex=1">
          <img src="@/assets/images/incubator/new/pic-1.png" style="width: 178px;height: 202px;margin-top: -24px;"/>
          <p class="p-title">市场分析</p>
          <p class="text">深入挖掘目标市场的潜在需求，分析行业内的竞争格局与未来发展趋势，精准定位项目的市场机会与挑战。</p>
        </div>
        <div class="content" :class="{'active': contentIndex === 2}" @mouseenter="contentIndex=2">
          <img src="@/assets/images/incubator/new/pic-2.png" style="width: 192px;height: 205px;margin-top: -31px;"/>
          <p class="p-title">孵化方案</p>
          <p class="text">根据科技项目具体需求和目标，制定个性化的孵化方案。确保项目的商业逻辑清晰、可行性强。</p>
        </div>
      </div>
    </div>
    <div class="part-1" v-else-if="optionIndex === 1">
      <div class="inner">
        <div class="left">
          <div class="content-0" style="margin-top: 37px;">
            <div class="top-title">
              概念验证
            </div>
            <p class="word">为科研项目团队提供初步的市场和技术可行性分析，协助他们明确研究方向和应用前景。</p>
            <img src="@/assets/images/incubator/new/big-0.png" />
          </div>
          <div class="content-0" style="margin-top: 51px;align-self: flex-end;">
            <div class="top-title">
              中试熟化
            </div>
            <p class="word">提供实验设备、技术支持和专家指导，协助团队完成科研成果的中试和熟化，确保技术的可行性和稳定性。</p>
            <img src="@/assets/images/incubator/new/big-1.png" />
          </div>
          <div class="content-0" style="margin-top: 51px;">
            <div class="top-title">
              科研管理培训
            </div>
            <p class="word">为团队提供科研项目管理培训，包括进度管理、质量管理、风险管理等，提高他们的科研能力。</p>
            <img src="@/assets/images/incubator/new/big-2.png" />
          </div>
        </div>
        <img src="@/assets/images/incubator/new/big-right.png" class="right" />
      </div>
    </div>
    <div class="part-2" v-else-if="optionIndex === 2">
      <div class="inner">
        <div class="content">
          <p class="p-4">项目路演</p>
          <p class="p-5">组织定期的项目路演活动，为硬科技项目提供展示平台，吸引投资目光和促成合作。</p>
        </div>
        <div class="content">
          <p class="p-4">创投对接</p>
          <p class="p-5">与多家投资机构建立合作关系，为优质项目提供创投对接机会，协助团队获得资金支持。</p>
        </div>
        <div class="content">
          <p class="p-4">商业策划指导</p>
          <p class="p-5">提供商业计划书撰写指导，帮助团队明确商业模式、市场定位和发展战略。</p>
        </div>
      </div>
    </div>
    <div class="part-3" v-else-if="optionIndex === 3">
      <div class="inner">
        <p class="p-6">享受一站式服务涵盖工商税务咨询、政策申报指导、知识产权保护、企业运营咨询、人才服务培养、创业辅导以及技术研发支持等，为项目团队提供全面的创业支持。</p>
        <div class="contents">
          <div class="content m-t-36">
            <img src="@/assets/images/incubator/new/service-0.png" style="width: 118px;height: 111px;margin-bottom: 15px;"/>
            <p class="p-7 bottom-line-0">工商税务</p>
          </div>
          <div class="content">
            <img src="@/assets/images/incubator/new/service-1.png" style="width: 120px;height: 114px;margin-bottom: 13px;"/>
            <p class="p-7 bottom-line-1">知识产权</p>
          </div>
          <div class="content">
            <img src="@/assets/images/incubator/new/service-2.png" style="width: 120px;height: 112px;margin-bottom: 14px;"/>
            <p class="p-7 bottom-line-1">政策申报</p>
          </div>
          <div class="content m-t-44">
            <img src="@/assets/images/incubator/new/service-3.png" style="width: 120px;height: 118px;margin-bottom: 14px;"/>
            <p class="p-7 bottom-line-3">创业辅导</p>
          </div>
        </div>
        <img src="@/assets/images/incubator/new/service-big.png" class="bottom"/>
      </div>
    </div>
    <div class="part-4" v-else-if="optionIndex === 4">
      <div class="inner">
        <div class="content back-0">
          <p class="p-8">产业链资源整合</p>
          <p class="p-9">整合产业链上下游资源，为项目团队提供原材料供应、生产制造、市场推广等全方位支持。</p>
        </div>
        <div class="content back-1">
          <p class="p-8">产业园区对接</p>
          <p class="p-9">与多个产业园区建立合作关系，为项目提供落地和产业化的空间和支持。</p>
        </div>
        <div class="content back-2">
          <p class="p-8">政府招商对接</p>
          <p class="p-9">与政府招商部门保持密切沟通，推动项目与政府招商需求的高效对接，帮助项目方实现线下产业落地和获得政策扶持。</p>
        </div>
      </div>
    </div>
    <div class="part-5">
      <div class="inner">
        <p class="title">项目入驻流程</p>
        <div class="line">
          <div class="progress m-r-20">
            <img src="@/assets/images/incubator/new/num-1.png" style="width: 36px;height: 27px;margin-right: -16px;z-index: 20;"/>
            <p class="p-10">提交项目入驻</p>
            <img src="@/assets/images/incubator/new/arrow-right.png" class="arrow"/>
          </div>
          <div class="progress m-r-20">
            <img src="@/assets/images/incubator/new/num-2.png" style="width: 41px;height: 27px;margin-right: -20px;z-index: 20;"/>
            <p class="p-10">专业审核评估</p>
            <img src="@/assets/images/incubator/new/arrow-right.png" class="arrow"/>
          </div>
          <div class="progress m-r-20">
            <img src="@/assets/images/incubator/new/num-3.png" style="width: 40px;height: 27px;margin-right: -20px;z-index: 20;"/>
            <p class="p-10">通过项目审核</p>
            <img src="@/assets/images/incubator/new/arrow-right.png" class="arrow"/>
          </div>
          <div class="progress m-r-20">
            <img src="@/assets/images/incubator/new/num-4.png" style="width: 42px;height: 27px;margin-right: -20px;z-index: 20;"/>
            <p class="p-10">签订入孵协议</p>
            <img src="@/assets/images/incubator/new/arrow-right.png" class="arrow"/>
          </div>
          <div class="progress">
            <img src="@/assets/images/incubator/new/num-5.png" style="width: 40px;height: 27px;margin-right: -20px;z-index: 20;"/>
            <p class="p-10">享受全方位服务</p>
          </div>
        </div>
      </div>
    </div>
    <div class="introduction">
      <div class="inner">
        <p class="title">蓝珊瑚硬科技孵化加速器介绍</p>
        <div class="intro">
          <div class="left">
            <p class="p-11" style="margin-bottom: 40px;">一站式服务体系，全方位链接，提供全生命周期服务</p>
            <p class="p-12" style="margin-bottom: 25px;">蓝珊瑚硬科技孵化加速器是科企岛自运营的科技成果转化平台，深度融合了线上与线下的优势资源，打造全方位、一站式的服务体系，是科研项目从孵化到市场加速的全流程伙伴。无论是初期的概念验证、中试熟化，还是后期的市场推广、创投对接，都能提供专业的指导和支持，帮助项目团队跨越从科研到市场的鸿沟。</p>
            <p class="p-12" style="margin-bottom: 25px;">蓝珊瑚硬科技孵化加速器积极整合各方资源，为项目团队提供与资本市场对接，寻找合适的投资人和合作伙伴；与多个产业园区建立紧密的合作关系，为项目提供落地和产业化的空间和支持；此外，与政府共同推动科技创新政策的制定和实施，为项目团队创造更加有利的外部环境。</p>
            <p class="p-11">蓝珊瑚硬科技孵化加速器已经成为了一个的全方位、多维度的科技创新和成果转化服务平台。</p>
          </div>
          <div class="right">
            <img src="@/assets/images/incubator/new/bottom-back.png" />
          </div>
        </div>
        <div class="about">
          About us
        </div>
      </div>
    </div>
    <div class="form-title">
      <div class="title">
        <p class="p-13">蓝珊瑚硬科技孵化加速器</p>
        <p class="p-13">—连接科技、资本与产业，共创未来创新生态！</p>
      </div>
      <div class="object">
        <p class="p-14">入驻对象</p>
        <p class="p-15">- 项目方：拥有创新科研成果和项目的科研团队入驻。</p>
        <p class="p-15">- 资本方：投资机构、天使投资人等资本方入驻，寻找潜力项目。</p>
        <p class="p-15">- 产业方：产业园区、孵化器、政策服务机构、政府等产业方入驻，共同推动产业创新发展</p>
      </div>
    </div>
    <div class="form" id="Form">
      <div class="inner">
        <div class="form-option">
          <div class="option" :class="{'active': formIndex === 0}" @click="formIndex=0">
            项目方入驻
          </div>
          <div class="option" :class="{'active': formIndex === 1}" @click="formIndex=1">
            资本方入驻
          </div>
          <div class="option" :class="{'active': formIndex === 2}" @click="formIndex=2">
            产业方入驻
          </div>
        </div>
        <div v-if="formIndex === 0">
          <Form0></Form0>
        </div>
        <div v-else-if="formIndex === 1">
          <Form1></Form1>
        </div>
        <div v-else-if="formIndex === 2">
          <Form2></Form2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form0 from './components/Form0.vue';
import Form1 from './components/Form1.vue';
import Form2 from './components/Form2.vue';

export default{
  components:{
    Form0,
    Form1,
    Form2,
  },
  data(){
    return{
      optionIndex: 0,
      contentIndex: 0,
      formIndex: 0,
    }
  },
  methods:{
    scrollToSmooth(eId){
      if(!eId) return
      const dom = document.getElementById(eId)
      if(!dom) return
      document.scrollingElement.scrollTo({
        top: dom.offsetTop - 60,
        behavior: "smooth",
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.container{
  background: #f0f2f5;
  padding-bottom: 85px;
  scroll-behavior: smooth;
  background: #091747;
  .top-part{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 69px 0 105px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/06/09/1720514261459003');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .logo-line{
      display: flex;
      align-items: center;
      width: 1200px;
      justify-content: flex-start;
    }
    .line{
      display: flex;
      align-items: center;
      padding-bottom: 22px;
      .p-0{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 60px;
        color: #FFFFFF;
        line-height: 72px;
        padding-top: 130px;
        padding-right: 226px;
        padding-bottom: 5px;
        background-image: url('../../assets/images/incubator/new/title.png');
        background-size: 260px 82px;
        background-position: right bottom;
        background-repeat: no-repeat;
      }
    }
    .p-1{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 28px;
      color: #FFFFFF;
      line-height: 40px;
      letter-spacing: 12px;
      padding-bottom: 40px;
    }
    .p-2{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 28px;
      padding-bottom: 66px;
    }
    .button-line{
      display: flex;
      justify-content: center;
      button{
        width: 211px;
        height: 50px;
        background: linear-gradient( 50deg, #FFECD6 0%, #EDC896 100%);
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #000000;
        border: none;
        cursor: pointer;
      }
    }
  }
  .p-3{
    width: 100%;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 56px;
    padding: 48px 0;
  }
  .options-line{
    width: 100%;
    background: #2B3BB8;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 6px;
    .in-line{
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .option{
        width: 200px;
        height: 102px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding-top: 8px;
        .icon{
          width: 43px;
          height: 51px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #FFFFFF;
          line-height: 25px;
        }
      }
      .active{
        background: linear-gradient( 90deg, #FFEBD6 0%, #ECC795 100%);
        border-radius: 8px;
        position: relative;
        p{
          color: #111111 !important;
        }
        &::after{
          content: '';
          width: 200px;
          height: 6px;
          background: linear-gradient( 90deg, #FCEEDC 0%, #ECC796 100%);
          position: absolute;
          bottom: -23px;
          left: 0;
        }
      }
    }
    .dark{
      width: 100%;
      background: #091747;
      height: 17px;
    }
  }
  .part-0{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 105px;
    padding-bottom: 80px;
    .inner{
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .active{
        background: #2B3BB8 !important;
        .p-title{
          background: linear-gradient( 90deg, #2B3BB8 0%, #5264EE 49%, #2B3BB8 100%) !important;
        }
      }
      .content{
        width: 376px;
        height: 325px;
        background: #273478;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .p-title{
          width: 226px;
          height: 34px;
          background: linear-gradient( 90deg, #273478 0%, #1B4EA7 49%, #273478 100%);
          opacity: 0.99;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #FFFFFF;
          line-height: 34px;
          text-align: center;
          margin-top: -38px;
        }
        .text{
          width: 319px;
          height: 48px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 24px;
          margin-top: 27px;
        }
      }
    }
  }
  .part-1{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 80px;
    .inner{
      width: 1200px;
      display: flex;
      justify-content: space-between;
      padding-top: 56px;
      .left{
        width: 563px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .content-0{
          width: 450px;
          height: 156px;
          border-radius: 4px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: url('../../assets/images/incubator/new/par-new.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .top-title{
            position: absolute;
            left: 26px;
            top: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 36px;
            text-align: center;
            width: 117px;
            height: 36px;
            white-space: nowrap;
          }
          .word{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 24px;
            text-align: left;
            font-style: normal;
            width: 281px;
            margin-top: 30px;
          }
          img{
            position: absolute;
            right: -38px;
            top: -11px;
            width: 86px;
            height: 94px;
          }
        }
      }
      .right{
        width: 568px;
        height: 555px;
        margin-top: 4px;
      }
    }
  }
  .part-2{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 88px;
    padding-bottom: 80px;
    .inner{
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .content{
        background-image: url('../../assets/images/incubator/new/speed.png');
        background-size: 514px 312px;
        background-repeat: no-repeat;
        background-position: bottom center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 514px;
        height: 312px;
        .p-4{
          width: 250px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 20px;
          color: #60C7FF;
          line-height: 24px;
          padding-bottom: 40px;
        }
        .p-5{
          width: 250px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
          line-height: 24px;
        }
      }
    }
  }
  .part-3{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 97px;
    padding-bottom: 80px;
    .inner{
      width: 1027px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .p-6{
        width: 877px;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
        padding-bottom: 60px;
      }
      .contents{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .content{
          width: 226px;
          display: flex;
          align-items: center;
          flex-direction: column;
          .p-7{
            width: 100%;
            height: 34px;
            background: linear-gradient( 90deg, #091747 0%, #013698 49%, #091747 100%);
            opacity: 0.99;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #60C7FF;
            line-height: 34px;
            text-align: center;
          }
          .bottom-line-0{
            position: relative;
            &::before{
              content: '';
              position: absolute;
              bottom: -6px;
              left: 50%;
              margin-left: -2.5px;
              width: 5px;
              height: 5px;
              background: #65B5E0;
              border-radius: 50%;
            }
            &::after{
              content: '';
              position: absolute;
              bottom: -138px;
              margin-left: -0.5px;
              left: 50%;
              width: 180px;
              height: 132px;
              border-left: 1px solid #65B5E0;
              border-bottom: 1px solid #65B5E0;
              z-index: 1;
            }
          }
          .bottom-line-1{
            position: relative;
            &::before{
              content: '';
              position: absolute;
              bottom: -6px;
              left: 50%;
              margin-left: -2.5px;
              width: 5px;
              height: 5px;
              background: #65B5E0;
              border-radius: 50%;
            }
            &::after{
              content: '';
              position: absolute;
              bottom: -138px;
              margin-left: -0.5px;
              left: 50%;
              width: 180px;
              height: 132px;
              border-left: 1px solid #65B5E0;
              z-index: 1;
            }
          }
          .bottom-line-3{
            position: relative;
            &::before{
              content: '';
              position: absolute;
              bottom: -6px;
              right: 50%;
              margin-right: -2.5px;
              width: 5px;
              height: 5px;
              background: #65B5E0;
              border-radius: 50%;
            }
            &::after{
              content: '';
              position: absolute;
              bottom: -138px;
              margin-right: -0.5px;
              right: 50%;
              width: 180px;
              height: 132px;
              z-index: 1;
              border-right: 1px solid #65B5E0;
              border-bottom: 1px solid #65B5E0;
            }
          }
        }
      }
      .bottom{
        margin-top: 37px;
        width: 562px;
        height: 411px;
        z-index: 10;
      }
      .m-t-36{
        margin-top: 36px;
      }
      .m-t-44{
        margin-top: 44px;
      }
    }
  }
  .part-4{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
    .inner{
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .content{
        width: 374px;
        height: 252px;
        box-sizing: border-box;
        padding-left: 24px;
        padding-top: 30px;
        .p-8{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 20px;
          color: #60C7FF;
          line-height: 20px;
          margin-bottom: 30px;
        }
        .p-9{
          width: 172px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 27px;
        }
      }
      .back-0{
        background-image: url('../../assets/images/incubator/new/in-0.png');
        background-size: 100%;
        background-position: top center;
        background-repeat: no-repeat;
      }
      .back-1{
        background-image: url('../../assets/images/incubator/new/in-1.png');
        background-size: 100%;
        background-position: top center;
        background-repeat: no-repeat;
        padding-left: 55px !important;
      }
      .back-2{
        padding-left: 55px !important;
        background-image: url('../../assets/images/incubator/new/in-2.png');
        background-size: 100%;
        background-position: top center;
        background-repeat: no-repeat;
      }
    }
  }
  .part-5{
    width: 100%;
    height: 351px;
    background-image: url('../../assets/images/incubator/new/progress-line.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    .inner{
      width: 1200px;
      box-sizing: border-box;
      padding-top: 69px;
      .title{
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 36px;
        color: #FFFFFF;
        line-height: 50px;
        margin-bottom: 47px;
      }
      .line{
        display: flex;
        align-items: center;
        .m-r-20{
          margin-right: 20px;
        }
        .progress{
          display: flex;
          align-items: center;
          position: relative;
        }
        .p-10{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 62px;
          text-align: center;
          height: 62px;
          background: #1C4A9C;
          border: 1px solid #03FCFC;
          z-index: 10;
          padding: 0 28px
        }
        .arrow{
          width: 77px;
          height: 88px;
          margin-left: -36px;
          z-index: 20;
        }
      }
    }
  }
  .introduction{
    width: 100%;
    padding-top: 137px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .inner{
      width: 1200px;
      .title{
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 36px;
        color: #FFFFFF;
        line-height: 50px;
        padding-bottom: 80px;
      }
      .intro{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .right{
          padding-top: 60px;
          img{
            width: 500px;
            height: 506px;
          }
        }
        .left{
          width: 637px;
          .p-11{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 30px;
            width: 100%;
          }
          .p-12{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 30px;
            width: 100%;
          }
        }
      }
      .about{
        width: 1560px;
        height: 131px;
        background: #2B3BB8;
        box-sizing: border-box;
        padding-left: 127px;
        display: flex;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 34px;
        color: #FFFFFF;
        line-height: 48px;
        margin-top: -134px;
      }
    }
  }
  .form-title{
    width: 100%;
    height: 589px;
    background-image: url('../../assets/images/incubator/new/form-top.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      height: 105px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 36px;
      color: #FFFFFF;
      line-height: 35px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 76px;
      .p-13{
        text-align: center;
      }
    }
    .object{
      height: 126px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      .p-14{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 18px;
        text-align: center;
      }
      .p-15{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 18px;
        text-align: center;
      }
    }
  }
  .form{
    width: 100%;
    display: flex;
    justify-content: center;
    .inner{
      width: 948px;
      background: #FFFFFF;
      border-radius: 8px;
      padding-bottom: 53px;
      .form-option{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: -18px;
        padding-bottom: 27px;
        .option{
          width: 150px;
          height: 44px;
          background: #0A38C7;
          border-radius: 8px;
          cursor: pointer;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 44px;
          text-align: center;
          margin: 0 22px;
        }
        .active{
          background: linear-gradient( 90deg, #FFEBD6 0%, #ECC795 100%) !important;
          color: #333333 !important;
        }
      }
    }
  }
}
</style>