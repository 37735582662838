<template>
  <div class="form-container">
    <div class="title-line">
      项目基础信息
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>项目名称：</p>
        <el-input v-model="form.projectName" class="input" placeholder="请填写项目一句话简介，最多输入30字" @change="saveLocalForm" :maxlength="30" />
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>所属行业：</p>
        <MultiSelection :selections="multiIndustry" :placeholder="'请选择项目所属行业，最多3个'" :max="3" :ref="'industrys'" class="no-wrap" :value="form?.industry?.join(',')" @sendValue="sendValue" v-if="!reInit"></MultiSelection>
      </div>
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>所属单位：</p>
        <el-input :maxlength="30" v-model="form.belong" class="input" placeholder="请输入所属单位全称，如“北京大学化学与分子工程学院”" @change="saveLocalForm"/>
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>所在地：</p>
        <el-cascader ref="cascaderAddr" :props="props" style="width: 100%;" :placeholder="'请选择项目所在地'" @visible-change="visibleChange" @change="visibleChangeNew"
        @expand-change="visibleChange" v-model="form.address"></el-cascader>
      </div>
    </div>
    <div class="title-line">
      联系人信息
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>姓名：</p>
        <el-input :maxlength="30" v-model="form.name" class="input" placeholder="请填写您的姓名或称呼" @change="saveLocalForm"/>
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>职位：</p>
        <el-input v-model="form.position" class="input" placeholder="请填写您的职务" @change="saveLocalForm"/>
      </div>
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>联系方式：</p>
        <el-input :maxlength="11" v-model="form.phone" class="input" placeholder="请填写您的手机号"  @input="handelNumber"/>
      </div>
    </div>
    <div class="title-line">
      融资需求
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>是否需要融资：</p>
        <div class="selections">
          <div class="selection m-r-70" @click="changeOption('needPrice',true)">
            <img src="@/assets/images/incubator/new/selected.png" v-if="form.needPrice" />
            <img src="@/assets/images/incubator/new/select.png" v-else />
            <p>是</p>
          </div>
          <div class="selection" @click="changeOption('needPrice',false)">
            <img src="@/assets/images/incubator/new/selected.png" v-if="!form.needPrice" />
            <img src="@/assets/images/incubator/new/select.png" v-else />
            <p>否</p>
          </div>
        </div>
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>融资轮次：</p>
        <el-select v-model="form.turn" placeholder="请选择融资轮次" @change="saveLocalForm" class="input" :disabled="!form.needPrice">
          <el-option
            v-for="(item,index) in turnList"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="item-line">
      <div class="item">
        <div class="right-top-option" @click="changeOption('noPrice','-1')" v-if="form.needPrice">
          <img src="@/assets/images/incubator/new/not-need.png" v-if="!form.noPrice"/>
          <img src="@/assets/images/incubator/new/need.png" v-else/>
          <p>暂不透露</p>
        </div>
        <p class="item-title"><span>*</span>融资金额（单位万）：</p>
        <el-input :maxlength="30" v-model="form.price" class="input has-right" placeholder="请输入融资金额" @change="saveLocalForm" :disabled="!form.needPrice"/>
        <div class="input-right">
          <el-select v-model="form.priceType" @change="saveLocalForm" :disabled="!form.needPrice">
            <el-option
              v-for="(item,index) in ['人民币','美元']"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item">
        <div class="right-top-option" @click="changeOption('noPercent','-1')" v-if="form.needPrice">
          <img src="@/assets/images/incubator/new/not-need.png" v-if="!form.noPercent"/>
          <img src="@/assets/images/incubator/new/need.png" v-else/>
          <p>暂不透露</p>
        </div>
        <p class="item-title"><span>*</span>出让比例：</p>
        <el-input :maxlength="3" v-model="form.percent" class="input has-right" placeholder="请填写愿意的出让比例" @input="handelPercent" :disabled="!form.needPrice"/>
        <div class="input-right-word" :style="`${!form.needPrice ? 'color: #cccccc;' : ''}`">
          %
        </div>
      </div>
    </div>
    <div class="title-line">
      项目介绍（选填）
    </div>
    <div class="item-line">
      <div class="line">
        <div class="left-word">
          <p class="item-title">项目基本资料：</p>
          <p class="p-16">*上传商业计划书有利于更全面地展示项目，提高评估通过率，增加合作和投资机会。</p>
        </div>
        <div class="upload">
          <el-upload
            action="#"
            :accept="'.pdf,.ppt,.pptx'"
            v-model:file-list="form.file.files"
            :http-request="handleUpload"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :before-upload="beforeUploadFile"
            class="my-upload"
            :limit="1">
            <div class="upload-button">
              <img src="@/assets/images/incubator/new/upload.png" />
              <p>上传文件</p>
            </div>
          </el-upload>
        </div>
      </div>
    </div>
    <div class="item-line">
      <div class="line">
        <p class="item-title">项目基本资料：</p>
        <el-input :maxlength="500" type="textarea" resize="none" :rows="4" v-model="form.introduction" class="input" placeholder="请填写项目基本资料，方便投资人了解项目情况，如项目当前情况、项目投资亮点等" @input="saveLocalForm" show-word-limit/>
      </div>
    </div>
    <div class="button-line">
      <button @click="submit" v-loading="loading">确认提交</button>
    </div>
  </div>
</template>

<script>
import MultiSelection from '@/components/MultiSelection/MultiSelection';
import { getSelectFatherSonByType } from "@/api/config";
import { getprovince, getcity, getarea } from "@/api/demand";
import { ElMessage, } from 'element-plus';
import { requestVersion,uploadUserPhone } from "@/utils/consts";
import { uploadTypeFile,cosByUploadType, } from "@/api/upload";
import { randomFilename } from "@/utils/time";
import { bucketMap } from "@/utils/consts";
import { login } from "@/api/user";
import { activityRegister } from "@/api/activity";

export default{
  components:{
    MultiSelection,
  },
  data(){
    return{
      reInit: false,
      multiIndustry: [],  // 行业多选
      turnList: [
        '种子轮','天使轮','Pre-A','A轮','A+轮','B轮','B+轮','C轮','C+轮','D轮','E轮及以后','Pre-IPO','并购','战略投资','其他轮次'
      ],
      loading: false,
      uploading: false,
      form:{
        projectName: '',
        industry: [],
        belong: '',
        address: [],
        name: '',
        position: '',
        phone: '',
        needPrice: true,
        turn: '',
        price: '',
        priceType: '人民币',
        noPrice: false,
        percent: '',
        noPercent: false,
        file: {
          result: '',
          files: [],
        },
        introduction: '',
      },
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then(response => {
                const cities = []
                for (let item of response.data) {
                  cities.push({
                    value: item.provinceCode,
                    label: item.provinceName,
                    leaf: false
                  })
                }
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getcity(node.value).then(response => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.cityCode,
                    label: item.cityName,
                    leaf: false
                  })
                }
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.value).then(response => {
                const areas = []
                for (let item of response.data) {
                  areas.push({
                    value: item.areaCode,
                    label: item.areaName,
                    leaf: true
                  })
                }
                resolve(areas);
              });
            }
          }, 100);
        }
      },
    }
  },
  created(){
    this.getSelections()
  },
  mounted(){
    this.loadLocalForm()
  },
  methods:{
    submit(){
      if(this.uploading){
        ElMessage.warning('正在上传文件，请稍后')
        return
      }
      else if(this.loading){
        return
      }
      else if(!this.form.projectName){
        ElMessage.warning('请填写项目一句话简介，最多输入30字')
        return
      }
      else if(this.form.industry.length === 0){
        ElMessage.warning('请选择项目所属行业，最多3个')
        return
      }
      else if(!this.form.belong){
        ElMessage.warning('请输入所属单位全称，如“北京大学化学与分子工程学院”')
        return
      }
      else if(this.form.address.length === 0){
        ElMessage.warning('请选择项目所在地')
        return
      }
      else if(!this.form.name){
        ElMessage.warning('请填写您的姓名或称呼')
        return
      }
      else if(!this.form.position){
        ElMessage.warning('请填写您的职务')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请填写您的手机号')
        return
      }
      else if(this.form.needPrice && !this.form.turn){
        ElMessage.warning('请选择融资轮次')
        return
      }
      else if(this.form.needPrice && !this.form.price){
        ElMessage.warning('请输入融资金额')
        return
      }
      else if(this.form.needPrice && !this.form.percent){
        ElMessage.warning('请填写愿意的出让比例')
        return
      }
      this.loading = true
      activityRegister({
        activityName: '蓝珊瑚项目入驻',
        activityType: '45',
        code: '123',
        isCreateMember: false,
        contactWay: this.form.phone,
        companyName: this.form.belong,
        name: this.form.name,
        position: this.form.position,
        jsonStr: JSON.stringify({
          "项目名称": this.form.projectName,
          "所属行业": this.form.industry.map((item)=>item[1] || item[0]).join('，'),
          "所属单位": this.form.belong,
          "所在地": this.$refs[`cascaderAddr`].getCheckedNodes()[0].pathLabels[0] + this.$refs[`cascaderAddr`].getCheckedNodes()[0].pathLabels[1] + this.$refs[`cascaderAddr`].getCheckedNodes()[0].pathLabels[2],
          "是否需要融资": this.form.needPrice ? '是' : '否',
          "融资轮次": this.form.turn,
          "融资金额": this.form.price + '万' + this.form.priceType,
          "融资金额是否透露": this.form.noPrice ? '否' : '是',
          "出让比例": this.form.percent + '%',
          "出让比例是否透露": this.form.noPercent ? '否' : '是',
          "商业计划书": this.form.file.result,
          "项目基本资料": this.form.introduction,
        }),
      }).then(()=>{
        ElMessage.success('提交成功')
        this.clearForm()
        this.clearLocalForm()
      }).finally(()=>{
        this.loading = false
      })
    },
    handleExceed(){
      ElMessage.warning('最多只能上传1个文件')
    },
    beforeUploadFile(file){
      // 上传文件
      if(file.size > 50 * 1024 * 1024){
        // 大小判断 50M
        ElMessage.warning('上传文件大小过大，不能超过50M!')
        return false
      }
      else if(file.name.length > 70){    // 如果超过70个字符)
        ElMessage.warning('文件名称过长，请修改后再上传!')
        return false
      }
    },
    directUploadToTen(file){
      let self = this
      let cos = cosByUploadType(uploadTypeFile)
      let bucket = bucketMap.file
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket,
            Region: "ap-shanghai",
            Key: randomFilename(),
            StorageClass: "STANDARD",
            Body: file,
            onProgress: function (progressData) {
              const percentage = parseInt(progressData.percent * 100);
              if (percentage === 100){
                self.uploading = false;
                ElMessage.success('上传成功!')
              }
              else self.uploading = true
            },
          },
          function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
    handleUpload(val){
      // 先登录
      login({
        machineCode: '',
        version: requestVersion,
        canal: 1,
        type: 0,
        code: '006688',
        phone: uploadUserPhone,
      }).then(res=>{
        localStorage.setItem('IncubatorTempLogin',JSON.stringify(res.data))
        this.directUploadToTen(val.file).then(res=>{
          this.form.file.result = 'https://' + res.Location
          this.form.file.files = [{
            name: val.file.name,
            url: 'https://' + res.Location,
          },]
          this.saveLocalForm()
        }).finally(()=>{
          localStorage.removeItem('IncubatorTempLogin')
        })
      })
    },
    handleRemove(){
      this.form.file.files = []
      this.form.file.result = ''
      this.saveLocalForm()
    },
    changeOption(key,val){
      if(val === '-1'){
        this.form[key] = !this.form[key]
      }
      else{
        this.form[key] = val
      }
      if(key === 'needPrice' && !val){
        this.form.turn = ''
        this.form.price = ''
        this.form.priceType = '人民币'
        this.form.noPrice = false
        this.form.percent = ''
        this.form.noPercent = false
      }
      this.saveLocalForm()
    },
    handelNumber(){  // 数字处理
      let pattern = new RegExp("^[0-9]$")
      this.form.phone = Array.from(this.form.phone).filter(v => pattern.test(v)).join("")
      this.saveLocalForm()
    },
    handelPercent(){  // 数字处理
      let pattern = new RegExp("^[0-9]$")
      this.form.percent = Array.from(this.form.percent).filter(v => pattern.test(v)).join("")
      this.saveLocalForm()
    },
    visibleChangeNew(){
      this.saveLocalForm()
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    sendValue(val){
      this.form.industry = val
      this.saveLocalForm()
    },
    getSelections(){
      getSelectFatherSonByType(6).then(res=>{
        this.multiIndustry = res.data.map((item)=>{
          item.child = item.child?.map((jtem)=>{
            jtem.id = jtem.val
            return jtem
          })
          item.id = item.val
          return item
        })
      })
    },
    clearForm(){
      this.form = {
        projectName: '',
        industry: [],
        belong: '',
        address: [],
        name: '',
        position: '',
        phone: '',
        needPrice: true,
        turn: '',
        price: '',
        priceType: '人民币',
        noPrice: false,
        percent: '',
        noPercent: false,
        file: {
          result: '',
          files: [],
        },
        introduction: '',
      }
      this.reInit = true
      setTimeout(()=>{
        this.reInit = false
      },300)
    },
    saveLocalForm(){
      let form = {}
      if(localStorage.getItem('IncubatorForm')){
        form = JSON.parse(localStorage.getItem('IncubatorForm'))
      }
      form.form0 = this.form
      localStorage.setItem('IncubatorForm',JSON.stringify(form))
    },
    clearLocalForm(){
      let form = JSON.parse(localStorage.getItem('IncubatorForm'))
      delete form.form0
      localStorage.setItem('IncubatorForm',JSON.stringify(form))
    },
    loadLocalForm(){
      let form = localStorage.getItem('IncubatorForm')
      if(!form) return
      form = JSON.parse(form)
      if(!form.form0) return
      this.form = form.form0
    },
  }
}
</script>

<style lang="scss" scoped>
.form-container{
  width: 100%;
  .title-line{
    margin: 9px 0;
    height: 40px;
    border-left: 4px solid #0A38C7;
    background: #F3F3F3;
    box-sizing: border-box;
    padding-left: 41px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 40px;
  }
  .item-line{
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 45px;
    .line{
      width: 100%;
      margin: 8px 0;
      position: relative;
      .upload{
        padding-top: 10px;
        padding-bottom: 10px;
        .my-upload{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        .upload-button{
          border: 1px solid #CBCBCB;
          box-sizing: border-box;
          padding: 9px 10px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #111111;
          line-height: 14px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          img{
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }
        }
        :deep(.el-upload-list){
          width: 100%;
          .el-icon-close-tip{
            display: none;
          }
          .el-upload-list__item{
            transition:none !important;
            -webkit-transition:nonne !important;
          }
          .el-upload-list__item-name{
            transition:none !important;
            -webkit-transition:nonne !important;
          }
          .el-upload-list__item-status-label{
            top: 5px;
          }
          .el-upload-list__item-name{
            padding-top: 5px;
            padding-bottom: 5px;
          }
          .el-icon-close{
            top: 10px;
          }
        }
      }
      .left-word{
        position: absolute;
        left: 0;
        top: 0;
        .p-16{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #0A38C7;
          line-height: 16px;
          margin-top: 8px;
        }
      }
      .item-title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 16px;
        margin-bottom: 13px;
        span{
          color: #ED0900;
        }
      }
      .input{
        width: 100%;
      }
    }
    .item{
      width: 400px;
      margin: 9px 0;
      position: relative;
      .right-top-option{
        display: flex;
        position: absolute;
        right: 0;
        top: 5px;
        align-items: center;
        cursor: pointer;
        img{
          width: 21px;
          height: 13px;
          margin-right: 4px;
        }
        p{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #111111;
          line-height: 14px;
        }
      }
      .has-right{
        :deep(.el-input__inner){
          padding-right: 100px;
          box-sizing: border-box;
        }
      }
      .input-right-word{
        position: absolute;
        bottom: 11px;
        right: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 14px;
      }
      .input-right{
        position: absolute;
        bottom: 1px;
        right: 1px;
        :deep(.el-input__inner){
          width: 90px;
          border: none;
          height: 38px;
        }
      }
      .selections{
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        .m-r-70{
          margin-right: 70px;
        }
        .selection{
          display: flex;
          align-items: center;
          cursor: pointer;
          img{
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
          p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #111111;
            line-height: 16px;
          }
        }
      }
      .item-title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 16px;
        margin-bottom: 13px;
        span{
          color: #ED0900;
        }
      }
      .input{
        width: 100%;
      }
      .no-wrap{
        :deep(.el-cascader__tags){
          flex-wrap: nowrap !important;
          overflow: hidden;
        }
      }
    }
  }
  .button-line{
    padding-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    button{
      width: 225px;
      height: 36px;
      background: #0A38C7;
      border-radius: 3px;
      cursor: pointer;
      border: none;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
    }
  }
}
</style>