<template>
  <div class="box">
    <!-- <div class="cell-label" @click="labelClick" style="cursor: pointer" v-if="label !== ''">{{ label }}</div> -->
    <div class="cell-list">
      <div class="cell-item" v-for="num in length" :key="num"
        :class="[num === step ? 'cell-item-active' : 'cell-item-inActive']" ref="cellItems"
        @mousedown="preventBlur($event)" @click="clickCell(num)">
        {{ charArr[num - 1] }}
      </div>
    </div>
    <div class="box-right">
      <!-- <el-button type="primary" slot="append" icon="el-icon-refresh" @mousedown="preventBlur($event)"
        @click="clearVal">重置</el-button>
      <el-button type="primary" slot="append" icon="el-icon-refresh" @mousedown="preventBlur($event)"
        @click="clearVal">发送校验码</el-button> -->
    </div>
    <!-- 键位面板 -->
    <div class="key-panel" v-if="keyPanel">
      <div v-for="(list, key) in keyPanel" :key="key">
        <section v-if="key != 'props'" class="key-list"
          v-show="step > 0 && keyPanel.props && keyPanel.props[step - 1] &&
            (key == keyPanel.props[step - 1] || (typeof keyPanel.props[step - 1] == 'object' && keyPanel.props[step - 1].values && (keyPanel.props[step - 1].values.includes(charArr[keyPanel.props[step - 1].index]) ? keyPanel.props[step - 1].prop : keyPanel.props[step - 1].else) == key))">
          <div class="key-item" v-for="(item, index) in list" :key="key + index" :ref="key + index" @click="pickOn(item)"
            @mouseenter="hoverKeyItem(key, index)" @mouseout="leaveKeyItem(key)" @mousedown="preventBlur($event)">{{ item
            }}</div>
        </section>
      </div>
    </div>
    <el-input ref="inputField" style="position:absolute;opacity:0;width:300px!important;z-index:-2;" v-model="val"
      @input="inputChange" @blur="inputBlur" :disabled="disabled" @keydown="keyDownEvent" @paste="userPaste"></el-input>
  </div>
</template>

<script>
export default {
  name: 'CellInputFiled',
  props: {
    label: {
      type: String,
      required: true
    },
    length: {
      type: Number,
      required: true
    },
    valueName: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    keyPanel: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      val: '',
      charArr: [],
      // 光标位
      step: 0
    }
  },
  watch: {
    valueName: {
      immediate: true, // 页面初始化时执行
      handler(val) {
        this.val = val
        for (let i = val.length; i < this.length; i++) {
          this.val += ' '
        }
        this.charArr = this.val.split('')
      }
    }
  },
  mounted() { },
  methods: {
    userPaste(e){
      let clipboardData = e.clipboardData || window.clipboardData;
      let pastedData = clipboardData.getData('text');
      this.val = pastedData // 粘贴暂存
    },
    preventBlur(e) {
      // 防止输入框失焦
      e.preventDefault()
    },

    labelClick() {
      console.log(this.val, this.charArr, this.step);
      this.$refs.inputField.focus();
      this.step = 1;
    },

    // 输入框输入事件
    inputChange() {
      let step = this.step,
        length = this.length,
        val = this.val;
      // 退格删除操作
      if (val.length < length) {
        if (step > 0) {
          this.charArr[step - 1] = ' '
          this.val = this.charArr.join('')
          this.$emit('callBack', this.val)
          this.step -= 1
          if (this.step < 1) this.step = 1
        }
        return
      }
      // 判断是否允许输入
      if (this.keyPanel && this.keyPanel.props[step - 1]) {
        return;
      }
      // 替换操作
      this.val = val.substring(0, step - 1) + val.substring(length, length + 1) + val.substring(step, length)
      this.val = this.val.toUpperCase()
      this.$emit('callBack', this.val)
      if (step < length) {
        this.step += 1
      } else if (this.val.indexOf(' ') >= 0) {
        this.step = this.val.indexOf(' ') + 1
      }
    },
    // 面板选取事件
    pickOn(item) {
      let step = this.step,
        length = this.length
      this.charArr[this.step - 1] = item
      this.val = this.charArr.join('')
      this.$emit('callBack', this.val)
      if (step < length) {
        this.step += 1
      } else if (this.val.indexOf(' ') >= 0) {
        this.step = this.val.indexOf(' ') + 1
      }
    },
    leaveKeyItem(key) {
      const keylist = this.keyPanel[key];
      keylist.forEach((item, i) => {
        this.$refs[key + i][0].classList.remove('adjacent-left', 'adjacent-right', 'adjacent-top', 'adjacent-bottom', 'adjacent-left-top', 'adjacent-right-top', 'adjacent-left-bottom', 'adjacent-right-bottom')
      })
    },
    hoverKeyItem(key, index) {
      const keylist = this.keyPanel[key];
      let x = index % 6, y = Math.floor(index / 6);
      let lastY = Math.floor(keylist.length / 6), lastX = keylist.length % 6 - 1;
      keylist.forEach((item, i) => {
        this.$refs[key + i][0].classList.remove('adjacent-left', 'adjacent-right', 'adjacent-top', 'adjacent-bottom', 'adjacent-left-top', 'adjacent-right-top', 'adjacent-left-bottom', 'adjacent-right-bottom')
      })
      if (x > 0) { // 左
        this.$refs[key + (y * 6 + (x - 1))][0].classList.add('adjacent-left');
        if (y > 0) { // 左上
          this.$refs[key + ((y - 1) * 6 + (x - 1))][0].classList.add('adjacent-left-top');
        }
        if (y < lastY - 1 || (y != lastY && x - 1 <= lastX)) { // 左下
          this.$refs[key + ((y + 1) * 6 + (x - 1))][0].classList.add('adjacent-left-bottom');
        }
      }
      if (x < 5) { // 右
        if (y != lastY || x + 1 <= lastX) {
          this.$refs[key + (y * 6 + (x + 1))][0].classList.add('adjacent-right');
        }
        if (y > 0) { // 右上
          this.$refs[key + ((y - 1) * 6 + (x + 1))][0].classList.add('adjacent-right-top');
        }
        if (y < lastY - 1 || (y != lastY && x + 1 <= lastX)) { // 右下
          this.$refs[key + ((y + 1) * 6 + (x + 1))][0].classList.add('adjacent-right-bottom');
        }
      }
      if (y > 0) { // 上
        this.$refs[key + ((y - 1) * 6 + x)][0].classList.add('adjacent-top');
      }
      if (y < lastY) { // 下
        console.log(x, y)
        if (y < lastY - 1 || x <= lastX) {
          this.$refs[key + ((y + 1) * 6 + x)][0].classList.add('adjacent-bottom');
        }
      }
    },
    // 键盘按下事件
    keyDownEvent(event) {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          this.step = 1;
          break
        case 'ArrowRight':
          event.preventDefault();
          this.step = Math.min(this.step + 1, this.length);
          break
        case 'ArrowDown':
          event.preventDefault();
          this.step = this.length;
          break
        case 'ArrowLeft':
          event.preventDefault();
          this.step = Math.max(this.step - 1, 1);
          break
        default:
          break
      }
    },

    clickCell(step) {
      this.step = step
      this.$refs.inputField.focus()
    },

    // 重置
    clearVal() {
      this.$emit('callBack', '')
      this.$refs.inputField.focus()
    },

    // 输入框失焦校验
    inputBlur() {
      let succ = true;
      if (this.required) {
        setTimeout(() => {
          this.$refs.cellItems.forEach((cellItem, index) => {
            cellItem.classList.remove('cell-required');
            if (!this.charArr[index] || this.charArr[index] === ' ') {
              cellItem.classList.add('cell-required')
              succ = false;
            }
          })
          if (succ) {
            this.$emit('validate', this.val);
          } else {
            this.$message.warning('请填写完整')
          }
        }, 100)
      } else {
        for (let index in this.charArr) {
          if (!this.charArr[index] || this.charArr[index] === ' ') {
            succ = false;
            break;
          }
        }
        if (succ) {
          this.$emit('validate', this.val);
        } else {
          this.$message.warning('请填写完整')
        }
      }
      this.step = 0;
    },
  }
}
</script>
 
<style lang="scss" scoped>
.box {
  position: relative;
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 18px;

  .cell-label {
    flex-shrink: 0;
    padding: 0 5px;
  }

  .cell-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 2px;

    .cell-item {
      border: rgb(31, 31, 31) 1px solid;
      padding: 5px;
      text-align: center;
      margin: 3px;
      min-width: 60px;
      min-height: 60px;
      box-sizing: border-box;
      border-radius: 5px;
      cursor: pointer;
      line-height: 48px;
      background: #F7F7F7;
      font-size: 28px;
      color: #333333;
    }

    .cell-item-active {
      /* x 偏移量 | y 偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
      // box-shadow: 2px 2px 2px 1px rgba(79, 79, 80, 0.712);
      // -moz-box-shadow: 2px 2px 2px 1px rgba(79, 79, 80, 0.712);
      // -webkit-box-shadow: 2px 2px 2px 1px rgba(79, 79, 80, 0.712);
      transform: scale(1.1);
      transition: all 0.2s;
      border: rgb(27, 149, 219) 1px solid;
    }

    .cell-item-inActive {
      border: rgba(80, 79, 79, 0.2) 1px solid;
      // cursor: not-allowed;
    }

    .cell-required {
      border: solid red 1px !important;
    }
  }

  .box-right {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;

    .el-button+.el-button {
      margin-left: 0 !important;
    }
  }

  .key-panel {
    position: absolute;
    z-index: 2;
    top: 100%;
    width: 100%;

    .key-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.3em;
      background: rgba(36, 36, 36, 0.9);
      border: 1px solid rgba(239, 239, 239);
      max-width: calc(47px * 6 + 1.2em + 12px); // 盒子宽 + 外边距 + 边框宽

      .key-item {
        position: relative;
        display: inline-block;
        white-space: nowrap;
        cursor: pointer;
        color: rgb(239, 239, 239);
        text-align: center;
        box-sizing: border-box;
        // border-radius: 5px;
        min-width: 47px;
        max-width: 47px;
        padding: 0.3em;
        font-size: 20px;
        margin: 0.1em;
        transition: all 0.3s;
        font-weight: 500;
        border: 2px solid transparent;
      }

      .key-item:hover {
        border-color: rgb(239, 239, 239);
      }

      // border-image是css3属性所以存在兼容性问题，需要在属性前面设置-webkit等
      .adjacent-left {
        border-image: linear-gradient(to left, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -webkit-border-image: linear-gradient(to left, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -o-border-image: linear-gradient(to left, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -moz-border-image: linear-gradient(to left, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        border-image-slice: 10%;
      }

      .adjacent-right {
        border-image: linear-gradient(to right, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -webkit-border-image: linear-gradient(to right, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -o-border-image: linear-gradient(to right, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -moz-border-image: linear-gradient(to bottom, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        border-image-slice: 10%;
      }

      .adjacent-top {
        border-image: linear-gradient(to top, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -webkit-border-image: linear-gradient(to top, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -o-border-image: linear-gradient(to top, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -moz-border-image: linear-gradient(to bottom, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        border-image-slice: 10%;
      }

      .adjacent-bottom {
        border-image: linear-gradient(to bottom, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -webkit-border-image: linear-gradient(to bottom, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -o-border-image: linear-gradient(to bottom, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        -moz-border-image: linear-gradient(to bottom, rgba(239, 239, 239, 0.4) 0%, rgba(239, 239, 239, 0.2) 75%, transparent 90%);
        border-image-slice: 10%;
      }

      .adjacent-left-top {
        border-image: linear-gradient(to left top, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -webkit-border-image: linear-gradient(to left top, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -o-border-image: linear-gradient(to left top, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -moz-border-image: linear-gradient(to bottom, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        border-image-slice: 10%;
      }

      .adjacent-right-top {
        border-image: linear-gradient(to right top, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -webkit-border-image: linear-gradient(to right top, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -o-border-image: linear-gradient(to right top, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -moz-border-image: linear-gradient(to bottom, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        border-image-slice: 10%;
      }

      .adjacent-left-bottom {
        border-image: linear-gradient(to left bottom, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -webkit-border-image: linear-gradient(to left bottom, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -o-border-image: linear-gradient(to left bottom, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -moz-border-image: linear-gradient(to bottom, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        border-image-slice: 10%;
      }

      .adjacent-right-bottom {
        border-image: linear-gradient(to right bottom, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -webkit-border-image: linear-gradient(to right bottom, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -o-border-image: linear-gradient(to right bottom, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        -moz-border-image: linear-gradient(to bottom, rgba(239, 239, 239, 0.5) 0%, rgba(239, 239, 239, 0.1) 50%, transparent 80%);
        border-image-slice: 10%;
      }
    }
  }
}</style>