<template>
  <div class="content">
    <div class="types">
      <div class="type" :class="{'active':typeCur === 0}" @mouseenter="changeType(0)">
        <img src="@/assets/images/post-line/home/hot-news.png" class="icon" />
        <p>热点</p>
      </div>
      <div class="type" :class="{'active':typeCur === 1}" @mouseenter="changeType(1)">
        <p>商业</p>
      </div>
      <div class="type" :class="{'active':typeCur === 2}" @mouseenter="changeType(2)">
        <p>科技</p>
      </div>
      <div class="type" :class="{'active':typeCur === 3}" @mouseenter="changeType(3)">
        <p>财经</p>
      </div>
      <div class="type" :class="{'active':typeCur === 4}" @mouseenter="changeType(4)">
        <p>科研</p>
      </div>
    </div>
    <div class="b-container">
      <div class="left">
        <div class="thumb">
          <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
        </div>
        <div>
          <a class="text-otl-ell a-title">Q3调味品盘点：行业根基稳固，健康或成发展趋势Q3调味品盘点：行业根基稳固，健康或成发展趋势</a>
        </div>
        <p class="context text-otl-ell">新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼</p>
        <div class="publish-info">
          <div class="publisher">
            <div class="avatar">
              <Image :src="'https://person.zju.edu.cn/person/attachments/2021-03/0311030441-1279116452.jpg?imageMogr2/thumbnail/!100p'"></Image>
            </div>
            <div><a class="text-ol-ell a-name">小编辑小编辑小编辑小编辑小编辑</a></div>
            <p class="time">3小时前</p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="card" v-for="item in 4" :key="item">
          <div class="thumb">
            <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
          </div>
          <div class="infos">
            <div><a class="text-otl-ell a-title">4万字回顾｜2023电商复盘、2024经4万字回顾｜2023电商复盘、2024经4万字回顾｜2023电商复盘、2024经</a></div>
            <p class="context text-ol-ell">新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼</p>
            <div class="bottom">
              <p class="time">3小时前</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-0">
      <div class="left" v-for="item in 3" :key="item">
        <div class="thumb">
          <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
        </div>
        <div>
          <a class="text-ol-ell a-title">Q3调味品盘点：行业根基稳固，健康或成发展趋势Q3调味品盘点：行业根基稳固，健康或成发展趋势</a>
        </div>
        <p class="context text-otl-ell">新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼</p>
        <div class="publish-info">
          <div class="publisher">
            <div class="avatar">
              <Image :src="'https://person.zju.edu.cn/person/attachments/2021-03/0311030441-1279116452.jpg?imageMogr2/thumbnail/!100p'"></Image>
            </div>
            <div><a class="text-ol-ell a-name">小编辑小编辑小编辑小编辑小编辑</a></div>
            <p class="time">3小时前</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-1">
      <div class="left" v-for="item in 2" :key="item">
        <div class="thumb">
          <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
        </div>
        <div>
          <a class="text-otl-ell a-title">Q3调味品盘点：行业根基稳固，健康或成发展趋势Q3调味品盘点：行业根基稳固，健康或成发展趋势</a>
        </div>
        <p class="context text-otl-ell">新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼</p>
        <div class="publish-info">
          <div class="publisher">
            <div class="avatar">
              <Image :src="'https://person.zju.edu.cn/person/attachments/2021-03/0311030441-1279116452.jpg?imageMogr2/thumbnail/!100p'"></Image>
            </div>
            <div><a class="text-ol-ell a-name">小编辑小编辑小编辑小编辑小编辑</a></div>
            <p class="time">3小时前</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-2">
      <div class="left">
        <p class="title">科技视讯</p>
        <p class="tip">开启知识之门，获取最新技术资讯。</p>
      </div>
      <button class="button">查看全部 >></button>
    </div>
    <div class="section-3">
      <div class="left" v-for="item in 6" :key="item">
        <div class="thumb">
          <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
        </div>
        <div class="info-content">
          <div>
            <a class="text-otl-ell a-title">Q3调味品盘点：行业根基稳固，健康或成发展趋势Q3调味品盘点：行业根基稳固，健康或成发展趋势</a>
          </div>
          <div class="publish-info">
            <div class="publisher">
              <div class="avatar">
                <Image :src="'https://person.zju.edu.cn/person/attachments/2021-03/0311030441-1279116452.jpg?imageMogr2/thumbnail/!100p'"></Image>
              </div>
              <div><a class="text-ol-ell a-name">小编辑小编辑小编辑小编辑小编辑</a></div>
              <p class="time">3小时前</p>
            </div>
            <div class="play-num">
              <img src="@/assets/images/post-line/home/play-num.png" class="icon" />
              <p class="num text-ol-ell">322</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return{
      typeCur: 0,
    }
  },
  methods:{
    changeType(type){
      this.typeCur = type
    }
  }
}
</script>


<style lang="scss" scoped>
.content{
  width: 866px;
  padding-top: 60px;
  .types{
    display: flex;
    align-items: center;
    .type{
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon{
        width: 18px;
        height: 18px;
        margin-right: 3px;
      }
      p{
        margin-right: 25px;
        width: 36px;
        height: 25px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 25px;
      }
    }
    .active{
      p{
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 25px;
      }
    }
  }
  .b-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 26px;
    .left{
      width: 404px;
      .thumb{
        cursor: pointer;
        margin-top: 20px;
        width: 100%;
        height: 286px;
        margin-bottom: 18px;
      }
      .a-title{
        width: 100%;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 24px;
        height: 48px;
        cursor: pointer;
        &:hover{
          color: $--G60-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .context{
        margin-top: 12px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        height: 48px;
        max-width: 100%;
        margin-bottom: 12px;
      }
      .publish-info{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .publisher{
          display: flex;
          align-items: center;
          .avatar{
            width: 18px;
            height: 18px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 6px;
          }
          .a-name{
            display: block;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            max-width: 200px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #666666;
            }
          }
          .time{
            margin-left: 14px;
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
        .infos{
          display: flex;
          align-items: center;
          .icon{
            margin-left: 12px;
            width: 16px;
            height: 16px;
          }
          p{
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
            margin-left: 3px;
            max-width: 100px;
          }
        }
      }
    }
    .right{
      width: 440px;
      .card{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .thumb{
          cursor: pointer;
          width: 150px;
          height: 100px;
        }
        .infos{
          width: 273px;
          padding-top: 4px;
          .a-title{
            display: block;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 20px;
            height: 40px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #111111;
            }
          }
          .context{
            margin-top: 12px;
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 12px;
            max-width: 100%;
            margin-bottom: 12px;
          }
          .bottom{
            width: 100%;
            display: flex;
            align-items: center;
            .time{
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 12px;
            }
            .icon{
              width: 16px;
              height: 16px;
              margin-left: 30px;
            }
            .num{
              max-width: 50px;
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 12px;
              margin-left: 1px;
            }
          }
        }
      }
    }
  }
  .section-0{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 33px;
    .left{
      width: 280px;
      .thumb{
        cursor: pointer;
        margin-top: 20px;
        width: 100%;
        height: 170px;
        margin-bottom: 10px;
      }
      .a-title{
        display: block;
        width: 100%;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 24px;
        cursor: pointer;
        &:hover{
          color: $--G60-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .context{
        margin-top: 12px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        height: 48px;
        max-width: 100%;
        margin-bottom: 10px;
      }
      .publish-info{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .publisher{
          display: flex;
          align-items: center;
          .avatar{
            width: 18px;
            height: 18px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 6px;
          }
          .a-name{
            display: block;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            max-width: 150px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #666666;
            }
          }
          .time{
            margin-left: 14px;
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
        .infos{
          display: flex;
          align-items: center;
          .icon{
            margin-left: 12px;
            width: 16px;
            height: 16px;
          }
          p{
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
            margin-left: 3px;
            max-width: 100px;
          }
        }
      }
    }
  }
  .section-1{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 54px;
    .left{
      width: 420px;
      .thumb{
        cursor: pointer;
        margin-top: 20px;
        width: 100%;
        height: 170px;
        margin-bottom: 22px;
      }
      .a-title{
        display: block;
        width: 100%;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 24px;
        height: 48px;
        cursor: pointer;
        &:hover{
          color: $--G60-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .context{
        margin-top: 12px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        height: 48px;
        max-width: 100%;
        margin-bottom: 10px;
      }
      .publish-info{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .publisher{
          display: flex;
          align-items: center;
          .avatar{
            width: 18px;
            height: 18px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 6px;
          }
          .a-name{
            display: block;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            max-width: 150px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #666666;
            }
          }
          .time{
            margin-left: 14px;
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
        .infos{
          display: flex;
          align-items: center;
          .icon{
            margin-left: 12px;
            width: 16px;
            height: 16px;
          }
          p{
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
            margin-left: 3px;
            max-width: 100px;
          }
        }
      }
    }
  }
  .section-2{
    width: 100%;
    height: 90px;
    box-sizing: border-box;
    padding: 24px 18px 20px 24px;
    background: linear-gradient(90deg, #FBCD89 0%, #FDDFBA 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    .left{
      .title{
        font-size: 22px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #744907;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .tip{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #744907;
        line-height: 14px;
      }
    }
    .button{
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #744907;
      line-height: 14px;
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }
  .section-3{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left{
      width: 280px;
      .thumb{
        cursor: pointer;
        margin-top: 20px;
        width: 100%;
        height: 170px;
        margin-bottom: 10px;
      }
      .info-content{
        box-sizing: border-box;
        padding: 0 16px 17px;
        border: 1px solid #EBEBEB;
        border-top: none;
      }
      .a-title{
        width: 100%;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 24px;
        height: 48px;
        cursor: pointer;
        &:hover{
          color: $--G60-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .publish-info{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
        .publisher{
          display: flex;
          align-items: center;
          .avatar{
            width: 18px;
            height: 18px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 6px;
          }
          .a-name{
            display: block;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            max-width: 100px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #666666;
            }
          }
          .time{
            margin-left: 14px;
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
        .infos{
          display: flex;
          align-items: center;
          .icon{
            margin-left: 12px;
            width: 16px;
            height: 16px;
          }
          p{
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
            margin-left: 3px;
            max-width: 100px;
          }
        }
        .play-num{
          display: flex;
          align-items: center;
          .icon{
            width: 14px;
            height: 14px;
            margin-right: 3px;
          }
          .num{
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
      }
    }
  }
}
</style>