<template>
  <div class="container">
    <div class="part top">
      <div class="content">
        <p class="title">资讯中心</p>
        <p class="word">科技引领创新，资讯照亮未来<br/>科企投条为您呈现最新的科技资讯、商业动态、用户声音、专家观点</p>
      </div>
    </div>
    <div class="part">
      <Recommend></Recommend>
    </div>
    <div class="part">
      <Latest></Latest>
    </div>
    <div class="part">
      <all-news></all-news>
    </div>
  </div>
</template>

<script>
import Recommend from './components/info/Recommend.vue';
import Latest from './components/info/Latest.vue';
import AllNews from '@/views/post-line/components/info/AllNews'

export default{
  components:{
    AllNews,
    Latest,
    Recommend,
  }
}
</script>


<style scoped lang="scss">
.container{
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 200px;
  .part{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .top{
    background-image: url("../../assets/images/post-line/info/info-back.png");
    background-size: 100% 100%;
    height: 250px;
    box-sizing: border-box;
    padding: 50px 0 0;
    .content{
      width: 1200px;
      box-sizing: border-box;
      padding: 0 70px;
      .title{
        font-size: 36px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 50px;
        margin-bottom: 34px;
      }
      .word{
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 22px;
      }
    }
  }
}
</style>