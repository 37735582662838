<template>
  <div class="content">
    <div class="part-0">
      <img src="@/assets/images/post-line/home/login-t.png" class="title"/>
      <div class="line">
        <img src="@/assets/images/post-line/home/face-0.png" class="face" />
        <p class="word">定制推送更精准</p>
      </div>
      <div class="line">
        <img src="@/assets/images/post-line/home/face-1.png" class="face" />
        <p class="word">评论互动更有趣</p>
      </div>
      <div class="line" style="margin-bottom: 24px;">
        <img src="@/assets/images/post-line/home/face-2.png" class="face" />
        <p class="word">创作分享更自由</p>
      </div>
      <button class="button">立即登录</button>
    </div>
    <div class="part-1">
      <img src="@/assets/images/post-line/home/login-t.png" class="title"/>
      <div class="line">
        <p class="word">定制推送更精准</p>
      </div>
      <div class="line">
        <p class="word">评论互动更有趣</p>
      </div>
      <div class="line" style="margin-bottom: 17px;">
        <p class="word">创作分享更自由</p>
      </div>
      <button class="button">立即入驻</button>
    </div>
    <div class="part-2">
      <div class="title">
        <img src="@/assets/images/post-line/home/icon-0.png" class="icon" />
        <p>科技微讯</p>
      </div>
      <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p?imageMogr2/thumbnail/!100p" class="post"/>
      <div class="wrap-container">
        <div class="card" v-for="item in 10" :key="item">
          <img src="@/assets/images/post-line/home/f-quotation.png" class="f-quotation" />
          <img src="@/assets/images/post-line/home/b-quotation.png" class="b-quotation" />
          <p class="words text-ofl-ell">腾讯控股的大股东、南非Naspers集团荷兰子公司Prosus披露最新业绩报告，数据显示，公司截至9月底的上半财年合并营收为25.6亿美元，同比…腾讯控股的大股东、南非Naspers集团荷兰子公司Prosus披露最新业绩报告，数据显示，公司截至9月底的上半财年合并营收为25.6亿美元，同比…</p>
          <div class="publisher">
            <div class="line"></div>
            <div class="avatar">
              <Image :src="'https://person.zju.edu.cn/person/attachments/2021-03/0311030441-1279116452.jpg?imageMogr2/thumbnail/!100p'"></Image>
            </div>
            <div><a class="text-ol-ell a-name">小编辑小编辑小编辑小编辑小编辑</a></div>
          </div>
          <div class="infos">
            <p class="time text-ol-ell">3小时前</p>
            <div class="flex-cc">
              <div class="num">
                <img src="@/assets/images/post-line/home/thumb.png" class="icon" />
                <p class="text-ol-ell">322</p>
              </div>
              <div class="num">
                <img src="@/assets/images/post-line/home/comment.png" class="icon" />
                <p class="text-ol-ell">322</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button">
        <button>查看更多</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content{
  width: 320px;
  padding-top: 60px;
  .part-0{
    width: 100%;
    height: 240px;
    margin-bottom: 12px;
    background-image: url('../../../../assets/images/post-line/home/login-b.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      width: 189px;
      height: 47px;
      margin-bottom: 20px;
    }
    .line{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .face{
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      .word{
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 22px;
      }
    }
    .button{
      background-color: transparent;
      width: 137px;
      height: 30px;
      border: 1px solid #F2BF5C;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #9D8012;
      cursor: pointer;
    }
  }
  .part-1{
    width: 100%;
    height: 240px;
    margin-bottom: 12px;
    background-image: url('../../../../assets/images/post-line/home/set-b.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      width: 170px;
      height: 49px;
      margin-bottom: 20px;
    }
    .line{
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .face{
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      .word{
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 22px;
      }
    }
    .button{
      background-color: transparent;
      width: 137px;
      height: 30px;
      border: 1px solid #FF7373;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FF5454;
      cursor: pointer;
    }
  }
  .part-2{
    width: 100%;
    padding-top: 30px;
    .title{
      display: flex;
      align-items: flex-end;
      margin-bottom: 16px;
      .icon{
        width: 20px;
        height: 20px;
        margin-right: 11px;
      }
      p{
        font-size: 24px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 24px;
      }
    }
    .post{
      width: 100%;
      height: 135px;
      margin-bottom: 16px;
    }
    .wrap-container{
      width: 100%;
      height: 1172px;
      padding-top: 20px;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      overflow-y: auto;
      box-sizing: border-box;
      position: relative;
      &::-webkit-scrollbar{
        width: 17px;
      }
      &::-webkit-scrollbar-track{
        background-color: #F0F0F0;
      }
      &::-webkit-scrollbar-thumb{
        width: 10px;
        background: #D8D8D8;
        border-radius: 5px;
      }
      .card{
        box-sizing: border-box;
        width: 100%;
        padding: 20px 30px 20px;
        position: relative;
        .f-quotation{
          position: absolute;
          width: 17px;
          height: 14px;
          left: 9px;
          top: 0px;
        }
        .b-quotation{
          position: absolute;
          width: 17px;
          height: 14px;
          right: 20px;
          top: 120px;
        }
        .words{
          width: 100%;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 24px;
          height: 96px;
          margin-bottom: 20px;
        }
        .publisher{
          display: flex;
          align-items: center;
          justify-content: center;
          .line{
            width: 60px;
            height: 1px;
            background: #999999;
            margin-right: 16px;
          }
          .avatar{
            width: 30px;
            height: 30px;
            margin-right: 10px;
            border-radius: 50%;
            overflow: hidden;
          }
          .a-name{
            display: block;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 12px;
            max-width: 100px;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #666666;
            }
          }
        }
        .infos{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 30px;
          .time{
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
            max-width: 100px;
          }
          .num{
            display: flex;
            align-items: center;
            margin-left: 12px;
            .icon{
              width: 14px;
              height: 14px;
              margin-right: 2px;
            }
            p{
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 12px;
              max-width: 80px;
            }
          }
        }
      }
    }
    .button{
      width: 100%;
      height: 70px;
      box-sizing: border-box;
      border: 1px solid #EBEBEB;
      border-top: none;
      display: flex;
      align-items: center;
      justify-content: center;
      button{
        border: none;
        background-color: #ffffff;
        width: 59px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        margin-right: 5px;
        cursor: pointer;
        &:hover{
          color: $--G60-theme;
        }
      }
    }
  }
}
</style>