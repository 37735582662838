<template>
  <div class="container">
    <div class="part top">
      <div class="content">
        <p class="title">探角数字科创中心</p>
        <div class="line"></div>
        <p class="title">企业技术创新的基础设施</p>
        <p class="tip">整合海量科创资源，AI 智能匹配技术对接，精准解决企业技术难题，降低研发成本，提升创新效率</p>
        <div>
          <button class="button-0" @click="toVip">立即定制</button>
          <button class="button-1"  @click="showVideo">观看视频</button>
        </div>
      </div>
    </div>
    <div v-if="show">
      <el-dialog v-model="show" :show-close="false" :close-on-press-escape="false" :width="'900px'" :custom-class="'dialog-no-title'">
        <video style="width: 100%;object-fit: contain;margin-bottom: -4px;" controls autoplay>
          <source src="http://1306514709.vod2.myqcloud.com/bc2da50dvodsh1306514709/d19894721397757888292152540/GutHgTFmO5EA.mp4">
        </video>
      </el-dialog>
    </div>
    <div class="part INT" id="INT">
      <Introduction></Introduction>
    </div>
    <div class="part AD" id="AD">
      <Advantage></Advantage>
    </div>
    <div class="part" id="VALUE">
      <Value></Value>
    </div>
    <div class="part VERSION" id="VERSION">
      <Version></Version>
    </div>
    <div class="part US" id="US">
      <ContactUs></ContactUs>
    </div>
    <div class="part FAQ" id="FAQ">
      <ScrollLine :width="pageWidth"></ScrollLine>
    </div>
    <div class="part FOOTER" id="FOOTER">
      <A_Footer></A_Footer>
    </div>
    <div v-if="showPay">
      <PayWindow :product="product" @closeWindow="closeWindow"></PayWindow>
    </div>
  </div>
</template>

<script>
import ContactUs from './components/announce/ContactUs.vue';
import Value from './components/announce/Value.vue';
import Version from './components/announce/Version.vue';
import ScrollLine from './components/announce/ScrollLine.vue';
import Advantage from './components/announce/Advantage.vue';
import A_Footer from './components/announce/A_Footer'
import Introduction from './components/announce/Introduction.vue';
import PayWindow from '@/components/PayWindow.vue';
import { allProducts } from "@/api/product";

export default{
  components:{
    ContactUs,
    Introduction,
    ScrollLine,
    Version,
    Advantage,
    A_Footer,
    Value,
    PayWindow,
  },
  data(){
    return{
      pageWidth: '',
      showPay: false,
      product: {},
      show: false,
    }
  },
  mounted(){
    this.getProduct()
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  methods:{
    showVideo(){
      this.show = true
    },
    getProduct(){
      allProducts().then(res=>{
        const arr = res.data.result.filter(item=>item.summary.includes('数字科创中心'))
        this.product = arr[0]
      })
    },
    closeWindow(){
      this.showPay = false
    },
    toVip(){
      this.showPay = true
    },
    handleResize(){
      this.pageWidth = document.documentElement.clientWidth || document.body.clientWidth;
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .part{
    width: 1200px;
  }
  .AD{
    width: 100% !important;
  }
  .VERSION{
    width: 100% !important;
  }
  .FAQ{
    width: 100% !important;
    background: #F8F9FB;
    padding-top: 90px;
    padding-bottom: 96px;
  }
  .US{
    padding-top: 90px;
  }
  .FOOTER{
    width: 100% !important;
  }
  .top{
    width: 100% !important;
    height: 580px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705901477904032');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 166px;
    .content{
      width: 1200px;
      .title{
        font-size: 44px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #111111;
        line-height: 58px;
        background: linear-gradient(180deg, #51596B 0%, #0A0F14 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .line{
        width: 131px;
        height: 7px;
        background: linear-gradient(90deg, #1C222B 0%, #E7ECED 100%);
        margin: 10px 0;
      }
      .tip{
        margin-top: 10px;
        margin-bottom: 40px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
      .button-0{
        width: 202px;
        height: 48px;
        border-radius: 4px;
        border: 1px solid #FC8336;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FC8336;
        cursor: pointer;
        box-sizing: border-box;
        background-color: transparent;
        margin-right: 10px;
      }
      .button-1{
        width: 202px;
        height: 48px;
        background: linear-gradient(180deg, #F1A954 0%, #FF4103 100%);
        border-radius: 4px;
        border: none;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
}
</style>