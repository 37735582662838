<template>
  <div class="content">
    <div class="top">
      <div class="left">
        <p class="title"><a @click.stop href="/news?id=329071672229889" target="_blank">产业要闻</a></p>
        <div class="news-left">
          <div class="news animate__animated animate__fadeIn" v-for="item in list1" :key="item.id" @click="toDetail(item.id)">
            <div class="thumb">
              <Image :src="item.thumb" :imgId="item.id" />
            </div>
            <a @click.stop class="a-title text-otl-ell" :href="`/detail?id=${item.id}`" target="_blank">{{ item.title }}</a>
            <p class="time">{{ item.publishTime.slice(0,10) }}</p>
          </div>
        </div>
      </div>
      <div class="right">
        <p class="title"><a @click.stop href="/policies" target="_blank">政策导航</a></p>
        <div class="news-right">
          <div class="news animate__animated animate__fadeIn" v-for="item in list2" :key="item.id" @click="toDetail(item.id)">
            <p class="time">{{ item.publishTime }}</p>
            <a @click.stop class="a-title text-otl-ell" :href="`/detail?id=${item.id}`" target="_blank">{{ item.title }}</a>
            <p class="word text-ofl-ell">{{ checkSummary(item.summary) }}</p>
            <div class="more">
              <!-- <a @click.stop :href="`/detail?id=${item.id}`" target="_blank">MORE</a> -->
              <img src="@/assets/images/G60/home/more-d.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="title">
        <p>品</p>
        <p>牌</p>
        <p>活</p>
        <p>动</p>
      </div>
      <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/08/1704695943161709" class="activity" style="margin-right: 20px;" />
      <img src="@/assets/images/G60/home/park-0.png" class="activity" style="margin-right: 20px;" />
      <img src="@/assets/images/G60/home/park-1.png" class="activity" />
    </div>
  </div>
</template>

<script>
import { getlistHomepage,getG60DynamicList } from "@/api/G60";
import { routerWindow } from "@/utils/tool";
import { getG60Id } from "@/utils/G60/login";


export default{
  data(){
    return{
      // G60产业要闻ID 329071672229889
      // G60产业政策ID 329071695298561
      list1:[],
      list2:[],
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    checkSummary(word){
      if(!word) return word
      else if(word.indexOf('**kqd-img**') !== -1) return ''
      return word.replace('**kqd-str**','')
    },
    toDetail(id){
      if(!id) return
      routerWindow(this.$router,'/detail',{id})
    },
    getList(){
      getlistHomepage(1,4,329071672229889).then(res=>{
        this.list1 = res.data.result
      })
      getG60DynamicList({
        "articlesType": 331414692233217,  // 政策导航
        "esShow": true,
        "keyword": '',
        "includeDynamic": true,
        "limit": 4,
        "memberId": getG60Id(),
        "offset": 1,
        "type": 4,
      }).then(res=>{
        this.list2 = res.data.result.map((item)=>{
          item.id = item.dynamicId
          return item
        })
        this.list2 = this.list2.map((item)=>{
          // * 从sourceTitle中获取时间
          if(!item.sourceTitle || !item.sourceTitle.includes('发布时间：')){
            return item
          }
          const start = item.sourceTitle.indexOf('发布时间：') + 5
          const end = item.sourceTitle.indexOf('</span>',start)
          item.publishTime = item.sourceTitle.slice(start,end)
          return item
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  .top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .title{
      font-size: 30px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 30px;
      a{
        color: #111111;
        &:hover{
          color: $--G60-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
    }
    .left{
      width: 572px;
      height: 578px;
      .news-left{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .news{
          margin-top: 20px;
          width: 276px;
          cursor: pointer;
          &:hover{
            .a-title{
              color: $--G60-theme !important;
            }
          }
          .thumb{
            cursor: pointer;
            width: 100%;
            height: 170px;
            margin-bottom: 13px;
          }
          .a-title{
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 22px;
            margin-bottom: 12px;
            height: 44px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #111111;
            }
          }
          .time{
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 14px;
            letter-spacing: 1px;
          }
        }
      }
    }
    .right{
      width: 600px;
      height: 578px;
      .news-right{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .news{
          margin-top: 20px;
          width: 284px;
          height: 251px;
          border: 1px solid #B6B6B6;
          box-sizing: border-box;
          padding: 9px 16px 21px;
          cursor: pointer;
          &:hover{
            .a-title{
              color: $--G60-theme !important;
            }
            .more{
              a{
                color: $--G60-theme !important;
              }
            }
          }
          .time{
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 30px;
            margin-bottom: 10px;
          }
          .a-title{
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 24px;
            width: 100%;
            height: 48px;
            margin-bottom: 12px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #111111;
            }
          }
          .word{
            width: 100%;
            height: 96px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            margin-bottom: 8px;
          }
          .more{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            a{
              display: block;
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              color: #111111;
              line-height: 18px;
              cursor: pointer;
              &:hover{
                color: $--G60-theme !important;
              }
              &:visited{
                color: #111111;
              }
            }
            img{
              width: 19px;
              height: 10px;
              margin-left: 14px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .bottom{
    margin-top: 60px;
    display: flex;
    width: 100%;
    .title{
      width: 60px;
      height: 190px;
      background: $--G60-theme;
      box-sizing: border-box;
      padding-top: 40px;
      margin-right: 10px;
      p{
        width: 100%;
        text-align: center;
        font-size: 24px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 30px;
        margin-right: 8px;
      }
    }
    .activity{
      width: 363px;
      height: 190px;
    }
  }
}
</style>

