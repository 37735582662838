<template>
  <div class="container">
    <div class="logo-line">
      <img src="@/assets/images/Tan/identity/logo.png" class="logo"/>
      <p class="p-0">企业科技创新的核心基础设施</p>
    </div>
    <div class="window" v-loading="loading">
      <div class="content-0" v-if="step === 0">
        <p class="p-1">欢迎使用探角数字科创中心 · 打造企业专属科创版图</p>
        <div class="identity-0" @click="toStep(1)">
          <p class="p-2">立即创建探角<span>></span></p>
          <p class="p-3">如果您尚未创建探角，现在就为企业定制一个专属的科创基础设施</p>
        </div>
        <div class="identity-1" @click="toStep(-1)">
          <p class="p-2">加入已有探角<span>></span></p>
          <p class="p-3">如果您的企业已创建探角，可直接申请加入，享受全面的科创服</p>
        </div>
      </div>
      <div class="content-1 content-2" v-else-if="step === 1">
        <p class="p-1">创建企业信息，开启探角科创之旅</p>
        <div class="new-content">
          <div class="new-line m-r-20">
            <div class="part">
              <p class="new-title-line">企业基本信息</p>
              <div class="form-item">
                <div class="title"><span>*</span>企业名称：</div>
                <el-input v-model="form1.name" class="input" placeholder="请输入企业名称" maxlength="20"/>
              </div>
              <div class="form-item">
                <div class="title"><span>*</span>企业类型：</div>
                <el-select v-model="form1.type" style="width: 300px;" placeholder="请选择" clearable>
                  <el-option
                    v-for="(item,index) in typeList"
                    :key="index"
                    :label="item"
                    :value="item"/>
                </el-select>
              </div>
              <div class="form-item">
                <div class="title"><span>*</span>行业领域：</div>
                <MultiSelection :selections="multiIndustry" :placeholder="'请选择您主要关注的投资领域，最多5个'" :max="5" :ref="'industrys'" class="no-wrap" style="width: 300px;" :value="''" @sendValue="sendIndustry" v-if="!reInit"></MultiSelection>
              </div>
              <div class="form-item">
                <div class="title"><span>*</span>所在地区：</div>
                <el-cascader ref="cascaderAddr" :props="props" style="width: 300px;" :placeholder="'请选择'" @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange" v-model="form1.address"></el-cascader>
              </div>
            </div>
            <div class="part">
              <p class="new-title-line">个人信息</p>
              <div class="form-item">
                <div class="title"><span>*</span>姓名：</div>
                <el-input v-model="form3.name" class="input" placeholder="请填写" maxlength="20"/>
              </div>
              <div class="form-item">
                <div class="title"><span>*</span>职务：</div>
                <el-select v-model="form3.position" style="width: 300px;" placeholder="请选择" clearable>
                  <el-option
                    v-for="(item,index) in positionList"
                    :key="index"
                    :label="item"
                    :value="item"/>
                </el-select>
              </div>
            </div>
          </div>
          <div class="new-line">
            <div class="part">
              <p class="new-title-line">企业技术信息</p>
              <div class="form-item">
                <div class="title"><span>*</span>企业研发领域：</div>
                <el-select v-model="form3.core" multiple style="width: 300px;" placeholder="请输入，回车确认" clearable filterable allow-create default-first-option :multiple-limit="5">
                </el-select>
              </div>
              <div class="form-item">
                <div class="title"><span>*</span>主要产品服务：</div>
                <el-input v-model="form3.service" class="input" placeholder="请填写" maxlength="20"/>
              </div>
              <div class="form-item">
                <div class="title"><span>*</span>技术发展阶段：</div>
                <el-select v-model="form3.level" style="width: 300px;" placeholder="请选择" clearable>
                  <el-option
                    v-for="(item,index) in levelList"
                    :key="index"
                    :label="item"
                    :value="item"/>
                </el-select>
              </div>
              <!-- <div class="form-item">
                <div class="title">关注科研领域：</div>
                <el-select
                  v-model="form3.focus"
                  filterable
                  remote
                  multiple
                  placeholder="请搜索并选择领域"
                  remote-show-suffix
                  :remote-method="remoteMethod"
                  :loading="loadingRemote"
                  style="width: 300px">
                  <el-option
                    v-for="(item) in remoteList"
                    :key="item.id"
                    :label="item.associationText"
                    :value="item.associationText"
                  />
                </el-select>
              </div> -->
            </div>
          </div>
        </div>
        <button class="next-button" @click="toStep(3)" v-if="!fromHome">提交</button>
        <button class="next-button" @click="toStep(3)" v-else>确认</button>
      </div>
      <div class="content-3" v-else-if="step === 3">
        <div class="success-title">
          <div class="icon"></div>
          <p class="p-1">恭喜您，探角已成功创建</p>
        </div>
        <div class="info">
          <p class="p-2 text-ol-ell">企业名称： {{ successInfo?.companyName }}</p>
          <p class="p-2 text-ol-ell">企业探角ID：{{ successInfo?.companyId }}</p>
        </div>
        <div class="tips">
          <p>您现在可以：</p>
          <p>- 开始使用探角，享受科创大数据和智能科创服务</p>
          <p>- 根据您的研究方向订阅相关领域的海量专家资源</p>
          <p>- 随时访问探角平台，管理您的科创数据和项目</p>
        </div>
        <div class="button-line">
          <button class="left" @click="inviteMember">邀请成员加入</button>
          <button class="right" @click="toTan">进入探角</button>
        </div>
      </div>
      <div class="content_1" v-else-if="step === -1">
        <div class="join-title">
          <p class="p-1">加入探角</p>
          <p class="p-2">若您的企业已创建探角，请输入企业探角ID快速加入。<span @click="toStep(-4)">如何获取企业探角ID？</span></p>
        </div>
        <cell-input-field
          :length="8"
          label="''"
          :required="true"
          :valueName="joinCode"
          @callBack="getCodeCallBack"
          @validate="getCodeValidate"
        ></cell-input-field>
        <button class="next-button" @click="toStep(-2)" v-loading="existLoading">下一步</button>
      </div>
      <div class="content_100" v-else-if="step === -2">
        <p class="p-1">确认企业信息，申请加入探角</p>
        <p class="p-2">{{ existCompanyInfo?.tjCompanyName || '探角' }}创建</p>
        <div class="form-item">
          <div class="title"><span>*</span>姓名：</div>
          <el-input v-model="form2.name" class="input" placeholder="请输入姓名" maxlength="20"/>
        </div>
        <div class="form-item">
          <div class="title"><span>*</span>职务：</div>
          <el-select v-model="form2.position" style="width: 300px;" placeholder="请选择" clearable>
            <el-option
              v-for="(item,index) in positionList"
              :key="index"
              :label="item"
              :value="item"/>
          </el-select>
        </div>
        <button class="next-button" @click="toStep(-3)">提交申请</button>
      </div>
      <div class="content_2" v-else-if="step === -3">
        <img src="@/assets/images/Tan/identity/apply.png" class="apply"/>
        <p class="p-1">申请已提交！请等待主管理审核</p>
      </div>
      <div class="content_3" v-else-if="step === -4">
        <p class="p-1">如何获取企业探角ID</p>
        <p class="p-2">1. 企业探角ID是一个由8位字母和数字组成的唯一编码，每个企业在探角平台上都有这样唯一的标识，企业探角ID的获取权限仅限于团队管理员。</p>
        <p class="p-2">2. 如果您是企业成员并希望加入探角，请联系您企业探角的管理员获取企业探角ID。企业管理员可以在首页<span>「工作台页面-团队成员管理」</span>中轻松找到并获取企业探角ID。</p>
        <div class="wrap">
          <img src="@/assets/images/Tan/identity/code-pic.png" />
        </div>
        <button class="next-button" @click="toStep(-1)">下一步</button>
      </div>
    </div>
  </div>
</template>


<script>
import MultiSelection from '@/components/MultiSelection/MultiSelection';
import { getprovince, getcity, getarea } from "@/api/demand";
import { getSelectFatherSonByType } from "@/api/config";
import CellInputField from "@/components/CellInputField/CellInputField.vue";
import { createTanCompany,getTanCompanyExist,joinTanByApply } from "@/api/Tan/company";
import { getTanLocalInfo,getTanId } from "@/utils/Tan/login";
import { associationSearch } from '@/api/search';
import { ElMessage } from 'element-plus';
import { getTanMemberInfo } from "@/api/Tan/member";
import to from "await-to-js";

export default{
  components:{
    MultiSelection,
    CellInputField,
  },
  data(){
    return{
      existCompanyInfo: null,
      existLoading: false,
      loadingRemote: false,
      remoteList: [],
      step: 0,
      reInit: false,
      multiIndustry: [],
      loading: false,
      options:[
        -1,
      ],
      positionList : [],
      levelList: [],
      typeList: [],
      form1:{
        name: '',
        type: '',
        industry: '',
        address: [],
      },
      form3:{
        // 用户个人信息，企业技术信息
        name: '',
        position: '',
        service: '',
        core: [],
        level: '',
        focus: [],
      },
      form2:{
        name: '',
        position: '',
      },
      successInfo: null,
      joinCode: '',
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then(response => {
                const cities = []
                for (let item of response.data) {
                  cities.push({
                    code: item.provinceCode,
                    value: item.provinceName,
                    label: item.provinceName,
                    leaf: false
                  })
                }
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getcity(node.data.code).then(response => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    code: item.cityCode,
                    value: item.cityName,
                    label: item.cityName,
                    leaf: false
                  })
                }
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.data.code).then(response => {
                const areas = []
                for (let item of response.data) {
                  areas.push({
                    code: item.areaCode,
                    value: item.areaName,
                    label: item.areaName,
                    leaf: true
                  })
                }
                resolve(areas);
              });
            }
          }, 100);
        }
      },
    }
  },
  computed:{
    fromHome(){
      return this.$route.query.fromHome || ''
    },
  },
  watch:{
    $route(to){
      if(to.path !== '/identity') return
      this.getQuery()
    },
    step(val){
      if(val !== 1 && val !== 2){
        this.resetForm1()
      }
      else if(val !== 2){
        this.resetForm3()
      }
      else if(val !== -2 && val !== -1){
        this.joinCode = ''
      }
      else if(val !== -2){
        this.resetForm2()
      }
    },
  },
  mounted(){
    this.getQuery()
    this.checkStatus()
    this.getSelections()
  },
  methods:{
    checkStatus(){
      getTanMemberInfo(getTanId()).then(res=>{
        if(res.data?.tjCompanyId && this.step !== 3){
          // 已创建探角且非分享状态
          this.$router.push('/p-center')
        }
        else if(res.data?.tjCompanyId){
          this.successInfo = {
            companyId: res.data.tjCompanyId,
            companyName: res.data.tjCompanyName
          }
        }
        else{
          const query = {}
          if(this.fromHome){
            query.fromHome = this.fromHome
            query.step = this.$route.query.step
          }
          this.$router.push({
            path: '/identity',
            query,
          })
        }
      })
    },
    remoteMethod(string){
      if(this.loadingRemote || !string || string?.length < 2 || !getTanLocalInfo()?.memberInfo?.id) return
      this.loadingRemote = true
      associationSearch({
        "keyword": string,
        "searchType": 7,
      }).then(res=>{
        this.remoteList = res.data.filter((item)=>!item.expertName)
      }).finally(()=>{
        this.loadingRemote = false
      })
    },
    getCodeCallBack(val){
      this.joinCode = val
    },
    getCodeValidate(){
    },
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    sendIndustry(val){
      this.form1.industry = val.map(item=>{
        return item[item.length - 1]
      }).join(',')
    },
    resetForm3(){
      this.form3 ={
        name: '',
        position: '',
        service: '',
        core: [],
        level: '',
        focus: [],
      }
    },
    resetForm2(){
      this.form2 ={
        name: '',
        position: '',
      }
    },
    resetForm1(){
      this.form1 = {
        name: '',
        type: '',
        area: '',
        address: [],
      }
      this.reInit = true
      setTimeout(()=>{
        this.reInit = false
      },300)
    },
    getQuery(){
      const step = this.$route.query.step
      if(step) this.step = Number(step)
      else this.step = 0
    },
    async toStep(index){
      if(index === 3){
        // 创建探角成功后继续
        if(!this.form1.name){
          ElMessage.warning('请填写企业名称')
          return
        }
        else if(!this.form1.type){
          ElMessage.warning('请选择企业类型')
          return
        }
        else if(this.form1.industry?.length === 0){
          ElMessage.warning('请选择行业领域')
          return
        }
        else if(!this.form1.address){
          ElMessage.warning('请选择地址')
          return
        }
        else if(!this.form3.name){
          ElMessage.warning('请填写您的姓名')
          return
        }
        else if(!this.form3.position){
          ElMessage.warning('请选择您的职位')
          return
        }
        else if(this.form3.core?.length === 0){
          ElMessage.warning('请填写企业研发领域')
          return
        }
        else if(!this.form3.service){
          ElMessage.warning('请填写您的主要产品服务')
          return
        }
        else if(!this.form3.level){
          ElMessage.warning('请选择技术发展阶段')
          return
        }
        // else if(this.form3.focus?.length === 0){
        //   ElMessage.warning('请搜索选择关注科研领域')
        //   return
        // }
        this.loading = true
        const [err,res] = await to(createTanCompany({
          "address": this.form1.address.join(','),
          "area": this.form1.industry,
          "companyName": this.form1.name,
          "companyType": this.form1.type,
          "coreTechnologyAreas": this.form3.core.join(','),
          "duties": this.form3.position,
          "interestAreas": '', // this.form3.focus.join(',')
          "mainProductService": this.form3.service,
          "memberId": getTanLocalInfo()?.memberInfo?.id,
          "memberName": this.form3.name,
          "technologyPhase": this.form3.level,
        }))
        this.loading = false
        if(err){
          console.log('error',err);
          return
        }
        if(this.fromHome){
          // 判断是否从首页跳转
          this.$router.push({
            path: '/',
          })
          localStorage.setItem('TanHomeVip',JSON.stringify({type: this.fromHome,}))
          return
        }
        this.successInfo = res.data
      }
      else if(index === -2){
        if(this.joinCode.length < 8){
          ElMessage.warning('请输入正确企业探角ID')
          return
        }
        this.existLoading = true
        const [err,res] = await to(getTanCompanyExist(this.joinCode))
        this.existLoading = false
        if(err){
          console.log(err);
          return
        }
        if(!res.data){
          ElMessage.error('探角ID不存在或错误，请重新输入')
          return
        }
        else{
          this.existCompanyInfo = res.data
        }
      }
      else if(index === -3){
        if(!this.form2.name){
          ElMessage.warning('请填写您的姓名')
          return
        }
        else if(!this.form2.position){
          ElMessage.warning('请选择您的职位')
          return
        }
        this.loading = true
        const [err,res] = await to(joinTanByApply({
          "duties": this.form2.position,
          "memberId": getTanId(),
          "memberName": this.form2.name,
          "tjCompanyId": this.joinCode,
        }))
        this.loading = false
        if(err){
          console.log(err);
          return
        }
        if(res.code !== "Sx200"){
          console.log(res);
        }
      }
      const query = {
        step: index
      }
      if(this.fromHome){
        query.fromHome = this.fromHome
      }
      this.$router.push({
        path: '/identity',
        query,
      })
    },
    inviteMember(){
      this.$store.dispatch('Tan/setInviteShow',true)
    },
    toTan(){
      this.$router.push('/p-center')
    },
    getSelections(){
      getSelectFatherSonByType(6).then(res=>{
        this.multiIndustry = res.data.map((item)=>{
          item.child = item.child?.map((jtem)=>{
            jtem.id = jtem.val
            return jtem
          })
          item.id = item.val
          return item
        })
      })
      getSelectFatherSonByType(10032).then(res=>{
        this.typeList = res.data.map(item=>item.val)
      })
      getSelectFatherSonByType(10033).then(res=>{
        this.levelList = res.data.map(item=>item.val)
      })
      getSelectFatherSonByType(10034).then(res=>{
        this.positionList = res.data.map(item=>item.val)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: calc(100% + 17px);
  min-height: 100vh;
  background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/07/14/1723598508320586');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow-x: hidden;
  box-sizing: border-box;
  padding-top: 86px;
  padding-bottom: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  .logo-line{
    width: 881px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 94px;
    .logo{
      width: 93px;
      height: 51px;
      margin-right: 15px;
    }
    .p-0{
      position: relative;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      padding-left: 15px;
      padding-bottom: 8px;
      &::before{
        content: '';
        width: 1px;
        height: 14px;
        background: #333333;
        position: absolute;
        left: 0;
        bottom: 10px;
      }
    }
  }
  .window{
    width: 881px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 10px rgba(160,160,160,0.03);
    border-radius: 10px;
    .content-0{
      width: 100%;
      box-sizing: border-box;
      padding: 62px 54px 56px;
      .p-1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #333333;
        line-height: 33px;
        text-align: center;
        padding-bottom: 60px;
      }
      .identity-0{
        width: 100%;
        height: 130px;
        background-image: url('../../assets/images/Tan/identity/iden-1.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        padding-left: 42px;
        padding-top: 33px;
        cursor: pointer;
        margin-bottom: 30px;
        .p-2{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          padding-bottom: 8px;
          span{
            font-size: 22px;
          }
        }
        .p-3{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #666666;
        }
      }
      .identity-1{
        width: 100%;
        height: 130px;
        background-image: url('../../assets/images/Tan/identity/iden-0.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        padding-left: 42px;
        padding-top: 33px;
        cursor: pointer;
        .p-2{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          padding-bottom: 8px;
          span{
            font-size: 22px;
          }
        }
        .p-3{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #666666;
        }
      }
    }
    .content-2{
      display: flex;
      .form-item{
        .title{
          width: 105px !important;
          white-space: nowrap;
          text-align: right;
        }
        :deep(.el-input__inner){
          font-size: 14px;
        }
        :deep(.el-select__tags-text){
          font-size: 14px;
        }
      }
    }
    .content-1{
      width: 100%;
      box-sizing: border-box;
      padding: 47px 0px 58px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .new-content{
        width: 100%;
        display: flex;
      }
      .m-r-20{
        margin-right: 20px;
      }
      .new-line{
        width: 410px;
        box-sizing: border-box;
        padding-left: 20px;
        .new-title-line{
          width: 410px;
          height: 38px;
          background: #F4F7FC;
          box-sizing: border-box;
          border-left: 3px solid $--color-theme;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #222222;
          line-height: 38px;
          padding-left: 14px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        .part{
          width: 410px;
        }
      }
      .p-1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #333333;
        line-height: 33px;
        padding-bottom: 28px;
      }
      .form-item{
        display: flex;
        align-items: center;
        padding: 10px 0;
        .title{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 16px;
          white-space: nowrap;
          span{
            color: #ED0900;
          }
        }
        .input{
          width: 300px;
        }
      }
      .next-button{
        margin-top: 30px;
        width: 172px;
        height: 32px;
        background: $--color-theme;
        border-radius: 3px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
      }
    }
    .content_100{
      width: 100%;
      box-sizing: border-box;
      padding: 47px 0px 58px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: linear-gradient( 180deg, #CEDFFF 0%, #FEFFFF 21%, #FFFFFF 100%);
      box-shadow: 0px 2px 8px 8px rgba(3,11,52,0.01);
      border-radius: 10px;
      .p-1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #333333;
        line-height: 33px;
        padding-bottom: 7px;
        width: 100%;
        box-sizing: border-box;
        padding-left: 57px;
      }
      .p-2{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        line-height: 22px;
        padding-bottom: 50px;
        width: 100%;
        box-sizing: border-box;
        padding-left: 57px;
      }
      .form-item{
        display: flex;
        align-items: center;
        padding: 10px 0;
        .title{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 16px;
          white-space: nowrap;
          span{
            color: #ED0900;
          }
        }
        .input{
          width: 300px;
        }
      }
      .next-button{
        margin-top: 30px;
        width: 172px;
        height: 32px;
        background: $--color-theme;
        border-radius: 3px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
      }
    }
    .content-3{
      width: 100%;
      box-sizing: border-box;
      padding: 44px 65px 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-image: url('../../assets/images/Tan/identity/created.png');
      background-repeat: no-repeat;
      background-size: 100% 113px;
      background-position: top left;
      .success-title{
        width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 89px;
        .icon{
          width: 24px;
          height: 24px;
          margin-right: 8px;
          background-color: #ffffff;
          border-radius: 50%;
          background-image: url('../../assets/images/Tan/identity/success.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% 100%;
        }
        .p-1{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 24px;
          color: #192053;
          line-height: 33px;
        }
      }
      .info{
        width: 100%;
        height: 113px;
        background: #F7FCFF;
        border: 1px solid #E7E7E7;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 31px 200px;
        margin-bottom: 25px;
        .p-2{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          line-height: 17px;
          max-width: 700px;
        }
      }
      .tips{
        height: 91px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 52px;
        align-self: flex-start;
      }
      .button-line{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .left{
          width: 126px;
          height: 32px;
          border-radius: 4px;
          border: 1px solid #9CC6FF;
          box-sizing: border-box;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: $--color-theme;
          cursor: pointer;
          margin: 0 22px;
          background-color: #ffffff;
        }
        .right{
          width: 126px;
          height: 32px;
          border-radius: 4px;
          border: none;
          background-color: $--color-theme;
          box-sizing: border-box;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          cursor: pointer;
          margin: 0 22px;
        }
      }
    }
    .content_1{
      width: 100%;
      box-sizing: border-box;
      padding: 50px 65px 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-image: url('../../assets/images/Tan/identity/join.png');
      background-repeat: no-repeat;
      background-size: 100% 153px;
      background-position: top left;
      .join-title{
        width: 767px;
        .p-1{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 24px;
          color: #333333;
          line-height: 33px;
          padding-bottom: 7px;
        }
        .p-2{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          line-height: 22px;
          padding-bottom: 134px;
          span{
            color: $--color-theme;
            cursor: pointer;
          }
        }
      }
      .next-button{
        width: 172px;
        height: 32px;
        background: $--color-theme;
        border-radius: 3px;
        margin-top: 135px;
        cursor: pointer;
        border: none;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
      }
    }
    .content_2{
      height: 417px;
      background: linear-gradient( 180deg, #CEDFFF 0%, #FEFFFF 21%, #FFFFFF 100%);
      box-shadow: 0px 2px 8px 8px rgba(3,11,52,0.01);
      border-radius: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .apply{
        width: 165px;
        height: 111px;
        margin-bottom: 41px;
      }
      .p-1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        line-height: 28px;
      }
    }
    .content_3{
      width: 100%;
      box-sizing: border-box;
      padding: 53px 57px 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: linear-gradient( 180deg, #CEDFFF 0%, #FEFFFF 21%, #FFFFFF 100%);
      box-shadow: 0px 2px 8px 8px rgba(3,11,52,0.01);
      border-radius: 10px;
      .p-1{
        width: 755px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #333333;
        line-height: 33px;
        padding-bottom: 37px;
        box-sizing: border-box;
      }
      .p-2{
        width: 755px;
        padding: 10px 0;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 25px;
        box-sizing: border-box;
        span{
          color: $--color-theme;
        }
      }
      .wrap{
        width: 755px;
        height: 128px;
        background: #E0E0E0;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .next-button{
        width: 172px;
        height: 32px;
        background: $--color-theme;
        border-radius: 3px;
        margin-top: 30px;
        cursor: pointer;
        border: none;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
      }
    }
  }
}
.no-wrap{
  :deep(.el-select__tags) {
    white-space: nowrap;
    overflow: hidden;
  }
  :deep(.el-cascader__tags){
    flex-wrap: nowrap !important;
    overflow: hidden;
  }
}
</style>