<template>
  <div class="content">
    <p class="title">科创互动</p>
    <div class="cards">
      <div v-for="(item,index) in showList" :key="item.id" class="card animate__animated animate__fadeIn" :class="{'no-margin': index === 3}" @click="clickCard(item)">
        <p class="type text-ol-ell">{{ item?.info[0] }}</p>
        <div class="thumb">
          <Image :src="item.img" :imgId="item.id"></Image>
        </div>
        <a class="a-title text-otl-ell" v-if="item.pathPc" :href="item.pathPc" target="_blank" @click.stop>{{ item?.info[1] }}</a>
        <a class="a-title text-otl-ell" v-else>{{ item?.info[1] }}</a>
        <p class="time text-ol-ell">{{ item?.info[2] }}</p>
        <p class="address text-ol-ell">{{ item?.info[3] }}</p>
      </div>
    </div>
    <div class="dots" v-if="dotsNum > 1">
      <div v-for="item in dotsNum" :key="item" class="dot" :class="{'active': cur === item - 1}" @click="cur = item">
      </div>
    </div>
    <div class="buttons" v-if="dotsNum > 1">
      <img src="@/assets/images/G60/home/left-button.png" @click="leftButton"/>
      <img src="@/assets/images/G60/home/right-button.png" @click="rightButton"/>
    </div>
  </div>
</template>

<script>

export default{
  props: ['list'],
  data(){
    return{
      // 科创互动频道ID 329073557569537
      cur: 0,
    }
  },
  mounted(){
  },
  computed:{
    dotsNum(){
      return Math.ceil(this.list.length / 4)
    },
    showList(){
      return this.list.slice(this.cur * 4 ,this.cur + 4)
    },
  },
  methods:{
    clickCard(item){
      if(!item) return
      else if(!item.pathPc) return
      window.open(item.pathPc, '_blank');
    },
    leftButton(){
      this.cur -= 1
      if(this.cur < 0) this.cur = 0
    },
    rightButton(){
      this.cur += 1
      if(this.cur >= this.dotsNum) this.cur = this.dotsNum - 1
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  .title{
    font-size: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 30px;
    margin-bottom: 47px;
  }
  .cards{
    width: 100%;
    display: flex;
    .card{
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
      margin-right: 15px;
      width: 279px;
      height: 339px;
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      .type{
        width: 100%;
        box-sizing: border-box;
        padding: 16px 17px 14px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 14px;
      }
      .thumb{
        width: 100%;
        height: 174px;
      }
      .a-title{
        margin-top: 11px;
        margin-bottom: 14px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 22px;
        height: 44px;
        box-sizing: border-box;
        width: 100%;
        padding: 0 16px;
        &:hover{
          color: $--G60-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .time{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: $--G60-theme;
        margin-bottom: 14px;
        line-height: 12px;
        box-sizing: border-box;
        width: 100%;
        padding: 0 16px;
      }
      .address{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 12px;
        box-sizing: border-box;
        width: 100%;
        padding: 0 16px;
      }
    }
  }
  .dots{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .dot{
      width: 10px;
      height: 10px;
      background: #D7D7D7;
      border-radius: 50%;
      margin: 0 4.5px;
      cursor: pointer;
    }
    .active{
      background: $--G60-theme !important;
    }
  }
  .buttons{
    margin-top: 26px;
    width: 100%;
    display: flex;
    justify-content: center;
    img{
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin: 0 10px;
    }
  }
  .no-margin{
    margin-right: 0 !important;
  }
}
</style>