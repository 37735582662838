<template>
  <div class="container">
    <div class="part search">
      <Search></Search>
    </div>
    <div class="part">
      <Banner></Banner>
    </div>
    <div class="part">
      <div class="section-0">
        <left-news></left-news>
        <right-news></right-news>
      </div>
    </div>
    <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p?imageMogr2/thumbnail/!100p" class="line-image" />
    <div class="part">
      <div class="section-0">
        <LeftNews1></LeftNews1>
      </div>
    </div>
  </div>
</template>

<script>
import Search from './components/home/Search.vue';
import Banner from './components/home/Banner.vue';
import LeftNews from '@/views/post-line/components/home/LeftNews'
import LeftNews1 from '@/views/post-line/components/home/LeftNews1'
import RightNews from './components/home/RightNews.vue';

export default{
  components:{
    LeftNews,
    LeftNews1,
    RightNews,
    Search,
    Banner,
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  padding-bottom: 200px;
  .part{
    display: flex;
    justify-content: center;
  }
  .search{
    height: 236px;
    box-sizing: border-box;
    padding: 58px 52px;
    border-bottom: 1px solid #111111;
  }
  .section-0{
    width: 1200px;
    display: flex;
    justify-content: space-between;
  }
  .line-image{
    width: 1200px;
    height: 154px;
    margin: 16px auto 60px;
    cursor: pointer;
    display: block;
  }
}
</style>