<template>
  <div class="container">
    <div class="title">
      <p class="t-0">专家入驻</p>
      <p class="t-1">共筑产学研桥梁，共创合作机会</p>
    </div>
    <div class="f-container" v-loading="clearLoading">
      <div class="form" v-if="!clearLoading">
        <el-form :model="form" :rules="rules">
          <div class="flex-cc">
            <p class="f-title">基本信息</p>
            <p class="f-title m-l-534">工作单位</p>
          </div>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>姓名：</p>
              <el-form-item prop="name">
                <el-input class="input" v-model="form.name" maxlength="100" placeholder="请输入您的姓名" />
              </el-form-item>
            </div>
            <div class="flex-cc">
              <p class="i-name"><span>*</span>所在单位：</p>
              <el-form-item prop="belong">
                <el-input class="input" v-model="form.belong" maxlength="100" placeholder="请输入所在单位" />
              </el-form-item>
            </div>
          </div>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>联系电话：</p>
              <el-form-item prop="phone">
                <el-input class="input" v-model="form.phone" maxlength="11" placeholder="请输入联系电话" />
              </el-form-item>
            </div>
            <div class="flex-cc">
              <p class="i-name"><span>*</span>所在学院：</p>
              <el-form-item prop="college">
                <el-input class="input" v-model="form.college" maxlength="100" placeholder="请输入所在学院" />
              </el-form-item>
            </div>
          </div>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>电子邮箱：</p>
              <el-form-item prop="email">
                <el-input class="input" v-model="form.email" maxlength="100" placeholder="请输入电子邮箱" />
              </el-form-item>
            </div>
            <div class="flex-cc">
              <p class="i-name"><span>*</span>职称职级：</p>
              <el-form-item prop="position">
                <el-select v-model="form.position" placeholder="请选择职称职级" :style="filterStyle">
                  <el-option v-for="jtem in positionList" :key="jtem.id" :label="jtem.val" :value="jtem.val">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>所属地区：</p>
              <el-form-item prop="addressId">
                <el-cascader :style="filterStyle" placeholder="请选择所属地区" :popper-class="'el-popDropDownPanelAddress'" v-model="form.addressId" clearable :props="props" @change="addressChange" ref="formAddress" @visible-change="visibleChange" @expand-change="visibleChange">
                </el-cascader>
              </el-form-item>
            </div>
          </div>
          <div class="update-line">
            <p class="i-name"><span>*</span>上传头像：</p>
            <div class="img-container">
              <el-upload ref="upload0" action="#" list-type="picture-card" :limit="1" :accept="'image/*'"
                :before-upload="beforeUpload" :http-request="handleUpload" :on-exceed="handleExceed"
                :on-change="loadJsonFromFile">
                <template #default>
                  <img src="@/assets/images/G60/publish/upload-icon.png" alt="" srcset="" style="width: 100%;height: 90px;" v-if="headImage?.length === 0">
                </template>
                <template #file="{ file }">
                  <img v-if="file.raw.type[0] == 'i'" class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                  <div @click="handleRemoveImage(file)" class="icon-delete">
                    <i class="el-icon-delete" style="fontSize: 16px;color: #fff;"></i>
                  </div>
                </template>
              </el-upload>
            </div>
          </div>
          <div class="upload-tip" style="margin-bottom: 50px;">
            （最多上传1张，不能超过20M）
          </div>
          <div class="flex-cc">
            <p class="f-title">教育背景</p>
            <p class="f-title m-l-534">研究方向</p>
          </div>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>最高学历：</p>
              <el-form-item prop="education">
                <el-select v-model="form.education" placeholder="请选择最高学历" :style="filterStyle">
                  <el-option v-for="jtem in educationList" :key="jtem.id" :label="jtem.val" :value="jtem.val">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flex-cc">
              <p class="i-name"><span>*</span>技术领域：</p>
              <el-form-item prop="area">
                <el-cascader placeholder="请选择技术领域" :style="filterStyle"
                :props="{ checkStrictly: true, expandTrigger: 'hover' }" :popper-class="'el-popDropDownPanelArea'"
                :show-all-levels="false" v-model="form.area" clearable :options="areaList" @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange">
                </el-cascader>
              </el-form-item>
            </div>
          </div>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>毕业院校：</p>
              <el-form-item prop="school">
                <el-input class="input" v-model="form.school" maxlength="100" placeholder="请输入毕业院校" />
              </el-form-item>
            </div>
            <div class="flex-cc">
              <p class="i-name"><span>*</span>研究方向：</p>
              <el-form-item prop="direction">
                <el-input class="input" v-model="form.direction" maxlength="100" placeholder="请输入研究方向" />
              </el-form-item>
            </div>
          </div>
          <p class="f-title p-t-44">其他信息</p>
          <div class="textarea-line">
            <p class="i-name">个人简介：</p>
            <el-form-item prop="introduction">
              <el-input
                class="textarea"
                type="textarea"
                resize="none"
                :rows="4"
                maxlength="3000"
                placeholder="请输入个人简介"
                v-model="form.introduction">
              </el-input>
            </el-form-item>
          </div>
          <div class="textarea-line">
            <p class="i-name">成果荣誉：</p>
            <el-form-item prop="achievement">
              <el-input
                class="textarea"
                type="textarea"
                resize="none"
                :rows="4"
                maxlength="3000"
                placeholder="请输入成果荣誉"
                v-model="form.achievement">
              </el-input>
            </el-form-item>
          </div>
          <div class="update-line">
            <p class="i-name">上传附件：</p>
            <div class="file-item" :class="{'has-file':form.files?.length > 0,'img-container': form.files?.length === 0}">
              <el-upload ref="upload1" action="#" :limit="1"
                :before-upload="beforeUploadFile" :http-request="handleUploadFile" :on-exceed="handleExceedFile"
                :on-change="loadJsonFromFile_file">
                <template #default>
                  <img src="@/assets/images/G60/publish/upload-icon.png" alt="" srcset="" style="width: 100%;height: 90px;" v-if="form.files?.length === 0">
                  <div v-else class="file-container"  @click.stop>
                    <p class="file-name text-ol-ell">{{fileName[0].name}}</p>
                    <div @click.stop="deleteFile()" class="icon-delete">
                      <i class="el-icon-delete" style="fontSize: 16px;color: #000000;"></i>
                    </div>
                  </div>
                </template>
              </el-upload>
            </div>
          </div>
          <div class="upload-tip">
            （最多上传1个附件，文件不能超过100M）
          </div>
          <div class="checkbox-line" style="margin-top: 40px;">
            <div class="flex-cc">
              <el-checkbox label="我确认上述信息的真实性，并同意平台对我的信息进行审核和处理，以便促进产学研合作。" v-model="form.checked"></el-checkbox>
            </div>
          </div>
        </el-form>
        <div class="button-line">
          <button :disabled="btnDisable" @click="publish">立即入驻</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getprovince, getcity, getarea } from "@/api/demand";
import { ElMessage } from "element-plus";
import { uploadTypeImage,uploadTypeVideo, uploadTypeFile, cosByUploadType, canUpload } from "@/api/upload";
import { bucketMap,fileStaticPath } from "@/utils/consts";
import { randomFilename } from "@/utils/time";
import { addG60Expert } from "@/api/G60"
import { getSelectFatherSonByType } from "@/api/config";
import { getG60Id,getG60LocalInfo,setLocalInfo } from "@/utils/G60/login";
import { editProfileBasic } from "@/api/user"


export default {
  data() {
    return {
      clearLoading: false,
      headImage: [],
      form: {
        name: '',
        phone: '',
        email: '',
        education: '',
        address: '',
        addressId: [],
        school: '',
        belong: '',
        college: '',
        position: '',
        area: '',
        direction: '',
        introduction: '',
        achievement: '',
        files: [],
        checked: false,
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        phone:[
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
        email:[
          { required: true, message: '请输入联系邮箱', trigger: 'blur' },
        ],
        addressId:[
          { required: true, message: '请选择所属地区', trigger: 'change' },
        ],
        education:[
          { required: true, message: '请选择最高学历', trigger: 'change' },
        ],
        school:[
          { required: true, message: '请输入毕业院校', trigger: 'blur' },
        ],
        belong:[
          { required: true, message: '请输入所在单位', trigger: 'blur' },
        ],
        college:[
          { required: true, message: '请输入所在学院', trigger: 'blur' },
        ],
        position:[
          { required: true, message: '请选择职称职级', trigger: 'change' },
        ],
        area:[
          { required: true, message: '请选择所属领域', trigger: 'change' },
        ],
        direction:[
          { required: true, message: '请输入研究方向', trigger: 'blur' },
        ],
      },
      thumbCheckbox: [],
      fileName: [],
      props: {  // 懒加载列表内容
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then(response => {
                const cities = []
                for (let item of response.data) {
                  cities.push({
                    value: item.provinceCode,
                    label: item.provinceName,
                    leaf: false
                  })
                }
                resolve(cities);
                const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
                Array.from($el).map((item) => item.removeAttribute('aria-owns'));
              });
            }
            if (node.level == 1) {
              getcity(node.value).then(response => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.cityCode,
                    label: item.cityName,
                    leaf: false
                  })
                }
                const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
                Array.from($el).map((item) => item.removeAttribute('aria-owns'));
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.value).then(response => {
                const areas = []
                for (let item of response.data) {
                  areas.push({
                    value: item.areaCode,
                    label: item.areaName,
                    leaf: true
                  })
                }
                const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
                Array.from($el).map((item) => item.removeAttribute('aria-owns'));
                resolve(areas);
              });
            }
          }, 100);
        }
      },
      filterStyle: 'width: 420px;height: 40px;',
      checkboxStyle: 'width: 100%;',
      btnDisable: false,
      imageSize: 4,
      areaList: [],
      educationList: [],
      positionList: [],
    }
  },
  watch:{
    typeName(){
      this.form.corporate = []
      this.form.term = []
      this.form.purpose = []
    },
  },
  computed:{
    typeName(){
      return this.form.type || ''
    },
    cooperationShow(){
      if(!this.form.type) return []
      else{
        return this.cooperationList.filter(item=>item.remark === this.form.type)
      }
    },
  },
  mounted() {
    this.getSelections()
  },
  methods: {
    handleMinChange(e){
      let pattern = new RegExp("^[0-9]|-$")
      this.form.budget.min = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    handleMaxChange(e){
      let pattern = new RegExp("^[0-9]|-$")
      this.form.budget.max = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    clearForm(){
      this.form = {
        name: '',
        phone: '',
        email: '',
        education: '',
        address: '',
        addressId: [],
        school: '',
        belong: '',
        college: '',
        position: '',
        area: '',
        direction: '',
        introduction: '',
        achievement: '',
        files: [],
        checked: false,
      }
      this.headImage = []
      this.clearLoading = true
      setTimeout(()=>{
        this.clearLoading = false
      },200)
    },
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    getSelections(){
      getSelectFatherSonByType(97).then(res=>{
        this.areaList = res.data.map((item)=>{
          return{
            label: item.val,
            value: item.val,
            children: !item.child ? [] : item.child.map((jtem)=>{
              return{
                label: jtem.val,
                value: jtem.val,
                children: !jtem.child ? [] : jtem.child.map(ktem=>{
                  return{
                    label: ktem.val,
                    value: ktem.val,
                  }
                })
              }
            })
          }
        })
      })
      getSelectFatherSonByType(107).then(res=>{
        this.educationList = res.data
      })
      getSelectFatherSonByType(108).then(res=>{
        this.positionList = res.data
      })
    },
    addressChange(e){
      if(e?.length !== 3) return
      this.form.address = this.$refs.formAddress.getCheckedNodes()[0].pathLabels[0] + '/' + this.$refs.formAddress.getCheckedNodes()[0].pathLabels[1] + '/' + this.$refs.formAddress.getCheckedNodes()[0].pathLabels[2]
    },
    disabledDate(date) {  // 时间限定
			return date.getTime() < Date.now() - 8.64e7;
		},
    purposeBoxChange(val,o){
      if(val){
        if(o.target.defaultValue === '其他'){
          this.form.purpose = ['其他']
        }
        else{
          if(this.form.purpose.indexOf('其他') !== -1){
            this.form.purpose.splice(this.form.purpose.indexOf('其他'),1)
          }
        }
      }
    },
    termBoxChange(val,o){
      if(val){
        if(o.target.defaultValue === '其他'){
          this.form.term = ['其他']
        }
        else{
          if(this.form.term.indexOf('其他') !== -1){
            this.form.term.splice(this.form.term.indexOf('其他'),1)
          }
        }
      }
    },
    corBoxChange(val,o){
      if(val){
        if(o.target.defaultValue === '其他'){
          this.form.corporate = ['其他']
        }
        else{
          if(this.form.corporate.indexOf('其他') !== -1){
            this.form.corporate.splice(this.form.corporate.indexOf('其他'),1)
          }
        }
      }
    },
    async publish(){
      if(!this.form.name){
        ElMessage.warning('请输入您的姓名')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请输入联系电话')
        return
      }
      else if(!this.form.email){
        ElMessage.warning('请输入联系邮箱')
        return
      }
      else if(!this.form.education){
        ElMessage.warning('请选择最高学历')
        return
      }
      else if(!this.form.school){
        ElMessage.warning('请输入毕业院校')
        return
      }
      else if(!this.form.belong){
        ElMessage.warning('请输入所在单位')
        return
      }
      else if(!this.form.college){
        ElMessage.warning('请输入所在学院')
        return
      }
      else if(!this.form.position){
        ElMessage.warning('请选择职称职级')
        return
      }
      else if(!this.form.address){
        ElMessage.warning('请选择所属地区')
        return
      }
      else if(this.headImage?.length === 0){
        ElMessage.warning('请上传头像')
        return
      }
      else if(!this.form.area){
        ElMessage.warning('请选择技术领域')
        return
      }
      else if(!this.form.direction){
        ElMessage.warning('请输入研究方向')
        return
      }
      else if(!this.form.checked){
        ElMessage.warning('请确认信息真实性并同意平台审核')
        return
      }
      try {
        let result = await this.directUpload(this.headImage[0], uploadTypeImage)
        this.headImage[0] = result.Location.slice(result.Location.indexOf("/"))
      } catch (error) {
        console.log(error.message);
      }
      editProfileBasic({
        id: getG60Id(),
        handUrl: this.headImage[0],
      }).then(res=>{
        if(res.code === 'Sx200'){
          // 成功后修改本地缓存
          const info = getG60LocalInfo()
          info.memberInfo.headImage = this.headImage[0]
          setLocalInfo(info)
          this.$store.commit('user/SET_RELOAD_USER')
        }
      })
      const param = {
        annexFile: this.form.files[0] || '',
        applicationIndustry: this.form.area[this.form.area.length - 1],
        applicationIndustryParent: this.form.area[0] || '',
        area: this.form.address.split('/')[2],
        belong: this.form.belong,
        city: this.form.address.split('/')[1],
        college: this.form.college,
        direction: this.form.direction,
        education: this.form.education,
        email: this.form.email,
        graduateSchool: this.form.school,
        honor: this.form.achievement,
        introduction: this.form.introduction,
        domain: this.form.industry,
        memberId: getG60Id(),
        province: this.form.address.split('/')[0],
        name: this.form.name,
        phone: this.form.phone,
        rank: this.form.position
      }
      addG60Expert(param).then(res=>{
        if(res.code === 'Sx200'){
          this.clearForm()
          this.$store.dispatch('G60/openSuccess','感谢您完成专家入驻表单的填写!_我们的团队将尽快审核您的信息，并与您联系介绍更多合作机会和平台功能。')
        }
      })
    },
    changeThumb(i){
      this.thumbCheckbox = this.thumbCheckbox.map((item,index)=>{
        if(index === i) item = true
        else item = false
        return item
      })
    },
    findCheckbox(file){
      let arr = this.$refs['upload0'].uploadFiles
      let index = arr.indexOf(file)
      return index
    },
    deleteFile(){ // 删除文件
      this.form.files = []
      this.fileName = []
      this.$refs['upload1'].uploadFiles.splice(0, 1);
    },
    handleRemoveImage(file){
      let arr = this.$refs['upload0'].uploadFiles
      let index = arr.indexOf(file)
      this.$refs['upload0'].uploadFiles.splice(index, 1);
      this.headImage.splice(index, 1);
    },
    beforeUploadFile(file){
      if (file.size > 100 * 1024 * 1024) {
        // 不超过100M
        ElMessage.warning('上传文件大小过大，不能超过100M!')
        return false
      }
    },
    handleExceedFile(){ // 文件超出个数限制时的钩子
      ElMessage.warning('最多只能上传1个文件～')
    },
    beforeUpload(file) { // 上传图片前的判断
      if (file.size > 20 * 1024 * 1024) {
        // 不超过20M
        ElMessage.warning('上传图片大小过大，不能超过20M!')
        return false
      }
    },
    async handleUploadFile(val){
      if(val.file.size === 0){ // 空文件判断
        ElMessage.warning('请勿上传空文件!')
        this.deleteFile() //提示后删除
        return
      }
      if(val.file.name.length > 70){    // 如果超过70个字符
        ElMessage.warning('文件名称过长,请修改!')
        this.deleteFile() //提示后删除
        return
      }
      let res = await canUpload(val.file.name)
      // console.log(res.data,'格式判断');
      if(!res.data){  // 文件格式判断
        ElMessage.error('不支持上传此格式文件!')
        this.deleteFile() //提示后删除
        return
      }
      let result = await this.directUploadFile(val.file)
      // 路径+/年/月/日/时间戳/文件原名称(文件名称+类型)
      let path = result.Location.slice(result.Location.indexOf("/"))
      this.form.files.push('https://' + fileStaticPath + '/' + path.slice(0,path.indexOf('/',12) + 1) + this.fileName[0].name)
    },
    directUploadFile(file){ // 暂用video的桶
      let self = this
      let cos = cosByUploadType(uploadTypeFile)
      let bucket = bucketMap.file
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket, /* 必须 */
            Region: "ap-shanghai",     /* 存储桶所在地域，必须字段 */
            Key: randomFilename()+'/' + file.name,              /* 必须 */
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              // console.log(progressData)
              self.$refs['upload1'].uploadFiles.map((item) => {
                if (item.raw.uid === file.uid) {
                  if(item.percentage !== 100){  // 已经上传完成不再操作
                    item.percentage = parseInt(progressData.percent * 100)
                    if(item.percentage === 100) {
                      ElMessage.success('上传文件成功!')
                      self.btnDisabled = false
                    }
                    else  self.btnDisabled = true
                  }
                }
              })
            }
          },
          function (err, data) {
            if (err) {
              console.error('cos error code:',err.code,err.message);
              if(err.message.includes('getAuthorization')){
                // ! 获取授权错误
                ElMessage.warning('网络波动，请稍后再试!')
              }
              self.btnDisabled = false
              reject(err)
            } else {
              resolve(data)
            }
          }
        );
      })
    },
    async handleUpload(val) {  // 上传图片
      this.headImage.push(val.file)
    },
    directUpload(file, uploadType) {  // 图片视频直传
      var self = this
      let cosMap = {
        image: cosByUploadType(uploadTypeImage),
        video: cosByUploadType(uploadTypeVideo)
      }
      let cos = uploadType === uploadTypeImage ? cosMap.image : cosMap.video
      let bucket = uploadType === uploadTypeImage ? bucketMap.image : bucketMap.video
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket, /* 必须 */
            Region: "ap-shanghai",     /* 存储桶所在地域，必须字段 */
            Key: randomFilename(),              /* 必须 */
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              self.$refs['upload0'].uploadFiles.map((item) => {
                if (item.raw.uid === file.uid) {
                  item.percentage = parseInt(progressData.percent * 100)
                  if (item.percentage === 100) self.btnDisabled = false
                  else self.btnDisabled = true
                }
              })
            }
          },
          function (err, data) {
            if (err) {
              console.error('cos error code:',err.code,err.message);
              if(err.message.includes('getAuthorization')){
                // ! 获取授权错误
                ElMessage.warning('网络波动，请稍后再试!')
              }
              self.btnDisabled = false
              reject(err)
            } else {
              resolve(data)
            }
          }
        );
      })
    },
    handleExceed() { // 图片超出个数限制时的钩子
      ElMessage.warning(`最多只能上传1张图片～`)
    },
    async loadJsonFromFile(file, fileList) {  // 读取图片
      console.log(file)
      console.log(fileList);
    },
    loadJsonFromFile_file(file, fileList) {
      this.fileName = fileList
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background: rgba(255, 255, 255, 0.31);
  padding-bottom: 100px;

  .title {
    width: 100%;
    height: 174px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/11/27/170366520613363');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 56px 0;
    margin-bottom: 28px;

    .t-0 {
      width: 100%;
      text-align: center;
      font-size: 28px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 28px;
      margin-bottom: 17px;
    }

    .t-1 {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 16px;
    }
  }

  .f-container {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 1257px;
  }

  .form {
    width: 1132px;
    .m-l-534{
      margin-left: 534px;
    }
    .f-title {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .p-t-44 {
      padding-top: 44px;
    }

    .checkbox-line {
      display: flex;
      width: 100%;
      align-items: center;
      margin-left: 50px;
      :deep(.el-form-item__error){
        top: 80% !important;
      }
      .i-name {
        width: 135px;
        text-align: right;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        margin-bottom: 30px;
        span {
          color: #ED0900;
        }

        line-height: 16px;
      }

      :deep(.el-checkbox-group) {
        .el-form-item{
          width: calc(100% - 135px);
        }
        .el-checkbox__label {
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 16px;
        }

        .is-checked {
          .el-checkbox__label {
            color: $--G60-theme !important;
          }

          .el-checkbox__inner {
            background-color: $--G60-theme !important;
            border: 1px solid $--G60-theme !important;
          }
        }
      }
    }

    .textarea-line {
      width: 100%;
      display: flex;
      margin-bottom: 28px;
      :deep(.el-form-item__error){
        top: 110% !important;
      }
      .i-name {
        width: 135px;
        text-align: right;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        padding-top: 8px;

        span {
          color: #ED0900;
        }

        line-height: 16px;
      }

      .textarea {
        resize: none;
        box-sizing: border-box;
        width: 998px;
        height: 100px;
        border-radius: 4px;
        font-size: 16px;
        line-height: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
      }
    }

    .input-line {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 4px 0;

      .i-name {
        width: 135px;
        text-align: right;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        margin-bottom: 20px;
        span {
          color: #ED0900;
        }

        line-height: 16px;
      }

      .input {
        width: 420px;
        height: 40px;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 16px;
      }
      .budget-line{
        display: flex;
        align-items: center;
      }
      .small{
        width: 118px !important;
        position: relative;
        box-sizing: border-box;
        &::after{
          content: '万';
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 16px;
          position: absolute;
          right: 9px;
          top: 50%;
          margin-top: -8px;
        }
        :deep(.el-input__inner){
          padding-right: 34px !important;
        }
      }
      .tip{
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 16px;
        margin: 18px;
      }
      .checkbox{
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 16px;
        margin-left: 82px;
      }
    }

    .update-line {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 15px 0;

      .i-name {
        width: 135px;
        text-align: right;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        margin-bottom: 20px;
        color: #111111;

        span {
          color: #ED0900;
        }

        line-height: 16px;
      }
      .file-item{
        :deep(.el-upload-list){
          display: none !important;
        }
      }
      .has-file{
        max-width: calc(100% - 500px);
        height: 110px;
        .file-container{
          max-width: 600px;
          height: 110px;
          position: relative;
          .file-name{
            width: 100%;
            height: 90px;
            box-sizing: border-box;
            padding: 0 5px;
            font-size: 16px;
            line-height: 90px;
            text-align: center;
          }
          .icon-delete{
            padding: 0px 10px 0 10px;
            top: 50%;
            margin-top: -18px;
            height: 16px;
            right: -50px;
            position: absolute;
            cursor: pointer;
          }
        }

        :deep(.el-upload) {
          max-width: 600px !important;
          height: 110px !important;
          border: none !important;
          background-color: transparent !important;
        }
        :deep(.el-upload-list){
          max-width: 600px !important;
          height: 110px !important;
          .el-upload-list__item{
            position: relative;
            max-width: 600px !important;
            height: 110px !important;
            border: none !important;
            .el-upload-list__item-thumbnail{
              height: 90px !important;
            }
            .icon-delete{
              padding: 0px 10px 0 10px;
              top: 50%;
              margin-top: -18px;
              height: 16px;
              right: -50px;
              position: absolute;
              cursor: pointer;
            }
            .default-checkbox{
              font-size: 14px;
              color: #666666;
              bottom: -4px;
              left: 2px;
              position: absolute;
            }
          }
        }
      }
      .img-container {
        max-width: calc(100% - 500px);
        height: 110px;
        .file-container{
          width: 90px;
          height: 110px;
          position: relative;
          .file-name{
            width: 100%;
            height: 90px;
            box-sizing: border-box;
            padding: 0 5px;
            font-size: 16px;
            line-height: 90px;
            text-align: center;
          }
          .icon-delete{
            background-color: rgba(0,0,0,0.2);
            padding: 3px 10px 0 10px;
            top: 0;
            right: 0;
            position: absolute;
            cursor: pointer;
          }
        }

        :deep(.el-upload) {
          width: 90px !important;
          height: 110px !important;
          border: none !important;
          background-color: transparent !important;
        }
        :deep(.el-upload-list){
          width: 90px !important;
          height: 110px !important;
          .el-upload-list__item{
            position: relative;
            width: 90px !important;
            height: 110px !important;
            border: none !important;
            .el-upload-list__item-thumbnail{
              height: 90px !important;
            }
            .icon-delete{
              background-color: rgba(0,0,0,0.2);
              padding: 3px 10px 0 10px;
              top: 0;
              right: 0;
              position: absolute;
              cursor: pointer;
            }
            .default-checkbox{
              font-size: 14px;
              color: #666666;
              bottom: -4px;
              left: 2px;
              position: absolute;
            }
          }
        }
      }
    }
    .upload-tip {
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      margin-left: 130px;
      margin-top: -15px;
    }
    .button-line{
      padding-top: 35px;
      display: flex;
      justify-content: center;
      button{
        width: 226px;
        height: 40px;
        background: $--G60-theme;
        border-radius: 3px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
      }
    }
  }
}
</style>