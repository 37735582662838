<template>
  <div class="container" v-loading="totalLoading">
    <div class="title">
      <p class="t-0">需求发布</p>
      <p class="t-1">科研成果展示 促进转化落地</p>
    </div>
    <div class="f-container" v-loading="clearLoading">
      <div class="form" v-if="!clearLoading">
        <el-form :model="form" :rules="rules">
          <p class="f-title">需求基本信息</p>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>需求名称：</p>
              <el-form-item prop="name">
                <el-input class="input" v-model="form.name" maxlength="100" placeholder="请输入需求名称" />
              </el-form-item>
            </div>
            <div class="flex-cc">
              <p class="i-name"><span>*</span>期望解决时间：</p>
              <el-form-item prop="time">
                <el-date-picker
                  :style="filterStyle"
                  v-model="form.time"
                  type="daterange"
                  format="YYYY年MM月DD日"
                  :disabledDate="disabledDate"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>所属领域：</p>
              <el-form-item prop="area">
                <el-cascader placeholder="请选择" :style="filterStyle"
                :props="{ checkStrictly: true, expandTrigger: 'hover' }" :popper-class="'el-popDropDownPanelArea'"
                :show-all-levels="false" v-model="form.area" clearable :options="areaList" @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange">
                </el-cascader>
              </el-form-item>
            </div>
            <div class="flex-cc">
              <p class="i-name"><span>*</span>需求预算：</p>
              <el-form-item>
                <div class="budget-line">
                  <el-input class="input small" v-model="form.budget.min" maxlength="20"  @input="handleMinChange" />
                  <p class="tip">~</p>
                  <el-input class="input small" v-model="form.budget.max" maxlength="20" @input="handleMaxChange"/>
                  <el-checkbox label="面议" v-model="form.budget.face" class="checkbox"></el-checkbox>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>应用产业：</p>
              <el-form-item prop="industry">
                <el-cascader placeholder="请选择" :style="filterStyle" :props="{ checkStrictly: true, expandTrigger: 'hover' }" :popper-class="'el-popDropDownPanel'" :show-all-levels="false" v-model="form.industry" clearable :options="industryList" @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange">
                </el-cascader>
              </el-form-item>
            </div>
            <div class="flex-cc">
              <p class="i-name"><span>*</span>需求类型：</p>
              <el-form-item prop="form">
                <el-select v-model="form.type" placeholder="请选择需求类型" :style="filterStyle">
                  <el-option v-for="jtem in typeList" :key="jtem.id" :label="jtem.val" :value="jtem.val">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>所属地区：</p>
              <el-form-item prop="addressId">
                <el-cascader :style="filterStyle" placeholder="请选择所属地区" :popper-class="'el-popDropDownPanelAddress'" v-model="form.addressId" clearable :props="props" @change="addressChange" ref="formAddress" @visible-change="visibleChange" @expand-change="visibleChange">
                </el-cascader>
              </el-form-item>
            </div>
          </div>
          <div class="checkbox-line" v-if="form.type">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>合作方式：</p>
              <el-form-item prop="corporate">
                <el-checkbox-group v-model="form.corporate" :style="checkboxStyle" :max="3">
                  <el-checkbox :label="item.val" v-for="item in cooperationShow" :key="item.id" @change="corBoxChange"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>
          </div>
          <div v-if="form.type === '投融资'">
            <div class="checkbox-line">
              <div class="flex-cc">
                <p class="i-name"><span>*</span>融资期限：</p>
                <el-form-item prop="term">
                  <el-checkbox-group v-model="form.term" :style="checkboxStyle" :max="3">
                    <el-checkbox :label="item.val" v-for="item in termList" :key="item.id" @change="termBoxChange"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </div>
            <div class="checkbox-line">
              <div class="flex-cc">
                <p class="i-name"><span>*</span>融资用途：</p>
                <el-form-item prop="purpose">
                  <el-checkbox-group v-model="form.purpose" :style="checkboxStyle" :max="3">
                    <el-checkbox :label="item.val" v-for="item in purposeList" :key="item.id" @change="purposeBoxChange"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </div>
          </div>
          <p class="f-title p-t-44">需求描述信息</p>
          <div class="textarea-line">
            <p class="i-name"><span>*</span>需求简述：</p>
            <el-form-item prop="description">
              <el-input
                class="textarea"
                type="textarea"
                resize="none"
                :rows="4"
                maxlength="3000"
                placeholder="请输入需求简述"
                v-model="form.description">
              </el-input>
            </el-form-item>
          </div>
          <div class="textarea-line">
            <p class="i-name">需求达到目标：</p>
            <el-form-item prop="prospect">
              <el-input
                class="textarea"
                type="textarea"
                resize="none"
                :rows="4"
                maxlength="3000"
                placeholder="请输入需求达到目标"
                v-model="form.prospect">
              </el-input>
            </el-form-item>
          </div>
          <p class="f-title p-t-20">需求信息上传</p>
          <div class="update-line">
            <p class="i-name">上传需求附件：</p>
            <div class="file-item" :class="{'has-file':form.files?.length > 0,'img-container': form.files?.length === 0}">
              <el-upload ref="upload1" action="#" :limit="1"
                :before-upload="beforeUploadFile" :http-request="handleUploadFile" :on-exceed="handleExceedFile"
                :on-change="loadJsonFromFile_file">
                <template #default>
                  <img src="@/assets/images/G60/publish/upload-icon.png" alt="" srcset="" style="width: 100%;height: 90px;" v-if="form.files?.length === 0">
                  <div v-else class="file-container"  @click.stop>
                    <p class="file-name text-ol-ell">{{fileName[0].name}}</p>
                    <div @click.stop="deleteFile()" class="icon-delete">
                      <i class="el-icon-delete" style="fontSize: 16px;color: #000000;"></i>
                    </div>
                  </div>
                </template>
              </el-upload>
            </div>
          </div>
          <div class="upload-tip">
            （最多上传1个附件，文件不能超过100M）
          </div>
          <p class="f-title" style="margin-top: 40px;">需求联系信息（不会在前端展示）</p>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>联系人：</p>
              <el-form-item prop="person">
                <el-input class="input" v-model="form.person" maxlength="20" placeholder="请输入联系人"/>
              </el-form-item>
            </div>
          </div>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>所属单位：</p>
              <el-form-item prop="belong">
                <el-input class="input" v-model="form.belong" maxlength="20" placeholder="请输入所属单位" />
              </el-form-item>
            </div>
          </div>
          <div class="input-line">
            <div class="flex-cc">
              <p class="i-name"><span>*</span>联系电话：</p>
              <el-form-item prop="phone">
                <el-input class="input" v-model="form.phone" maxlength="11" placeholder="请输入联系电话" />
              </el-form-item>
            </div>
          </div>
          <div class="input-line">
            <div class="flex-cc" style="position: relative;">
              <p class="i-name"><span>*</span>验证码：</p>
              <el-form-item prop="captcha">
                <el-input class="input captcha" v-model="form.captcha" maxlength="6" placeholder="请输入验证码" @input="handleNumber()" />
              </el-form-item>
              <div class="captcha-button" :class="{'captcha-disable': !showCode}" @click="getPhoneCode()">
                {{ showCode ? '验证码' : count + '秒' }}
              </div>
            </div>
          </div>
        </el-form>
        <div class="button-line">
          <button :disabled="btnDisable" @click="publish">立即发布</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getprovince, getcity, getarea } from "@/api/demand";
import { ElMessage } from "element-plus";
import { uploadTypeImage,uploadTypeVideo, uploadTypeFile, cosByUploadType } from "@/api/upload";
import { bucketMap,fileStaticPath } from "@/utils/consts";
import { randomFilename } from "@/utils/time";
import { publishDemand } from "@/api/G60"
import { getSelectFatherSonByType } from "@/api/config";
import { getG60Id,G60Login,removeG60LocalInfo } from "@/utils/G60/login";
import { getPhoneCode } from "@/api/user";


export default {
  data() {
    return {
      totalLoading: false,
      clearLoading: false,
      form: {
        name: '',
        time: [],
        area: '',
        budget: {
          min: '',
          max: '',
          face: false,
        },
        address: '',
        addressId: [],
        industry: '',
        type: '',
        corporate: [],  // 最多3个
        term: [], // 最多3个
        purpose: [],// 最多3个
        description: '',
        aim: '',
        files: [],
        phone: '',
        belong: '',
        person: '',
        captcha: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入成果名称', trigger: 'blur' },
        ],
        time:[{
          type: 'array',
          required: true,
          message: '请选择日期区间',
          fields: {
            0: { type: 'date', required: true, message: '请选择开始日期' },
            1: { type: 'date', required: true, message: '请选择结束日期' }
          },
          trigger: 'change',}
        ],
        addressId:[
          { required: true, message: '请选择所属地区', trigger: 'change' },
        ],
        area:[
          { required: true, message: '请选择所属领域', trigger: 'change' },
        ],
        industry:[
          { required: true, message: '请选择应用产业', trigger: 'change' },
        ],
        type:[
          { required: true, message: '请选择需求类型', trigger: 'change' },
        ],
        corporate: [
          { type: 'array', required: true, message: '请至少选择一个合作方式，最多选择3项', trigger: 'change' }
        ],
        term:[
          { type: 'array', required: true, message: '请至少选择一个融资期限，最多选择3项', trigger: 'change' }
        ],
        purpose:[
          { type: 'array', required: true, message: '请至少选择一个融资用途，最多选择3项', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请输入需求简述', trigger: 'blur' },
        ],
        person:[
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        belong:[
          { required: true, message: '请输入所属单位', trigger: 'blur' },
        ],
        phone:[
          { required: true, message: '请输入联系方式', trigger: 'blur' },
        ],
        captcha:[
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
      count: 60,
      showCode: true, // 是否显示倒计时
      timer: null,
      thumbCheckbox: [],
      fileName: [],
      props: {  // 懒加载列表内容
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then(response => {
                const cities = []
                for (let item of response.data) {
                  cities.push({
                    value: item.provinceCode,
                    label: item.provinceName,
                    leaf: false
                  })
                }
                resolve(cities);
                const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
                Array.from($el).map((item) => item.removeAttribute('aria-owns'));
              });
            }
            if (node.level == 1) {
              getcity(node.value).then(response => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.cityCode,
                    label: item.cityName,
                    leaf: false
                  })
                }
                const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
                Array.from($el).map((item) => item.removeAttribute('aria-owns'));
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.value).then(response => {
                const areas = []
                for (let item of response.data) {
                  areas.push({
                    value: item.areaCode,
                    label: item.areaName,
                    leaf: true
                  })
                }
                const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
                Array.from($el).map((item) => item.removeAttribute('aria-owns'));
                resolve(areas);
              });
            }
          }, 100);
        }
      },
      filterStyle: 'width: 420px;height: 40px;',
      checkboxStyle: 'width: 100%;',
      btnDisable: false,
      imageSize: 4,
      industryList: [],
      termList: [],
      typeList: [],
      purposeList: [],
      areaList: [],
      cooperationList: [],
    }
  },
  watch:{
    typeName(){
      this.form.corporate = []
      this.form.term = []
      this.form.purpose = []
    },
    budgetFace(val){
      if(val){
        this.form.budget.min = ''
        this.form.budget.max = ''
      }
    },
    budgetMin(val){
      if(val){
        this.form.budget.face = false
      }
    },
    budgetMax(val){
      if(val){
        this.form.budget.face = false
      }
    },
  },
  computed:{
    typeName(){
      return this.form.type || ''
    },
    cooperationShow(){
      if(!this.form.type) return []
      else{
        return this.cooperationList.filter(item=>item.remark === this.form.type)
      }
    },
    budgetFace(){
      return this.form.budget.face
    },
    budgetMin(){
      return this.form.budget.min
    },
    budgetMax(){
      return this.form.budget.max
    },
  },
  mounted() {
    this.getSelections()
  },
  methods: {
    getPhoneCode() {
      const TIME_COUNT = 60;
      const reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (reg.test(this.form.phone) == false) {
        ElMessage.warning("您输入的手机号码格式不正确，请重新输入");
        return;
      }
      getPhoneCode({ phone: this.form.phone })
        .then(() => {
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.showCode = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
          ElMessage.success("发送成功");
        })
        .catch((err) => {
          ElMessage.error({ message: err.message });
        });
    },
    handleNumber(){  // 数字处理
      let pattern = new RegExp("^[0-9]|-$")
      this.form.captcha = Array.from(this.form.captcha).filter(v => pattern.test(v)).join("")
    },
    handleMinChange(e){
      let pattern = new RegExp("^[0-9]|-$")
      this.form.budget.min = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    handleMaxChange(e){
      let pattern = new RegExp("^[0-9]|-$")
      this.form.budget.max = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    clearForm(){
      this.form = {
        name: '',
        time: [],
        area: '',
        budget: {
          min: '',
          max: '',
          face: false,
        },
        address: '',
        addressId: [],
        industry: '',
        type: '',
        corporate: [],  // 最多3个
        term: [], // 最多3个
        purpose: [],// 最多3个
        description: '',
        aim: '',
        files: [],
        phone: '',
        belong: '',
        person: '',
        captcha: '',
      }
      if(this.timer){
        this.showCode = true;
        clearInterval(this.timer);
        this.timer = null;
      }
      this.clearLoading = true
      setTimeout(()=>{
        this.clearLoading = false
      },200)
    },
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    getSelections(){
      getSelectFatherSonByType(98).then(res=>{
        this.industryList = res.data.map((item)=>{
          return{
            label: item.val,
            value: item.val,
            children: !item.child ? [] : item.child.map((jtem)=>{
              return{
                label: jtem.val,
                value: jtem.val,
                children: !jtem.child ? [] : jtem.child.map(ktem=>{
                  return{
                    label: ktem.val,
                    value: ktem.val,
                    children: !ktem.child ? [] : ktem.child.map(ltem=>{
                      return{
                        label: ltem.val,
                        value: ltem.val,
                      }
                    })
                  }
                })
              }
            })
          }
        })
      })
      getSelectFatherSonByType(97).then(res=>{
        this.areaList = res.data.map((item)=>{
          return{
            label: item.val,
            value: item.val,
            children: !item.child ? [] : item.child.map((jtem)=>{
              return{
                label: jtem.val,
                value: jtem.val,
                children: !jtem.child ? [] : jtem.child.map(ktem=>{
                  return{
                    label: ktem.val,
                    value: ktem.val,
                  }
                })
              }
            })
          }
        })
      })
      getSelectFatherSonByType(106).then(res=>{
        this.cooperationList = res.data
      })
      getSelectFatherSonByType(103).then(res=>{
        this.typeList = res.data
      })
      getSelectFatherSonByType(104).then(res=>{
        this.termList = res.data
      })
      getSelectFatherSonByType(105).then(res=>{
        this.purposeList = res.data
      })
    },
    addressChange(e){
      if(e?.length !== 3) return
      this.form.address = this.$refs.formAddress.getCheckedNodes()[0].pathLabels[0] + '/' + this.$refs.formAddress.getCheckedNodes()[0].pathLabels[1] + '/' + this.$refs.formAddress.getCheckedNodes()[0].pathLabels[2]
    },
    disabledDate(date) {  // 时间限定
			return date.getTime() < Date.now() - 8.64e7;
		},
    purposeBoxChange(val,o){
      if(val){
        if(o.target.defaultValue === '其它'){
          this.form.purpose = ['其它']
        }
        else{
          if(this.form.purpose.indexOf('其它') !== -1){
            this.form.purpose.splice(this.form.purpose.indexOf('其它'),1)
          }
        }
      }
    },
    termBoxChange(val,o){
      if(val){
        if(o.target.defaultValue === '其它'){
          this.form.term = ['其它']
        }
        else{
          if(this.form.term.indexOf('其它') !== -1){
            this.form.term.splice(this.form.term.indexOf('其它'),1)
          }
        }
      }
    },
    corBoxChange(val,o){
      if(val){
        if(o.target.defaultValue === '其它'){
          this.form.corporate = ['其它']
        }
        else{
          if(this.form.corporate.indexOf('其它') !== -1){
            this.form.corporate.splice(this.form.corporate.indexOf('其它'),1)
          }
        }
      }
    },
    async publish(){
      if(!this.form.name){
        ElMessage.warning('请输入需求名称')
        return
      }
      else if(this.form.time.length === 0){
        ElMessage.warning('请选择期望解决时间')
        return
      }
      else if(!this.form.area){
        ElMessage.warning('请选择所属领域')
        return
      }
      else if(!this.budgetFace && !this.budgetMin && !this.budgetMax){
        ElMessage.warning('请输入或选择需求预算')
        return
      }
      else if(Number(this.budgetMin) > Number(this.budgetMax)){
        ElMessage.warning('请输入正确的需求预算')
        return
      }
      else if(!this.form.industry){
        ElMessage.warning('请选择应用产业')
        return
      }
      else if(!this.form.type){
        ElMessage.warning('请选择需求类型')
        return
      }
      else if(!this.form.address){
        ElMessage.warning('请选择所属地区')
        return
      }
      else if(this.form.type === '投融资' && this.form.term?.length === 0){
        ElMessage.warning('请选择融资期限')
        return
      }
      else if(this.form.type === '投融资' && this.form.purpose?.length === 0){
        ElMessage.warning('请选择融资用途')
        return
      }
      else if(!this.form.corporate?.length === 0){
        ElMessage.warning('请选择合作方式')
        return
      }
      else if(!this.form.description){
        ElMessage.warning('请输入需求简述')
        return
      }
      else if(!this.form.person){
        ElMessage.warning('请输入需求联系人')
        return
      }
      else if(!this.form.belong){
        ElMessage.warning('请输入需求所属单位')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请输入需求联系电话')
        return
      }
      else if(!this.form.captcha){
        ElMessage.warning('请输入验证码')
        return
      }
      this.totalLoading = true
      // 先登录
      try {
        await G60Login({
          machineCode: '',
          phone: this.form.phone,
          code: this.form.captcha,
        })
      } catch (error) {
        console.log(error.message);
      }
      // 再进行附件上传
      try {
        if(this.form.files?.length > 0){
          let result = await this.directUploadFile(this.form.files[0])
          // 路径+/年/月/日/时间戳/文件原名称(文件名称+类型)
          let path = result.Location.slice(result.Location.indexOf("/"))
          this.form.files[0] = 'https://' + fileStaticPath + '/' + path.slice(0,path.indexOf('/',12) + 1) + this.fileName[0].name
        }
      } catch (error) {
        this.deleteFile()
      }
      // 之后再进行发布
      let date = this.form.time[0]
      let date2 = this.form.time[1]
      const date0 = `${date.getFullYear()}年${(date.getMonth() + 1) > 10 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)}月${date.getDate() > 10 ? date.getDate() : '0' + date.getDate()}日`;
      const date1 = `${date2.getFullYear()}年${(date2.getMonth() + 1) > 10 ? (date2.getMonth() + 1) : '0' + (date2.getMonth() + 1)}月${date2.getDate() > 10 ? date2.getDate() : '0' + date2.getDate()}日`;
      console.log(date0,date1);
      const param = {
        addContactsReq:{
          contacts: this.form.person,
          phone: this.form.phone,
          position: this.form.belong,
        },
        annexFile: this.form.files[0] || '',
        applicationIndustry: this.form.area[this.form.area.length - 1],
        applicationIndustryParent: this.form.area[0] || '',
        area: this.form.address.split('/')[2],
        city: this.form.address.split('/')[1],
        budget: this.form.budget.face ? '面议' : this.form.budget.min + '-' + this.form.budget.max,
        cooperationModeList: this.cooperationList.filter((item)=>this.form.corporate.includes(item.val)).map(item=>{
          return{
            selectConfigId: item.id,
            selectConfigVal: item.val,
          }
        }),
        domain: this.form.industry[this.form.industry.length - 1],
        domainParent: this.form.industry[0] || '',
        endDate: date1,
        financingPeriod: this.termList.filter((item)=>this.form.term.includes(item.val)).map(item=>{
          return{
            selectConfigId: item.id,
            selectConfigVal: item.val,
          }
        }),
        financingPurposes: this.purposeList.filter((item)=>this.form.purpose.includes(item.val)).map(item=>{
          return{
            selectConfigId: item.id,
            selectConfigVal: item.val,
          }
        }),
        marketOutlook: this.form.prospect,
        memberId: getG60Id(),
        province: this.form.address.split('/')[0],
        sketch: this.form.description,
        startDate: date0,
        target: this.form.prospect,
        title: this.form.name,
        typeList: this.typeList.filter((item)=>item.val === this.form.type).map(item=>{
          return{
            selectConfigId: item.id,
            selectConfigVal: item.val,
          }
        }),
      }
      publishDemand(param).then(res=>{
        if(res.code === 'Sx200'){
          this.clearForm()
          this.$store.dispatch('G60/openSuccess','您的需求已提交审核_产学研经理将在1-3个工作日内与您联系！')
          removeG60LocalInfo()  // 发布成功后删除缓存
        }
      }).finally(()=>{
        this.totalLoading = false
      })
    },
    changeThumb(i){
      this.thumbCheckbox = this.thumbCheckbox.map((item,index)=>{
        if(index === i) item = true
        else item = false
        return item
      })
    },
    findCheckbox(file){
      let arr = this.$refs['upload0'].uploadFiles
      let index = arr.indexOf(file)
      return index
    },
    deleteFile(){ // 删除文件
      this.form.files = []
      this.fileName = []
      this.$refs['upload1'].uploadFiles.splice(0, 1);
    },
    handleRemoveImage(file){
      let arr = this.$refs['upload0'].uploadFiles
      let index = arr.indexOf(file)
      this.$refs['upload0'].uploadFiles.splice(index, 1);
      this.form.images.splice(index, 1);
      this.thumbCheckbox.splice(index, 1)
      if(!this.thumbCheckbox.find(item=>item === true) && this.thumbCheckbox.length > 0){
        this.thumbCheckbox[0] = true
      }
    },
    beforeUploadFile(file){
      if (file.size > 100 * 1024 * 1024) {
        // 不超过100M
        ElMessage.warning('上传文件大小过大，不能超过100M!')
        return false
      }
    },
    handleExceedFile(){ // 文件超出个数限制时的钩子
      ElMessage.warning('最多只能上传1个文件～')
    },
    beforeUpload(file) { // 上传图片前的判断
      if (file.size > 20 * 1024 * 1024) {
        // 不超过20M
        ElMessage.warning('上传图片大小过大，不能超过20M!')
        return false
      }
    },
    async handleUploadFile(val){
      if(val.file.size === 0){ // 空文件判断
        ElMessage.warning('请勿上传空文件!')
        this.deleteFile() //提示后删除
        return
      }
      if(val.file.name.length > 70){    // 如果超过70个字符
        ElMessage.warning('文件名称过长,请修改!')
        this.deleteFile() //提示后删除
        return
      }
      this.form.files.push(val.file)
    },
    directUploadFile(file){ // 暂用video的桶
      let self = this
      let cos = cosByUploadType(uploadTypeFile)
      let bucket = bucketMap.file
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket, /* 必须 */
            Region: "ap-shanghai",     /* 存储桶所在地域，必须字段 */
            Key: randomFilename()+'/' + file.name,              /* 必须 */
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              // console.log(progressData)
              self.$refs['upload1'].uploadFiles.map((item) => {
                if (item.raw.uid === file.uid) {
                  if(item.percentage !== 100){  // 已经上传完成不再操作
                    item.percentage = parseInt(progressData.percent * 100)
                    if(item.percentage === 100) {
                      ElMessage.success('上传文件成功!')
                      self.btnDisabled = false
                    }
                    else  self.btnDisabled = true
                  }
                }
              })
            }
          },
          function (err, data) {
            if (err) {
              console.error('cos error code:',err.code,err.message);
              if(err.message.includes('getAuthorization')){
                // ! 获取授权错误
                ElMessage.warning('网络波动，请稍后再试!')
              }
              self.btnDisabled = false
              reject(err)
            } else {
              resolve(data)
            }
          }
        );
      })
    },
    async handleUpload(val) {  // 上传图片
      let result = await this.directUpload(val.file, uploadTypeImage)
      this.form.images.push(result.Location.slice(result.Location.indexOf("/")))
      this.thumbCheckbox.push(false)
      if(this.thumbCheckbox.length === 1) this.thumbCheckbox[0] = true
      console.log(result,'hehe22',this.form);
    },
    directUpload(file, uploadType) {  // 图片视频直传
      var self = this
      let cosMap = {
        image: cosByUploadType(uploadTypeImage),
        video: cosByUploadType(uploadTypeVideo)
      }
      let cos = uploadType === uploadTypeImage ? cosMap.image : cosMap.video
      let bucket = uploadType === uploadTypeImage ? bucketMap.image : bucketMap.video
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket, /* 必须 */
            Region: "ap-shanghai",     /* 存储桶所在地域，必须字段 */
            Key: randomFilename(),              /* 必须 */
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              self.$refs['upload0'].uploadFiles.map((item) => {
                if (item.raw.uid === file.uid) {
                  item.percentage = parseInt(progressData.percent * 100)
                  if (item.percentage === 100) self.btnDisabled = false
                  else self.btnDisabled = true
                }
              })
            }
          },
          function (err, data) {
            if (err) {
              console.error('cos error code:',err.code,err.message);
              if(err.message.includes('getAuthorization')){
                // ! 获取授权错误
                ElMessage.warning('网络波动，请稍后再试!')
              }
              self.btnDisabled = false
              reject(err)
            } else {
              resolve(data)
            }
          }
        );
      })
    },
    handleExceed() { // 图片超出个数限制时的钩子
      ElMessage.warning(`最多只能上传${this.imageSize}张图片～`)
    },
    async loadJsonFromFile(file, fileList) {  // 读取图片
      console.log(file)
      console.log(fileList);
    },
    loadJsonFromFile_file(file, fileList) {
      this.fileName = fileList
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background: rgba(255, 255, 255, 0.31);
  padding-bottom: 100px;

  .title {
    width: 100%;
    height: 174px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/11/25/1703502115166064');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 56px 0;
    margin-bottom: 28px;

    .t-0 {
      width: 100%;
      text-align: center;
      font-size: 28px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 28px;
      margin-bottom: 17px;
    }

    .t-1 {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 16px;
    }
  }

  .f-container {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 1257px;
  }

  .form {
    width: 1132px;

    .f-title {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .p-t-44 {
      padding-top: 44px;
    }

    .checkbox-line {
      display: flex;
      width: 100%;
      align-items: center;
      :deep(.el-form-item__error){
        top: 80% !important;
      }
      .i-name {
        width: 135px;
        text-align: right;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        margin-bottom: 30px;
        span {
          color: #ED0900;
        }

        line-height: 16px;
      }

      :deep(.el-checkbox-group) {
        .el-form-item{
          width: calc(100% - 135px);
        }
        .el-checkbox__label {
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 16px;
        }

        .is-checked {
          .el-checkbox__label {
            color: $--G60-theme !important;
          }

          .el-checkbox__inner {
            background-color: $--G60-theme !important;
            border: 1px solid $--G60-theme !important;
          }
        }
      }
    }

    .textarea-line {
      width: 100%;
      display: flex;
      margin-bottom: 28px;
      :deep(.el-form-item__error){
        top: 110% !important;
      }
      .i-name {
        width: 135px;
        text-align: right;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        padding-top: 8px;

        span {
          color: #ED0900;
        }

        line-height: 16px;
      }

      .textarea {
        resize: none;
        box-sizing: border-box;
        width: 998px;
        height: 100px;
        border-radius: 4px;
        font-size: 16px;
        line-height: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
      }
    }

    .input-line {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 4px 0;

      .i-name {
        width: 135px;
        text-align: right;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        margin-bottom: 20px;
        span {
          color: #ED0900;
        }

        line-height: 16px;
      }

      .input {
        width: 420px;
        height: 40px;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 16px;
      }
      .captcha-button{
        position: absolute;
        right: 0;
        top: 0;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: $--G60-theme;
        cursor: pointer;
        padding: 0 15px;
      }
      .captcha-disable{
        color: #00c38280 !important;
      }
      .budget-line{
        display: flex;
        align-items: center;
      }
      .small{
        width: 118px !important;
        position: relative;
        box-sizing: border-box;
        &::after{
          content: '万';
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 16px;
          position: absolute;
          right: 9px;
          top: 50%;
          margin-top: -8px;
        }
        :deep(.el-input__inner){
          padding-right: 34px !important;
        }
      }
      .tip{
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 16px;
        margin: 18px;
      }
      .checkbox{
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 16px;
        margin-left: 82px;
      }
    }

    .update-line {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 15px 0;

      .i-name {
        width: 135px;
        text-align: right;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        margin-bottom: 20px;
        color: #111111;

        span {
          color: #ED0900;
        }

        line-height: 16px;
      }
      .file-item{
        :deep(.el-upload-list){
          display: none !important;
        }
      }
      .has-file{
        max-width: calc(100% - 500px);
        height: 110px;
        .file-container{
          max-width: 600px;
          height: 110px;
          position: relative;
          .file-name{
            width: 100%;
            height: 90px;
            box-sizing: border-box;
            padding: 0 5px;
            font-size: 16px;
            line-height: 90px;
            text-align: center;
          }
          .icon-delete{
            padding: 0px 10px 0 10px;
            top: 50%;
            margin-top: -18px;
            height: 16px;
            right: -50px;
            position: absolute;
            cursor: pointer;
          }
        }

        :deep(.el-upload) {
          max-width: 600px !important;
          height: 110px !important;
          border: none !important;
          background-color: transparent !important;
        }
        :deep(.el-upload-list){
          max-width: 600px !important;
          height: 110px !important;
          .el-upload-list__item{
            position: relative;
            max-width: 600px !important;
            height: 110px !important;
            border: none !important;
            .el-upload-list__item-thumbnail{
              height: 90px !important;
            }
            .icon-delete{
              padding: 0px 10px 0 10px;
              top: 50%;
              margin-top: -18px;
              height: 16px;
              right: -50px;
              position: absolute;
              cursor: pointer;
            }
            .default-checkbox{
              font-size: 14px;
              color: #666666;
              bottom: -4px;
              left: 2px;
              position: absolute;
            }
          }
        }
      }

      .img-container {
        max-width: calc(100% - 500px);
        height: 110px;
        .file-container{
          width: 90px;
          height: 110px;
          position: relative;
          .file-name{
            width: 100%;
            height: 90px;
            box-sizing: border-box;
            padding: 0 5px;
            font-size: 16px;
            line-height: 90px;
            text-align: center;
          }
          .icon-delete{
            background-color: rgba(0,0,0,0.2);
            padding: 3px 10px 0 10px;
            top: 0;
            right: 0;
            position: absolute;
            cursor: pointer;
          }
        }

        :deep(.el-upload) {
          width: 90px !important;
          height: 110px !important;
          border: none !important;
          background-color: transparent !important;
        }
        :deep(.el-upload-list){
          width: 90px !important;
          height: 110px !important;
          .el-upload-list__item{
            position: relative;
            width: 90px !important;
            height: 110px !important;
            border: none !important;
            .el-upload-list__item-thumbnail{
              height: 90px !important;
            }
            .icon-delete{
              background-color: rgba(0,0,0,0.2);
              padding: 3px 10px 0 10px;
              top: 0;
              right: 0;
              position: absolute;
              cursor: pointer;
            }
            .default-checkbox{
              font-size: 14px;
              color: #666666;
              bottom: -4px;
              left: 2px;
              position: absolute;
            }
          }
        }
      }
    }
    .upload-tip {
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      margin-left: 130px;
      margin-top: -15px;
    }
    .button-line{
      padding-top: 35px;
      display: flex;
      justify-content: center;
      button{
        width: 226px;
        height: 40px;
        background: $--G60-theme;
        border-radius: 3px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
      }
    }
  }
}
</style>