<template>
  <div class="content">
    <img src="@/assets/images/post-line/home/logo.png" class="logo" />
    <div class="input-line">
      <input v-model="key" placeholder="请输入关键字，更多筛选请去专家通" class="input" />
      <button class="button">搜 索</button>
    </div>
    <div class="hot-line">
      <img src="@/assets/images/G60/labSpace/hot.png" class="img" />
      <a v-for="item in 5" :key="item" class="a-hot">人工智能</a>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return{
      key: '',
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo{
    width: 142px;
    height: 33px;
    margin-bottom: 24px;
  }
  .input-line{
    width: 600px;
    display: flex;
    align-items: center;
    height: 42px;
    margin-bottom: 14px;
    .input{
      width: 505px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #E8E8E8;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-size: 14px;
      box-sizing: border-box;
      padding: 0 13px;
      height: 42px;
    }
    .button{
      width: 94px;
      height: 42px;
      background: #2280FF;
      border-radius: 0px 4px 4px 0px;
      cursor: pointer;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      border: none;
    }
  }
  .hot-line{
    width: 600px;
    display: flex;
    align-items: center;
    .img{
      width: 50px;
      height: 17px;
      padding-right: 10px;
      border-right: 1px solid #111111;
      margin-right: 7px;
    }
    .a-hot{
      display: block;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      margin: 0 14px;
      &:hover{
        color: $--G60-theme !important;
      }
      &:visited{
        color: #333333;
      }
    }
  }
}
</style>