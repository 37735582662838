<template>
  <div class="content">
    <p class="title">
      资讯推荐
    </p>
    <div class="b-container">
      <div class="left">
        <div class="thumb">
          <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
        </div>
        <div>
          <a class="text-ol-ell a-title">Q3调味品盘点：行业根基稳固，健康或成发展趋势Q3调味品盘点：行业根基稳固，健康或成发展趋势</a>
        </div>
        <div class="publish-info">
          <div class="publisher">
            <div class="avatar">
              <Image :src="'https://person.zju.edu.cn/person/attachments/2021-03/0311030441-1279116452.jpg?imageMogr2/thumbnail/!100p'"></Image>
            </div>
            <div><a class="text-ol-ell a-name">小编辑小编辑小编辑小编辑小编辑</a></div>
            <p class="time">3小时前</p>
          </div>
          <div class="infos">
            <img src="@/assets/images/post-line/info/thumb.png" class="icon" />
            <p class="num text-ol-ell">322</p>
            <img src="@/assets/images/post-line/info/comment.png" class="icon" />
            <p class="num text-ol-ell">322</p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="card" v-for="item in 4" :key="item">
          <div class="thumb">
            <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
          </div>
          <div class="infos">
            <div><a class="text-otl-ell a-title">4万字回顾｜2023电商复盘、2024经4万字回顾｜2023电商复盘、2024经4万字回顾｜2023电商复盘、2024经</a></div>
            <p class="context text-ol-ell">新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼</p>
            <div class="bottom">
              <p class="time">3小时前</p>
              <img src="@/assets/images/post-line/info/thumb.png" class="icon" />
              <p class="num text-ol-ell">322</p>
              <img src="@/assets/images/post-line/info/comment.png" class="icon" />
              <p class="num text-ol-ell">322</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content{
  width: 1200px;
  padding-top: 50px;
  .title{
    font-size: 26px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 26px;
  }
  .b-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left{
      width: 574px;
      .thumb{
        cursor: pointer;
        margin-top: 16px;
        width: 100%;
        height: 358px;
        margin-bottom: 18px;
      }
      .a-title{
        display: block;
        width: 100%;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 24px;
        cursor: pointer;
        &:hover{
          color: $--G60-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .publish-info{
        margin-top: 32px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .publisher{
          display: flex;
          align-items: center;
          .avatar{
            width: 18px;
            height: 18px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 6px;
          }
          .a-name{
            display: block;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            max-width: 200px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #666666;
            }
          }
          .time{
            margin-left: 14px;
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
        .infos{
          display: flex;
          align-items: center;
          .icon{
            margin-left: 12px;
            width: 16px;
            height: 16px;
          }
          p{
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
            margin-left: 3px;
            max-width: 100px;
          }
        }
      }
    }
    .right{
      width: 587px;
      .card{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        .thumb{
          cursor: pointer;
          width: 150px;
          height: 100px;
        }
        .infos{
          width: 420px;
          padding-top: 5px;
          .a-title{
            display: block;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 19px;
            height: 38px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #111111;
            }
          }
          .context{
            margin-top: 12px;
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 12px;
            max-width: 100%;
            margin-bottom: 12px;
          }
          .bottom{
            width: 100%;
            display: flex;
            align-items: center;
            .time{
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 12px;
            }
            .icon{
              width: 16px;
              height: 16px;
              margin-left: 30px;
            }
            .num{
              max-width: 50px;
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 12px;
              margin-left: 1px;
            }
          }
        }
      }
    }
  }
}
</style>