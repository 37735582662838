<template>
  <div class="content">
    <p class="c-title">产品功能介绍</p>
    <div class="introductions">
      <div class="left">
        <div class="part" :class="{'active': cur === 0}" @mouseenter="enterCur(0)" @mouseleave="leaveCur(0)">
          <p class="title">专家资源大数据</p>
          <p class="tip" v-if="cur === 0">构建全面、多维度专家数据库</p>
        </div>
        <div class="part" :class="{'active': cur === 1}" @mouseenter="enterCur(1)" @mouseleave="leaveCur(1)">
          <p class="title">个性化专家推荐</p>
          <p class="tip" v-if="cur === 1">推荐科研专家，高效对接技术合作</p>
        </div>
        <div class="part" :class="{'active': cur === 2}" @mouseenter="enterCur(2)" @mouseleave="leaveCur(2)">
          <p class="title">发布需求与管理</p>
          <p class="tip" v-if="cur === 2">一站式需求管理，及时响应与解决</p>
        </div>
        <div class="part" :class="{'active': cur === 3}" @mouseenter="enterCur(3)" @mouseleave="leaveCur(3)">
          <p class="title">海量创新赛道项目</p>
          <p class="tip" v-if="cur === 3">国内外项目汇聚，发掘合作投资新机遇</p>
        </div>
        <div class="part" :class="{'active': cur === 4}" @mouseenter="enterCur(4)" @mouseleave="leaveCur(4)">
          <p class="title">前沿科技趋势</p>
          <p class="tip" v-if="cur === 4">实时掌握科技动态，决策先人一步</p>
        </div>
      </div>
      <div class="right">
        <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705913552822205" alt="" class="l">
        <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705913561233628" alt="" class="r">
        <img class="animate__animated animate__fadeInUp animate__faster img-p" style="width: 763px;height: 443px;" src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705913876226912" v-if="cur === 0"/>
        <img class="animate__animated animate__zoomInRight sub-p" style="width: 388px;height: 265px;" src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705913890328266" v-if="cur === 0 && showSubImg"/>
        <img class="animate__animated animate__fadeInUp animate__faster img-p" style="width: 763px;height: 433px;" src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705915095435581" v-if="cur === 3"/>
        <img class="animate__animated animate__zoomInRight sub-p" style="width: 394px;height: 271px;" src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705915125955157" v-if="cur === 3 && showSubImg"/>
        <img class="animate__animated animate__fadeInUp animate__faster img-p" style="height: 433px;" src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/170591528837502" v-if="cur === 2"/>
        <img class="animate__animated animate__fadeInUp animate__faster img-p" style="width: 763px;height: 433px;" src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705915188332803" v-if="cur === 1"/>
        <img class="animate__animated animate__fadeInUp animate__faster img-p" style="width: 763px;height: 433px;" src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705915317426459" v-if="cur === 4"/>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return{
      cur: -1,
      inter: null,
      showSubImg: false,
      timeout: null,
    }
  },
  watch:{
    cur(){
      this.showSubImg = false
      if(this.timeout){
        clearTimeout(this.timeout)
        this.timeout = null
      }
      this.timeout = setTimeout(()=>{
        this.showSubImg = true
      },500)
    },
  },
  mounted(){
    this.cur = 0
    this.inter = setInterval(()=>{
      if(this.cur === 4){
        this.cur = 0
      }
      else{
        this.cur+= 1
      }
    },5000)
  },
  methods:{
    enterCur(index){
      if(this.inter){
        clearInterval(this.inter)
        this.inter = null
        this.cur = index
      }
      else{
        this.cur = index
      }
    },
    leaveCur(){
      this.inter = setInterval(()=>{
        if(this.cur === 4){
          this.cur = 0
        }
        else{
          this.cur+= 1
        }
      },5000)
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  padding-top: 90px;
  width: 100%;
  .c-title{
    width: 100%;
    font-size: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #111111;
    line-height: 45px;
    padding-bottom: 30px;
    text-align: center;
  }
  .introductions{
    display: flex;
    .right{
      width: 882px;
      height: 500px;
      position: relative;
      overflow: hidden;
      .img-p{
        position: absolute;
        top: 25px;
        left: 77px;
      }
      .sub-p{
        position: absolute;
        right: 0;
        bottom: 70px;
      }
      .l{
        width: 403px;
        height: 396px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .r{
        width: 334px;
        height: 328px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  .part{
    width: 329px;
    height: 70px;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    .title{
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 30px;
      width: 200px;
    }
    .tip{
      width: 200px;
      margin-top: 5px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 30px;
      white-space: nowrap;
    }
  }
  .active{
    height: 113px !important;
    box-shadow: 0px 2px 11px 0px #F1F1F1;
  }
}
</style>