<template>
  <div class="content">
    <p class="title">最新资讯</p>
    <div class="top">
      <div class="card" v-for="item in 2" :key="item">
        <div class="thumb">
          <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
        </div>
        <div class="infos">
          <div><a class="text-otl-ell a-title">4万字回顾｜2023电商复盘、2024经4万字回顾｜2023电商复盘、2024经4万字回顾｜2023电商复盘、2024经</a></div>
          <p class="context text-otl-ell">新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼</p>
          <div class="publisher">
            <div class="avatar">
              <Image :src="'https://person.zju.edu.cn/person/attachments/2021-03/0311030441-1279116452.jpg?imageMogr2/thumbnail/!100p'"></Image>
            </div>
            <div><a class="text-ol-ell a-name">小编辑小编辑小编辑小编辑小编辑</a></div>
            <p class="time">3小时前</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="card" v-for="item in 5" :key="item">
        <div class="thumb">
          <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
        </div>
        <div class="infos">
          <div><a class="text-otl-ell a-title">4万字回顾｜2023电商复盘、2024经4万字回顾｜2023电商复盘、2024经4万字回顾｜2023电商复盘、2024经</a></div>
          <div class="publisher">
            <div class="avatar">
              <Image :src="'https://person.zju.edu.cn/person/attachments/2021-03/0311030441-1279116452.jpg?imageMogr2/thumbnail/!100p'"></Image>
            </div>
            <div><a class="text-ol-ell a-name">小编辑小编辑小编辑小编辑小编辑</a></div>
            <p class="time">3小时前</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content{
  width: 1200px;
  padding-top: 60px;
  .title{
    font-size: 26px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 26px;
    margin-bottom: 32px;
  }
  .top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    .card{
      display: flex;
      .thumb{
        width: 230px;
        height: 154px;
        cursor: pointer;
        margin-right: 15px;
      }
      .infos{
        width: 342px;
        padding-top: 7px;
        .a-title{
          display: block;
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #111111;
          line-height: 24px;
          height: 48px;
          cursor: pointer;
          &:hover{
            color: $--G60-theme !important;
          }
          &:visited{
            color: #111111;
          }
        }
        .context{
          margin-top: 14px;
          font-size: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          width: 100%;
          margin-bottom: 14px;
        }
        .publisher{
          display: flex;
          align-items: center;
          .avatar{
            width: 18px;
            height: 18px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 6px;
          }
          .a-name{
            display: block;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            max-width: 150px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #666666;
            }
          }
          .time{
            margin-left: 14px;
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
      }
    }
  }
  .bottom{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    .card{
      .thumb{
        width: 230px;
        height: 154px;
        cursor: pointer;
        margin-bottom: 15px;
      }
      .infos{
        width: 230px;
        .a-title{
          display: block;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #111111;
          line-height: 19px;
          height: 38px;
          cursor: pointer;
          &:hover{
            color: $--G60-theme !important;
          }
          &:visited{
            color: #111111;
          }
        }
        .publisher{
          display: flex;
          margin-top: 16px;
          align-items: center;
          .avatar{
            width: 18px;
            height: 18px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 6px;
          }
          .a-name{
            display: block;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            max-width: 90px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #666666;
            }
          }
          .time{
            margin-left: 14px;
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
      }
    }
  }
}
</style>