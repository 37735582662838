<template>
  <div class="table-0">
    <img src="@/assets/images/G60/forum/circle-0.png" class="circle"/>
    <div class="title">
      上午开幕式暨主题大会
    </div>
    <div class="t light">
      <div class="left">
        08:15
      </div>
      <div class="right">
        <p>参观嘉宾签到</p>
        <p class="tip">签到地址：G60科创走廊科创大厦（主会场）</p>
      </div>
    </div>
    <div class="t dark">
      <div class="left">
        08:30-09:15
      </div>
      <div class="right">
        <p>参观长三角G60科创走廊规划展示馆</p>
        <p class="tip">由大巴从主会场短驳至展馆，参观结束后返回</p>
      </div>
    </div>
    <div class="t light">
      <div class="left">
        09:09:15
      </div>
      <div class="right">
        <p>参会嘉宾签到 (主会场)</p>
        <p class="tip">签到地址：G60科创走廊科创大厦B座二楼</p>
      </div>
    </div>
    <div class="t dark">
      <div class="left">
        09:30
      </div>
      <div class="right">
        <p>大会开幕式（主会场）</p>
      </div>
    </div>
    <div class="t light">
      <div class="left">
        09:30-10:00
      </div>
      <div class="right">
        <p>领导致辞</p>
        <p class="tip">长三角G60科创走廊联席会议办公室领导</p>
        <p class="tip">上海市工商联领导</p>
        <p style="margin-top: 20px;">联合主办方致辞</p>
      </div>
    </div>
    <div class="t dark">
      <div class="left">
        10:00-11:00
      </div>
      <div class="right">
        <p>会议主旨演讲：科企岛数字科创平台赋能硬科技创新生态</p>
        <p class="tip">演讲嘉宾：张超 科企岛数字科创平台联席CEO</p>
        <p style="margin-top: 20px;">会议主旨演讲：人工智能驱动生物医药产业的革新与飞跃</p>
        <p class="tip">演讲嘉宾：毛开云 中国科学院上海营养与健康研究所副研究员</p>
        <p style="margin-top: 20px;">会议主旨演讲：“融通科技 伙伴银行”浦发银行科技金融服务</p>
        <p class="tip">演讲嘉宾：葛亮 浦发银行总行科技金融部副总经理</p>
        <p style="margin-top: 20px;">会议主旨演讲：产业投行生态战略-赋能科技创新企业</p>
        <p class="tip">演讲嘉宾：李东 财中金控主管合伙人兼总裁</p>
        <p style="margin-top: 20px;">会议主旨演讲：数字化时代下科创企业研发体系如何建立</p>
        <p class="tip">演讲嘉宾：汤侃磊 国家工信部评审专家、上海市经信委中小企业服务专家</p>
      </div>
    </div>
    <div class="t light">
      <div class="left">
        11:00-11:30
      </div>
      <div class="right">
        <p>圆桌讨论：人工智能引领科技发展在科创领域的应用赋能</p>
        <div class="line">
          <p class="tip line-l">圆桌嘉宾：</p>
          <p class="tip">叶劲超 中国中小企业协会副会长、上海市信用促进中心副主任</p>
        </div>
        <div class="line">
          <p class="line-l"></p><p class="tip">周正曙 原上海市经信委外经处处长</p>
        </div>
        <div class="line">
          <p class="line-l"></p><p class="tip">刘文标 之江商学院副院长</p>
        </div>
        <div class="line">
          <p class="line-l"></p><p class="tip">余朝刚 上海工程技术大学城市轨道交通学院副教授</p>
        </div>
        <div class="line">
          <p class="line-l"></p><p class="tip">王旭富  稻蓝资本联合创始人</p>
        </div>
        <div class="line">
          <p class="line-l"></p> <p class="tip">乔颖异 科企岛数字科创平台首席运营官</p>
        </div>
      </div>
    </div>
    <div class="t dark">
      <div class="left">
        11:30-12:00
      </div>
      <div class="right">
        <p>授牌仪式</p>
        <p class="tip">长三角数字化科创服务专委会成立授牌</p>
        <p style="margin-top: 20px;">合作发起仪式</p>
        <p class="tip">以人工智能驱动科创发展生态合作倡议发布</p>
      </div>
    </div>
    <div class="t light">
      <div class="left">
        12:00
      </div>
      <div class="right">
        <p>交流工作餐</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-0{
  width: 100%;
  padding-top: 20px;
  position: relative;
  .circle{
    position: absolute;
    left: 26px;
    top: 0;
    width: 70px;
    height: 70px;
    z-index: 1;
  }
  .title{
    width: 100%;
    height: 73px;
    background: linear-gradient( 90deg, #42B4F5 0%, #2354E3 50%, #0AC896 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 28px;
    text-align: justify;
    font-style: normal;
  }
  .light{
    background: linear-gradient( 90deg, #0765C0 0%, #121288 100%);
  }
  .dark{
    background: linear-gradient( 90deg, #1F4099 0%, #121288 100%);
  }
  .t{
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px 20px 54px;
    display: flex;
    justify-content: space-between;
    .left{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
      text-align: justify;
      font-style: normal;
    }
    .right{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 18px;
      font-style: normal;
      width: 320px;
      .line{
        display: flex;
        justify-content: flex-start;
        .line-l{
          width: 60px;
          flex-shrink: 0;
        }
      }
      .tip{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #DDDDDD !important;
        line-height: 16px;
        font-style: normal;
        margin-top: 10px;
      }
    }
  }
}
</style>