<template>
  <div class="container">
    <div class="top">
      <div class="top-part">
        <p>随着科技发展的日新月异，科企岛作为科创服务行业的领军互联网企业，正致力于推动科创行业的蓬勃发展。为了更好地拓展市场，现面向全国范围招募区域合伙人，共同开创科创服务新纪元。</p>
        <p>作为科企岛的区域合伙人，您将有机会复制我们成功的商业运营模式，借助我们的海量科研专家和项目资源，为当地企业提供优质<br/>的科创服务，同时也将获得丰厚的回报和广阔的发展空间。</p>
        <button @click="scrollToForm">立即加入</button>
      </div>
    </div>
    <div class="part" style="padding-top: 100px;">
      <div class="title">
        <img src="@/assets/images/activity/partner/wing-l.png" class="l"/>
        <p>企业简介</p>
        <img src="@/assets/images/activity/partner/wing-r.png" class="r"/>
      </div>
      <div class="window">
        <div class="left">
          <p>科企岛是集专业软件、海量科创大数据及优质科创运营服务为一体的数字化科创服务平台。我们致力于打破技术供需之间的信息壁垒，在高校、科研机构与企业间搭建起沟通的桥梁，推动科技创新成果的转化与应用，矢志成为科创服务行业的标杆。</p>
          <p>科企岛的使命是推动科技创新与产业发展深度融合，通过我们的平台与服务，让每一份科研成果都能转化为现实生产力，让每一家企业都能享受到科技创新带来的红利。我们的愿景是打造一个全球领先的科创生态系统，连接科技、产业与资本，共创未来新生态。</p>
          <p>科企岛的核心业务致力于构建数字科创服务生态，通过探角数字科创中心为企业提供海量科研专家和项目资源对接，精准匹配企业需求，解决企业技术难题，加速创新步伐。同时，我们为区域和产业园区提供一体化科创服务平台，推动地方科技创新和产业升级，助力区域经济高质量发展。另外，蓝珊瑚硬科技孵化加速器专注于科研成果的转化，为科技项目提供从孵化到加速的全流程服务。</p>
          <p>科企岛以AI技术为核心驱动力，打造全智能科创生态体系，为客户提供涵盖数据、软件到运营的全方位解决方案，引领科技创新的未来。</p>
        </div>
        <div class="right">
          <img src="@/assets/images/activity/partner/sh.png"/>
        </div>
      </div>
    </div>
    <div class="part" style="padding-top: 114px;">
      <div class="title">
        <img src="@/assets/images/activity/partner/wing-l.png" class="l"/>
        <p>发展规划</p>
        <img src="@/assets/images/activity/partner/wing-r.png" class="r"/>
      </div>
      <div class="time-line">
        <div class="time-window">
          <div class="time">
            <div class="time-top">
              2020年
            </div>
            <p class="p-t">科企岛数字科创服务平台诞生<br/>布局于全国32家机构<br/>持续扩张新型科技产业</p>
          </div>
          <img src="@/assets/images/activity/partner/time-right.png" class="icon"/>
          <div class="time">
            <div class="time-top">
              2021年<br/>|<br/>2024年
            </div>
            <p class="p-t">完善人工智能中台设计<br/>及科技化引擎创建索引<br/>开发企业核心科技及专利 <br/>完善大数据仓库，产品服务体系</p>
          </div>
          <img src="@/assets/images/activity/partner/time-right.png" class="icon"/>
          <div class="time">
            <div class="time-top">
              2024年
            </div>
            <p class="p-t">产品商业化布局<br/>商业化模型落地营收1000万元以上<br/>打造20+个基础合作客户</p>
          </div>
          <img src="@/assets/images/activity/partner/time-right.png" class="icon"/>
          <div class="time">
            <div class="time-top">
              2027年
            </div>
            <p class="p-t">两年内盈利并成为行业前三<br/>三年后完成IPO上市<br/>成功搭建科技创新基础生态<br/>形成科技产业链</p>
          </div>
        </div>
      </div>
    </div>
    <div class="part" style="padding-top: 150px;padding-bottom: 164px;">
      <div class="title">
        <img src="@/assets/images/activity/partner/wing-l.png" class="l"/>
        <p>招募合伙人对象</p>
        <img src="@/assets/images/activity/partner/wing-r.png" class="r"/>
      </div>
      <div class="object">
        <div class="o b-0">
          <p class="p-0">科技创业者与创新团队</p>
          <p class="p-1">正在寻找创新平台、资源和支持的创业者或团队对科创服务有浓厚兴趣，希望通过科企岛平台和资源带来的长期回报。</p>
        </div>
        <div class="o b-1">
          <p class="p-0">行业经验丰富的销售与市场专家</p>
          <p class="p-1">具有深厚的行业经验和市场资源，能够迅速推广科企岛的产品和服务。渴望通过创新的科创服务产品来拓展自己的业务领域。</p>
        </div>
        <div class="o b-2">
          <p class="p-0">地方科技园区、孵化器和加速器负责人</p>
          <p class="p-1">负责运营和管理科技园区、孵化器等机构，拥有丰富政府资源的合作伙伴，希望引进更多前沿的创新资源和服务，以提升本地科创水平。</p>
        </div>
      </div>
    </div>
    <div class="part back-1" style="padding-top: 61px;">
      <div class="title">
        <img src="@/assets/images/activity/partner/d-l.png" style="width: 120px;height: 9px;margin-bottom: 16px;" class="l"/>
        <p style="color: #111111;">区域科创生态合作伙伴</p>
        <img src="@/assets/images/activity/partner/d-r.png" style="width: 120px;height: 9px;margin-bottom: 16px" class="r"/>
      </div>
      <div class="lines">
        <div class="line">
          <p class="p-0">科创服务市场深度拓展</p>
          <p class="p-1">作为科创合伙人，您将在所在区域内深度拓展科企岛的科创服务市场，通过为所在区域的企业、产业园区、政府等机构引进并推广专业的科创软件、科研大数据等核心产品，助力各方实现数字化转型，提升创新能力，推动地方经济向数字化、智能化方向迈进，为区域发展注入新动能。</p>
        </div>
        <div class="line">
          <p class="p-0">区域品牌共建与赋能</p>
          <p class="p-1">作为区域品牌共建者，您将与科企岛携手共创品牌影响力。借助科企岛的体系支持，您将在当地策划并组织举办品牌活动、赛事论坛，提升科企岛品牌影响力，同时，您将为其他合伙人提供必要的赋能与支持，共同打造一个繁荣发展的区域科创生态。</p>

        </div>
        <div class="line">
          <p class="p-0">区域产学研基地共建</p>
          <p class="p-1">作为产学研联动创新的推动者，您将积极发挥桥梁纽带作用，紧密连接所在区域的高校、科研机构与企业。通过整合产学研资源，促进科技创新成果的转化与产业化，共同打造独具特色的科企岛产学研基地。推动科技成果落地、加速产业升级，为地方经济的可持续发展注入源源不断的创新动力。</p>
        </div>
      </div>
    </div>
    <div class="part" style="padding-top: 114px;">
      <div class="title">
        <img src="@/assets/images/activity/partner/wing-l.png" class="l"/>
        <p>区域合伙人6大支持</p>
        <img src="@/assets/images/activity/partner/wing-r.png" class="r"/>
      </div>
      <div class="pyramid-content">
        <div class="pyramid">
          <p class="p-p" style="padding-top: 55px;">品牌授权</p>
          <p class="p-p" style="padding-top: 26px;">平台资源</p>
          <p class="p-p" style="padding-top: 30px;">培训支持</p>
          <p class="p-p" style="padding-top: 30px;">营销支持</p>
          <p class="p-p" style="padding-top: 30px;">运营支持</p>
          <p class="p-p" style="padding-top: 30px;">技术支持</p>
        </div>
        <div class="right-p">
          <div class="num-line">
            <img src="@/assets/images/activity/partner/num-1.png" />
            获得科企岛品牌授权合作带来的的影响力和资源网络带来的长期回报。
          </div>
          <div class="num-line">
            <img src="@/assets/images/activity/partner/num-2.png" />
            全面的业务培训，包括产品知识、市场策略、运营管理等，帮助合伙人快速熟悉业务和市场。
          </div>
          <div class="num-line">
            <img src="@/assets/images/activity/partner/num-3.png" />
            协助合伙人搭建本地化的科创服务平台，提供技术支持和运营指导。
          </div>
          <div class="num-line">
            <img src="@/assets/images/activity/partner/num-4.png" />
            定期组织合伙人沟通交流和业务指导，分享成功经验与教训，促进共同成长。
          </div>
        </div>
      </div>
    </div>
    <div class="part back-0" style="padding-top: 173px;">
      <div class="title">
        <img src="@/assets/images/activity/partner/wing-l.png" class="l"/>
        <p>区域合伙人4大收益</p>
        <img src="@/assets/images/activity/partner/wing-r.png" class="r"/>
      </div>
      <div class="web">
        <div class="num-line">
          <img src="@/assets/images/activity/partner/num-1.png" />
          获得科企岛科创软件和科研大数据产品的独家区域代理权，享受高额销售分成
        </div>
        <div class="num-line">
          <img src="@/assets/images/activity/partner/num-2.png" />
          通过建立产学研基地和举办科技论坛、创业大赛等活动，获得多元化的收益来源
        </div>
        <div class="num-line">
          <img src="@/assets/images/activity/partner/num-3.png" />
          根据合伙人的业绩表现，提供丰厚的收益分成和激励政策
        </div>
        <div class="num-line">
          <img src="@/assets/images/activity/partner/num-4.png" />
          成为科企岛合伙人，站在行业风口，成为科创服务行业的领军人物，实现个人价值和事业梦想
        </div>
        <img src="@/assets/images/activity/partner/img-0.png" />
      </div>
    </div>
    <div class="part" style="padding-top: 112px;">
      <div class="title" style="padding-bottom: 52px;">
        <img src="@/assets/images/activity/partner/wing-l.png" class="l"/>
        <p>合作流程</p>
        <img src="@/assets/images/activity/partner/wing-r.png" class="r"/>
      </div>
      <div class="cors">
        <div class="cor cor-1">
          <div class="img">
            <img src="@/assets/images/activity/partner/i-1.png" style="width: 30px;height: 34px;" />
          </div>
          <p>提交申请，提供资料</p>
        </div>
        <img src="@/assets/images/activity/partner/i-0.png" class="arrow-r"/>
        <div class="cor cor-2">
          <div class="img">
            <img src="@/assets/images/activity/partner/i-2.png" style="width: 34px;height: 36px;"/>
          </div>
          <p>审核资历，综合评估</p>
        </div>
        <img src="@/assets/images/activity/partner/i-0.png" class="arrow-r"/>
        <div class="cor cor-3">
          <div class="img">
            <img src="@/assets/images/activity/partner/i-3.png" style="width: 34px;height: 34px;"/>
          </div>
          <p>签约合作，获得授权</p>
        </div>
        <img src="@/assets/images/activity/partner/i-0.png" class="arrow-r"/>
        <div class="cor cor-4">
          <div class="img">
            <img src="@/assets/images/activity/partner/i-4.png" style="width: 36px;height: 34px;"/>
          </div>
          <p>接受培训，提升能力</p>
        </div>
        <img src="@/assets/images/activity/partner/i-0.png" class="arrow-r"/>
        <div class="cor cor-5">
          <div class="img">
            <img src="@/assets/images/activity/partner/i-5.png" style="width: 30px;height: 34px;"/>
          </div>
          <p>开展业务，推广产品</p>
        </div>
      </div>
    </div>
    <div class="part form">
      <div class="form-container">
        <div class="b-left">
          <p class="p-0">加入科企岛区域合伙人共创未来!<br/>我们期待与您携手共创城市科创新篇章!</p>
          <p class="p-1">战略发展<br/>汇聚全国精英，携手共创行业辉煌未来!<br/>2024年-2026年计划招募300名区域合伙人，<br/>共同推进全国市场加速布局，<br/>瞄准万亿级广阔市场</p>
        </div>
        <div class="b-form">
          <div class="t">
            <div class="item">
              <p><span>*</span>姓名</p>
              <el-input class="input" v-model="form.name" />
            </div>
            <div class="item">
              <p><span>*</span>联系方式</p>
              <el-input class="input" v-model="form.phone"  @input="handlePhoneChange" maxlength="11"/>
            </div>
          </div>
          <div class="item full">
            <p><span>*</span>意向合作区域</p>
            <el-input class="input" v-model="form.area" />
          </div>
          <button class="submit-button" @click="submit">提交申请</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { activityRegister } from "@/api/activity";

export default{
  data(){
    return{
      form:{
        name: '',
        phone: '',
        area: '',
      }
    }
  },
  methods:{
    scrollToForm(){
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    },
    handlePhoneChange(e){
      let pattern = new RegExp("^[0-9]|-$")
      this.form.phone = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    clearForm(){
      this.form = {
        name: '',
        phone: '',
        area: '',
      }
    },
    submit(){
      if(!this.form.name){
        ElMessage.warning('请输入您的姓名')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请输入您的联系方式')
        return
      }
      else if(!this.form.area){
        ElMessage.warning('请输入您的意向合作区域')
        return
      }
      const param = {
        activityName: '合伙人招募',
        activityType: '43',
        code: '123',
        isCreateMember: false,
        contactWay: this.form.phone,
        companyName: '',
        name: this.form.name,
        jsonStr: JSON.stringify({
          '意向合作区域': this.form.area,
        }),
      }
      activityRegister(param).then((res) => {
        if (res.code === "Sx200") {
          ElMessage.success('提交成功!')
          this.clearForm()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  .top{
    width: 100%;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/05/07/1717750882544758');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 300px 0 75px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .top-part{
      width: 1200px;
      p{
        width: 935px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 28px;
        padding-bottom: 11px;
      }
      button{
        width: 188px;
        height: 51px;
        background: linear-gradient( 90deg, #CAA26A 0%, #E7C892 100%);
        margin-top: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #483600;
        cursor: pointer;
        border: none;
      }
    }
  }
  .back-1{
    background-image: url('../../assets/images/activity/partner/back-1.png');
    background-size: 100% 531px;
    height: 531px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: bottom;
    .lines{
      width: 1200px;
      display: flex;
      padding-top: 5px;
      justify-content: space-between;
      .line{
        width: 387px;
        height: 244px;
        background: #FFFFFF;
        box-sizing: border-box;
        padding-top: 33px;
        padding-left: 30px;
        padding-right: 30px;
        .p-0{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 20px;
          color: #111111;
          line-height: 28px;
          padding-bottom: 17px;
        }
        .p-1{
          position: relative;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #111111;
          line-height: 24px;
          text-align: justify;
        }
      }
    }
  }
  .back-0{
    background-image: url('../../assets/images/activity/partner/back-0.png');
    background-size: 100% 502px;
    height: 740px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .part{
    background-color: #191B1F;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      display: flex;
      align-items: flex-end;
      padding-bottom: 75px;
      .l{
        width: 133px;
        height: 18px;
        margin-right: 21px;
        margin-bottom: 10px;
      }
      p{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 40px;
        line-height: 56px;
        color: transparent;
        background-image: linear-gradient(0deg, #FCDFB8 0%, #F6CA92 100%);
        background-clip: text;
      }
      .r{
        width: 133px;
        height: 18px;
        margin-left: 14px;
        margin-bottom: 10px;
      }
    }
    .window{
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .left{
        width: 679px;
        p{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 32px;
          text-indent: 2em;
        }
      }
      .right{
        margin-top: 13px;
        height: 360px;
        box-sizing: border-box;
        padding-bottom: 47px;
        padding-right: 31px;
        background: linear-gradient(to right, #CAA26B 0%, #E4C48E 100%);
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: 483px 294px;
        img{
          width: 452px;
          height: 313px;
        }
      }
    }
    .web{
      padding-top: 33px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .num-line{
        display: flex;
        align-items: center;
        width: 629px;
        img{
          width: 33px;
          height: 22px;
          margin-right: 8px;
          margin-top: 0 !important;
        }
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 41px;
      }
      img{
        width: 884px;
        height: 252px;
        margin-top: 15px;
      }
    }
    .object{
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .b-0{
        background-image: url('../../assets/images/activity/partner/b-0.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .b-1{
        background-image: url('../../assets/images/activity/partner/b-1.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .b-2{
        background-image: url('../../assets/images/activity/partner/b-2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .o{
        width: 387px;
        height: 435px;
        box-sizing: border-box;
        padding-bottom: 35px;
        padding-left: 26px;
        padding-right: 34px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .p-0{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #FFFFFF;
          margin-bottom: 18px;
        }
        .p-1{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 18px;
        }
      }
    }
    .cors{
      width: 1200px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 178px;
      .arrow-r{
        width: 42px;
        height: 30px;
        margin-top: 85px;
      }
      .cor{
        width: 171px;
        height: 179px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        .img{
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 73px;
          margin-bottom: 18px;
        }
      }
      .cor-1{
        background-image: url('../../assets/images/activity/partner/bb-1.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .cor-2{
        background-image: url('../../assets/images/activity/partner/bb-2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .cor-3{
        background-image: url('../../assets/images/activity/partner/bb-3.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .cor-4{
        background-image: url('../../assets/images/activity/partner/bb-4.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .cor-5{
        background-image: url('../../assets/images/activity/partner/bb-5.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .form{
    padding-bottom: 210px;
    .form-container{
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .b-left{
        .p-0{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 24px;
          line-height: 42px;
          background: linear-gradient(180deg, #CCA46D 0%, #FBDDB5 100%);
          background-clip: text;
          color: transparent;
          padding-bottom: 35px;
        }
        .p-1{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #FFFFFF;
          line-height: 28px;
        }
      }
      .b-form{
        width: 672px;
        .t{
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 32px;
        }
        .full{
          width: 100% !important;
          margin-bottom: 40px;
        }
        .submit-button{
          width: 100%;
          height: 46px;
          box-sizing: border-box;
          background: linear-gradient( 270deg, #CCA46D 0%, #FBDDB5 100%);
          border-radius: 2px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
          cursor: pointer;
        }
        .item{
          width: 320px;
          p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 20px;
            span{
              color: #D10909;
            }
          }
          .input{
            width: 100%;
            height: 44px;
            background-color: #ffffff;
            border-radius: 2px;
            font-size: 16px;
            color: #111111;
            box-sizing: border-box;
            :deep(input){
              border: none;
              height: 44px;
            }
          }
        }
      }
    }
  }
  .time-line{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    &::before{
      width: 100%;
      height: 1px;
      background: #494238;
      content: '';
      position: absolute;
      left: 0;
      top: 59px;
      z-index: 10;
    }
    .time-window{
      display: flex;
      justify-content: space-between;
      width: 1200px;
      position: relative;
      z-index: 20;
      .time{
        width: 218px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .time-top{
          width: 104px;
          height: 120px;
          background-image: url('../../assets/images/activity/partner/time-back.png');
          background-size: 100% 120px;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 18px;
          color: #191B1F;
          line-height: 20px;
          text-align: center;
        }
        .p-t{
          width: 100%;
          text-align: center;
          margin-top: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #CCA56E;
          line-height: 20px;
        }
      }
      .icon{
        width: 22px;
        height: 39px;
        margin-top: 40px;
      }
    }
  }
  .pyramid-content{
    width: 1200px;
    background: linear-gradient( 85deg, #C2934D 0%, #191B1F 100%);
    background-size: 925px 252px;
    background-position: right;
    background-repeat: no-repeat;
    background-position-y: 55px;
    position: relative;
    display: flex;
    .right-p{
      padding-top: 100px;
      .num-line{
        display: flex;
        align-items: center;
        img{
          width: 33px;
          height: 22px;
          margin-right: 8px;
        }
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 41px;
      }
    }
    .pyramid{
      width: 499px;
      height: 345px;
      background-image: url('../../assets/images/activity/partner/pyramid.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .p-p{
        width: 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
        text-align: center;
      }
    }
  }
}
</style>