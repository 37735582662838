<template>
  <div v-if="searchForm.type === 1" class="filter-lists top-96" :class="{'top-229': isSearch}">
    <div style="position: relative;">
      <el-select v-model="city" placeholder="选择城市" :disabled="(!isVip && isLogin)|| chooseCity?.length === 0" clearable :style="`width: 132px;`" @click="checkLogin">
        <el-option
            v-for="item in chooseCity"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <img src="../../assets/images/search/vip-icon.png" class="img-icon"/>
    </div>
    <div style="position: relative;">
      <el-select v-model="school" placeholder="科研院所" :disabled="(!isVip && isLogin)|| chooseSchool?.length === 0" clearable :style="`width: 132px;`" @click="checkLogin">
        <el-option
            v-for="item in chooseSchool"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <img src="../../assets/images/search/vip-icon.png" class="img-icon"/>
    </div>
    <div style="position: relative;">
      <el-select v-model="college" placeholder="选择学院" :disabled="(!isVip && isLogin)|| chooseCollege?.length === 0" clearable :style="`width: 132px;`" @click="checkLogin">
        <el-option
            v-for="item in chooseCollege"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <img src="../../assets/images/search/vip-icon.png" class="img-icon"/>
    </div>
    <div style="position: relative;">
      <el-select v-model="title" placeholder="选择职称" :disabled="(!isVip && isLogin)|| chooseTitle?.length === 0" clearable :style="`width: 132px;`" @click="checkLogin">
        <el-option
          v-for="item in chooseTitle"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <img src="../../assets/images/search/vip-icon.png" class="img-icon"/>
    </div>
    <div class="checkbox-item" v-if="!isAchievement">
      <el-checkbox v-model="searchName" :disabled="(!isVip && isLogin)" @click="checkLogin(1)">搜名字</el-checkbox>
    </div>
  </div>
  <!-- 中国地图 -->
  <div style="width: 100%;" class="map-container">
    <div style="width: 100%;">
      <div class="map-controller flex-cc">
        <div class="left flex-cc">
          <img src="../../assets/images/map/map-icon.png" alt="" style="width: 18px;height: 16px;margin-top: 6px;margin-right: 6px;">
          数据看板
        </div>
        <div class="right" v-if="!showMap" @click="showMap = !showMap">
          展开<i class="el-icon-arrow-down"></i>
        </div>
        <div class="right" v-else-if="showMap" @click="showMap = !showMap">
          收起<i class="el-icon-arrow-up"></i>
        </div>
      </div>
      <div v-if="showMap">
        <ChinaMap :mapHeight="385" ></ChinaMap>
      </div>
    </div>
  </div>
<div :class="{'is-search': isSearch,'no-keyword': !loading && keyword?.length === 0}">
  <div style="width: 100%;" class="map-container">
    <div style="width: 100%;">
      <div class="map-controller flex-cc" style="border-bottom: none;">
        <div class="left flex-cc">
          <img src="../../assets/images/map/map-icon.png" alt="" style="width: 18px;height: 16px;margin-top: 6px;margin-right: 6px;">
          专家列表
        </div>
      </div>
    </div>
  </div>
  <!-- 数据 -->
  <div class="show-data">
    <searchData :data="''" :keyword="$route.query.keyword ? $route.query.keyword: ''" style="width: 1200px;" :backGroundFFFFFF="true"  @setShowData="setShowData" ref="searchData" :expert="total" v-if="!isAchievement"></searchData>
    <searchData :data="''" :keyword="$route.query.keyword ? $route.query.keyword: ''" style="width: 1200px;" :backGroundFFFFFF="true"  @setShowData="setShowData" ref="searchData" :achieve="total" v-else></searchData>
  </div>
  <!-- 需求按钮 -->
  <div class="show-demand flex-rc" v-if="!loading && searchForm.type === 1 && showData">
    <demandLine v-if="showData"></demandLine>
  </div>
  <div class="root" v-if="ismOrpc=='pc'">
    <div class="search-content-wrapper" :class="{'wrapper-background': type === 1}">
      <div class="search-content" v-loading="loading" :class="{'new-publish': type === 0}">
        <div class="search-list" v-if="type === 0">
          <publish-cards :data="publishes" v-if="publishes?.length > 0"
          />
          <el-empty v-if="publishes.length === 0" description="暂无数据" style="margin-top:80px;"></el-empty>
        </div>
        <div class="search-list" >
          <ProfileCardGroupNew :line-items="4" size="small" :data="members" :large="true" v-if="members?.length > 0" :noBottom="true" :isLastPage="lastPage" @getParticipleLists="getParticipleLists" :isSearch="true"/>
          <el-empty v-if="members.length === 0 && searchForm.type === 1" description="暂无数据" style="background: #ffffff;width: 1200px;box-sizing: border-box;border:1px solid #D8D8D8; border-left: none;border-right: none;"></el-empty>
          <!-- 体验次数 -->
          <div class="no-vip" v-if="showViewTime && isLogin && viewedTime && noVipViewTime" id="noVIP">
            <div class="left">
              {{ viewedTime + '/' + noVipViewTime}}
            </div>
            <div class="middle">
              <div style="line-height:48px">
                你可以免费查看<span>{{noVipViewTime}}</span>位专家主页
              </div>
              <div>
                已体验<span>{{ viewedTime }}</span>次
              </div>
            </div>
            <div class="right" @click="jumpTo">
              立即购买
            </div>
          </div>
          <!-- 定制数字科创中心 -->
          <!-- <div class="no-vip" v-if="showCreateButton && isLogin" id="noVIP">
            <div class="middle" style="margin-left: 50px;">
              <div>
                创建身份，<span>免费获取</span>查看机会
              </div>
            </div>
            <div class="right" @click="jumpTo(1)">
              立即创建
            </div>
          </div> -->
        </div>
        <div class="pagination-container" v-if="total > 0">
          <el-pagination
            v-if="!pageReload"
            :background="true"
            layout="prev, pager, next"
            :total="total"
            :page-size="searchForm.limit"
            v-model:current-page="currentPage">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
  <mobile-search v-else></mobile-search>
</div>
</template>

<script>
import  {memberDetails } from "@/api/user"
import {checkVip} from '@/utils/auth'
import publishCards from "./components/publish-cards.vue";
import ProfileCardGroupNew from "@/components/profile/ProfileCardGroupNew";
import {search,searchExpert,searchFilterNew,labExpertList, searchAchievementsApi,
viewExpertCount,showViewCount
} from "@/api/search";
import {mapGetters} from 'vuex'
import { isMobile } from '@/config/mobile'
import MobileSearch from '@/views/mobile/search'
import { ElMessage } from 'element-plus';
import searchData from '../news/components/searchData.vue'
import demandLine from '../news/components/demandLine.vue'
import ChinaMap from "../../components/ChinaMap/ChinaMap.vue";

export default {
  name: "searchResult",
  components: { publishCards, ProfileCardGroupNew, MobileSearch,searchData,demandLine,ChinaMap},
  emits: ['setLoading'],
  props:{
    isSearch:{
      // 是否搜索中心
      type: Boolean,
      default: false,
    },
    keyword: {
      type: String,
      default: "",
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showMap: false,  // 是否展示地图
      searchName: false,  // 是否搜名字
      showData: false, // 是否展示数据栏
      pageReload: false,  // 分页重新加载
      isLoading: false, // 是否从路由中读取参数
      isVip: false, // 是否VIP
      showViewTime: false,  // 是否展示次数提示
      showCreateButton: false,  // 是否展示定制数字科创中心
      noVipViewTime: 3, // 非VIP查看专家次数 max
      viewedTime: 0, // 已经看过的次数
      filter: [], // 存搜索专家筛选选项
      isLogin: false,
      ismOrpc: 'pc',
      total: 0, // 总页数
      searchForm: { // 搜索参数
        keyword: "",
        limit: 20,
        id: 0,
        analyzerType: 2,  // 2：粗颗粒 1：细颗粒
        offset: 0,
        type: 1
      },
      currentPage: 1,
      pageNumber: -1,  // 粗颗粒的总页数
      publishes: [],  // 动态搜索结果
      members: [],  // 人员搜索结果
      city: '',
      college: '',
      school: '',
      title: '',
      chooseCity:[],
      chooseSchool:[],
      chooseCollege:[],
      chooseTitle:[],
      cities:[],
      colleges:[],
      schools:[],
      titles:[],
      loading: false, // 搜索加载
      initialized: false  // 判断是否进行过初始化
    }
  },
  computed: {
    isAchievement(){  // 是否搜成果
      return this.$route.query?.searchType + '' === 8 + ''
    },
    lastPage(){ // 判断是否最后一页且非加载中且有关键词且细颗粒无搜索
      return this.currentPage === this.pageNumber && !this.loading && this.keyword?.length > 0 && Number(this.searchForm.analyzerType) !== 1
    },
    ...mapGetters(['id','showTop','imDialogVisible'])
  },
  watch: {
    async imDialogVisible(val){
      // 刷新查看次数及显示
      if(val){
        let res = null
        let userInfo = localStorage.getItem('userInfo')
        if(userInfo){
          try {
            res = await memberDetails(JSON.parse(userInfo).id)
            this.isVip = res.data.isVip
          } catch (error) {
            console.log(error);
          }
          if(!res.data.organizationId){
            // 1.11.3 普通用户不显示
            this.showCreateButton = false
          }
          if(!this.isVip && !this.showCreateButton){  //  先判断是否需要展示
            let res = await showViewCount(JSON.parse(userInfo).id)
            this.showViewTime = res.data.show
            res = await viewExpertCount(JSON.parse(userInfo).id)
            this.noVipViewTime = res.data.max
            this.viewedTime = res.data.viewNum
          }
        }
      }
    },
    loading(val){
      // 监听加载
      if(!val){
        this.$emit('setLoading',[false,this.isAchievement ? '2' : '0'])
      }
    },
    searchName(val){
      if(!localStorage.getItem('userInfo')){  // 未登录前往登录
        ElMessage.warning('您尚未登录！')
        this.$router.push({ // 更改后进行路由保存
          path: "/login",
          query: {
            redirect: this.isSearch ? '/search-center' : '/search',
            keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
            city: this.$route.query.city ? this.$route.query.city : '',
            school: this.$route.query.school ? this.$route.query.school : '',
            college: this.$route.query.college ? this.$route.query.college : '',
            title: this.$route.query.title ? this.$route.query.title : '',
            offset: 1,
            searchType: this.$route.query.searchType ? this.$route.query.searchType : 1,
            analyzerType: 2,
            searchName: 0,
            type: this.$route.query.type ? this.$route.query.type : ''
          },
        })
        return
      }
      this.$router.push({ // 更改后进行路由保存
        path: this.isSearch ? '/search-center' : '/search',
        query: {
          keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
          city: this.$route.query.city ? this.$route.query.city : '',
          school: this.$route.query.school ? this.$route.query.school : '',
          college: this.$route.query.college ? this.$route.query.college : '',
          title: this.$route.query.title ? this.$route.query.title : '',
          offset: 1,
          searchType: this.$route.query.searchType ? this.$route.query.searchType : 1,
          analyzerType: 2,
          searchName: val ? 1 : 0,
          type: this.$route.query.type ? this.$route.query.type : ''
        },
      })
    },
    $route(to,from){  // 搜专家、搜成果转换清空
      if(to.path === '/search' && from.path === '/search'){
        if(from.query.searchType !== to.query.searchType || from.query.keyword !== to.query.keyword){
          // 改为不重置选项
          // this.city = ''
          // this.school = ''
          // this.college = ''
          // this.title = ''
          // this.searchName = !from.query.keyword && to.query.searchName === 1 + '' ? true : false // 防止遗漏选项
          // this.searchForm.analyzerType = 2  // 修改关键词后全部重置
        }
        else if(from.query.analyzerType !== to.query.analyzerType){
          this.searchForm.analyzerType = to.query.analyzerType  // 修改颗粒度
          this.filterSelect(3)
        }
        else if(from.query.searchName !== to.query.searchName){ // 修改搜名字
          this.filterSelect(3)
        }
      }
      else if(this.isSearch && to.path === '/search-center' && from.path === '/search-center'){
        // 搜索中心
        if(from.query.searchType !== to.query.searchType || from.query.keyword !== to.query.keyword){
          // 改为不重置选项
          // this.city = ''
          // this.school = ''
          // this.college = ''
          // this.title = ''
          // this.searchName = !from.query.keyword && to.query.searchName === 1 + '' ? true : false // 防止遗漏选项
          // this.searchForm.analyzerType = 2  // 修改关键词后全部重置
        }
        else if(from.query.analyzerType !== to.query.analyzerType){
          this.searchForm.analyzerType = to.query.analyzerType  // 修改颗粒度
          this.filterSelect(3)
        }
        else if(from.query.searchName !== to.query.searchName){ // 修改搜名字
          this.filterSelect(3)
        }
      }
    },
    async city(val){
      this.clearNumber()
      this.school = ''  // 变化时先清空
      this.college = ''
      this.chooseSchool = []
      this.chooseCollege = []
      if(!val && val !== 0){
        this.$router.push({ // 更改后进行路由保存
          path: this.isSearch ? '/search-center' : '/search',
          query: {
            keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
            city: '',
            school: '',
            college: '',
            title: this.$route.query.title ? this.$route.query.title : '',
            offset: this.$route.query.offset ? Number(this.$route.query.offset) : 1,
            searchType: this.$route.query.searchType ? this.$route.query.searchType : 1,
            analyzerType: this.$route.query.analyzerType ? this.$route.query.analyzerType : 2,
            searchName : this.$route.query.searchName ? this.$route.query.searchName : 0,
            type: this.$route.query.type ? this.$route.query.type : ''
          },
        })
        await this.init()
        return
      }
      if(!this.isLoading) await this.filterSelect(2) // 加载中不进行搜索
      this.schools = this.filter[val].childList.map((item,index)=>{
        return {
          label: item.showText,
          value: index
        }
      })
      this.$router.push({ // 更改后进行路由保存
        path: this.isSearch ? '/search-center' : '/search',
        query: {
          keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
          city: val,
          school: this.$route.query.school && this.isLoading ? this.$route.query.school : '', // 从路由中读取状态
          college: this.$route.query.college && this.isLoading ? this.$route.query.college : '', // 从路由中读取状态
          title: this.$route.query.title ? this.$route.query.title : '',
          offset: this.$route.query.offset ? Number(this.$route.query.offset) : 1,
          searchType: this.$route.query.searchType ? this.$route.query.searchType : 1,
          analyzerType: this.$route.query.analyzerType ? this.$route.query.analyzerType : 2,
          searchName : this.$route.query.searchName ? this.$route.query.searchName : 0,
          type: this.$route.query.type ? this.$route.query.type : ''
        },
      })
      this.chooseSchool = this.schools  // 根据选择补充学校
      if(this.$route.query.school && this.isLoading) {  // 从路由中读取状态 选择学校
        this.school = Number(this.$route.query.school)
      }
      else if(!this.$route.query.school && this.isLoading){ // 没有后续参数
        this.isLoading = false
        await this.filterSelect(2)
      }
    },
    async school(val){
      if(this.chooseSchool.length === 0){ // 空判断
        return
      }
      this.clearNumber()
      this.college = ''
      this.chooseCollege = []
      if(!this.isLoading) await this.filterSelect(2) // 加载中不进行搜索
      if((!val && val !== 0)) {
        this.$router.push({ // 更改后进行路由保存
          path: this.isSearch ? '/search-center' : '/search',
          query: {
            keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
            city: this.$route.query.city,
            school: '',
            college: '',
            title: this.$route.query.title ? this.$route.query.title : '',
            offset: this.$route.query.offset ? Number(this.$route.query.offset) : 1,
            searchType: this.$route.query.searchType ? this.$route.query.searchType : 1,
            analyzerType: this.$route.query.analyzerType ? this.$route.query.analyzerType : 2,
            searchName : this.$route.query.searchName ? this.$route.query.searchName : 0,
            type: this.$route.query.type ? this.$route.query.type : ''
          },
        })
        return  // 清空后不对后续进行填充
      }
      this.colleges = this.filter[this.city].childList[val].childList.map((item,index)=>{
        return {
          label: item.showText || ' ',
          value: index
        }
      })
      this.$router.push({ // 更改后进行路由保存
        path: this.isSearch ? '/search-center' : '/search',
        query: {
          keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
          city: this.$route.query.city,
          school: val,
          college: this.$route.query.college && this.isLoading ? this.$route.query.college : '', // 从路由中读取状态
          title: this.$route.query.title ? this.$route.query.title : '',
          offset: this.$route.query.offset ? Number(this.$route.query.offset) : 1,
          searchType: this.$route.query.searchType ? this.$route.query.searchType : 1,
          analyzerType: this.$route.query.analyzerType ? this.$route.query.analyzerType : 2,
          searchName : this.$route.query.searchName ? this.$route.query.searchName : 0,
          type: this.$route.query.type ? this.$route.query.type : ''
        },
      });
      this.chooseCollege = this.colleges  // 填充学院
      if(this.$route.query.college && this.isLoading) { // 从路由中读取状态 选择学院
        this.college = Number(this.$route.query.college)
      }
      else if(!this.$route.query.college && this.isLoading){  // 没有后续参数
        this.isLoading = false
        await this.filterSelect(2)
      }
    },
    async college(val){
      if(this.chooseCollege.length === 0){ // 空判断
        return
      }
      if((!val && val !== 0)) {
        this.$router.push({ // 更改后进行路由保存
          path: this.isSearch ? '/search-center' : '/search',
          query: {
            keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
            city: this.$route.query.city,
            school: this.$route.query.school,
            college: '',
            title: this.$route.query.title ? this.$route.query.title : '',
            offset: this.$route.query.offset ? Number(this.$route.query.offset) : 1,
            searchType: this.$route.query.searchType ? this.$route.query.searchType : 1,
            analyzerType: this.$route.query.analyzerType ? this.$route.query.analyzerType : 2,
            searchName : this.$route.query.searchName ? this.$route.query.searchName : 0,
            type: this.$route.query.type ? this.$route.query.type : ''
          },
        })
        let that = this
        setTimeout(async()=>{ //添加延迟，防止无法获取变化
          that.clearNumber()
          if(that.isLoading) that.isLoading = false // 从路由中读取状态 修改结束
          await that.filterSelect(2)
        },200)
        return  // 清空后不对后续进行填充
      }
      this.$router.push({ // 更改后进行路由保存
        path: this.isSearch ? '/search-center' : '/search',
        query: {
          keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
          city: this.$route.query.city,
          school: this.$route.query.school,
          college: val,
          title: this.$route.query.title ? this.$route.query.title : '',
          offset: this.$route.query.offset ? Number(this.$route.query.offset) : 1,
          searchType: this.$route.query.searchType ? this.$route.query.searchType : 1,
          analyzerType: this.$route.query.analyzerType ? this.$route.query.analyzerType : 2,
          searchName : this.$route.query.searchName ? this.$route.query.searchName : 0,
          type: this.$route.query.type ? this.$route.query.type : ''
        },
      });
      let that = this
      setTimeout(async()=>{ //添加延迟，防止无法获取变化
        that.clearNumber()
        if(that.isLoading) that.isLoading = false // 从路由中读取状态 修改结束
        await that.filterSelect(2)
      },200)
    },
    async title(val){
      this.clearNumber()
      this.$router.push({ // 路由变化保存
        path: this.isSearch ? '/search-center' : '/search',
        query: {
          keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
          city: this.$route.query.city ? this.$route.query.city : '',
          school: this.$route.query.school ? this.$route.query.school : '',
          college: this.$route.query.college ? this.$route.query.college : '',
          title: val,
          offset: this.$route.query.offset ? Number(this.$route.query.offset) : 1,
          searchType: this.$route.query.searchType ? this.$route.query.searchType : 1,
          analyzerType: this.$route.query.analyzerType ? this.$route.query.analyzerType : 2,
          searchName : this.$route.query.searchName ? this.$route.query.searchName : 0,
          type: this.$route.query.type ? this.$route.query.type : ''
        },
      });
      let that = this
      setTimeout(async()=>{ //添加延迟，防止无法获取变化
        if(that.isLoading && (that.$route.query.city === '' || !that.$route.query.city)){ // 如果加载中，且城市无值
          that.isLoading = false
        }
        await that.filterSelect(2)
      },200)
    },
    async type(){
      this.clearNumber()
      await this.init()
    },
    async keyword(val){
      this.clearNumber()
      this.searchForm.keyword = val // 修改参数
      this.searchForm.offset = 1  // 返回第一页
      if(!this.isLoading && this.initialized){
        let that = this
        setTimeout(async ()=>{
          await that.filterSelect(1) // 非加载且初始化完成后进行修改
        },100)
      }
    },
    async currentPage(val,oldVal) {
      if(val === -1) return // -1不进行处理
      document.body.scrollTop = 0 // 回到顶部
      document.documentElement.scrollTop = 0
      let userInfo = localStorage.getItem('userInfo')
      if(!userInfo && val > 50){  // 非VIP、未登录 最多50页
        ElMessage.warning('未登录最多查看50页专家!')
        this.currentPage = 50
        val = 50
      }
      else if(userInfo && !this.isVip && val > 50){
        ElMessage.warning('非VIP用户最多查看50页专家!')
        this.currentPage = 50
        val = 50
      }
      this.searchForm.offset = val
      if(oldVal > 50 && val == 50){ // 已经是50页，然后点击了50之后的页数
        this.pageReload = true
        let that = this
        setTimeout(()=>{  // 重新加载分页组件
          that.currentPage = 50
          that.pageReload = false
        },500)
        return
      }
      this.$router.push({ // 换页路由保存
        path: this.isSearch ? '/search-center' : '/search',
        query: {
          keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
          city: this.$route.query.city ? this.$route.query.city : '',
          school: this.$route.query.school ? this.$route.query.school : '',
          college: this.$route.query.college ? this.$route.query.college : '',
          title: this.$route.query.title ? this.$route.query.title : '',
          offset: val,
          searchType: this.$route.query.searchType ? this.$route.query.searchType : 1,
          analyzerType: this.$route.query.analyzerType ? this.$route.query.analyzerType : 2,
          searchName : this.$route.query.searchName ? this.$route.query.searchName : 0,
          type: this.$route.query.type ? this.$route.query.type : ''
        },
      });
      if(this.isLoading && (this.$route.query.city === '' || !this.$route.query.city) && (this.$route.query.title === '' || !this.$route.query.title)){ // 如果只有页码有保存
        this.isLoading = false
      }
      await this.updateSearchResult()
    },
  },
  beforeUnmount(){
    window.removeEventListener('scroll',this.scrollEvent)
  },
  async mounted() {
    window.addEventListener('scroll',this.scrollEvent)
    let userInfo = localStorage.getItem('userInfo')
    if(userInfo) {
      this.isLogin = true
      let res = null
      try {
        res = await memberDetails(JSON.parse(userInfo).id)
        this.isVip = res.data.isVip
      } catch (error) {
        console.log(error);
      }
      if(!res.data.organizationId){
        // 1.11.3 普通用户不显示
        this.showCreateButton = false
      }
      if(!this.isVip && !this.showCreateButton){  //  先判断是否需要展示
        let res = await showViewCount(JSON.parse(userInfo).id)
        this.showViewTime = res.data.show
        res = await viewExpertCount(JSON.parse(userInfo).id)
        this.noVipViewTime = res.data.max
        this.viewedTime = res.data.viewNum
      }
    }
    if (this.isMobile()) {
			this.ismOrpc = 'mobile'
      return
		}
    if (!this.initialized) {
      this.initialized = true
      if((this.$route.query.city != '' && this.$route.query.city)|| (this.$route.query.title != '' && this.$route.query.title)|| (this.$route.query.offset != 1 && this.$route.query.offset)) this.isLoading = true // 从历史中读取
      await this.init() // 判断完之后进行读取
      this.city = this.$route.query.city ? Number(this.$route.query.city) : ''
      this.title = this.$route.query.title ? Number(this.$route.query.title) : ''
      this.currentPage = this.$route.query.offset ? Number(this.$route.query.offset) : 1
      this.searchName = this.$route.query.searchName === 1 + '' ? true : false
    }
    let right = document.getElementsByClassName('btn-next')[0]
    let left = document.getElementsByClassName('btn-prev')[0]
    if(!left || !right){  // 无分页
      return
    }
    left.removeChild(left.childNodes[0])
    right.removeChild(right.childNodes[0])
    left.textContent = '上一页'
    right.textContent = '下一页'
    left.style.borderRadius = '13px'
    left.style.padding = '0px 10px'
    right.style.borderRadius = '13px'
    right.style.padding = '0px 10px'
  },
  methods: {
    scrollEvent(){  // 滚动事件
      const clientHeight = document.scrollingElement.clientHeight;
      const scrollTop = document.scrollingElement.scrollTop;
      const scrollHeight = document.scrollingElement.scrollHeight;
      let dom = document.getElementById('noVIP')
      if(!dom) return
      if (scrollHeight - clientHeight - scrollTop <= 342) {
        dom.style.bottom =  60 + 342 - (scrollHeight - clientHeight - scrollTop) + 'px'
      }
      else{
        dom.style.bottom = 60 + 'px'
      }
    },
    clearNumber(){  // 清除部分数字
      this.pageNumber = -1  // 清空粗颗粒页数统计
    },
    async getParticipleLists(){ // 如果有页数说明是刷新后的
      let userInfo = localStorage.getItem('userInfo')
      if(!userInfo && this.currentPage === 50){  // 非VIP、未登录 最多50页
        ElMessage.warning('未登录最多查看50页专家!')
        return
      }
      else if(userInfo && !this.isVip && this.currentPage === 50){
        ElMessage.warning('非VIP用户最多查看50页专家!')
        return
      }
      this.$router.push({
        path: this.isSearch ? '/search-center' : '/search',
        query: {
          keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
          city: this.$route.query.city ? this.$route.query.city : '',
          school: this.$route.query.school ? this.$route.query.school : '',
          college: this.$route.query.college ? this.$route.query.college : '',
          title: this.$route.query.title ? this.$route.query.title : '',
          offset: 1,
          searchType: this.$route.query.searchType ? this.$route.query.searchType : 1,
          analyzerType: 1,  // 细颗粒
          searchName : this.$route.query.searchName ? this.$route.query.searchName : 0,
          type: this.$route.query.type ? this.$route.query.type : ''
        },
      })
      let that = this
      setTimeout(()=>{
        that.$refs.searchData.setType() // 增加延迟防止显示错误
      },100)
    },
    setShowData(val){  // 判断是否展示数据栏
      this.showData = val
    },
    checkLogin(type){
      if(type === 1 && !this.isLogin){
        return
      }
      if(!this.isLogin){  // 未登录去登录
        ElMessage.warning('你尚未登录!')
        this.$router.push({path:'/login',query: {'redirect': this.$route.fullPath}})
      }
      else if(!this.isVip){ // 非VIP弹窗
        this.$store.dispatch("message/hideIMWindow")
        this.$store.commit('message/SET_IM_WINDOW_DIALOG', true)
      }
    },
    jumpTo(type){  // 跳转页面
      if(type === 1){ // 创建身份
        // this.$store.dispatch("popup/setPopup", {
        //   type: 11, // 创建身份弹窗
        //   parameter: {
        //     point: 1 // 埋点位置
        //   },
        // });
        return
      }
      this.$router.push('/vip')
    },
    isMobile,
    checkVip,
    async init() {  // 初始化
      let res = await searchFilterNew()  // 获取专家筛选条件
      this.setOptions(res)
      await this.initSearchForm()
      if(this.isLoading) return // 加载中不进行搜索
      await this.updateSearchResult()
    },
    async filterSelect(type){
      if(this.isLoading) return // 加载中不进行搜索
      if(type === 1 || type === 2 || type === 3){ // type:1 关键词变化回到第一页 type: 2 筛选项修改回到第一页 type: 3 细颗粒或搜名字回到首页
        this.currentPage = -1
        let that = this
        setTimeout(()=>{
          that.currentPage = 1
        },100)
        return
      }
      this.publishes = []
      this.members = []
      this.loading = true
      this.searchForm.id = this.id
      const apiCity = this.city === '' ? '' : this.chooseCity[this.city].label
      const apiSchool = this.school === '' ? '' : this.chooseSchool[this.school].label
      const apiCollege = this.college === '' ? '' : this.chooseCollege[this.college].label
      const apiTitle = this.title === '' ? '' : this.chooseTitle[this.title].label
      let that = this
      if(this.$route.query.searchType==8){
        let res = await searchAchievementsApi(this.searchForm,this.isLogin,apiCity,apiSchool,apiCollege,apiTitle)
        this.total = res.data.total;
        this.members = res.data.result[0].epertList;
        this.pageNumber = res.data.pages
        if(this.searchForm.analyzerType + '' === '1' && this.total === 0) this.loading = false
        else if(this.total === 0) this.getParticipleLists()
        else this.loading = false
        setTimeout(()=>{
          that.scrollEvent()
        },500)
        return
      }
      if(!this.keyword && !apiCity && !apiSchool && !apiCollege && !apiTitle){
        let res = await labExpertList(this.searchForm,apiCity,apiSchool,apiCollege,apiTitle)
        this.total = res.data.total
        this.members = res.data.result[0].epertList
        this.loading = false
        setTimeout(()=>{
          that.scrollEvent()
        },500)
      }else{
        let res = await searchExpert(this.searchForm,this.isLogin,apiCity,apiSchool,apiCollege,apiTitle,this.$route.query.searchName === 1 + '' ?  true : false)
        this.total = res.data.total
        this.members = res.data.result[0].epertList
        this.pageNumber = res.data.pages
        if(this.searchForm.analyzerType + '' === '1' && this.total === 0) this.loading = false
        else if(this.total === 0) this.getParticipleLists()
        else this.loading = false
        setTimeout(()=>{
          that.scrollEvent()
        },500)
      }
    },
    setOptions(res){
      this.cities = res.data.screenConditionList.map((item,index)=>{  // 城市选项
        return {
          label: item.showText,
          value: index
        }
      })
      this.titles = res.data.positionList.map((item,index)=>{ // 职称选项
        return {
          label: item,
          value: index
        }
      })
      this.filter = res.data.screenConditionList
      this.chooseCity = this.cities
      this.chooseSchool = []
      this.chooseCollege = []
      this.chooseTitle = this.titles
    },
    async updateSearchResult() {  // 更新搜索结果
      this.loading = true
      this.searchForm.id = this.id
      if(this.searchForm.type === 1){
        this.filterSelect()
        return
      }
      search(this.searchForm).then(res => {
        this.loading = true
        let data = res.data
        if (this.searchForm.type === 0) {
          if(data.total !== 0){
            this.total = data.total
            this.publishes = data.result
            this.publishes.map((item)=>{  // 保留换行
              if(item.summary.length === 0){ // 简介为空说明为旧的内容
                  item.content = item.content.replace(/\n/g,'<br/>')
              }
            })
          }
          else{
            if(this.publishes.length > 0){
              ElMessage.warning('暂无相关内容!')
            }
            else{
              this.publishes = []
              this.total = 0
            }
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    async initSearchForm() {  // 初始化搜索
      this.searchForm.keyword = this.keyword || ""
      this.searchForm.limit = this.isLogin ? 20 : 5
      this.searchForm.offset = 1
      this.searchForm.type =  1
      this.searchForm.analyzerType = this.$route.query.analyzerType ? Number(this.$route.query.analyzerType) : 2
      this.currentPage = this.searchForm.offset
      if(this.searchForm.type === 0){
        this.searchForm.limit = 20
      }
    },
    changeCate(item) {  // 更换目录
      this.activePane = item
    },
  }
}
</script>

<style scoped lang="scss">
.map-container{
  background-color: #FFFFFF;
  width: 100%;
  .map-controller {
    width: 100%;
    padding-left: 21px;
    padding-right: 47px;
    box-sizing: border-box;
    height: 56px;
    background-color: #ffffff;
    border-bottom: 1px solid #f3f3f3;
    justify-content: space-between;
    .left{
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 28px;
    }
    .right{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2280FF;
      cursor: pointer;
    }
  }
}
.show-demand{
  width: 100%;
  box-sizing: border-box;
  padding-top: 10px;
  border-top: none;
  background: #ffffff;
}
.is-search{
  // margin-top:  15px !important;
}
.no-keyword{
  // border-top: 1px solid #D8D8D8;
}
.show-data{
  display: flex;
  justify-content: center;
  background: #ffffff;
  // margin-top: 30px;
}
.search-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  border-radius: 4px;
}
.wrapper-background{
  background-color: #FAF9F9;
}
.top-96{
  top: 96px !important;
}
.top-229{
  top: 229px !important;
}
.top-217{
  top: 202px !important;
}
.top-346{
  top: 346px;
}
.top-200{
  top: 200px;
}
.checkbox-item{
  display: flex;
  align-items: center;
  :deep() .el-checkbox__label{
    color: #333333 !important;
  }
}

.filter-lists{
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url('../../assets/images/search/filter-back-new.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 20px;
  height: 70px;
  padding: 0px 210px;
  box-sizing: border-box;
  position: sticky;
  z-index: 200;
  .img-icon{
    position: absolute;
    width: 54px;
    height: 20px;
    right: -9px;
    top: -11px;
  }
  :deep() .is-disabled{
    input{
      border: none !important;
    }
  }
  :deep() input{
    font-size: 14px !important;
  }
}
.search-content {
  box-sizing: border-box;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0 20px 0;
}
.new-publish{
  width: calc(100vw - 17px) !important;
}
.search-list {
  // min-height: 300px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background: #ffffff;
  border-top: none;
  border-bottom: none;
  .no-vip{
    position: fixed;
    width: 1200px;
    height: 120px;
    background: #E7F4FF;
    box-shadow: 0px 8px 50px 0px #E5EAF2;
    border-radius: 60px;
    padding: 0 50px;
    left: 50%;
    bottom: 60px;
    margin-left: -600px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2002;
    .left{
      font-size: 84px;
      font-family: PingFang SC;
      font-weight: 600;
      color: $--color-theme;
      padding-right: 60px;
      border-right: 1px solid #D9E3FE;
    }
    .middle{
      margin-left: -160px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #101010;
      span{
        color: #E02020;
      }
    }
    .right{
      width: 161px;
      height: 62px;
      background: $--color-theme;
      border-radius: 31px;
      cursor: pointer;
      color: #FFFFFF;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      text-align: center;
      line-height: 62px;
    }
  }
}
.pagination-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: -40px;
  // border: 1px solid #D8D8D8;
  box-sizing: border-box;
  border-top: none;
  padding: 50px 0;
  background: #ffffff;
  :deep() .btn-next{
    background: #ffffff;
  }
  :deep() .btn-prev{
    background: #ffffff;
  }
  :deep() .number{
    background: #ffffff !important;
  }
  :deep() .active{
    background: $--color-theme !important;
  }
  :deep() .el-icon-more{
    background: #ffffff !important;
  }
}
</style>
