<template>
  <div class="content">
    <div class="new-line">
      <div>
        <p class="title">
          G60科创服务平台
        </p>
        <div class="right">
          <div class="section-left" @click="toRoute('/space')">
            <img src="@/assets/images/G60/home/title-0.png" style="width: 132px;height: 21px;" />
            <div class="buttons">
              <div class="button-top">
                <button @click.stop="toRoute('/experts')">找专家</button>
                <button @click.stop="toRoute('/achieves')">找成果</button>
              </div>
              <button class="button-bottom" @click.stop="toRoute('/demands')">查看企业需求</button>
            </div>
          </div>
          <div class="section-right">
            <div class="section s-top" @click="toRoute('/user')">
              <img src="@/assets/images/G60/home/title-1.png" style="width: 112px;height: 21px;" />
              <div class="buttons">
                <button class="big" @click.stop="toRoute('/user')">数字科创中心</button>
                <button class="small" @click.stop="toRoute('/publish-demand')">发布需求</button>
              </div>
            </div>
            <div class="section bottom" @click="toRoute('/user')">
              <img src="@/assets/images/G60/home/title-2.png" style="width: 150px;height: 21px;" />
              <div class="buttons">
                <button class="big" @click.stop="toRoute('/user')">产学研空间</button>
                <button class="small" @click.stop="toRoute('/publish-project')">发布成果</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="title">
          G60九大城市科创门户
        </p>
        <div class="cities">
          <div class="city wait animate__animated animate__fadeIn" @click="toCity(0)"><img src="@/assets/images/G60/cities/shang_hai.png" /><p>上海</p></div>
          <div class="city wait animate__animated animate__fadeIn" @click="toCity(1)"><img src="@/assets/images/G60/cities/su_zhou.png" /><p>苏州</p></div>
          <div class="city wait animate__animated animate__fadeIn" @click="toCity(2)"><img src="@/assets/images/G60/cities/jia_xing.png" /><p>嘉兴</p></div>
          <div class="city wait animate__animated animate__fadeIn" @click="toCity(3)"><img src="@/assets/images/G60/cities/hu_zhou.png" /><p>湖州</p></div>
          <div class="city wait animate__animated animate__fadeIn" @click="toCity(4)"><img src="@/assets/images/G60/cities/hang_zhou.png" /><p>杭州</p></div>
          <div class="city wait animate__animated animate__fadeIn" @click="toCity(5)"><img src="@/assets/images/G60/cities/jin_hua.png" /><p>金华</p></div>
          <div class="city wait animate__animated animate__fadeIn" @click="toCity(6)"><img src="@/assets/images/G60/cities/xuan_cheng.png" /><p>宣城</p></div>
          <div class="city wait animate__animated animate__fadeIn" @click="toCity(7)"><img src="@/assets/images/G60/cities/wu_hu.png" /><p>芜湖</p></div>
          <div class="city wait animate__animated animate__fadeIn" @click="toCity(8)"><img src="@/assets/images/G60/cities/he_fei.png" /><p>合肥</p></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { routerWindow } from '@/utils/tool.js';
// import { ElMessage } from 'element-plus';

export default{
  methods:{
    toRoute(path){
      if(!path) return
      routerWindow(this.$router,path)
    },
    toCity(index){
      switch (index) {
        case 0:
          break;
        default:
          // ElMessage.warning('近期上线')
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  .new-line{
    display: flex;
    align-items: flex-start;
    .title{
      font-size: 26px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 30px;
      margin-bottom: 27px;
    }
    .cities{
      width: 755px;
      display: flex;
      justify-content: space-between;
      .wait{
        position: relative;
        &:hover::before{
          position: absolute;
          content: '即将上线';
          width: 26px;
          height: 28px;
          position: absolute;
          top: 4px;
          left: 7px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 14px;
          letter-spacing: 1px;
          z-index: 50;
        }
        &:hover::after{
          z-index: 20;
          position: absolute;
          content: '';
          width: 39px;
          height: 40px;
          position: absolute;
          background-image: url('../../../../assets/images/G60/home/wait-icon.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          top: 0;
          left: 0;
        }
      }
      .city{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        border-radius: 8px;
        img{
          width: 75px;
          height: 196px;
        }
        p{
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 18px;
          position: absolute;
        }
      }
    }
    .right{
      width: 435px;
      margin-right: 10px;
      height: 220px;
      background-image: url('../../../../assets/images/G60/home/right-back.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      .section-left{
        width: 158px;
        height: 196px;
        background-image: url('../../../../assets/images/G60/home/back-0.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        padding: 17px 10px 10px;
        cursor: pointer;
        .buttons{
          margin-top: 87px;
          width: 100%;
          .button-top{
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 10px;
            button{
              width: 64px;
              height: 26px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid $--G60-theme;
              cursor: pointer;
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              color: #666666;
              &:hover{
                color: $--G60-theme;
              }
            }
          }
          .button-bottom{
            width: 100%;
            height: 26px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid $--G60-theme;
            cursor: pointer;
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #666666;
            &:hover{
              color: $--G60-theme;
            }
          }
        }
      }
      .section-right{
        width: 266px;
        .s-top{
          background-image: url('../../../../assets/images/G60/home/back-1.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-bottom: 10px;
          width: 100%;
          height: 96px;
          cursor: pointer;
          .buttons{
            margin-top: 21px;
          }
        }
        .bottom{
          background-image: url('../../../../assets/images/G60/home/back-2.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 100%;
          height: 90px;
          cursor: pointer;
          .buttons{
            margin-top: 16px;
          }
        }
        .section{
          box-sizing: border-box;
          padding: 17px 10px 10px;
          .buttons{
            display: flex;
            .big{
              width: 90px;
              height: 26px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid $--G60-theme;
              cursor: pointer;
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              color: #666666;
              margin-right: 10px;
              &:hover{
                color: $--G60-theme;
              }
            }
            .small{
              width: 64px;
              height: 26px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid $--G60-theme;
              cursor: pointer;
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              color: #666666;
              margin-right: 10px;
              &:hover{
                color: $--G60-theme;
              }
            }
          }
        }
      }
    }
  }
}
</style>