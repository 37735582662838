<template>
  <div class="container">
    <div class="section s0">
      <div class="part">
        <div class="title">
          科企岛人才工作站
        </div>
        <button class="submit" @click="showDialog=true">我是科技人才！点击一键加入</button>
        <div class="bottom">
          <div class="p">
            <p>引智聚才，科企共赢<br/>科技人才招引一站式服务平台</p>
          </div>
          <div class="p">
            <p>具备全国百余座城市产业合作资源<br/>大数据、数字化精准服务科技人才</p>
          </div>
          <div class="p">
            <p>匹配最优的产业城市<br/>打造落地产业合作方案</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="s1">
        <div class="left">
          <p class="title">平台简介</p>
          <p class="intro"><span>科企岛简介</span><br/>科企岛数字化科创服务平台，利用丰富的专业软件、海量科创数据以及优质的运营服务为企业、科研人员、政府园区和金融机构等提供全方位的科技创新支持和解决方案。平台集结了大量知名专家、热门专利和顶级成果等资源，以满足不同客户的特定需求。无论是在寻找专家、技术、项目或融资方面，科企岛都能提供专业的服务和定制化解决方案，帮助客户快速达成科技创新目标并实现商业价值。</p>
          <p class="intro"><span>人才平台简介</span><br/>科技人才招引一站式服务平台，高端人才服务头部品牌，致力于科技人才的“连接器、服务器、加速器、聚合器”，帮助科技人才在科企岛独有的运营模式下，对接企业、产业、资本，助力人才发展与科技发展。</p>
        </div>
        <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699852293650351" class="right" />
      </div>
    </div>
    <div class="section">
      <div class="s2">
        <div class="title">服务优势</div>
        <div class="parts">
          <div class="part p0">
            <p class="p-t">全国科技人才<br/>引入通道与多维度评价机制</p>
            <p class="word">科企岛与全国100多个城市建立科技人才引入通道，通过搭建科企岛人才招引评价平台，以多维度、广视角的方式评价和转化人才，促进科技人才的流动与优化配置。</p>
          </div>
          <div class="part p1">
            <p class="p-t">全链条生态服务体系<br/>驱动数字化科创发展</p>
            <p class="word">科企岛通过大数据、数字化的方式，打造人才引进、评价、交流、政策扶持等全链条的生态服务体系，为企业提供全方位的科创支持，推动数字化科创发展。</p>
          </div>
          <div class="part p2">
            <p class="p-t">一站式精准服务<br/>助力科技人才招引</p>
            <p class="word">科企岛提供科技人才招引服务事项一站式办理、点对点精准服务，帮助企业高效、精准地引进所需科技人才，提升企业的科技创新能力和竞争力。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div style="width: 100%;">
        <div class="p-title">科技人才活动</div>
        <div class="s3">
          <div class="part">
            <div class="left">
              <p class="word" style="padding-top: 0;"><span>人才产业对接会</span><br/><br/>科企岛提供促进人才和产业之间相互对接的活动，实现人才资源的优化配置和产业发展的良性循环。通过现场交流、项目展示、资源共享等方式，实现人才和产业的精准对接。</p>
              <p class="word"><span>人才沙龙</span><br/><br/>科企岛面向科技领域的专业人才提供一个交流、互动、合作的平台。旨在促进科技人才的交流与合作，帮助人才更好地了解行业动态、发展趋势和政策环境。</p>
              <p class="word" style="padding: 30px 0 30px;"><span>人才资本对接会</span><br/><br/>科企岛将科技人才与投资者、创业团队等资本方进行精准对接，提供展示自我价值的机会，同时也为资本方提供筛选优秀项目和团队的机会。</p>
              <p class="word no-bottom"><span>人才企业对接会</span><br/><br/>科企岛邀请有需求的企业和人才参加，通过现场交流、洽谈和对接，促进人才和企业的对接与合作。</p>
            </div>
            <div class="right">
              <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699853193616390" class="img" />
              <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699853795828875" class="img" />
              <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699853805146868" class="img" />
              <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699853816483717" class="img" />
              <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699853826745652" class="img" style="margin-top: 5px;"/>
              <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699853839922014" class="img" style="margin-top: 5px;"/>
              <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/16998538497191" class="img" />
              <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699853858659410" class="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="s4">
        <div class="title">
          热门人才区域
        </div>
        <div class="content">
          <div class="left">
            <p class="num">
              <span>50+</span><br/>
              地区
            </p>
            <p class="num">
              <span>30W+</span><br/>
              专家
            </p>
            <p class="num">
              <span>500W+</span><br/>
              产业
            </p>
          </div>
          <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699854252613538" alt="" class="img">
        </div>
      </div>
    </div>
    <div class="section s5">
      <div class="part">
        <div class="left">
          <p class="phone">15201980253</p>
          <p class="name">崔女士<br/>邮箱：cuizixuan@keqidao.com</p>
        </div>
        <div class="right">
          <p class="title"><span>我是科技人才</span><br/>即刻申请加入</p>
          <button class="submit" @click="showDialog=true">注册了解详情</button>
        </div>
      </div>
    </div>
    <el-dialog v-model="showDialog" :show-close="true" title="加入科企岛人才工作站" :width="'800px'">
      <WorkbenchForm @closeWindow="closeWindow" />
    </el-dialog>
  </div>
</template>

<script>
import WorkbenchForm from '@/views/activity/components/WorkbenchForm'
export default {
  components:{
    WorkbenchForm,
  },
  data() {
    return {
      showDialog: false,
    }
  },
  methods:{
    closeWindow(){
      this.closeWindow = false
    },
  }
}
</script>

<style scoped lang="scss">
.container{
  width: 100%;
  background-color: #f0f2f5;
  :deep(.el-dialog__header){
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    border-bottom: 1px solid #D8D8D8 !important;
  }
  .section{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .s0{
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699851659770673');
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 128px 0 63px;
    .part{
      width: 1200px;
      .title{
        font-size: 70px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 104px;
        text-shadow: 0px 3px 4px rgba(0,0,0,0.67);
        margin-bottom: 31px;
      }
      .submit{
        width: 272px;
        height: 53px;
        background: #E95330;
        border-radius: 6px;
        cursor: pointer;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: 71px;
        border: none;
      }
      .bottom{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .p{
          width: 350px;
          height: 88px;
          background: rgba($color: #000000, $alpha: 0.33);
          font-size: 17px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          box-sizing: border-box;
          white-space: nowrap;
          padding-top: 15px;
          padding-left: 30px;
        }
      }
    }
  }
  .s1{
    width: 1200px;
    display: flex;
    justify-content: space-between;
    .right{
      width: 464px;
      height: 538px;
    }
    .left{
      padding-top: 71px;
      width: 678px;
      .title{
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 36px;
        width: 100%;
        margin-bottom: 45px;
      }
      .intro{
        text-align: justify;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 34px;
        width: 100%;
        margin-bottom: 45px;
        span{
          font-size: 18px;
        }
      }
    }
  }
  .s2{
    width: 1200px;
    padding-top: 80px;
    .title{
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 36px;
      padding-bottom: 55px;
    }
    .parts{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .part{
        width: 384px;
        height: 501px;
        box-sizing: border-box;
        padding-top: 300px;
        .p-t{
          width: 100%;
          text-align: center;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #111111;
          line-height: 26px;
          margin-bottom: 13px;
        }
        .word{
          width: 325px;
          padding-left: 30px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 28px;
        }
      }
      .p0{
        background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699852859675160');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .p1{
        background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699852940950095');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .p2{
        background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699852952816852');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .p-title{
    width: 1200px;
    margin: 0 auto;
    padding: 69px 0 52px;
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 32px;
  }
  .s3{
    width: 100%;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699853314367965');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    padding-top: 63px;
    padding-bottom: 30px;
    .part{
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .right{
        width: 782px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .img{
          width: 381px;
          margin-bottom: 30px;
          height: 200px;
        }
      }
      .left{
        padding-top: 9px;
        .no-bottom{
          border-bottom: none !important;
        }
        .word{
          padding-top: 25px;
          width: 365px;
          padding-bottom: 25px;
          border-bottom: 1px solid #FFFFFF;
          text-align: justify;
          span{
            font-size: 18px;
          }
          font-size: 16px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 31px;
        }
      }
    }
  }
  .s4{
    width: 1200px;
    padding-top: 70px;
    .title{
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 32px;
      margin-bottom: 48px;
    }
    .content{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .img{
        width: 991px;
        height: 608px;
      }
      .left{
        width: 135px;
        padding-top: 85px;
        .num{
          margin-bottom: 84px;
          span{
            font-size: 40px;
            line-height: 56px;
          }
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #111111;
          line-height: 24px;
        }
      }
    }
  }
  .s5{
    margin-top: 70px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/13/1699854550523789');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 423px;
    .part{
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .left{
        padding-top: 84px;
        .phone{
          font-size: 60px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 84px;
          padding-bottom: 42px;
          position: relative;
          margin-bottom: 42px;
          &::before{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 58px;
            height: 6px;
            background: #FFFFFF;
            content: '',
          }
        }
        .name{
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 38px;
        }
      }
      .right{
        width: 270px;
        height: 240px;
        background: #ffffff;
        padding: 90px 143px 0;
        margin-right: 2px;
        margin-top: 45px;
        .title{
          width: 100%;
          text-align: center;
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          margin-bottom: 40px;
          span{
            color: #E95330;
          }
          color: #111111;
          line-height: 55px;
        }
        .submit{
          border: none;
          width: 270px;
          height: 66px;
          background: #E95330;
          border-radius: 33px;
          cursor: pointer;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>