<template>
  <div class="content">
    <div class="top">

    </div>
    <div class="bottom">
      <div class="activity">
        <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
      </div>
      <div class="activity">
        <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
      </div>
      <div class="activity">
        <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content{
  width: 1200px;
  .top{
    width: 100%;
    height: 363px;
    margin-bottom: 26px;
  }
  .bottom{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .activity{
      width: 388px;
      height: 180px;
    }
  }
}
</style>