<template>
    <div v-if="demandList.length > 0" class="container" :class="{'search-list':search}">
        <div v-for="item in demandList" :key="item.id" class="demand-card" @click="toDetail(item.id)">
            <div class="right-infos">
                <div class="title">
                    {{item.title}}
                </div>
                <div class="infos">
                    <div class="left">
                        <div class="info">
                            需&nbsp;求&nbsp;方：{{item.name}}
                        </div>
                        <div class="time">
                            发布时间：{{item.createTime}}
                        </div>
                    </div>
                </div>
                <!-- <div class="type" v-if="item.demandType?.name">
                    {{item.demandType.name}}需求
                </div> -->
            </div>
            <button class="hold-demand" @click.stop="startChat(item)">
                承接需求
            </button>
        </div>
    </div>
    <div v-else style="width:100%;">
        <el-empty description="暂无相关资讯" style="height: 300px;background: #ffffff;width: 100%;"></el-empty>
    </div>
</template>

<script>
import {staticPath, videoStaticPath} from "@/utils/consts";
import { mapGetters } from 'vuex'
import { ElMessage } from 'element-plus';

export default {
    props: [ 'demandList','channelId','search'],
    computed: {
        ...mapGetters(['breadcrumbItems']),
    },
    data() {
        return {
            staticPath,videoStaticPath
        }
    },
    methods:{
        startChat(demand) { // 承接需求
            let userInfo = localStorage.getItem('userInfo')
            userInfo = JSON.parse(userInfo)
            if(!userInfo){  // 未登录的情况
                ElMessage.warning('您尚未登录!')
                this.$router.push({path:'/login',query: {
                    'redirect': this.$route.path,
                    'keywords': this.$route.query.keywords ? this.$route.query.keywords : '',
                    'page': this.$route.query.page ? this.$route.query.page : '',
                    'channelId': this.$route.query.channelId ? this.$route.query.channelId : 'all',
                    'industryId':   this.$route.query.industryId ? this.$route.query.industryId : 'all',
                }})
                return
            }
            if(demand.memberId === userInfo.id){ // 增加判断，不能对自己发出的需求发送消息
                ElMessage.warning('无法对自己的需求进行承接!')
                return
            }
            this.$store.commit('message/SET_DEMAND_INFO',{id: demand.id + '', title: demand.title})
            this.$store.commit('message/SET_OPEN_SERVICE_WINDOW',true) // 打开客服窗口
        },
        setBread(){ // 设置面包屑
            if(this.channelId.id !== 'all' && this.channelId !== 'all'){
                this.$store.dispatch('breadcrumb/setItems',[...this.breadcrumbItems,{path:this.$route.fullPath,meta:{breadTitle:this.channelId.val}}])
            }
        },
        toDetail(id){  // 前往详情
            this.setBread() // 前往页面前设定面包屑
            this.$router.push({
                path: '/demand-detail',  // 需求详情页面
                query:{
                    content: 1,
                    fromSearch: this.search ? 1 : 0,    // 传入是否搜索
                    publishId: id
                }
            })
        },
    },
}
</script>

<style scoped lang="scss">
.container{
    width: 100%;
    background: #ffffff;
    padding-top: 10px;
}
.search-list{
    padding: 10px 30px !important;
    box-sizing: border-box !important;
}
.demand-card{
    width: 100%;
    background: #F8F8F8;
    box-sizing: border-box;
    display: flex;
    margin: 10px 0;
    padding: 20px 30px;
    align-items: center;
    .hold-demand{
        border: none;
        cursor: pointer;
        width: 96px;
        height: 34px;
        background: $--color-theme;
        box-shadow: 0px 2px 4px 1px rgba(95,31,2,0.15);
        border-radius: 4px !important;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 34px;
        text-align: center;
    }
    .right-infos{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 100px);
        .title{
            word-break: break-all;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            width: calc(100% - 100px);
            margin-bottom: 33px;
            line-height: 33px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .infos{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left{
                width: 625px;
                display: flex;
                align-items: center;
                .info{
                    width: 250px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                }
                .time{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                }
            }
        }
        .type{
            width: 120px;
            height: 26px;
            border-radius: 4px;
            border: 1px solid $--color-theme;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $--color-theme;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
        }
    }
}
</style>