<template>
    <div class="out-container">
        <div class="in-container">
            <!-- 搜索栏 -->
            <div class="search-bar top-44">
                <div class="title-big">
                    需求库
                </div>
                <div class="input">
                    <el-input placeholder="请输入关键词搜索" v-model="keywords" @keydown.enter="newSearch()"></el-input>
                    <div class="search-button" @click="newSearch()"><i class="el-icon-search"></i></div>
                </div>
                <div class="publish" @click="goToPublish">
                    发布需求
                </div>
            </div>
            <!-- 选择框 -->
            <div class="selections" v-loading="loading">
                <!-- 需求类型 -->
                <div class="type"  style="padding-top: 0px;">
                    <div class="title">
                        需求类型：
                    </div>
                    <!-- <div class="selection-container" v-if="!more1 && allDemandTypes.length > 1">
                        <div v-for="(item) in allDemandTypes.slice(0,7)" :key="item.id" class="selection" @click="selectOption(0,item)"
                        :class="{
                            'choose': item.id + '' === channelId.id && channelId.id.length > 0,
                            'others': channelId.id.length > 0}"
                        >
                            {{item.val}}
                        </div>
                        <div @click="more1 = true" class="selection more" v-if="allDemandTypes.length > 7">
                            更多<i class="el-icon-arrow-down"></i>
                        </div>
                    </div> -->
                    <div class="selection-container" v-if="allDemandTypes.length > 1">
                        <div v-for="(item) in allDemandTypes" :key="item.id" class="selection" @click="selectOption(0,item)"
                        :class="{
                            'choose': item.id + '' === channelId.id && channelId.id.length > 0,
                            'others': channelId.id.length > 0}"
                        >
                            {{item.val}}
                        </div>
                        <!-- <div @click="more1 = false" class="selection back">
                            收起<i class="el-icon-arrow-up"></i>
                        </div> -->
                    </div>
                </div>
                <!-- 行业分类 -->
                <div class="type">
                    <div class="title" style="margin-bottom: 20px;">
                        行业分类：
                    </div>
                    <div class="selection-container" v-if="!more2 && allIndustries.length > 1">
                        <div v-for="(item) in allIndustries.slice(0,9)" :key="item.id" class="selection" @click="selectOption(1,item)"
                        :class="{
                            'choose': item.id + '' === industryId.id && industryId.id.length > 0,
                            'others': industryId.id.length > 0}"
                        >
                            {{item.val}}
                        </div>
                        <div @click="more2 = true" class="selection more">
                            更多<i class="el-icon-arrow-down"></i>
                        </div>
                    </div>
                    <div class="selection-container" v-else-if="allIndustries.length > 1">
                        <div v-for="(item) in allIndustries" :key="item.id" class="selection" @click="selectOption(1,item)"
                        :class="{
                            'choose': item.id + '' === industryId.id && industryId.id.length > 0,
                            'others': industryId.id.length > 0}"
                        >
                            {{item.val}}
                        </div>
                        <div @click="more2 = false" class="selection back2">
                            收起<i class="el-icon-arrow-up"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex" style="justify-content: space-between;margin-top: 15px;">
                <!-- 需求库左侧 -->
                <div style="width: 925px;">
                    <!-- 已选内容 -->
                    <div class="selected">
                        <div class="title" style="margin-right: 30px;">已选内容：</div>
                        <div class="select" v-if="channelId.id.length > 0 && channelId.val !== '全部' && channelId.val.length > 0" @click="clearOption(0)">{{channelId.val}} <i class="el-icon-close"></i></div>
                        <div class="select" v-if="industryId.id.length > 0 && industryId.val !== '全部' && industryId.val.length > 0" @click="clearOption(1)">{{industryId.val}}<i class="el-icon-close"></i></div>
                        <div class="clear" @click="clearOption(2)">清空<i class="el-icon-delete"></i></div>
                    </div>
                    <!-- 需求卡片 -->
                    <demandCard :demandList="demandList" v-loading="demandLoading" :channelId="channelId" :search="true"></demandCard>
                    <!-- 分页 -->
                    <div class="pagination">
                        <el-pagination
                            v-if="!demandLoading && demandList?.length > 0"
                            background
                            layout="prev, pager, next"
                            :total="total"
                            v-model:current-page="offset"
                            :page-size="limit"
                            @current-change="pageChange"
                            >
                        </el-pagination>
                    </div>
                </div>
                <!-- 右侧表单 -->
                <DemandForm></DemandForm>
            </div>
        </div>
    </div>
</template>

<script>
import { getDemandList,getposttype } from "@/api/demand"
import { getSelectFatherSonByType } from "@/api/config"
import demandCard from './components/demandCard.vue';
import DemandForm from './components/DemandForm'
import { ElMessage } from 'element-plus';
import { mapGetters } from 'vuex'
import { iurChannelId } from "@/utils/consts.js"
import { routerWindow } from "@/utils/tool";


export default {
    components:{
        demandCard,
        DemandForm
    },
    computed: {
        ...mapGetters(['breadcrumbItems','showTop']),
        loading(){  // 判断是否在加载中
            return this.selectionLoading.indexOf('true') !== -1
        }
    },
    mounted(){
        this.$store.dispatch('user/setNavbarBread',[
            {
                path: '/',
                name: '首页',
            },
            {
                path: '',
                name: '数字科创',
            },
            {
                path: '',
                name: '需求库',
            },
        ])
        this.getQuery()
        this.getAllLists()
    },
    data(){
        return{
            keywords: '',   // 关键词
            limit: 20,  // 每页个数
            offset: 1,   // 页数
            selectionLoading: ['false','false'],    // 频道、行业加载
            channelId: { id: '',val: '' },   // 咨询类型
            industryId: { id: '',val: '' },  // 行业类型
            allDemandTypes: [],    // 所有频道
            allIndustries: [],  // 所有行业
            demandLoading: false, // 需求加载
            demandList: [],   // 需求列表
            total: 0,   // 总页数
            more1: true,   // 需求更多
            more2: false,   // 行业更多
        }
    },
    watch:{
        loading(val){   // 监听加载变化
            if(!val){
                this.getVal() // 获取类型后记录路由
            }
        },
        $route(){
            this.getQuery()
            this.getVal()
        },
    },
    methods:{
        goToPublish(){  // 发布需求
            routerWindow(this.$router,'/postdemand',{})
        },
        setBread(){ // 设置面包屑
            if(this.channelId.id !== 'all'){
                this.$store.dispatch('breadcrumb/setItems',[...this.breadcrumbItems,{path: this.$route.fullPath,meta:{breadTitle:this.channelId.val}}])
            }
        },
        pageChange(val){    // 翻页
            this.offset = val
            this.$router.push({
                path:'/search-demand',
                query:{
                    keywords: this.keywords,
                    page: this.offset,
                    channelId: this.channelId.id,
                    industryId: this.industryId.id
                }
            })
        },
        clearOption(type){  // 清除参数 type 0:需求类型 1:行业 2:清空
            switch (type) {
                case 0:
                    this.channelId = { id: 'all',val: '全部'}
                    break;
                case 1:
                    this.industryId = { id: 'all',val: '全部'}
                    break;
                default:
                    if(this.channelId.id === 'all' && this.industryId.id === 'all'){
                        ElMessage.warning('请选择分类后再清空!')
                        return
                    }
                    this.channelId = { id: 'all',val: '全部'}
                    this.industryId = { id: 'all',val: '全部'}
                    break;
            }
            this.$router.push({
                path:'/search-demand',
                query:{
                    keywords: this.keywords,
                    page: 1,
                    channelId: this.channelId.id,
                    industryId: this.industryId.id
                }
            })
        },
        newSearch(){    // 根据keywords跳转
            if(this.keywords.length === 0){ // 空判断
                ElMessage.warning('请输入搜索关键词!')
                return
            }
            if(this.keywords === this.$route.query.keywords){
                ElMessage.warning('已经搜索当前关键词!')
                return
            }
            this.$router.push({
                path:'/search-demand',
                query:{
                    keywords: this.keywords,
                    page: 1,
                    channelId: this.channelId.id,
                    industryId: this.industryId.id
                }
            })
        },
        selectOption(type,item){ // 选择参数 type 0:需求类型 1:行业
            if(type === 0){
                this.channelId.id = item.id + ''
                this.channelId.val = item.val
                this.$router.push({
                    path:'/search-demand',
                    query:{
                        keywords: this.keywords,
                        page: 1,
                        channelId: this.channelId.id,
                        industryId: this.industryId.id
                    }
                })
                return
            }
            this.industryId.id = item.id + ''
            this.industryId.val = item.val
            this.$router.push({
                path:'/search-demand',
                query:{
                    keywords: this.keywords,
                    page: 1,
                    channelId: this.channelId.id,
                    industryId: this.industryId.id
                }
            })
        },
        getAllLists(){    // 获取所有列表
            this.selectionLoading = this.selectionLoading.map((item)=>{
                item = 'true'
                return item
            })
            let userInfo = localStorage.getItem('userInfo')
            let id = '1'
            if(userInfo) {
                userInfo = JSON.parse(userInfo)
                id = userInfo.id
            }
            getposttype(id).then(res=>{ // 获取获取需求类型
                this.allDemandTypes = [ {id: 'all',val: '全部'},...res.data.list.map((item)=>{return {id: item.id + '',val:item.name}})]
                this.selectionLoading[0] = 'false'
            }).catch(error=>{
                this.allDemandTypes = [ {id: 'all',val: '全部'}]
                console.log('获取需求类型失败:',error);
            })
            getSelectFatherSonByType(6).then(res=>{ // 获取行业
                this.allIndustries= [{id:'all',val:'全部'},...res.data]
                this.selectionLoading[1] = 'false'
            }).catch(error=>{
                console.log('获取行业错误:',error);
                this.allIndustries = [{id:'all',val:'全部'}]
            })
        },
        getVal(){   // 设定显示的val
            if(this.allDemandTypes.length > 1 && this.channelId.id){
                this.allDemandTypes.find((item, index)=>{
                    if(item.id + '' === this.channelId.id){
                        this.channelId.val = item.val
                        if(index >= 10) this.more1 = true   // 在第二行
                        return
                    }
                })
            }
            if(this.allIndustries.length > 1 && this.industryId.id){
                this.allIndustries.find((item)=>{
                    if(item.id + '' === this.industryId.id){
                        this.industryId.val = item.val
                        return
                    }
                })
            }
            this.getDemands()
            this.setBread() // 获取路由Id后进行面包屑设置
        },
        getQuery(){ // 获取路由参数
            this.keywords = !this.$route.query.keywords || this.$route.query.keywords.length === 0 ? '' : this.$route.query.keywords
            this.offset = this.$route.query.page ? Number(this.$route.query.page) : 1
            this.channelId.id = this.$route.query?.channelId?.length > 0 ? this.$route.query?.channelId : 'all'
            this.industryId.id = this.$route.query?.industryId?.length > 0 ? this.$route.query?.industryId : 'all'
        },
        async getDemands(){ // 获取需求
            this.demandLoading = true
            let newsType = ''
            let industryId = ''
            if(this.channelId.id !== 'all') newsType = this.channelId.id
            if(this.industryId.id !== 'all') {  // 获取小分类的id
                this.allIndustries.find((item)=>{
                    if(item.id + '' === this.industryId.id + ''){
                        industryId = item.child.map((child)=>child.id)
                        return true
                    }
                })
            }
            let res = undefined
            try {
                res = await getDemandList(industryId,newsType,this.keywords,this.limit,this.offset,0,iurChannelId) // 有关键词搜索
            } catch (error) {
                console.log('获取需求失败!', error);
                this.demandList = []
                this.total = 0
                this.demandLoading = false
                return
            }
            this.demandList = res.data.result
            this.total = res.data.total
            this.demandLoading = false
        },
    }
}
</script>

<style scoped lang="scss">
.out-container{
    width: 100%;
    display: flex;
    justify-content: center;
    background: #FAF9F9;
    .in-container{
        width: 1200px;
    }
}
.sticky-position{
    position: sticky;
    z-index: 999;
}
.top-44{
    top: 44px;
}
.top-88{
    top: 88px;
}
.top-92{
    top: 92px;
    padding-bottom: 20px;
}
.top-136{
    top: 136px;
    padding-bottom: 20px;
}
.search-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    padding: 50px 0;
    background: #FAF9F9;
    z-index: 2000;
    .publish{
        width: 88px;
        height: 50px;
        background: $--color-theme;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30px;
        cursor: pointer;
    }
    .title-big{
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 33px;
    }
    .input{
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1px solid $--color-theme;
        margin-left: 30px;
        :deep() .el-input{
            width: 424px;
            font-size: 16px;
        }
        :deep() .el-input__inner{
            border: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 50px;
            padding-right: 0;
        }
        .search-button{
            width: 80px;
            height: 50px;
            background: $--color-theme;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 16px;
            color: #ffffff;
        }
    }
}
.selections{
    .type{
        display: flex;
        justify-content: flex-start;
        white-space: nowrap;
        padding: 20px 0px 10px 0px;
        .selection-container{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            white-space: nowrap;
            position: relative;
            width: 1200px;
            .selection{
                margin: 0 15px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
                cursor: pointer;
                display: flex;
                align-items: flex-start;
                margin-bottom: 20px;
            }
            .selection:hover{
                color: $--color-theme;
            }
            .more{
                color: #666666;
                display: flex;
                align-items: center;
                position: absolute;
                right: -20px;
                top: 0;
            }
            .back{
                color: #666666;
                position: absolute;
                right: -20px;
                top: 0;
                display: flex;
                align-items: center;
            }
            .back2{
                color: #666666;
                position: absolute;
                right: -20px;
                top: 0;
                display: flex;
                align-items: center;
            }
            .choose{
                color: $--color-theme !important;
            }
            .others{
                color: gray;
            }
        }
    }
}
.selected{
    display: flex;
    background: #ffffff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 24px 30px;
    padding-bottom: 0px;
    align-items: center;
    .select{
        height: 30px;
        border-radius: 4px;
        border: 1px solid #666666;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        display: flex;
        justify-content: center;
        padding: 0 8px;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;
        i {
            font-size: 14px;
            margin-left: 8px;
        }
    }
    .select:hover{
        color: $--color-theme;
        border: 1px solid $--color-theme;
    }
    .clear:hover{
        color: $--color-theme;
        border: 1px solid $--color-theme;
    }
    .clear{
        height: 30px;
        border-radius: 4px;
        border: 1px solid #666666;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        display: flex;
        justify-content: center;
        padding: 0 8px;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;
        i {
            font-size: 14px;
            margin-left: 8px;
        }
    }
}
.pagination{
    margin-top: -9px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #ffffff;
    padding: 50px 0;
    margin-bottom: 20px;
    :deep() .btn-next{
        background: #ffffff;
    }
    :deep() .btn-prev{
        background: #ffffff;
    }
    :deep() .number{
        background: #ffffff !important;
    }
    :deep() .active{
        background: $--color-theme !important;
    }
}
.title{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
}
</style>