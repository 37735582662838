<template>
  <div class="container">
    <header class="navbar" :style="`margin-left: -${scrollLeft}px;background: ${backgroundColor};`">
      <div class="center">
        <div class="anchor" @click="clickAnchor(0)">大会主旨</div>
        <div class="anchor" @click="clickAnchor(1)">大会亮点</div>
        <div class="anchor" @click="clickAnchor(2)">大会议程</div>
        <div class="anchor" @click="clickAnchor(3)">重磅嘉宾</div>
        <div class="anchor" @click="clickAnchor(4)">组织机构</div>
        <div class="anchor" @click="clickAnchor(5)">参会报名</div>
        <div class="anchor" @click="clickAnchor(6)">参会指南</div>
        <div class="anchor" @click="clickAnchor(7)">联系方式</div>
      </div>
    </header>
    <div class="right-buttons">
      <div class="button" @click="right(0)">
        <img src="@/assets/images/activity/assembly/img-0.png" style="width: 36px;height: 36px;margin-bottom: 8px;"/>
        <p>图文直播</p>
      </div>
      <div class="button" @click="right(1)">
        <img src="@/assets/images/activity/assembly/img-1.png" style="width: 56px;height: 52px;"/>
        <p>视频直播</p>
      </div>
      <div class="button" @click="right(2)">
        <img src="@/assets/images/activity/assembly/img-2.png" style="width: 20px;height: 20px;margin-bottom: 8px;"/>
        <p>返回顶部</p>
      </div>
    </div>
    <div class="top">
      <img src="@/assets/images/G60/forum/word.png" class="word"/>
      <p class="p-0">2024长三角科技创新服务发展大会</p>
      <div class="line">
        <img src="@/assets/images/G60/forum/icon-0.png" style="width: 18px;height: 18px;margin-right: 7px;"/>
        <p>2024.5.31</p>
      </div>
      <div class="line">
        <img src="@/assets/images/G60/forum/icon-1.png" style="width: 14px;height: 20px;margin-right: 7px;"/>
        <p>上海松江·长三角G60科创走廊策源地</p>
      </div>
      <button class="button" @click="toJoin()">报名已结束</button>
    </div>
    <div class="part" style="padding-top: 113px;padding-bottom: 114px;" id="PART0">
      <div class="title">
        <p>大会<br/>主旨</p>
      </div>
      <div class="window">
        <img src="@/assets/images/G60/forum/icon-2.png" class="left"/>
        <img src="@/assets/images/G60/forum/icon-3.png" class="right"/>
        <p>长三角科技创新服务发展大会是以贯彻国家“以科技创新引领现代化产业体系建设”的发展任务，加强以长三角G60科创走廊为代表的区域科技创新赋能成果的内伸外延，促进全域科创生态的全面合作的主题系列活动。通过大会的举办，以人工智能驱动硬科技创新、产业赋能硬科技发展等为讨论内容与方向，吸引科技、产业、资本、企业等各方资源的融合发展，一起来探讨硬科技创新服务的生态发展，以长三角G60科创走廊的引领价值与产业赋能经验，为更多的生态合作者助力高质量发展。</p>
      </div>
    </div>
    <div class="wing-line" id="PART1">
      <p class="wing">大会亮点</p>
    </div>
    <div class="intro">
      <div class="in">
        <img src="@/assets/images/G60/forum/intro-0.png"/>
        <p class="p-0">硬科技聚焦</p>
        <p class="p-1">深入探讨人工智能、硬科技等前沿科技领域的最新趋势和应用，为参会者提供一场科技与智慧的盛宴。</p>
      </div>
      <div class="in">
        <img src="@/assets/images/G60/forum/intro-1.png"/>
        <p class="p-0">长三角科创生态展示</p>
        <p class="p-1">全面展示长三角区域科技创新的引领价值和产业赋能经验，促进全域科创生态的全面合作与发展。</p>
      </div>
      <div class="in">
        <img src="@/assets/images/G60/forum/intro-2.png"/>
        <p class="p-0">产学研深度融合</p>
        <p class="p-1">产学研融合的交流平台，促进科研机构、高校、企业之间的紧密合作，推动科技成果转化和应用。</p>
      </div>
    </div>
    <div class="wing-line" id="PART2">
      <p class="wing">大会议程</p>
    </div>
    <div class="table">
      <div style="width: 600px">
        <Table0></Table0>
      </div>
      <div style="width: 600px;">
        <Table1></Table1>
      </div>
    </div>
    <div class="wing-line" id="PART3">
      <p class="wing">会议嘉宾</p>
    </div>
    <p class="member-tip">按嘉宾姓氏音序排序，持续更新中</p>
    <div class="members-line">
      <div class="members">
        <div class="member" v-for="(item,index) in members" :key="item" :class="{'m-l-23': index % 5 !== 0}">
          <img class="avatar" :src="item.demandContent.重磅嘉宾头像"/>
          <p class="name text-ol-ell"><span class="big">{{ item.memberName }}</span><br/>{{ item.organizationName }}<br/>{{ item.position }}</p>
        </div>
      </div>
    </div>
    <div class="wing-line" style="margin-bottom: 25px;" id="PART4">
      <p class="wing">组织机构</p>
    </div>
    <div class="company-info">
      <p class="company">指导单位</p>
      <p class="company-name">上海市工商业联合会</p>
      <p class="company">主办单位</p>
      <p class="company-name">长三角数字技术应用服务产业链联盟</p>
      <p class="company-name">科企岛数字科创平台</p>
      <p class="company-name">梦想小镇沪杭创新中心</p>
      <p class="company">支持单位</p>
      <p class="company-name">长三角G60科创走廊联席会议办公室</p>
      <p class="company">协办单位</p>
      <p class="company-name">宣城市科学技术局</p>
    </div>
    <p class="company" style="margin-top: 0;margin-bottom: 53px;">大会媒体矩阵</p>
    <div class="media-window">
      <div class="media">
        <div class="img" v-for="item in 24" :key="item">
          <img :src="require('@/assets/images/G60/forum/media/' + (item - 1) +'.png')" />
        </div>
      </div>
    </div>
    <div class="wing-line" id="PART5">
      <p class="wing">参会报名</p>
    </div>
    <div class="join-window">
      <div class="inner">
        <img src="@/assets/images/G60/forum/left-1.png"/>
        <div class="center">
          <p>参会提示</p>
          <p>1、参会验证通知：报名提交成功后将于会前2-3天统一发送参会验证短信，请您妥善保管，需现场出示验证；</p>
          <p>2、报名截止时间：请您在5月28日前完成报名，逾期将无法在线上报名，可选择现场报名参会；</p>
          <p>3、交通与食宿说明：参会代表往返交通及住宿费用自理，大会期间用餐由组委会统一安排；</p>
          <p>4、解释权声明：大会组委会保留最终解释权。</p>
          <button class="button" @click="toJoin()">报名已结束</button>
        </div>
        <img src="@/assets/images/G60/forum/right-1.png"/>
      </div>
    </div>
    <div class="wing-line">
      <p class="wing" style="width: 200px;">举办地介绍</p>
    </div>
    <div class="address-intro">
      <img src="@/assets/images/G60/forum/left-2.png" class="img"/>
      <p>
        长三角G60科创走廊是包括上海松江、嘉兴、杭州、金华、苏州、湖州、宣城、芜湖、合肥9座城市的长三角一体化发展矩阵，上海松江区是“G60科创走廊”的策源地。长三角G60科创走廊产业赋能中心于2024年1月上线，是利用长三角G60科创走廊科创、产业、金融、人才等资源，为各级政府、园区产业协同以及为各类企业发展提供全方位赋能的综合服务平台。
      </p>
    </div>
    <div class="wing-line" id="PART6">
      <p class="wing">参会指南</p>
    </div>
    <div class="address">
      <p class="p-0">大会地址</p>
      <div class="bottom">
        <div class="left">
          <p>主会场：G60科创走廊科创大厦（上午）</p>
          <p>地  址：上海市松江区莘砖公路668号G60科创大厦二楼松江厅</p>
        </div>
        <div class="left">
          <p>分会场：长三角G60科创走廊宣城科创中心（下午）</p>
          <p>地  址：上海市松江区云振路410号6号楼</p>
        </div>
      </div>
    </div>
    <div class="address-way">
      <div class="transport">
        <div class="up">
          上海虹桥机场、上海虹桥火车站出发
        </div>
        <div class="bottom">
          <div class="line">
            <img src="@/assets/images/G60/forum/16-14.png" style="width: 16px;height: 14px;margin-right: 8px;padding-top: 4px;"/>
            <p>距会场约30分钟车程</p>
          </div>
          <div class="line">
            <p>或地图导航为长三角g60科创走廊规划展示馆<span class="tip"><br/>(总行程约1.5小时)</span></p>
          </div>
          <div class="line">
            <img src="@/assets/images/G60/forum/12-16.png" style="width: 12px;height: 16px;margin-right: 12px;padding-top: 4px;"/>
            <p>乘坐<span>【虹桥枢纽4路 → 松莘线B线】</span>至漕河泾开发区松江园区站</p>
          </div>
          <div class="line">
            <img src="@/assets/images/G60/forum/12-18.png" style="width: 12px;height: 18px;margin-right: 12px;padding-top: 2px;"/>
            <p>后步行800米到达</p>
          </div>
        </div>
      </div>
      <div class="transport">
        <div class="up">
          上海浦东机场出发
        </div>
        <div class="bottom">
          <div class="line">
            <img src="@/assets/images/G60/forum/16-14.png" style="width: 16px;height: 14px;margin-right: 8px;padding-top: 4px;"/>
            <p>距会场约1小时车程</p>
          </div>
          <div class="line">
            <p>或地图导航为长三角g60科创走廊规划展示馆<span class="tip"><br/>(总行程约1.5小时)</span></p>
          </div>
          <div class="line">
            <img src="@/assets/images/G60/forum/12-16.png" style="width: 12px;height: 16px;margin-right: 12px;padding-top: 4px;"/>
            <p>乘坐<span>【机场九线 → 松莘线B线】</span>至漕河泾开发区松江园区站</p>
          </div>
          <div class="line">
            <img src="@/assets/images/G60/forum/12-18.png" style="width: 12px;height: 18px;margin-right: 12px;padding-top: 2px;"/>
            <p>后步行800米到达</p>
          </div>
        </div>
      </div>
      <div class="transport">
        <div class="up">
          上海站 出发
        </div>
        <div class="bottom">
          <div class="line">
            <img src="@/assets/images/G60/forum/16-14.png" style="width: 16px;height: 14px;margin-right: 8px;padding-top: 4px;"/>
            <p>距会场约1小时车程</p>
          </div>
          <div class="line">
            <p>或地图导航为长三角g60科创走廊规划展示馆<span class="tip"><br/>(总行程约1.5小时)</span></p>
          </div>
          <div class="line">
            <img src="@/assets/images/G60/forum/12-16.png" style="width: 12px;height: 16px;margin-right: 12px;padding-top: 4px;"/>
            <p>乘坐<span>【地铁1号线 → 松莘线B线】</span>至漕河泾开发区松江园区站</p>
          </div>
          <div class="line">
            <img src="@/assets/images/G60/forum/12-18.png" style="width: 12px;height: 18px;margin-right: 12px;padding-top: 2px;"/>
            <p>后步行800米到达</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" id="PART7">
      <div class="center">
        <div class="section-0">
          <p class="p-0">关于科企岛</p>
          <p class="p-1">2024长三角科技创新服务发展大会</p>
          <p class="p-1">报名参会及咨询热线：400-6265-298</p>
        </div>
        <div class="section-1">
          <p class="p-0">大会咨询</p>
          <p class="p-1">崔女士</p>
          <p class="p-1">电话：18121469018</p>
          <p class="p-1">邮箱：cuizixuan@keqidao.com</p>
        </div>
        <div class="section-2">
          <p class="p-0">商务合作</p>
          <p class="p-1">乔先生</p>
          <p class="p-1">电话：13166080725</p>
          <p class="p-1">邮箱：qiaoyingyi@keqidao.com</p>
        </div>
        <div class="section-3">
          <p class="p-0">媒体合作</p>
          <p class="p-1">乔先生</p>
          <p class="p-1">电话：13166080725</p>
          <p class="p-1">邮箱：qiaoyingyi@keqidao.com</p>
        </div>
        <div class="section-4">
          <p class="p-0">扫描二维码 下载科企岛APP</p>
          <img class="code" src="@/assets/images/G60/forum/code.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table0 from './components/Table0.vue'
import Table1 from './components/Table1.vue'
// import { getActivityMember } from '@/api/activity';
// import { mixedSort } from '@/utils/mixedSort';
// import { environment } from "@/utils/consts";
import { setTitle,setKeywords,setDescription, } from "@/utils/tool.js"

export default{
  components:{
    Table0,Table1,
  },
  data(){
    return{
      scrollLeft: 0, // 横向滚动距离
      members: [],
      constMember: [
        {
          'demandContent':{
            '重磅嘉宾头像': 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/04/29/1716955639192498',
          },
          'memberName': '葛亮',
          'organizationName': '浦发银行总行科技',
          'position': '金融部副总经理',
        },
        {
          'demandContent':{
            '重磅嘉宾头像': 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/04/29/1716955657507566',
          },
          'memberName': '李东',
          'organizationName': '财中金控',
          'position': '主管合伙人兼总裁',
        },
        {
          'demandContent':{
            '重磅嘉宾头像': 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/04/29/1716955668987834',
          },
          'memberName': '刘文标',
          'organizationName': '之江商学院',
          'position': '副院长',
        },
        {
          'demandContent' :{
            '重磅嘉宾头像': 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/04/23/1716447131356038',
          },
          'memberName': '汤侃磊',
          'organizationName': '国家工信部评审专家、上海',
          'position': '市经信委中小企业服务专家',
        },
        {
          'demandContent' :{
            '重磅嘉宾头像': 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/04/29/171695568661726',
          },
          'memberName': '王延平',
          'organizationName': '中国医药生物技术协会',
          'position': '膳食纤维分会会长',
        },
        {
          'demandContent':{
            '重磅嘉宾头像': 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/04/23/1716447176848590',
          },
          'memberName': '闫俊杰',
          'organizationName': '北京三创国际',
          'position': '董事长 ',
        },
        {
          'demandContent':{
            '重磅嘉宾头像': 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/04/23/1716447147553017',
          },
          'memberName': '余朝刚',
          'organizationName': '上海工程技术大学',
          'position': '副教授',
        },
        {
          'demandContent':{
            '重磅嘉宾头像': 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/04/29/1716955697903441',
          },
          'memberName': '张超',
          'organizationName': '科企岛数字科创平台',
          'position': '联席CEO',
        },
        {
          'demandContent':{
            '重磅嘉宾头像': 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/04/29/1716955722499679',
          },
          'memberName': '周正曙',
          'organizationName': '原上海市经信委外经处',
          'position': '处长',
        },
        {
          'demandContent':{
            '重磅嘉宾头像': 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/04/29/1716955733720628',
          },
          'memberName': '朱宏芳',
          'organizationName': '中慧道(上海)企业管理服',
          'position': '务有限公司总经理',
        },
      ],
      backgroundColor: '',
    }
  },
  beforeUnmount(){  // 移除事件监听
    window.onscroll = () => {}
  },
  created(){
    setTitle('数智硬科技·聚能新未来 - 长三角科技创新服务发展峰会')
    setDescription('长三角科技创新服务发展峰会汇聚科技、产业、资本等多方力量，共同探讨人工智能驱动硬科技创新与产业赋能。峰会将于2024年5月31日在上海松江举办，旨在加强长三角G60科创走廊科技创新成果的内伸外延，促进全域科创生态的全面合作。')
    setKeywords('科企岛、长三角G60科创走廊、人工智能驱动、硬科技创新、产业赋能、科技创新服务、上海松江、科创生态合作')
  },
  mounted(){
    this.setScroll()  // 设置横向滚动
    this.getMember()
  },
  methods:{
    right(index){
      switch (index) {
        case 2:
          window.scrollTo(0,0)
          break;
        case 1:
          window.open('https://www.keqidao.com/live?roomId=357564653109249')
          break;
        case 0:
          window.open('https://live.photoplus.cn/live/79768230?accessFrom=qrcode&userType=null&code=0914p3ll2h3Axd4X13nl2YPZQK34p3l1&state=STATE#/live')
          break;
        default:
          break;
      }
    },
    toJoin(){
      // if(environment === 'uat'){
      //   window.open('https://umobile.keqidao.com/G60-forum')
      // }
      // else{
      //   window.open('https://mobile.keqidao.com/G60-forum')
      // }
    },
    getMember(){
      // getActivityMember({
      //   "limit": 100,
      //   "searchText": '"重磅嘉宾意愿": "是"',
      //   "type": 42,
      // }).then(res=>{
      //   this.members = mixedSort(res.data,'memberName').map((item)=>{
      //     if(item.demandContent){
      //       item.demandContent = JSON.parse(item.demandContent)
      //     }
      //     return item
      //   })
      // })
      this.members = this.constMember
    },
    setScroll(){
      this.scrollLeft = Math.max(
        document.body.scrollLeft,
        document.documentElement.scrollLeft
      );
      this.handleScroll()
      window.onscroll = () => {
        this.handleScroll()
        this.scrollLeft = Math.max(
          document.body.scrollLeft,
          document.documentElement.scrollLeft
        );
      };
    },
    handleScroll () {
      const scrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.backgroundColor = `rgba(0, 0, 0,${(scrollTop / (scrollTop + 1000)) >= 0.8 ? (scrollTop / (scrollTop + 1000)) : (scrollTop / (scrollTop + 1000)) + 0.2})`
    },
    clickAnchor(index){
      const element = document.getElementById('PART' + index);
      if(element) element.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  background: linear-gradient( 90deg, #0E109C 0%, #161471 100%);
  .right-buttons{
    position: fixed;
    right: 0;
    top: 40vh;
    z-index: 2000;
    .button{
      width: 80px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #ffffff;
      margin: 15px 0;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      p{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 14px;
      }
    }
  }
  .navbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    min-width: 1210px;
    z-index: 100;
    .center{
      width: 1210px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .anchor{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        text-align: justify;
        font-style: normal;
        cursor: pointer;
        margin-left: 30px;
      }
    }
  }
  .top{
    width: 100%;
    background-image: url('../../assets/images/G60/forum/top-back.png');
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;
    padding-top: 141px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 87px;
    .word{
      width: 412px;
      height: 193px;
      margin-bottom: 22px;
    }
    .p-0{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #FFFFFF;
      line-height: 30px;
      text-align: justify;
      font-style: normal;
      margin-bottom: 49px;
    }
    .line{
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      p{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 28px;
        text-align: justify;
        font-style: normal;
      }
    }
    .button{
      margin-top: 56px;
      width: 385px;
      height: 60px;
      background-color: #606266;
      cursor: not-allowed;
      border-radius: 1px;
      border: 2px solid #FFFFFF;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 33px;
    }
  }
  .part{
    width: 1200px;
    position: relative;
    margin: 0 auto;
    .title{
      width: 186px;
      height: 186px;
      background-image: url('../../assets/images/G60/forum/back-0.png');
      background-size: cover;
      background-position-x: center;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 63px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 40px;
      color: #FFFFFF;
      line-height: 48px;
      letter-spacing: 1px;
      text-align: justify;
      font-style: normal;
    }
    .window{
      margin-left: 71px;
      width: 1129px;
      height: 218px;
      box-sizing: border-box;
      position: relative;
      background: linear-gradient( 77deg, #251FBA 0%, #0074C1 100%);
      border: 1px solid #283ED5;
      padding: 40px 40px 40px 154px;
      p{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 33px;
        letter-spacing: 1px;
        text-align: justify;
        font-style: normal;
        width: 935px;
      }
      .left{
        width: 37px;
        height: 33px;
        position: absolute;
        left: 156px;
        top: -20px;
      }
      .right{
        width: 37px;
        height: 33px;
        position: absolute;
        right: 47px;
        bottom: -14px;
      }
    }
  }
  .wing-line{
    width: 100%;
    height: 66px;
    .wing{
      margin: 0 auto;
      font-size: 40px;
      line-height: 56px;
      padding: 0 151px;
      background: -webkit-linear-gradient(0deg, #446EFF 0%, #C6A4FF 100%); /* Chrome, Safari */
      background: linear-gradient(0deg, #446EFF 0%, #C6A4FF 100%); /* 标准语法 */
      -webkit-background-clip: text; /* Chrome, Safari */
      background-clip: text;
      -webkit-text-fill-color: transparent; /* Chrome, Safari */
      color: transparent; /* 兼容不支持background-clip的浏览器 */
      text-align: center;
      position: relative;
      width: 160px;
      z-index: 2;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 183px;
        height: 50px;
        background-image: url('../../assets/images/G60/forum/wing-0.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 1;
      }
      &::after{
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 183px;
        height: 50px;
        background-image: url('../../assets/images/G60/forum/wing-1.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 1;
      }
    }
  }
  .intro{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .in{
      width: 385px;
      padding-top: 37px;
      background: linear-gradient( 180deg, #1A36BC 0%, #11128E 100%);
      background-size: 100% 293px;
      background-repeat: no-repeat;
      background-position-y: bottom;
      background-position-x: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 40px;
      margin-bottom: 104px;
      img{
        width: 143px;
        height: 176px;
        margin-bottom: 14px;
      }
      .p-0{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 25px;
        text-align: justify;
        font-style: normal;
        margin-bottom: 19px;
      }
      .p-1{
        width: 324px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 28px;
        text-align: justify;
        font-style: normal;
      }
    }
  }
  .table{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 129px;
    box-sizing: border-box;
  }
  .member-tip{
    width: 100%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
    text-align: center;
    margin-top: 7px;
    margin-bottom: 75px;
  }
  .members-line{
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 132px;
    .m-l-23{
      margin-left: 112px;
      margin-bottom: 50px;
    }
    &::before{
      width: 270px;
      content: '';
      z-index: 1;
      height: 1005px;
      background-image: url('../../assets/images/G60/forum/left-0.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      top: 0;
    }
    &::after{
      width: 270px;
      content: '';
      z-index: 1;
      height: 1005px;
      background-image: url('../../assets/images/G60/forum/right-0.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 0;
    }
    .members{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      .member{
        width: 150px;
        z-index: 2;
        margin-bottom: 20px;
        .avatar{
          width: 150px;
          height: 150px;
          background: linear-gradient( 141deg, #88F2FE 0%, #2045FF 100%);
          background-position: bottom;
          margin-bottom: 20px;
          object-fit: cover;
          border-radius: 50%;
          border: 2px solid #ffffff;
          box-sizing: border-box;
        }
        .name{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color:#f7f7f7;
          line-height: 22px;
          width: 100%;
          text-align: center;
          .big{
            font-size: 18px;
            font-weight: bold;
            line-height: 35px;
            color:#ffffff;
          }
        }
      }
    }
  }
  .company-info{
    width: 100%;
    position: relative;
    padding-bottom: 150px;
    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -89px;
      z-index: 1;
      width: 640px;
      height: 178px;
      background: linear-gradient( 81deg, #333BB2 0%, #11128C 100%);
      border-radius: 1px;
    }
    &::after{
      content: '';
      position: absolute;
      right: 0;
      top: 192px;
      z-index: 1;
      width: 358px;
      height: 238px;
      background: linear-gradient( 114deg, #23218C 0%, #161471 100%);
      border-radius: 1px;
    }
    .company{
      width: 100%;
      text-align: center;
      margin-bottom: 29px;
      margin-top: 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: bold;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 28px;
      text-align: center;
      font-style: normal;
      z-index: 2;
      position: relative;
    }
    .company-name{
      width: 100%;
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 25px;
      text-align: center;
      font-style: normal;
      margin-bottom: 23px;
      z-index: 2;
      position: relative;
    }
  }
  .company{
    width: 100%;
    text-align: center;
    margin-bottom: 29px;
    margin-top: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    position: relative;
  }
  .media-window{
    width: 100%;
    padding: 48px 0  20px;
    background: rgba($color: #FFFFFF, $alpha: 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 156px;
    .media{
      width: 1120px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .img{
        width: 14.3%;
        height: 96px;
        margin-bottom: 24px;
        img{
          width: 154px;
          height: 100%;
        }
      }
    }
  }
  .join-window{
    width: 1200px;
    height: 394px;
    border: 10px solid;
    border-image: linear-gradient(134deg, rgba(32, 137, 255, 1), rgba(31, 188, 230, 1), rgba(30, 153, 186, 1), rgba(202, 59, 203, 1)) 10 10;
    box-sizing: border-box;
    margin: 117px auto 129px;
    display: flex;
    align-items: center;
    justify-content: center;
    .inner{
      width: 1140px;
      height: 340px;
      display: flex;
      align-items: center;
      img{
        width: 142px;
        height: 340px;
      }
      .center{
        width: calc(100% - 284px);
        box-sizing: border-box;
        padding: 30px 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
          width: 100%;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          margin-bottom: 30px;
        }
        .button{
          width: 185px;
          height: 44px;
          background-color: #606266;
          cursor: not-allowed;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 22px;
          text-align: center;
          font-style: normal;
          border: none;
        }
      }
    }
  }
  .address-intro{
    width: 1200px;
    margin: 67px auto 130px;
    display: flex;
    align-items: center;
    position: relative;
    .img{
      z-index: 1;
      width: 792px;
      height: 469px;
    }
    p{
      z-index: 2;
      width: 515px;
      height: 261px;
      background: linear-gradient( 99deg, #1F4099 0%, #161475 100%);
      box-sizing: border-box;
      padding: 46px 28px 0;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 30px;
      letter-spacing: 1px;
      text-align: justify;
      font-style: normal;
      margin-left: -110px;
    }
  }
  .address{
    width: 1200px;
    box-sizing: border-box;
    height: 178px;
    background: rgba(34,96,191,0.5);
    border: 1px solid #0265F5;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 28px;
    .p-0{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 25px;
      text-align: center;
      font-style: normal;
      width: 100%;
      padding-top: 21px;
      margin-bottom: 34px;
    }
    .bottom{
      width: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 14px;
      .left{
        width: 555px;
        height: 80px;
        background: rgba($color: #190443, $alpha: 0.12);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        box-sizing: border-box;
        padding: 0 27px;
        p{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 16px;
          text-align: justify;
          font-style: normal;
        }
      }
    }
  }
  .address-way{
    width: 1200px;
    margin: 0 auto 85px;
    display: flex;
    justify-content: space-between;
    .transport{
      width: 380px;
      border-radius: 6px 6px 0px 0px;
      .up{
        padding: 4px 0;
        border-radius: 6px 6px 0px 0px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 17px;
        color: #FFFFFF;
        line-height: 32px;
        text-align: justify;
        font-style: normal;
        padding-left: 38px;
        position: relative;
        background: linear-gradient( 270deg, #B300E3 0%, #214FE4 44%, #0DB0E1 100%);
        height: 40px;
        box-sizing: border-box;
        &::before{
          content: "";
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-right: 11px solid transparent;
          border-left: 11px solid #ffffff;
          border-bottom: 7px solid transparent;
          position: absolute;
          left: 20px;
          top: 14px;
        }
      }
      .bottom{
        width: 100%;
        border: 1px solid #0265F5;
        background: rgba(34,96,191,0.5);
        box-sizing: border-box;
        padding: 10px 0 10px 16px;
        .line{
          display: flex;
          width: 100%;
          padding: 5px 0;
          p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
            line-height: 22px;
            font-style: normal;
            span{
              color: #89F3FF;
            }
            .tip{
              font-size: 14px;
              color: #ffffff !important;
            }
          }
        }
      }
    }
  }
  .footer{
    width: 100%;
    margin-bottom: 212px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 65px;
    padding-bottom: 61px;
    background: #02005F;
    .center{
      display: flex;
      width: 1200px;
      justify-content: space-between;
      .section-0{
        width: 241px;
      }
      .section-1{
        width: 197px;
      }
      .section-2{
        width: 202px;
      }
      .section-3{
        width: 202px;
      }
      .section-4{
        width: 173px;
        img{
          width: 72px;
          height: 72px;
          margin-top: 20px;
          margin-left: 51px;
        }
      }
      .p-0{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 15px;
        text-align: justify;
        font-style: normal;
        white-space: nowrap;
      }
      .p-1{
        margin-top: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 15px;
        text-align: justify;
        font-style: normal;
        white-space: nowrap;
      }
    }
  }
}
</style>