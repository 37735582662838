<template>
  <div class="content">
    <div class="section-0">
      <div class="line-0">
        <div class="left">
          <p class="title">企业资讯一览</p>
          <p class="tip">开启知识之门，获取最新技术资讯。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content{
  width: 866px;
  .section-0{
    width: 100%;
    .line-0{
      width: 866px;
      height: 90px;
      background: linear-gradient(90deg, #C9CDFF 0%, #EFF0FF 100%);
      box-sizing: border-box;
      padding: 24px 18px 20px 24px;
      margin-bottom: 7px;
    }
  }
}
</style>