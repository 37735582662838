<template>
  <div class="container">
    <div v-if="show">
      <el-dialog v-model="show" :show-close="false" :close-on-press-escape="false" :width="'900px'" :custom-class="'dialog-no-title'">
        <video style="width: 100%;object-fit: contain;margin-bottom: -4px;" controls autoplay>
          <source src="http://1306514709.vod2.myqcloud.com/bc2da50dvodsh1306514709/d19894721397757888292152540/GutHgTFmO5EA.mp4">
        </video>
      </el-dialog>
    </div>
    <div class="new-banner-line">
      <p class="banner-title" @click="toBanner">{{ bannerName }}</p>
      <div class="flex" style="align-items: flex-end;">
        <p class="a-titles">
          <a v-for="(item,index) in banners" :key="item.id" target="_blank" :href="item.pathPc" class="a-title"><span v-if="index > 0">|</span>{{ item.title }}</a>
        </p>
        <p class="more-button" @click="toNews">更多<img src="@/assets/images/G60/home/more-news.png" /></p>
      </div>
    </div>
    <div class="part search">
      <Search></Search>
    </div>
    <div class="part head">
      <Headline></Headline>
    </div>
    <div class="part nine-line">
      <NewLine></NewLine>
    </div>
    <div class="part">
      <Park></Park>
    </div>
    <div class="part view" v-if="views.length > 0">
      <Viewpoint :list="views"></Viewpoint>
    </div>
    <div class="part">
      <Innovation></Innovation>
    </div>
    <div class="part lab">
      <LabCor></LabCor>
    </div>
    <div class="part">
      <div class="project">
        <div class="top">
          <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/19/1705651119293689" class="img" />
          <p class="title">探角数字科创Saas服务平台<br/>企业科技创新全流程解决方案</p>
          <a class="button-0" @click="checkLocal">立即定制</a>
          <a class="button-1" @click="checkG60">观看视频</a>
        </div>
        <div class="bottom">
          <div class="section">
            <img src="@/assets/images/G60/home/p0.png" style="width: 64px;" />
            <p class="title">数据驱动</p>
            <p class="word">利用AI和大数据整合并分析全球的技术、产业及市场数据，通过先进的数据分析模型，为企业提供精准、有价值的决策支持</p>
          </div>
          <div class="section">
            <img src="@/assets/images/G60/home/p1.png" style="width: 80px;" />
            <p class="title">技术匹配</p>
            <p class="word">深入解析企业的技术需求和技术属性，从海量的科研专家和项目中，精准匹配出最符合企业需求的技术解决方案和合作伙伴</p>
          </div>
          <div class="section">
            <img src="@/assets/images/G60/home/p2.png" style="width: 66px;" />
            <p class="title">人才对接</p>
            <p class="word">汇聚众多领域的科研专家和产业导师。企业可以通过平台轻松对接到这些专家资源，共同攻克技术难题，突破发展瓶颈</p>
          </div>
          <div class="section">
            <img src="@/assets/images/G60/home/p3.png" style="width: 71px;" />
            <p class="title">项目资源</p>
            <p class="word">企业可以通过参与海量的项目资源，不仅获得资金和政策支持，还能与更多的合作伙伴建立联系，共同拓展业务领域，实现更大的发展</p>
          </div>
        </div>
      </div>
    </div>
    <footer class="about">
      <div class="content">
        <div class="up">
          <div class="top">
            <p class="title">长三角科创走廊</p>
            <div>
              <p class="intro">长三角G60科创走廊包括G60国家高速公路和沪苏湖、商合杭高速铁路沿线的上海松江，江苏苏州，浙江杭州、湖州、嘉兴、金华，安徽合肥、芜湖、宣城9个市(区)，从秉持新发展理念的基层生动实践上升为长三角一体化发展国家战略的重要平台。</p>
              <p class="intro">紧扣“一体化”和“高质量”两个关键，坚持市场化、法治化、国际化导向，瞄准国际先进科创能力和产业体系，全力打造中国制造迈向中国创造的先进走廊、科技和制度创新驱动的先试走廊、产城融合发展的先行走廊。</p>
            </div>
          </div>
        </div>
        <div class="bottom">
          <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/11/14/1702523108845992" class="img" />
          <div class="codes">
            <div class="code">
              <img src="@/assets/images/G60/home/g60-gong.png"/>
              <p>微信公众号</p>
            </div>
            <div class="line"></div>
            <div class="code">
              <img src="@/assets/images/G60/home/g60-video.png"/>
              <p>微信公众号</p>
            </div>
            <p class="tip">扫码关注</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import LabCor from './components/home/LabCor.vue';
import Innovation from './components/home/Innovation.vue';
import Park from './components/home/Park.vue';
import Headline from './components/home/Headline.vue';
import Viewpoint from './components/home/Viewpoint';
import Search from './components/home/Search.vue';
import NewLine from './components/home/NewLine.vue';
import { getAllCommercial } from "@/api/G60";
import { environment } from '@/utils/consts';
import { getSelectFatherSonByType } from "@/api/config";
import { routerWindow } from "@/utils/tool";
// import { getG60LocalInfo } from "@/utils/G60/login";
// import { ElMessage } from 'element-plus';
// import { memberDetails } from "@/api/user";
// import { checkG60ExpertExist } from "@/api/G60";

export default{
  components:{
    Search,
    NewLine,
    Viewpoint,
    Headline,
    Park,
    Innovation,
    LabCor,
  },
  data(){
    return{
      banners: [],
      bannerName: '',
      bannerLink: '',
      show: false,
      views: [],
      checkLoading: false,
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    toNews(){
      routerWindow(this.$router,'/news',{id: 331415558356993})
    },
    toBanner(){
      if(!this.bannerLink) return
      window.open(this.bannerLink)
    },
    getList(){
      getSelectFatherSonByType(10030).then((res)=>{
        this.bannerName = res.data[0].val
        this.bannerLink = res.data[0].remark
      })
      getAllCommercial(342681154813953).then(res=>{
        this.banners = res.data
      })
      getAllCommercial('329073557569537').then(res=>{
        this.views = res.data.map((item)=>{
          item.info = item.title.split('_')
          return item
        })
      })
    },
    checkLocal(){
      window.open(environment === 'uat' ? 'https://utanjiao.keqidao.com' : 'https://tanjiao.keqidao.com')
    },
    checkG60(){
      this.show = true
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  width: 100%;
  .new-banner-line{
    width: 100%;
    min-height: 88px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/02/06/170971291083113');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 13px 0;
    .banner-title{
      padding-top: 13px;
      width: 1200px;
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 22px;
      color: #009DFF;
      line-height: 30px;
      margin-bottom: 12px;
      cursor: pointer;
    }
    .a-titles{
      width: 1100px;
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #111111;
      line-height: 20px;
      a{
        span{
          padding: 0 20px;
          color: #111111 !important;
          &:visited{
            color: #111111 !important;
          }
          &:hover{
            color: #111111 !important;
          }
          &:active{
            color: #111111 !important;
          }
        }
        &:visited{
          color: #111111;
          span{
            color: #111111 !important;
          }
        }
        &:hover{
          color: #009DFF !important;
          span{
            color: #111111 !important;
          }
        }
        &:active{
          color: #009DFF !important;
          span{
            color: #111111 !important;
          }
        }
      }
    }
    .more-button{
      width: 50px;
      margin-left: 50px;
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      &:hover{
        color: $--G60-theme;
      }
      img{
        width: 12px;
        height: 6px;
        margin-left: 3px;
      }
    }
  }
  .about{
    width: 100%;
    background-color: #1F3856;
    display: flex;
    justify-content: center;
    .content{
      width: 1200px;
      padding-top: 60px;
      padding-bottom: 114px;
      .up{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .top{
          width: 100%;
          display: flex;
          justify-content: space-between;
          .title{
            font-size: 26px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 37px;
          }
          .intro{
            text-indent: 2em;
            padding-top: 9px;
            width: 937px;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;
            letter-spacing: 1px;
          }
        }
      }
      .bottom{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        .img{
          width: 844px;
          height: 353px;
        }
        .codes{
          width: 250px;
          padding-top: 24px;
          .code{
            width: 100%;
            display: flex;
            align-items: center;
            img{
              width: 98px;
              height: 98px;
              margin-right: 25px;
            }
            p{
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #FFFFFF;
              line-height: 22px;
            }
          }
          .line{
            width: 100%;
            height: 1px;
            background-color: #ffffff;
            margin: 28px 0;
          }
          .tip{
            width: 124px;
            height: 28px;
            background: $--G60-theme;
            margin-top: 33px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 28px;
            text-align: center;
          }
        }
      }
      .right{
        padding-top: 6px;
        width: 249px;
        .title{
          font-size: 24px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 33px;
          width: 100%;
          text-align: right;
          margin-bottom: 200px;
          height: 33px;
        }
        .word{
          font-size: 28px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 40px;
          margin-bottom: 5px;
          span{
            color: #DB0519;
          }
        }
        .tip{
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 20px;
        }
        .line{
          width: 100%;
          height: 1px;
          background-color: #ffffff;
          margin: 40px 0;
        }
        .more{
          margin-top: 58px;
          width: 124px;
          height: 28px;
          background: $--G60-theme;
          border: none;
          cursor: pointer;
          margin-top: 58px;
          font-size: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 17px;
          img{
            width: 19px;
            height: 10px;
            margin-left: 14px;
          }
        }
      }
    }
  }
  .part{
    width: 100%;
    background-color: #f0f2f5;
    display: flex;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;
    .project{
      width: 1200px;
      .top{
        width: 100%;
        position: relative;
        .img{
          width: 1200px;
          height: 314px;
        }
        .title{
          position: absolute;
          top: 74px;
          left: 97px;
          font-size: 30px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #111111;
          line-height: 48px;
          letter-spacing: 1px;
        }
        .button-0{
          position: absolute;
          top: 216px;
          left: 101px;
          width: 118px;
          height: 38px;
          background: $--G60-theme;
          border-radius: 6px;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          cursor: pointer;
          border: none;
          line-height: 38px;
          text-align: center;
          &:visited{
            color: #ffffff !important;
          }
        }
        .button-1{
          position: absolute;
          top: 216px;
          left: 233px;
          width: 118px;
          height: 38px;
          background: #ffffff;
          border-radius: 6px;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: $--G60-theme;
          cursor: pointer;
          border: none;
          text-align: center;
          line-height: 38px;
          &:visited{
            color: $--G60-theme !important;
          }
        }
      }
      .bottom{
        margin-top: 32px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        .section{
          width: 219px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img{
            height: 80px;
            margin-bottom: 10px;
          }
          .title{
            font-size: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #111111;
            line-height: 25px;
            margin-bottom: 19px;
          }
          .word{
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #111111;
            line-height: 26px;
          }
        }
      }
    }
  }
  .lab{
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/11/14/1702531619705752');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .head{
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/11/15/170260655023441');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-bottom: 21px !important;
  }
  .view{
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/11/15/1702608503515038');
    background-size: 100% 302px;
    background-repeat: no-repeat;
    padding-bottom: 0px !important;
  }
  .nine-line{
    padding-top: 45px !important;
    padding-bottom: 0 !important;
  }
  .search{
    height: 224px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/02/04/1709544390333220');
    background-size: 100% 224px;
    background-repeat: no-repeat;
    padding-top: 0 !important;
    padding-bottom: 0px !important;
  }
}
</style>