<template>
  <div class="profile-card-container" :class="{'search-card' : isSearch}" :style="`${loading ? 'cursor: wait;' : 'cursor: pointer;'}${isG60 ? 'background: transparent;': ''}`">
    <div class="top-infos" @click.stop="ToUserBtn">
      <div class="avatar">
        <!-- 'https://images.weserv.nl/?url=' + -->
        <Image :src="profile.dbDataStatus === 0 ? profile.headImage : profile.headImage" :imgId="profile?.id" :alt="'专家'" :isHeadImg="true"></Image>
      </div>
      <div class="user-info" :class="{'max-size': size ==='small'}">
        <div class="user-names">
          <div :class="{'G60-color-name': isG60,'name': !isG60}" >
            <span v-html="profile.name"></span>
          </div>
          <div v-if="profile.position" class="user-position">
            {{ profile.position }}
          </div>
          <div style="border: 1px solid #44B46F;color: #44B46F;" v-if="profile.education" class="user-position">
            {{ profile.education }}
          </div>
          <!-- 匹配程度 -->
          <div class="expert-score fs-14" v-if="$route.path === '/detail'">
            <img src="../../assets/images/user/rate/compare-rate.png" style="width: 42px;height: 14px;" />
            <div v-if="profile.score === 100" class="flex-cc">
              <img src="../../assets/images/user/rate/compare-1.png" style="width: 6px;height: 12px;" />
              <img src="../../assets/images/user/rate/compare-0.png" style="width: 9px;height: 12px;" />
              <img src="../../assets/images/user/rate/compare-0.png" style="width: 9px;height: 12px;" />
            </div>
            <div v-else-if="profile.score >= 10 && profile.score < 100" class="flex-cc">
              <img :src="rateNumber[getTen(profile.score)]" :style="`width: ${getTen(profile.score) === 1 ? 6 : 9}px;height: 12px;`" />
              <img :src="rateNumber[getLast(profile.score)]" :style="`width: ${getLast(profile.score) === 1 ? 6 : 9}px;height: 12px;`" />
            </div>
            <div v-else-if="profile.score < 10 && profile.score > 0" class="flex-cc">
              <img :src="rateNumber[profile.score]" :style="`width: ${profile.score === 1 ? 6 : 9}px;height: 12px;`" />
            </div>
            <img src="../../assets/images/user/rate/compare-icon.png" style="width: 10px;height: 12px;" />
          </div>
        </div>
        <div class="user-belong">
          <span>工作单位：</span>{{profile.belong}}
        </div>
        <div class="user-belong">
          <div v-html="showDirection" class="text-ol-ell"></div>
        </div>
      </div>
    </div>
    <el-button @click.stop="ToUserBtn" class="homepage-button" :class="{'search-button': isSearch,'G60-color-button': isG60,}" :style="`${isSearch ? 'border-radius: 4px !important;' : 'border-radius: 4px !important;'}}`" v-loading="loading">
      {{isSearch ? '联络专家' : '查看主页' }}
    </el-button>
  </div>
</template>

<script>
import {staticPath,talentProjectSystemPage,environment} from "@/utils/consts";
import { G60site,TanSite } from "@/router/index";
import {getToken,checkVip} from '@/utils/auth'
import { ElMessage } from 'element-plus';
import { trackingUser,checkUserDiction } from "@/api/user"
import to from "await-to-js";
import { routerWindow } from "@/utils/tool";

export default {
  name: "ProfileCard",
  computed:{
    showDirection(){
      if(this.profile.shortDirection?.trim()) return '<span style="color: #999999;">研究方向：</span>' + this.profile.shortDirection?.trim()
      else if(this.profile.direction?.trim()) return '<span style="color: #999999;">研究方向：</span>' + this.profile.direction?.trim()
      return ''
    },
    isG60(){
      return G60site
    },
    isTan(){  // ? 暂时无用
      return TanSite
    }
  },
  data() {
    return {
      loading: false, // 是否加载接口中
      imgLoaded: false, // 图片加载完成
      isLogin: false,   // 是否登录
      staticPath: staticPath,
      rateNumber:[
        require('../../assets/images/user/rate/compare-0.png'),
        require('../../assets/images/user/rate/compare-1.png'),
        require('../../assets/images/user/rate/compare-2.png'),
        require('../../assets/images/user/rate/compare-3.png'),
        require('../../assets/images/user/rate/compare-4.png'),
        require('../../assets/images/user/rate/compare-5.png'),
        require('../../assets/images/user/rate/compare-6.png'),
        require('../../assets/images/user/rate/compare-7.png'),
        require('../../assets/images/user/rate/compare-8.png'),
        require('../../assets/images/user/rate/compare-9.png'),
      ],
      subscribeStatus: false,
      subscribeLoading: false
    }
  },
  props: {
    isSettled:{
      // 是否入驻页面
      type: Boolean,
      default: () => false
    },
    isSearch:{
      // 是否搜索页面
      type: Boolean,
      default: false,
    },
    noBottom:{
      type: Boolean,
      default: false,
    },
    index: {
        type: Number
    },
    large: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: () => {
        return "large"
      },
    },
    blacklist: {
      type: Boolean,
      default: () => false
    },
    profile: {
      type: Object,
      required: true,
      default: () => {
        return {
          belong: "",
          education: "",
          headImage: "",
          id: -1,
          name: "",
          organizationName: "",
          position: "",
          subscribe: false
        }
      }
    },
    showTags: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },
  mounted() {
    this.isLogin = getToken() !== ""
  },
  methods: {
    checkVip,
    getTen(num){
      // 获取10位
      return Math.floor(num / 10)
    },
    getLast(num){
      // 获取10位
      return num % 10
    },
    async ToUserBtn(){
      if(this.loading) return // 加载中则返回
      if(talentProjectSystemPage.includes(this.$route.path)){
        routerWindow(this.$router,'/talent-project-detail',{expertId: this.profile.id,})
        return
      }
      else if(this.isG60){
        routerWindow(this.$router,'/detail',{expertId: this.profile.id,})
        return
      }
      this.loading = true
      // TODO_Track: 联系专家 e_chatexpert_from_expertlist
      if(!TanSite){
        if(!this.isLogin){
          this.loading = false
          this.$router.push('/login')
          ElMessage.warning('您尚未登录!')
          return
        }
        let userInfo = localStorage.getItem('userInfo')
        userInfo = JSON.parse(userInfo)
        const [trackErr,trackRes] = await to(trackingUser(JSON.stringify({"memberId": userInfo.id,"expertId": this.profile.id,"source": "pc"}),"专家联系类型"))  // 登录了就进行埋点
        if(trackErr){
          this.loading = false
          console.error("track user error:",trackErr);
          ElMessage.error("联络错误，请稍后再试")
          return
        }
        trackRes?.code === "Sx200" && console.log("track contact expert success");
        if(!this.isSettled){
          // 查看用户的身份，如果无身份则打开引导注册数字科创中心的窗口
          // const [err, hasOrg] = await to(memberDetails(userInfo.id))
          // if(err){
          //   console.error("get userinfo error:",err);
          //   this.loading = false
          //   return
          // }
          // if(!hasOrg.data.organizationId && !hasOrg.data.isVip){ // 无身份用户且不是VIP
          //   this.$store.commit("message/SET_CREATE_WINDOW_VISIBLE",true)
          //   this.loading = false
          //   return
          // }
          let res = await checkUserDiction({uniqueLab:'/lab/findExpertByIdNew'},false,this.profile.id)
          if(!res.data){ // 直接通过接口判断
            if(userInfo.isVip){
              // 是VIP
              ElMessage.warning('使用次数超限!')
              return
            }
            this.$store.dispatch("message/hideIMWindow")
            // this.$store.commit("message/SET_CREATE_WINDOW_VISIBLE",true)
            // this.$store.commit('message/SET_IM_WINDOW_DIALOG', true)
            routerWindow(this.$router,'/vip',{setType:2})
            this.loading = false
            return
          }
        }
      }
      this.loading = false
      if(this.profile.organizationType === 1){
        if(TanSite){
          // tanjiao页面
          window.open(environment === 'uat' ? `https://uwww.keqidao.com/lab?id=${this.profile.organizationId}&identity=${this.profile.organizationType}` : `https://www.keqidao.com/lab?id=${this.profile.organizationId}&identity=${this.profile.organizationType}`)
          return
        }
        routerWindow(this.$router,'/lab',{id: this.profile.organizationId,identity: this.profile.organizationType})
        return
      }
      if(this.profile.dbDataStatus === 1){
        if(TanSite){
          // tanjiao页面
          window.open(environment === 'uat' ? `https://uwww.keqidao.com/my-lab?id=${this.profile.id}` : `https://www.keqidao.com/my-lab?id=${this.profile.id}`)
          return
        }
        routerWindow(this.$router,'/my-lab',{id: this.profile.id})
      }
      else{
        let keyword = ''  // 传入专家高亮词
        let achieve = ''  // 传入成果高亮词
        if(this.$route.path === '/search' && this.$route.query.keyword && this.$route.query.searchType == 8) achieve = this.$route.query.keyword
        else if(this.$route.path === '/search' && this.$route.query.keyword) keyword = this.$route.path === '/search' && this.$route.query.keyword
        else if(this.$route.path === '/search-center'  && this.$route.query.keyword && this.$route.query.searchType == 8) achieve = this.$route.query.keyword
        else if(this.$route.path === '/search-center'  && this.$route.query.keyword) keyword = this.$route.query.keyword
        if(TanSite){
          // tanjiao页面
          window.open(environment === 'uat' ? `https://uwww.keqidao.com/my-lab-doctor?expertId=${this.profile.id}&achieve=${achieve}&keyword=${keyword}` : `https://www.keqidao.com/my-lab-doctor?expertId=${this.profile.id}&achieve=${achieve}&keyword=${keyword}`)
          return
        }
        routerWindow(this.$router,'/my-lab-doctor',{expertId: this.profile.id,achieve:achieve,keyword:keyword})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.search-card{
  padding: 20px 50px 20px 30px !important;
  box-sizing: border-box;
}
.profile-card-container {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: #FFFFFF;
  justify-content: space-between;
  position: relative;
  &:hover{
    .G60-color-name{
      color: $--G60-theme !important;
    }
  }
  &::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 30px;
    width: calc(100% - 60px);
    height: 1px;
    background-color: #F3F3F3;
  }
}

.top-infos{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.border-bottom{
  position: relative;
}
.border-bottom::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 22px;
  width: 756px;
  height: 2px;
  background: #F1F5F8;
}

.avatar {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  cursor: pointer !important;
  overflow: hidden;
}


.position {
  height: 22px;
  border-radius: 4px;
  border: 1px solid #FD9550;
  color: #FB8F49;
  padding: 2px 4px 2px 4px;
}

.tag-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.tag {
  margin: 10px;
  height: 22px;
  border-radius: 11px;
  padding: 0 10px 0 10px;
  border: 1px solid #FFFFFF;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}

.tag-img {
  padding: 3px;
  height: 16px;
  width: 16px;
}

.tag-img img {
  height: 100%;
  width: 100%;
}
.new-button{
  background: $--color-theme !important;
  box-shadow: 0px 2px 4px 1px rgba(2,58,95,0.1500) !important;
  border-radius: 24px !important;
  width: 96px !important;
  height: 34px !important;
  font-size: 14px !important;
  font-family: PingFangSC-Regular, PingFang SC !important;
  font-weight: 400 !important;
  color: #FFFFFF !important;
  padding: 7px 0;
  box-sizing: border-box;
  text-align: center;
}

.search-button{
  width: 92px;
  height: 50px;
  background: #2280FF;
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
}
.homepage-button {
  width: 92px;
  height: 40px;
  background: #2280FF;
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  text-align: center;
  padding-left: 18px;
}
.G60-color-button{
  background-color: $--G60-theme !important;
}
.max-size{
  max-width: 498px !important;
}
.user-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // border: 2px solid blue;
    justify-content: flex-start;
    margin-left: 20px;
    max-width: 800px;
    .user-names{
      display: flex;
      align-items: center;
      margin-top: 10px;
      .G60-color-name{
        margin-right: 20px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #111111;
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &:hover{
          color: $--G60-theme !important;
        }
      }
      .name{
        margin-right: 20px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #111111;
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .name:hover{
        color: $--color-theme !important;
      }
      .expert-score{
        color: #FF4443;
        display: flex;
        align-items: center;
      }
      .user-position{
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        border: 1px solid $--color-theme;
        color: $--color-theme;
        padding: 1px 4px;
        padding-bottom: 2px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
      }
    }
    .user-belong{
      margin-top: 12px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      width: 100%;
      span{
        color: #999999;
      }
    }
}
.user-direction{
  background: #F2F1F6;
  border-radius: 16px;
  padding: 7px 19px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #3B4352;
  margin: 10px 0;
  display: flex;
  align-items: center;
  .overflow{
    max-width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>