<template>
  <div class="flex-dc-rc">
    <img src="../../../assets/images/demand/demand-form.png" alt="" style="width: 260px;height: 110px;" class="img">
    <div class="form-container" v-loading="loading">
      <!-- 需求类型 -->
      <div class="input" v-if="types.length > 0">
        <div style="width: 100%;" class="flex-cc">
          <div class="title fs-16 fc-333 required">
            需求分类
          </div>
        </div>
        <el-select v-model="typeId" :placeholder="`请选择需求分类`" style="width: 100%;">
          <el-option
            v-for="item in types"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <!-- 需求表单 -->
      <div v-for="item in form" :key="item.id" class="input" :class="{'not-show': !judgeShow(item)}">
        <div style="width: 100%;" class="flex-cc">
          <div class="title fs-16 fc-333" :class="{'required': item.notNull + '' === '1'}">
            {{item.name}}
          </div>
        </div>
        <!-- 输入 -->
        <el-input :maxlength="item.additionCheck.maxlength ? Number(item.additionCheck.maxlength) : 64" v-model="item.value" :placeholder="item.nullTip" v-if="item.type === 'input'"></el-input>
        <!-- 地址 -->
        <el-cascader :ref="'cascaderAddress'" :props="props"
          style="width: 100%;"
          :placeholder="item.nullTip"
          v-else-if="item.type === 'qidaoAddress'"
          v-model="item.value"></el-cascader>
        <!-- 时间 -->
        <el-date-picker
          v-else-if="item.type === 'picker'"
          v-model="item.value"
          :clearable="false"
          type="date"
          :editable="false"
          :disabledDate="disabledDate"
          :placeholder="item.nullTip">
        </el-date-picker>
        <!-- 单选 -->
        <el-select v-model="item.value" :placeholder="item.nullTip" v-else-if="item.type === 'qidaoDict'" style="width: 100%;">
          <el-option
            v-for="jtem in item.selections"
            :key="jtem.id"
            :label="jtem.val"
            :value="jtem.id">
          </el-option>
        </el-select>
        <!-- 多选 -->
        <el-select v-model="item.value" :placeholder="item.nullTip" style="width: 100%;" :collapse-tags="true" multiple v-else-if="item.type === 'selectMultiple'">
          <el-option
            v-for="jtem in item.selections"
            :key="jtem.id"
            :label="jtem.val"
            :value="jtem.id">
          </el-option>
        </el-select>
        <!-- 文本框输入 -->
        <el-input :resize="'none'" type="textarea" :rows="4" v-model="item.value" :placeholder="item.nullTip" v-if="item.type === 'textarea'"></el-input>
        <!-- 行业 -->
        <el-cascader
          v-if="item.type === 'qidaoIndustry'"
          style="width: 100%;"
          :ref="'cascader' + item.id"
          v-model="item.value"
          :options="item.selections"
          :placeholder="item.nullTip"
        ></el-cascader>
      </div>
      <!-- 手机号 -->
      <div class="input" v-if="unLogin">
        <div style="width: 100%;" class="flex-cc">
          <div class="title fs-16 fc-333 required">
            手机号
          </div>
        </div>
        <el-input :maxlength="11" v-model="phone" @input="handleNumber(0)" placeholder="请输入手机号"></el-input>
      </div>
      <!-- 验证码 -->
      <div class="input" v-if="unLogin">
        <div style="width: 100%;" class="flex-cc">
          <div class="title fs-16 fc-333 required">
            验证码
          </div>
        </div>
        <el-input :maxlength="6" v-model="code" @input="handleNumber(1)" placeholder="请输入验证码"></el-input>
        <div class="code">
          <button v-if="showCode" type="primary"  @click="getPhoneCode">获取验证码</button>
          <button v-else style="color: #B0B5C0" class="forbid">{{count}}S</button>
        </div>
      </div>
      <!-- 提交按钮 -->
      <div class="flex-rc">
        <el-button class="submit-button" :disabled="loading" @click="publish">提交审核</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getPhoneCode } from "@/api/user";  // 获取用户信息，更新名字头像
import { ElMessage } from 'element-plus';
import { getprovince, getcity, getarea, getposttype,getposttemplate, publishDemand,getIndustry} from "@/api/demand";
import { getListConfigsByTypes } from "@/api/config";
import { requestVersion } from '@/utils/consts';
import to from 'await-to-js';
export default {
  data(){
    return{
      phone: '',
      code: '',
      count: 60,
      showCode: true, // 是否显示倒计时
      timer: null,
      notShow: ["radioDict","uploadFile","multiMedia"], // 不显示的类型
      form: [], // 需求表单
      types: [],  // 需求类型
      typeId: '', // 类型Id
      version: '',  // 版本号
      unLogin: false, // 是否未登录
      loading: false, // 加载中
      props: {  // 懒加载列表内容
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then(response => {
                const cities = []
                for (let item of response.data) {
                  cities.push({
                    value: item.provinceCode,
                    label: item.provinceName,
                    leaf: false
                  })
                }
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getcity(node.value).then(response => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.cityCode,
                    label: item.cityName,
                    leaf: false
                  })
                }
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.value).then(response => {
                const areas = []
                for (let item of response.data) {
                  areas.push({
                    value: item.areaCode,
                    label: item.areaName,
                    leaf: true
                  })
                }
                resolve(areas);
              });
            }
          }, 100);
        }
      },
    }
  },
  watch:{
    typeId(val){
      // 切换类型
      if(val.length <= 0) return
      this.loading = true
      this.getDemandTemplate(val)
    }
  },
  created(){
    if(!localStorage.getItem('userInfos')){
      this.unLogin = true
    }
    this.getPostType()
  },
  methods:{
    async publish(){
      // 发布需求
      let params = []
      let postParam = {  // 上传的需求内容
        "memberId": '',
        "params": [
          {
            "additionType": "",
            "key": "",
            "name": "",
            "sequence": 0,
            "parameterId": 0,
            "type": "",
            "value": ""
          }
        ],
        "type": 1,   // 1 纯文字 2 图片 3 视频
        "summary": '', // 长度300
        "thumb": '', // 无封面
        "typeId": this.typeId,
        "version": this.version
      }
      try {
        this.form.forEach((param)=>{
          let item = {
            "additionType": param.additionType,
            "key": param.key,
            "name": param.name,
            "parameterId": param.id,
            "sequence": param.sequence,
            "type": param.type,
            "value": ''
          }
          if(this.notShow.includes(param.type)){
            // 不展示内容 判断"radioDict"公开发布
            if(param.type === "radioDict"){
              // 默认勾选
              item.value = param.selections[0].id
            }
          }
          else if(param.type === 'selectMultiple'){
            // 多选
            if(param.value?.length > 0){
              param.value = param.value.join(',')
            }
            item.value = param.value
          }
          else if(param.type === 'picker'){
            // 时间
            if(param.value?.length > 0){
              param.value = param.value.slice(0,10)
            }
            item.value = param.value
          }
          else if(param.type === 'qidaoAddress'){
            // 地址
            if(param.value?.length > 0){
              const addressAreaId = parseInt(param.value[2]);
              const addressCityId = parseInt(param.value[1]);
              const addressProvinceId = parseInt(param.value[0]);
              const addressAreaName = this.$refs.cascaderAddress.getCheckedNodes()[0].pathLabels[2]
              const addressCityName = this.$refs.cascaderAddress.getCheckedNodes()[0].pathLabels[1]
              const addressProvinceName = this.$refs.cascaderAddress.getCheckedNodes()[0].pathLabels[0]
              item.value = JSON.stringify({
                "addressCityId": addressCityId.toString(),
                "addressAreaName": addressAreaName,
                "addressCityName":  addressCityName,
                "addressDetail": addressProvinceName + '-' + addressCityName + '-' + addressAreaName,
                "addressAreaId": addressAreaId.toString(),
                "addressProvinceId": addressProvinceId.toString(),
                "addressProvinceName":  addressProvinceName
              })
            }
          }
          else if(param.type === 'textarea'){
            postParam.summary = '**kqd-str**' + param.value
            if(postParam.summary.length > 300){
              // summary 记录
              postParam.summary = postParam.summary.slice(0,290)
            }
            item.value = param.value
          }
          else{
            item.value = param.value
          }
          if(item.value){
            params.push(item)
          }
          else if(!item.value && param.notNull + '' === '1'){
            // 必填项
            throw new Error(param.nullTip)
          }
        })
      } catch (error) {
        ElMessage.warning(error.message)
        return
      }
      postParam.params = params
      if(this.unLogin){
        // 登录操作
        if(this.phone.length === 0){
          ElMessage.warning('请输入手机号!')
          return
        }
        var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if(reg.test(this.phone)==false){
          ElMessage.warning('您输入的手机号码格式不正确，请重新输入');
          return
        }
        if(this.code.length === 0){
          ElMessage.warning('请输入验证码!')
          return
        }
        else if(this.code.length !== 6){
          ElMessage.warning('请输入六位验证码!')
          return
        }
        const loginParam = {
          machineCode: "",
          version: requestVersion,
          canal: 1,
          type: 0,
          code: this.code,
          phone: this.phone
        }
        this.loading = true
        this.$store.dispatch("user/login", loginParam).then(() => {
          this.unLogin = false  // 登录成功
          let userInfo = localStorage.getItem('userInfos')
          userInfo = JSON.parse(userInfo).memberInfo
          let { id,organizationType, role,memberIndustry } = userInfo
          postParam.memberId = id
          this.loading = true
          // 登录后发布需求
          publishDemand(postParam).then((res)=>{
            if(res.code === 'Sx200'){
              ElMessage.success('发布成功!')
              this.phone = ''
              this.code = ''
              this.form = []
              this.getDemandTemplate(this.typeId)
            }
          }).catch(err=>{
            console.error('发布需求失败',err);
            this.loading = false
            return
          })
          // 没有身份的跳去选择角色，助手除外 或者未选择行业前不打开窗口
          let noIndustry = false
          if(!(memberIndustry != null && memberIndustry.length != 0)) noIndustry = true
          if(userInfo.organizationId) noIndustry = false
          if (organizationType === null) {
            if (role === 4) {
              return
            } else if(noIndustry){
              this.$router.push({path: `/industry-setup`, query: {...this.$route.query}})
            } else {
              this.$store.dispatch('user/setUpdateNavbar')
            }
          } else {
            this.$store.dispatch("user/updateVipInfo")
            this.$store.dispatch('user/setUpdateNavbar')
          }
        })
        return
      }
      else{
        postParam.memberId = JSON.parse(localStorage.getItem('userInfos')).memberInfo.id
      }
      this.loading = true
      const [err,res] = await to(publishDemand(postParam))
      if(err){
        console.error('发布需求失败',err);
        this.loading = false
        return
      }
      if(res.code === 'Sx200'){
        ElMessage.success('发布成功!')
        this.form = []
        this.getDemandTemplate(this.typeId)
      }
    },
    disabledDate(date) {  // 判断日记合法
			return date.getTime() < Date.now() - 8.64e7;
		},
    judgeShow(param){
      // 判断参数是否展示
      if(this.notShow.includes(param.type)) return false
      return true
    },
    async getDemandTemplate(id){
      // 获取需求模板
      let [err, res] = await to(getposttemplate('-100',id))
      if(err){
        console.error('获取需求模板错误');
        return
      }
      this.form = res.data.params
      this.version = res.data.version
      for(let i = 0;i < this.form.length;i++){
        if(this.form[i].additionType && !isNaN(Number(this.form[i].additionType))){
          [err, res] = await to(getListConfigsByTypes(Number(this.form[i].additionType)))
          if(err){
            console.error('获取选项失败',err);
            continue
          }
          this.form[i].selections = res.data[Number(this.form[i].additionType)]
        }
        else if(this.form[i].type === 'qidaoIndustry'){
          [err,res] = await to(getIndustry())
          if(err){
            console.error('获取行业失败',err);
            continue
          }
          this.form[i].selections = res.data.map((item)=>{
            if(item.children && item.children.length > 0){
              item.children = item.children.map((jtem)=>{
                if(jtem.children && jtem.children.length > 0){
                  jtem.children.forEach((ktem)=>{
                    ktem.value = ktem.id
                    ktem.label = ktem.val
                  })
                }
                jtem.value = jtem.id
                jtem.label = jtem.val
                return jtem
              })
            }
            item.value = item.id
            item.label = item.val
            return item
          })
        }
      }
      setTimeout(()=>{
        // 防止崩溃
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
        this.loading = false
      },100)
    },
    async getPostType(){
      // 获取需求类型
      const [err, type] = await to(getposttype('-1'))
      if(err){
        console.error('获取需求类型失败:',err);
        return
      }
      this.types = type.data.list
      if(this.types.length > 0) this.typeId = this.types[0].id
    },
    handleNumber(type){
      // 数字输入处理
      let pattern = new RegExp("^[0-9]|-$")
      switch (type) {
        case 0:
          this.phone = Array.from(this.phone).filter(v => pattern.test(v)).join("")
          break;
        default:
          this.code = Array.from(this.code).filter(v => pattern.test(v)).join("")
          break;
      }
    },
    getPhoneCode () {
      // 获取验证码
      if(this.phone.length === 0){
        ElMessage.warning('请输入手机号!')
        return
      }
      const TIME_COUNT = 60;
      var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if(reg.test(this.phone)==false){
        ElMessage.warning('您输入的手机号码格式不正确，请重新输入');
        return
      }
      getPhoneCode({phone: this.phone}).then(() => {
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.showCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count --;
            } else {
              this.showCode = true;
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
        ElMessage.success('发送成功')
      })
  },
  }
}
</script>

<style scoped lang="scss">
.img{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
}
.form-container{
  background: #ffffff;
  width: 260px;
  box-sizing: border-box;
  padding: 15px 20px;
  min-height: 550px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  .submit-button{
    width: 92px;
    height: 30px;
    min-height: 30px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 0px;
    background: $--color-theme;
    box-shadow: 0px 2px 4px 1px rgba(95,31,2,0.15);
    border-radius: 4px !important;
    font-size: 13px;
    color: #ffffff;
    margin-bottom: 5px;
  }
  .title{
    max-width: 100%;
    margin-bottom: 8px;
    line-height: 22px;
    position: relative;
  }
  .required::after{
    content: '*';
    font-size: 14px;
    position: absolute;
    right: -10px;
    top: 2px;
    color: #E71B00;
  }
  .not-show{
    display: none !important;
  }
  .input{
    margin-bottom: 15px;
    font-size: 14px;
    position: relative;
    :deep() {
      .el-date-editor--date{
        .el-input__inner {
          padding-left: 40px !important;
        }
      }
      .el-cascader{
        .el-input__inner {
          padding-right: 20px !important;
        }
        .el-input__suffix-inner{
          height: 34px !important;
        }
      }
      .el-input__inner {
        height: 34px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      input::-webkit-input-placeholder {
        color: #D8D8D8 !important;
      }
      .el-input__prefix, .el-input__suffix {
        height: 34px !important;
      }
      /* 下面设置右侧按钮居中 */
      .el-input__suffix {
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: flex-start;
      }
      .el-input__icon {
        line-height: 34px;
      }
    }
    .code{
      position: absolute;
      right: 10px;
      bottom: 10px;
      button{
        text-align: center;
        cursor: pointer;
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF9129;
        line-height: 20px;
        border: none;
        background: #ffffff;
      }
      button::before{
        content: "";
        position: absolute;
        width: 1px;
        height: 18px;
        background: #E7E7E7;
        right: 80px;
        top: 1px;
      }
      .forbid{
        cursor: not-allowed;
      }
    }
  }
}
</style>