<template>
  <div class="login-container">
    <div class="login-panel" v-loading="apiLoading" :class="{'border-right-top': !bindPhone}">
      <!-- 退出登录按钮 -->
      <div class="cancel-button" @click="cancelLogin">
        <img src="../../assets/images/cancel-login.png"/>
      </div>
      <!-- 标题 -->
      <div class="login-title">
        <div style="color: #333333;font-weight:bold;font-size: 44px">{{bindPhone ? '绑定手机号':'快捷登录'}}</div>
      </div>
      <div class="login-form" :class="{'bind-form': bindPhone}">
        <div class="input-container">
          <!-- 手机号 11位 -->
          <el-input @input="handlePhoneChange" v-model="loginForm.phoneNumber" placeholder="请输入手机号码" class="input-box" maxlength="11">
            <template #suffix v-if="loginForm.phoneNumber !== ''">
              <i class="clear-icon el-icon-circle-close el-input__icon" @click="clearPhoneNumber()"> </i>
            </template>
          </el-input>
          <div class="empty-phone" v-if="btnClicked && loginForm.phoneNumber.length === 0">
            请输入手机号
          </div>
        </div>
        <div class="input-container">
          <!-- 手机号 11位 -->
          <el-input v-model="loginForm.captcha" placeholder="请输入密码" class="input-box" maxlength="15" type="password" @keyup.enter="login()">
            <template #suffix v-if="loginForm.captcha !== ''">
              <i class="clear-icon el-icon-circle-close el-input__icon" @click="clearCaptcha()"> </i>
            </template>
          </el-input>
          <div class="empty-phone" v-if="btnClicked && loginForm.captcha.length === 0">
            请输入密码
          </div>
        </div>
        <!-- <div class="input-container">
          <el-input @input="handleCaptcha" v-model="loginForm.captcha" placeholder="短信验证码" class="input-box-code" maxlength="6" @keyup.enter="login()">
            <template #suffix v-if="loginForm.captcha !== ''">
              <i class="clear-icon el-icon-circle-close el-input__icon" @click="clearCaptcha()"> </i>
            </template>
          </el-input>
          <div class="empty-code" v-if="btnClicked && loginForm.captcha.length === 0">
            请输入验证码
          </div>
          <el-button @click="getCode()" class="code" :class="{'code-button': show, 'code-btn-disable': !show || !validPhone(loginForm.phoneNumber)}"
            :disabled="!show || !validPhone(loginForm.phoneNumber)">
            <span v-if="(show && !validPhone(loginForm.phoneNumber)) || !sendFlag" style="color: #c0c4cc">获取验证码</span>
            <span v-if="
                    show &&
                    validPhone(loginForm.phoneNumber) &&
                    !repeatFlag &&
                    sendFlag
                  ">获取验证码</span>
            <span v-if="!show">{{ count }}秒重新发送</span>
            <span v-else-if="show && validPhone(loginForm.phoneNumber) && repeatFlag">重新发送</span>
          </el-button>
        </div> -->
        <div class="tip-container" v-if="bindPhone">
          <i class="el-icon-warning" />
          {{bindPhone ? '为了您的手机号安全，请绑定手机':''}}
        </div>
        <div v-else style="height: 30px;width:100%;">
        </div>
        <div class="login-button-container">
          <!-- 登录按钮 -->
          <div class="login-button" @click="login()">{{bindPhone ? '提交':'登录/注册'}}</div>
        </div>
        <!-- 协议、声明 -->
        <div class="protocols" v-if="!bindPhone">
          登录即表示已阅读并同意
          <a class="protocol-name" target="_blank" href="/yhxy">《科企岛用户协议》</a>
          、
          <a class="protocol-name" target="_blank" href="/yszc">《科企岛隐私政策》</a>
          及
          <span>
            <a class="protocol-name" target="_blank" href="/nrsm">《科企岛内容声明》</a>。未注册的手机号或第三方帐号验证后将自动创建新帐号
          </span>
        </div>
        <!-- 绑定确认窗口 -->
        <div class="bind-info">
          <el-dialog v-model="bindWindow" top="20%" width="20%">
            <div class="bind-word">
              {{isRegistered}}是否使用 {{otherType}} 头像、昵称替换原信息
            </div>
            <div class="bind-btns">
              <div class="bind-btn" @click="confirm(0)">
                取消
              </div>
              <div class="bind-btn confirm" @click="confirm(1)">
                确认
              </div>
            </div>
          </el-dialog>
        </div>
        <!-- 未完成窗口 -->
        <div class="bind-info">
          <el-dialog v-model="unfinished" top="20%" width="20%">
            <div class="bind-word">
              登录尚未完成，是否继续？
            </div>
            <div class="bind-btns">
              <div class="bind-btn" @click="confirm(2)">
                退出
              </div>
              <div class="bind-btn confirm" @click="confirm(3)">
                继续登录
              </div>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validatePhone } from '@/utils/common/validate'
import { getPhoneCode,weChatLogin,QQLogin,checkPhone } from '@/api/user'
import { ElMessage } from 'element-plus'
import { weChatAppid,qqAppid } from '@/utils/consts';
import { TPSLogin,getLocalInfo } from "@/utils/talent-project-system/login";
import Fingerprint2 from '@fingerprintjs/fingerprintjs'


export default {
  name: "login",
  data() {
    return {
      machineCode: '',
      btnClicked: false,  // 是否点击了按钮
      showCode: true,  // 显示二维码
      codeLoading: false, // 二维码加载
      unfinished: false, // 尚未完成登录
      apiLoading: false, // 加载过程
      isRegistered: '',  // 已经有过注册
      disableCode: false, // 微信验证出错
      accessToken: '',    // QQ accessToken
      expires_in: '',     // QQ expires_in
      otherType: '',      // 第三方登录类型 QQ 微信
      confirmBind: null, // 确认绑定帐号信息
      redirectURI: '',   // 重定位地址
      getUserInfo: {},   // 获取的用户信息
      bindWindow: false,  // 绑定窗口
      loginQQ: false, // QQ登录
      loginQrcode: false, // 显示二维码
      bindPhone: false, // 绑定手机号
      loginForm: {  // 登录表单 电话与验证码
        phoneNumber: "",
        captcha: ""
      },
      sendFlag: true, // 发送验证码标志
      TIME_COUNT: 60, // 验证码倒计时60s
      count: "",  // 计时器显示
      show: true, // 判断是否点击了发送按钮
      redirect: undefined,  // 重定位路径
      otherQuery: undefined,  // 其他参数
      repeatFlag: false // 是否重复点击验证码
    }
  },
  async created(){  // 设定 navbar
    this.$store.commit('message/SET_ACTIVENAV','-')
    let fullPath = this.$route.fullPath
    if(JSON.stringify(this.$route.query.uatTest) === '"false"'){
      window.location.href = 'https://uwww.keqidao.com' + fullPath
      return
    }
    this.redirectURI = encodeURIComponent('https://www.keqidao.com' + fullPath)
    await this.judgeQQ()
    await this.judgeWechat()
  },
  async mounted(){
    this.getPrint()
    if(getLocalInfo()) this.$router.push('/talent-project-talents')
  },
  watch: {
    $route: { // 根据路径内容，获取所有参数
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
          if(this.redirect === '/industry-setup'){  // 返回首页  企业入驻页面也返回 设定行业页面
            this.redirect = '/'
            this.otherQuery = undefined
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    getPrint(){
      let that = this
      setTimeout(function () {
        Fingerprint2.get({
          excludes:{
            userAgent: true,
            // webdriver: true,
            // language: true,
            // colorDepth: true,
            deviceMemory: true,
            // localStorage: true,
            pixelRatio: true,
            // hardwareConcurrency: true,
            screenResolution: true,
            availableScreenResolution: true,
            // timezoneOffset: true,
            // timezone: true,
            // sessionStorage: true,
            // indexedDb: true,
            // addBehavior: true,
            openDatabase: true,
            // cpuClass: true,
            // platform: true,
            // doNotTrack: true,
            canvas: true,
            plugins: true,
            // canvas: true,
            webgl: true,
            webglVendorAndRenderer: true,
            // adBlock: true,
            // hasLiedLanguages: true,
            // hasLiedResolution: true,
            // hasLiedOs: true,
            // hasLiedBrowser: true,
            // touchSupport: true,
            fonts: true,
            // fontsFlash: true,
            audio: true,
            enumerateDevices: true,
          },
        },function (components) {
          const string = JSON.stringify(components)
          // arr1.forEach((item,index)=>{
          //   const value1 = JSON.stringify(item.value)
          //   const value2 = JSON.stringify(arr2[index].value)
          //   if(value1 !== value2) console.log(item.key,value1,'hehe',value2);
          // })
          const djb2Code = function(str){
            let hash = 5381;
            for (let i = 0; i < str.length; i++) {
              const char = str.charCodeAt(i);
              hash = ((hash << 5) + hash) + char; /* hash * 33 + c */
            }
            return hash;
          }
          console.log('转换前：',components);
          console.log('标识转换后：',djb2Code(string));
          that.machineCode = djb2Code(string)
        })
      }, 500)
    },
    async refreshCode(){ // 刷新二维码
      this.codeLoading = true
      /* eslint-disable-next-line */
      await new WxLogin({
        self_redirect: false,
        id: "wx_login",
        appid: weChatAppid,
        scope: "snsapi_login",
        redirect_uri: this.redirectURI,
        style: "black"
      });
      this.codeLoading = false
    },
    async oAuthLogin(params){ // 登录后缓存
      this.$store.dispatch("user/otherLogin", params).then(() => {
        let userInfo = localStorage.getItem('userInfos')
        userInfo = JSON.parse(userInfo).memberInfo
        let { organizationType, role } = userInfo
        // 没有身份的跳去选择角色，助手除外
        if (organizationType === null) {
          if (role === 4) {
            return
          } else {
            this.$router.push({ path: '/' })
          }
        } else {
          this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
          this.$store.dispatch("user/updateVipInfo")
          // setTimeout(() => {  // 延迟刷新 防止回到首页
          //   location.reload()
          // }, 1000);
        }
      }).catch((error)=>{
        console.log('loginErrorL',error);
      })
    },
    erroPage(){ // 出现绑定错误等
      let that = this
      this.disableCode = true
      if(this.$route.query.redirect){
        this.$router.push('/tempLogin')  // 改变路由
        setTimeout(()=>{
          that.$router.push({path:'/login',query: {'redirect': this.$route.redirect}})
        },500)
      }
      else{
        this.$router.push('/tempLogin')
        setTimeout(()=>{
          that.$router.push('/login')
        },500)
      }
    },
    async judgeQQ(){  // QQ判断
      const path = this.$route.fullPath
      const tokenStart = path.indexOf('access_token')
      const tokenEnd = path.indexOf('expires_in')
      if(tokenStart !== -1 && tokenEnd !== -1){ // QQ登录 截取token
        this.codeLoading = true
        this.apiLoading = true
        this.otherType = 'QQ'
        const token = path.slice(path.indexOf('#') + 14, tokenEnd - 1)
        const expires_in = path.slice(tokenEnd + 11)
        this.accessToken = token
        this.expires_in = expires_in
        let res = {}
        try {
          res = await QQLogin(token,expires_in,9)
          console.log(res);
        } catch (error) {
          this.apiLoading = false
          this.codeLoading = false
          if(error.message === 'bindPhone'){
            this.bindPhone = true // 未绑定手机号
            this.showCode = false
            return
          }
        }
        this.codeLoading = false
        this.apiLoading = false
        if(res.code === 'Sx200'){
          await this.oAuthLogin(res)
        }
      }
    },
    async judgeWechat(){      // 微信判断
      if(this.$route.query.code && this.$route.query.state){
        this.apiLoading = true
        this.codeLoading = true
        this.otherType = '微信'
        let res = {}
        try {
          res = await weChatLogin(this.$route.query.code,1)
          console.log(res);
        } catch (error) {
          console.log(error);
          this.apiLoading = false
          this.codeLoading = false
          if(error.message === 'bindPhone'){
            this.bindPhone = true // 未绑定手机号
            this.showCode = false
            return
          }
          else if(error.message === 'codeError'){ // code失效
            this.erroPage()
          }
        }
        this.codeLoading = false
        this.apiLoading = false
        if(res.code === 'Sx200'){
          await this.oAuthLogin(res)
        }
      }
      else return
    },
    wechatLogin(){  // 微信登录
      this.loginQrcode = true
      this.$nextTick(()=>{
        /* eslint-disable-next-line */
        new WxLogin({
          self_redirect: false,
          id: "wx_login",
          appid: weChatAppid,
          scope: "snsapi_login",
          redirect_uri: this.redirectURI,
          style: "black"
        });
      })

    },
    qqLogin(){  // qq登录
      window.location.href = `https://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=${qqAppid}&response_type=token&scope=all&redirect_uri=${this.redirectURI}`
    },
    cancelLogin(){  // 取消登录按钮
      if(this.bindPhone){ // 绑定手机状态
        this.unfinished = true
        return
      }
      this.$router.push('/')  // 回到首页，防止重复返回登录页面
    },
    handlePhoneChange(e) {  // 手机号处理，只填入数字
      if (this.show && this.repeatFlag) {
        this.repeatFlag = false
      }
      let pattern = new RegExp("^[0-9]|-$")
      this.loginForm.phoneNumber = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    handleCaptcha(e) {  // 验证码处理，只输入数字
      let pattern = new RegExp("^[0-9]*$")
      this.loginForm.captcha = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    clearPhoneNumber() {  // 清空手机号
      this.loginForm.phoneNumber = ""
    },
    clearCaptcha() {  // 清空验证码
      this.loginForm.captcha = ""
    },
    validPhone(phone) { // 判断手机号是否合法
      if (!validatePhone(phone)) {
        return false;
      } else if (phone === "") {
        return false;
      } else {
        return true;
      }
    },
    getCode() { // 验证码倒计时60s
      if(this.disableCode){ // code无效
        return
      }
      if (this.sendFlag) {
        const params = {
          phone: this.loginForm.phoneNumber
        }
        getPhoneCode(params).then((res) => {
          if (res.code === 'Sx200') {
            ElMessage.success('验证码已发送!')
            this.repeatFlag = true;
            if (!this.timer) {
              this.count = this.TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= this.TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }
        });
      }
    },
    confirm(type){  // 是否需要绑定
      if(type === 0) {
        this.confirmBind = false
      }
      else if(type === 2){
        this.erroPage()
        return
      }
      else if(type === 3){
        this.unfinished = false
        return
      }
      else {
        this.confirmBind = true
      }
      this.bindWindow = false
      this.login()
    },
    async login() { // 登录按钮
      if(this.disableCode){ // code无效
        return
      }
      this.btnClicked = true
      if(this.loginForm.phoneNumber.length === 0){
        ElMessage.warning('请输入手机号！')
        return
      }
      if(this.loginForm.captcha.length === 0){
        ElMessage.warning('请输入密码！')
        return
      }
      if(this.confirmBind == null && this.otherType.length > 0){ // 判断是否需要绑定
        let res = await checkPhone(this.loginForm.phoneNumber)  // 判断手机是否绑定
        if(res.data === true) { // 已注册，显示绑定
          this.isRegistered = '此手机号码已注册，'
          this.bindWindow = true
          return
        }
        if(res.data === false){ // 未注册，直接替换
          this.confirmBind = true
        }
      }
      if(this.bindPhone){ // 绑定电话状态
        let res = {}
        this.btnClicked = false
        if(this.otherType === '微信'){
          try {
            res = await weChatLogin(this.$route.query.code,6,this.loginForm.phoneNumber,this.loginForm.captcha,this.confirmBind)
          } catch (error) {
            console.log(error);
            if(error.message === 'codeError'){ // code失效
              this.erroPage()
              return
            }
          }
          if(res.code === 'Sx200'){
            await this.oAuthLogin(res)
          }
        }
        else if(this.otherType === 'QQ'){
          try {
            res = await QQLogin(this.accessToken,this.expires_in,10,this.loginForm.phoneNumber,this.loginForm.captcha,this.confirmBind)
          } catch (error) {
            console.log(error);
            if(error.message === 'codeError'){ // code失效
              this.erroPage()
              return
            }
          }
          if(res.code === 'Sx200'){
            await this.oAuthLogin(res)
          }
        }
        return
      }
      TPSLogin({
        machineCode: this.machineCode,
        phone: this.loginForm.phoneNumber,
        code: this.loginForm.captcha,
      }).then(()=>{
        if(this.$route.query.redirect){
          window.location.href = this.$route.query.redirect
        }
        else{
          this.$router.push({
            path: '/talent-project-talents'
          })
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    getOtherQuery(query) {  // 获取路径内所有参数
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/color.scss';
.fix-top {
  position: fixed;
  top: 0;
}
.login-container {
  width: 100%;
  background: url("https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/2022/03/12/1649746463487874") no-repeat center top;
  background-size: cover;
  min-height: 1080px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.code-btn-disable {
  width: 30%;
  height: 44px;
  margin-left: 10px;
  border: none !important;
  font-size: 22px;
  font-weight: 400;
  background: #ffffff !important;
  ::v-deep span{
    color: $--color-theme !important;
  }
}
.scan-login{
  margin-top: 60px;
  background: #F1F1F1;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 630px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-right: 1px solid gray;
  flex-direction: column;
  position: relative;
  .top-pic{
    position: absolute;
    top: 60px;
    width: 136px;
    height: 50px;
  }
  .cover-text{
    position: absolute;
    top: 110px;
    width: 136px;
    height: 40px;
    background: rgb(241, 241, 241);
  }
  .scan-text{
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0F1C34;
  }
}
.border-right-top{
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.login-panel {
  margin-top: 80px;
  width: 552px;
  min-height: 620px;
  overflow: hidden;
  position: relative;
  z-index: 900;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  .cancel-button{
    position: absolute;
    right: 28px;
    top: 28px;
    cursor: pointer;
    img{
      width: 28px;
      height: 28px;
    }
  }
}
.login-title {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  height: 60px;
}
.code-button {
  display: inline-block;
  width: 30%;
  height: 44px;
  margin-left: 10px;
  line-height: .8;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 400;
border: none !important;
  background: #ffffff;
  ::v-deep span{
    color: $--color-theme !important;
  }
}
.code-button :hover,
.code-button :active {
  display: inline-block;
  ::v-deep span{
    color: $--color-theme !important;
  }
}
.login-form {
  background: white;
  min-height: 500px;
  text-align: center;
}
.bind-form {
  min-height: 450px;
}
.input-container {
  width: 100%;
  padding: 40px 50px 10px 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  position: relative;
  .empty-phone{
    position: absolute;
    right: 50px;
    bottom: -20px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #E3373F;
  }
  .empty-code{
    position: absolute;
    right: 50px;
    bottom: -20px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #E3373F;
  }
  .code{
    position: absolute;
    right: 51px;
    top: 50%;
    margin-top: -7px;
  }
  .code::before{
    position: absolute;
    content: '';
    width: 2px;
    height: 14px;
    background: #DFE0E5;
    border-radius: 1px;
    left: -5px;
    top: 50%;
    margin-top:-7px;
  }
  img{
    position: absolute;
    width: 30px;
    height: 34px;
    left: 70px;
    top: 50%;
    margin-top: -2px;
  }
  ::v-deep .is-disabled{
    background: #f09a53;
    // border-radius: 18px;
  }
  ::v-deep input{
    height: 44px;
    font-size: 22px;
    border-radius: 0 !important;
  }
  .input-box{
    height: 44px;
    border: 1px solid #DFE0E5;
  }
  .input-box-code{
    width: 100%;
    height: 44px;
    border: 1px solid #DFE0E5;
  }
}
::v-deep .el-input__inner {
  border: 0px solid #eee !important;
}
.tip-container {
  margin-top: 30px;
  padding: 0px 30px 0 30px;
  color: #999999;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  i{
    color: $--color-theme;
    font-size: 18px;
    padding-right: 10px;
  }
}
.clear-icon {
  cursor: pointer;
  font-size: 22px;
  margin-top: 3px;
}
.login-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.login-button {
  width: 82%;
  border-radius: 4px;
  cursor: pointer;
  height: 66px;
  background: $--color-theme;
  color: white;
  font-size: 28px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-button:hover {
  background: $--color-theme;
}
.protocols {
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  line-height: 24px;
  padding: 10px 50px 0 50px;
  font-size: 14px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #B0B5C0;
  float:left;
  text-align: justify;
  word-break: break-all;
  a:hover{
    color: #faae6f;
  }
}
.protocol-name {
  color: #B0B5C0;
  word-break: break-all;
}
.other-login{
  margin-top: 90px;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-around;
  img{
    width: 36px;
    height: 36px;
    padding-bottom: 10px;
  }
  .login-type{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ::v-deep .el-dialog__header{
    background: #E9E9E9;
    min-width: 300px;
    position: relative;
  }
  ::v-deep .el-dialog__body{
    background: #E9E9E9;
    min-width: 300px;
  }
}
.bind-info{
  ::v-deep .el-dialog__header{
    background: #ffffff;
    min-width: 300px;
    position: relative;
  }
  ::v-deep .el-dialog__body{
    background: #ffffff;
    min-width: 300px;
  }
}
.bind-word{
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #333333;
}
.bind-btns{
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-top: 20px;
  margin-bottom: -30px;
  border-top: 2px solid #c7c7c7;
  display: flex;
  align-items: center;
  .bind-btn{
    width: 50%;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #666666;
    padding: 20px 0;
    cursor: pointer;
  }
  .confirm{
    color: #EC8541;
    border-left: 2px solid #c7c7c7;
  }
}
</style>