<template>
  <div class="content">
    <div class="top">
      <div class="left">
        <img src="@/assets/images/G60/home/area-title.png" class="img" />
      </div>
      <div class="left">
        <div class="options">
          <p class="option" :class="{'active': cur === 0}" @click="cur=0">找专家</p>
          <p class="option" :class="{'active': cur === 2}" @click="cur=2">找项目</p>
          <p class="option" :class="{'active': cur === 3}" @click="cur=3">找成果</p>
          <p class="option" :class="{'active': cur === 1}" @click="cur=1">找专利</p>
        </div>
        <div class="input-line">
          <input v-model="key" class="input" placeholder="请输入专家姓名、项目、成果等关键词" @keyup.enter="startSearch"/>
          <button class="search-button" @click="startSearch">搜索</button>
        </div>
      </div>
    </div>
    <!-- <div class="announce-line animate__animated animate__fadeIn" v-if="list1.length > 0" >
      <div class="date">
        <p class="day">{{ announceNews.publishTime.slice(8,10) }}日</p>
        <p class="year">{{ announceNews.publishTime.slice(0,4) }}年{{ announceNews.publishTime.slice(5,7) }}月</p>
      </div>
      <div class="infos">
        <div class="info-title">
          <img src="@/assets/images/G60/home/bell.png" class="bell" />
          <p>通知公告</p>
        </div>
        <div class="info" @click="toDetail(announceNews.id)">
          <a @click.stop class="a-info text-ol-ell" :href="`/detail?id=${announceNews.id}`" target="_blank">{{ announceNews.title }}</a>
          <div class="time">{{ announceNews.publishTime }}</div>
        </div>
      </div>
    </div>
    <div v-else style="height: 76px;width: 1px;margin-bottom: 60px;"></div> -->
  </div>
</template>

<script>
import { routerWindow } from '@/utils/tool.js';
import { ElMessage } from 'element-plus';
import { getlistHomepage } from "@/api/G60";


export default{
  data(){
    return{
      // G60 通知公告频道ID 329969431543809
      cur: 0,
      key: '',
      list1: [],
      list2: [],
      listIndex: 0,
      listInterval: null,
    }
  },
  computed:{
    announceNews(){
      return this.list1[0]
    },
  },
  mounted(){
    this.getList()
  },
  beforeUnmount(){
    if(this.listInterval) clearInterval(this.listInterval)
  },
  methods:{
    toDetail(id){
      if(!id) return
      routerWindow(this.$router,'/detail',{id})
    },
    getList(){
      getlistHomepage(1,1,329969431543809).then(res=>{
        this.list1 = res.data.result
      })
    },
    toRoute(path){
      if(!path) return
      routerWindow(this.$router,path)
    },
    startSearch(){
      if(this.key.length === 0) {
        ElMessage.warning('请输入搜索关键词')
        return
      }
      switch (this.cur) {
        case 0:
          routerWindow(this.$router,'/search',{
            keyword: this.key,
            city:  '',
            school:  '',
            college: '',
            title: '',
            offset: 1,
            searchType: 1,
            analyzerType: 2,
            searchName : 0,
            type: '0'
          })
          break;
        case 1:
          routerWindow(this.$router,'/search',{
            keywords: this.key,
            page: 1,
            patentType: '',
            legalStatus: '',
            life: '',
            type: '4'
          })
          break;
        case 2:
          routerWindow(this.$router,'/search',{
            keyword: this.key,
            page: 1,
            techId: '',
            industryId: '',
            corId: '',
            typeId: '',
            type: '1'
          })
          break;
        case 3:
          routerWindow(this.$router,'/search',{
            keyword: this.key,
            city:  '',
            school:  '',
            college: '',
            title: '',
            offset: 1,
            searchType: 8,
            analyzerType: 2,
            searchName : 0,
            type: '2'
          })
          break;
        default:
          break;
      }
      // this.$store.dispatch('G60/openCor','123')
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .left{
      .img{
        width: 547px;
        height: 52px;
      }
      .options{
        width: 324px;
        padding-bottom: 3px;
        margin-bottom: 17px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .option{
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 25px;
          cursor: pointer;
        }
        .active{
          position: relative;
          &::after{
            content: '';
            left: 50%;
            margin-left: -10px;
            bottom: -2px;
            position: absolute;
            width: 20px;
            height: 2px;
            background: #FFFFFF;
          }
        }
      }
      .input-line{
        width: 538px;
        position: relative;
        .input{
          width: 100%;
          border: none;
          background-color: #ffffff;
          height: 46px;
          border-radius: 6px;
          font-size: 14px;
          color: #111111;
          box-sizing: border-box;
          padding-left: 15px;
          padding-right: 100px;
        }
        .search-button{
          position: absolute;
          top: 0;
          right: 0;
          border: none;
          width: 94px;
          height: 46px;
          background: $--G60-theme;
          border-radius: 6px;
          cursor: pointer;
          font-size: 20px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }
  .announce-line{
    width: 100%;
    height: 76px;
    background: rgba(255,255,255,0.9);
    border-radius: 6px;
    border: 1px solid #FFFFFF;
    margin-bottom: 60px;
    display: flex;
    box-sizing: border-box;
    padding: 8px 35px 8px 8px;
    justify-content: space-between;
    .date{
      width: 105px;
      height: 60px;
      background: $--G60-theme;
      border-radius: 4px;
      .day{
        width: 100%;
        text-align: center;
        margin-top: 7px;
        margin-bottom: 4px;
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
      }
      .year{
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
      }
    }
    .infos{
      width: 1033px;
      .info-title{
        display: flex;
        margin-bottom: 23px;
        .bell{
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
      .info{
        width: 100%;
        justify-content: space-between;
        display: flex;
        cursor: pointer;
        &:hover{
          .a-info{
            color: $--G60-theme !important;
          }
          .time{
            color: $--G60-theme;
          }
        }
        .a-info{
          display: block;
          max-width: 900px;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 14px;
          cursor: pointer;
          &:hover{
            color: $--G60-theme !important;
          }
          &:visited{
            color: #111111;
          }
        }
        .time{
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 14px;
        }
      }
    }
  }
}
</style>