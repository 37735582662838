<template>
  <div class="content">
    <p class="title">产学研合作</p>
    <p class="word">产学研中心是专为科技创新、产业研究与实践应用相结合而设立的平台。在这里，企业，科研机构和专家人才紧密合作，打破传统接线，共同推动创新成果从实验室走向市场。通过产学研深度融合，不断引领园区企业走向科技创新的前沿，为园区的可持续发展和产业升级做出积极贡献。</p>
    <div class="numbers">
      <div class="left">
        <div class="num" style="padding-bottom: 44px;">
          <img src="@/assets/images/G60/home/num0.png" style="width: 86px;" />
          <p class="tip">专家教授</p>
        </div>
        <div class="num" style="padding-bottom: 44px;">
          <img src="@/assets/images/G60/home/num1.png" style="width: 105px;" />
          <p class="tip">技术成果</p>
        </div>
        <div class="num" style="padding-bottom: 44px;">
          <img src="@/assets/images/G60/home/num2.png" style="width: 93px;height: 23px;" />
          <p class="tip">入驻企业</p>
        </div>
        <div class="num">
          <img src="@/assets/images/G60/home/num3.png" style="width: 67px;" />
          <p class="tip">知识产权</p>
        </div>
        <div class="num">
          <img src="@/assets/images/G60/home/num4.png" style="width: 80px;" />
          <p class="tip">创新项目</p>
        </div>
        <div class="num">
          <img src="@/assets/images/G60/home/num5.png" style="width: 74px;height: 23px;" />
          <p class="tip">创投机构</p>
        </div>
      </div>
      <div class="right">
        <a href="/publish-demand" target="_blank"><img src="@/assets/images/G60/home/entrance.png" class="img" /></a>
        <a href="/publish-project" target="_blank"><img src="@/assets/images/G60/home/publish.png" class="img" /></a>
      </div>
    </div>
    <div class="voice-line">
      <div class="show-date">
        <p class="day">{{ day }}日</p>
        <p class="bottom">{{ year }}年{{ month}}月</p>
      </div>
      <img src="@/assets/images/G60/home/voice.png" class="icon" />
      <div class="word">
        <div class="line animate__animated animate__fadeIn" v-if="showList2[0]">
          <p class="left text-ol-ell">{{ showList2[0].position }}发布了<span>1</span>条{{ showList2[0].type + '' === '1' ? '成果': '产学研合作' }}</p>
          <a @click.stop class="more" href="/space" target="_blank">更多</a>
        </div>
        <div class="line animate__animated animate__fadeIn" v-if="showList2[1]">
          <p class="left text-ol-ell">{{ showList2[1].position }}发布了<span>1</span>条{{ showList2[1].type + '' === '1' ? '成果': '产学研合作' }}</p>
          <a @click.stop class="more" href="/space" target="_blank">更多</a>
        </div>
      </div>
    </div>
    <div class="recommends">
      <div class="experts">
        <p class="t-title">推荐专家</p>
        <div v-loading="loadingExperts">
          <div class="expert animate__animated animate__fadeIn" v-for="expert in experts" :key="expert.id">
            <div class="avatar" @click="openExpertWindow(expert.id)">
              <Image :src="expert?.img" :imgId="expert?.id" :alt="'专家'" :isHeadImg="true"></Image>
            </div>
            <div class="info" @click="openExpertWindow(expert.id)">
              <div class="name">
                <a target="_blank" class="text-ol-ell" @click="openExpertWindow(expert.id)">{{ expert.name }}</a>
                <p class="position text-ol-ell" v-if="expert.rank">{{ expert.rank }}</p>
                <p class="education text-ol-ell" v-if="expert.education">{{ expert.education }}</p>
              </div>
              <p class="belong text-ol-ell">工作单位：{{ expert.belong || '-' }}</p>
              <p class="direction text-ol-ell">研究方向：{{ expert.direction || '-' }}</p>
            </div>
            <div class="right">
              <button @click="openExpertWindow(expert.id)">申请合作</button>
            </div>
          </div>
        </div>
      </div>
      <div class="projects">
        <p class="t-title">推荐项目</p>
        <div v-loading="loadingProjects">
          <div class="project animate__animated animate__fadeIn" v-for="item in projects" :key="item.id">
            <div class="left" @click="openAchieveWindow(item.id)">
              <a target="_blank" class="p-title text-ol-ell">{{ item.title }}</a>
              <p class="content text-ol-ell" :title="'item.sketch'">{{ item.sketch }}</p>
              <div class="infos">
                <p class="text-ol-ell" style="margin-right: 20px;">技术成熟度：{{ item.stage }}</p>
                <p class="text-ol-ell">合作类型：{{ item?.cooperationModeList[0] }}</p>
              </div>
              <div class="keys">
                <div class="key" v-for="jtem in 1" :key="jtem">
                  <a class="text-ol-ell">{{ item.applicationIndustry }}</a>
                </div>
              </div>
            </div>
            <div class="right">
              <button class="button" @click="openAchieveWindow(item.id)">申请合作</button>
            </div>
          </div>
        </div>
      </div>
      <div class="demands">
        <p class="t-title">企业需求</p>
        <div v-loading="loadingDemands">
          <div class="demand animate__animated animate__fadeIn" v-for="item in demands" :key="item.id" @click="openDemandWindow(item.id)">
            <p class="publisher text-ol-ell"><span>{{ item.position }}</span>发布了新需求：</p>
            <div class="info">
              <img src="@/assets/images/G60/home/demand-icon.png" class="icon" />
              <a class="a-info text-otl-ell">{{ item.title }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { getG60ProjectList,getG60DemandList,getG60ExpertList,getG60Broadcast } from "@/api/G60";
import { initTime } from "@/utils/time"

export default{
  data(){
    return{
      year: '',
      month: '',
      day: '',
      list2: [],
      listIndex: 0,
      listInterval: null,
      experts: [],
      projects: [],
      demands: [],
      loadingExperts: false,
      loadingProjects: false,
      loadingDemands: false,
    }
  },
  computed:{
    showList2(){
      return this.list2.slice(this.listIndex,this.listIndex + 2)
    },
  },
  mounted(){
    this.getDate()
    this.getList()
  },
  methods:{
    getDate(){
      const time = JSON.parse(initTime())
      this.day = time.day
      this.month = time.month
      this.year = time.year
    },
    openExpertWindow(id){
      if(!id) return
      this.$store.dispatch('G60/openCor',id)
    },
    openDemandWindow(id){
      if(!id) return
      this.$store.dispatch('G60/openDemand',id)
    },
    openAchieveWindow(id){
      if(!id) return
      this.$store.dispatch('G60/openAchieve',id)
    },
    getList(){
      getG60Broadcast().then(res=>{
        this.list2 = res.data
        this.listInterval = setInterval(() => {
          this.listIndex += 2
          if(this.listIndex >= this.list2.length){
            this.listIndex = 0
          }
        }, 5000);
      })
      const param = {
        applicationIndustryParent: '',
        area: '',
        city: '',
        cooperationModeId: '',
        domain: '',
        keyword: '',
        showCooperationMode: true,
        limit: 3,
        offset: 1,
        province: '',
        stage: '',
        typeId: ''
      }
      this.loadingProjects = true
      getG60ProjectList(param).then(res=>{
        this.projects = res.data.result
      }).finally(()=>{
        this.loadingProjects = false
      })
      const param1 = {
        applicationIndustryParent: '',
        area: '',
        city: '',
        cooperationModeId: '',
        domain: '',
        keyword: '',
        limit: 4,
        offset: 1,
        province: '',
        term: '',
        typeId: '',
        purpose: '',
      }
      this.loadingDemands = true
      getG60DemandList(param1).then(res=>{
        this.demands = res.data.result
      }).finally(()=>{
        this.loadingDemands = false
      })
      const param2 = {
        "applicationIndustryParent": "",
        "area": "",
        "city": "",
        "graduateSchool": "",
        "keyword": "",
        "limit": 4,
        "offset": 1,
        "province": "",
        "rank": ""
      }
      this.loadingExperts = true
      getG60ExpertList(param2).then(res=>{
        this.experts = res.data.result
      }).finally(()=>{
        this.loadingExperts = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  .title{
    font-size: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 30px;
    margin-bottom: 44px;
  }
  .word{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 26px;
    letter-spacing: 1px;
    margin-bottom: 35px;
  }
  .numbers{
    width: 100%;
    display: flex;
    .left{
      padding-top: 6px;
      display: flex;
      flex-wrap: wrap;
      width: 700px;
      .num{
        width: 33%;
        img{
          height: 26px;
          margin-bottom: 15px;
        }
        .tip{
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #111111;
          line-height: 18px;
        }
      }
    }
    .right{
      width: 500px;
      display: flex;
      justify-content: space-between;
      .img{
        width: 240px;
        height: 167px;
        cursor: pointer;
      }
    }
  }
  .recommends{
    padding-top: 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .experts{
      width: 393px;
      height: 461px;
      padding-left: 19px;
      padding-right: 15px;
      box-sizing: border-box;
      padding-top: 33px;
      background: rgba(255,255,255,0.3);
      border: 1px solid #FFFFFF;
      .t-title{
        font-size: 24px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 30px;
        padding-bottom: 15px;
      }
      .expert{
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 24px;
        .avatar{
          width: 66px;
          height: 66px;
          margin-right: 12px;
          cursor: pointer;
        }
        .info{
          width: 195px;
          cursor: pointer;
          &:hover{
            a{
              color: $--G60-theme !important;
            }
          }
          .name{
            width: 100%;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            a{
              cursor: pointer;
              font-size: 18px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              color: #111111;
              line-height: 18px;
              display: block;
              max-width: 270px;
              &:hover{
                color: $--G60-theme !important;
              }
              &:visited{
                color: #111111;
              }
            }
            .position{
              margin-left: 10px;
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #2280FF;
              max-width: 50px;
            }
            .education{
              margin-left: 8px;
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #44B46F;
              max-width: 50px;
            }
          }
          .belong{
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 14px;
            width: 100%;
            margin-bottom: 8px;
          }
          .direction{
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 14px;
            width: 100%;
          }
        }
        .right{
          margin-left: 8px;
          button{
            margin-top: 4px;
            width: 74px;
            height: 28px;
            background: #00C381;
            border-radius: 6px;
            cursor: pointer;
            border: none;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
    }
    .projects{
      width: 393px;
      height: 461px;
      background: rgba(255,255,255,0.3);
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      padding-top: 33px;
      padding-left: 19px;
      padding-right: 15px;
      .t-title{
        font-size: 24px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 30px;
        padding-bottom: 24px;
      }
      .project{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        .left{
          width: 280px;
          cursor: pointer;
          &:hover{
            .p-title{
              color: $--G60-theme !important;
            }
          }
          .p-title{
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 16px;
            width: 100%;
            margin-bottom: 18px;
            display: block;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #111111;
            }
          }
          .content{
            width: 100%;
            margin-bottom: 12px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 14px;
          }
          .infos{
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            p{
              white-space: nowrap;
              font-size: 14px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 14px;
              max-width: 125px;
            }
          }
          .keys{
            width: 100%;
            display: flex;
            align-items: center;
            .key{
              max-width: 100%;
              margin-right: 3%;
              a{
                display: block;
                font-size: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #999990;
                line-height: 20px;
                border: 1px solid #d8d8d8;
                border-radius: 2px;
                height: 22px;
                box-sizing: border-box;
                padding: 0 8px;
                max-width: 100%;
                &:hover{
                  color: #999999 !important;
                }
                &:visited{
                  color: #999990;
                }
              }
            }
          }
        }
        .right{
          .button{
            margin-bottom: 13px;
            width: 74px;
            height: 28px;
            background: $--G60-theme;
            border-radius: 6px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
            border: none;
          }
        }
      }
    }
    .demands{
      width: 393px;
      height: 461px;
      background: rgba(255,255,255,0.3);
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      padding-top: 33px;
      padding-left: 19px;
      padding-right: 19px;
      .t-title{
        font-size: 24px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 30px;
        padding-bottom: 15px;
      }
      .demand{
        width: 100%;
        margin-top: 25px;
        cursor: pointer;
        &:hover{
          .a-info{
            color: $--G60-theme !important;
          }
        }
        .publisher{
          display: block;
          width: 100%;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 14px;
          margin-bottom: 5px;
          span{
            color: #2280FF;
          }
        }
        .info{
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .icon{
            width: 18px;
            height: 18px;
            margin-right: 13px;
          }
          .a-info{
            width: 320px;
            max-height: 44px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #111111;
            line-height: 22px;
            cursor: pointer;
            &:hover{
              color: $--G60-theme !important;
            }
            &:visited{
              color: #111111;
            }
          }
        }
      }
    }
  }
  .voice-line{
    height: 49px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-left: 14px;
    background-size: 300px 49px;
    background-repeat: no-repeat;
    background-position-x: right;
    margin-top: 44px;
    .show-date{
      width: 84px;
      height: 41px;
      background: $--G60-theme;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 4px;
      margin-right: 13px;
      .day{
        font-size: 20px;
        line-height: 20px;
        margin-top: 4px;
        color: #FFFFFF;
      }
      .bottom{
        margin-top: 2px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 14px;
      }
    }
    .icon{
      height: 16px;
      margin-right: 11px;
      width: 16px;
    }
    .word{
      width: calc(100% - 160px);
      display: flex;
      justify-content: space-between;
      margin-bottom: 0 !important;
      .line{
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .more{
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          &:hover{
            color: $--G60-theme !important;
          }
          &:visited{
            color: #999999;
          }
        }
      }
      .left{
        max-width: 460px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 23px;
        span{
          color: $--G60-theme;
        }
      }
      .right{
        max-width: 460px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 23px;
        span{
          color: $--G60-theme;
        }
      }
    }
  }
}
</style>