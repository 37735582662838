<template>
    <div class="login-container">
      <!-- 左侧二维码 -->
      <div class="scan-login" v-loading="true">
        <img src="../../assets/images/wechat-login/login-icon.png" alt="" class="top-pic">
        <div class="cover-text"></div>
        <div id="wx_login" style="height: 340px;overflow:hidden;margin-top: -50px;" @click="refreshCode">
        </div>
        <div class="scan-text">
          微信扫一扫登录
        </div>
      </div>
      <div class="login-panel" v-loading="loading">
        <!-- 退出登录按钮 -->
        <div class="cancel-button">
          <img src="../../assets/images/cancel-login.png"/>
        </div>
        <!-- 标题 -->
        <div class="login-title">
          <div style="color: #333333;font-weight:bold;font-size: 44px">{{bindPhone ? '绑定手机号':'登录'}}</div>
        </div>
        <div class="login-form" :class="{'bind-form': bindPhone}">
          <div class="input-container">
            <!-- 手机号 11位 -->
            <el-input @input="handlePhoneChange" v-model="loginForm.phoneNumber" placeholder="请输入手机号码" class="input-box" maxlength="11">
              <template #suffix v-if="loginForm.phoneNumber !== ''">
                <i class="clear-icon el-icon-circle-close el-input__icon"> </i>
              </template>
            </el-input>
          </div>
          <div class="input-container">
            <!-- 验证码 暂为 6位 -->
            <el-input @input="handleCaptcha" v-model="loginForm.captcha" placeholder="短信验证码" class="input-box-code" maxlength="6">
              <template #suffix v-if="loginForm.captcha !== ''">
                <i class="clear-icon el-icon-circle-close el-input__icon"> </i>
              </template>
            </el-input>
            <!-- 验证码按钮 -->
            <el-button :class="{'code-button': show, 'code-btn-disable': !show || !validPhone(loginForm.phoneNumber)}"
              :disabled="!show || !validPhone(loginForm.phoneNumber)">
              <span v-if="(show && !validPhone(loginForm.phoneNumber)) || !sendFlag" style="color: #c0c4cc">获取验证码</span>
              <span v-if="
                      show &&
                      validPhone(loginForm.phoneNumber) &&
                      !repeatFlag &&
                      sendFlag
                    ">获取验证码</span>

              <span v-if="!show">重新发送({{ count }})</span>
              <span v-else-if="show && validPhone(loginForm.phoneNumber) && repeatFlag">重新发送</span>
            </el-button>
          </div>
          <div class="tip-container" v-if="bindPhone">
            <i class="el-icon-warning" />
            {{bindPhone ? '为了您的手机号安全，请绑定手机':''}}
          </div>
          <div v-else style="height: 30px;width:100%;">
          </div>
          <div class="login-button-container">
            <!-- 登录按钮 -->
            <div class="login-button">{{bindPhone ? '提交':'登录'}}</div>
          </div>
          <!-- 协议、声明 -->
          <div class="protocols" v-if="!bindPhone">
            登录即表示已阅读并同意
            <a class="protocol-name" target="_blank" href="/yhxy">《科企岛用户协议》</a>
            、
            <a class="protocol-name" target="_blank" href="/yszc">《科企岛隐私政策》</a>
            及
            <span>
              <a class="protocol-name" target="_blank" href="/nrsm">《科企岛内容声明》</a>。未注册的手机号或第三方帐号验证后将自动创建新帐号
            </span>
          </div>
          <!-- 其他第三方登录 -->
          <div class="other-login" v-if="!bindPhone">
            <div class="login-type">
              <img src="../../assets/images/wechat-login/wechat-login.png"/>
              微信登录
            </div>
            <div class="login-type" id="qqLoginBtn">
              <img src="../../assets/images/qq-login/qq-login.png"/>
              QQ登录
            </div>
            <!-- 二维码窗口 -->
            <el-dialog v-model="loginQrcode">
              <div id="wx_login"></div>
            </el-dialog>
          </div>
          <!-- 绑定确认窗口 -->
          <div class="bind-info">
            <el-dialog v-model="bindWindow" top="20%" width="20%">
              <div class="bind-word">
                {{isRegistered}}是否使用 {{otherType}} 头像、昵称替换原信息
              </div>
              <div class="bind-btns">
                <div class="bind-btn">
                  取消
                </div>
                <div class="bind-btn confirm">
                  确认
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import { validatePhone } from '@/utils/common/validate'
  export default {
    name: "tempLogin",
    data(){
        return{
            loading: true,
            show: true, // 判断是否点击了发送按钮
            bindPhone: false,
            loginForm:{
                phoneNumber: '',
                captcha: ''
            },
            repeatFlag: false
        }
    },
    methods:{
        validPhone(phone) { // 判断手机号是否合法
        if (!validatePhone(phone)) {
          return false;
        } else if (phone === "") {
          return false;
        } else {
          return true;
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  @import '../../assets/css/color.scss';
  .fix-top {
    position: fixed;
    top: 0;
  }
  .login-container {
    height: calc(100vh - 70px);
    width: 100%;
    background: url("../../assets/images/login-bg.png") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  .code-btn-disable {
    width: 30%;
    height: 44px;
    margin-left: 10px;
    border: none !important;
    font-size: 22px;
    font-weight: 400;
	background: #ffffff !important;
    ::v-deep span{
      color: #F57B15 !important;
    }
  }
  .scan-login{
    margin-top: 60px;
    background: #F1F1F1;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 630px;
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-right: 1px solid gray;
    flex-direction: column;
    position: relative;
    .top-pic{
      position: absolute;
      top: 60px;
      width: 136px;
      height: 50px;
    }
    .cover-text{
      position: absolute;
      top: 110px;
      width: 136px;
      height: 40px;
      background: rgb(241, 241, 241);
    }
    .scan-text{
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0F1C34;
    }
  }
  .border-right-top{
    border-top-right-radius: 8px;
  }
  .login-panel {
    margin-top: 60px;
    width: 552px;
    min-height: 660px;
    overflow: hidden;
    position: relative;
    z-index: 9999;
    border-bottom-right-radius: 8px;
    .cancel-button{
      position: absolute;
      right: 28px;
      top: 28px;
      cursor: pointer;
      img{
        width: 28px;
        height: 28px;
      }
    }
  }
  .login-title {
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
    height: 60px;
  }
  .code-button {
    display: inline-block;
    width: 30%;
    height: 44px;
    margin-left: 10px;
    line-height: .8;
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    border: none !important;
    background: #ffffff;
    ::v-deep span{
      color: #F57B15 !important;
    }
  }
  .code-button :hover,
  .code-button :active {
    display: inline-block;
    ::v-deep span{
      color: #F57B15 !important;
    }
  }
  .login-form {
    background: white;
    min-height: 500px;
    text-align: center;
  }
  .bind-form {
    min-height: 450px;
  }
  .input-container {
    width: 100%;
    padding: 40px 50px 10px 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    position: relative;
    .empty-phone{
      position: absolute;
      right: 50px;
      bottom: -20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #E3373F;
    }
    .empty-code{
      position: absolute;
      right: 50px;
      bottom: -20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #E3373F;
    }
    .code{
      position: absolute;
      right: 51px;
      top: 50%;
      margin-top: -7px;
    }
    .code::before{
      position: absolute;
      content: '';
      width: 2px;
      height: 14px;
      background: #DFE0E5;
      border-radius: 1px;
      left: -5px;
      top: 50%;
      margin-top:-7px;
    }
    img{
      position: absolute;
      width: 30px;
      height: 34px;
      left: 70px;
      top: 50%;
      margin-top: -2px;
    }
    ::v-deep .is-disabled{
      background: #f09a53;
      // border-radius: 18px;
    }
    ::v-deep input{
      height: 44px;
      font-size: 22px;
      border-radius: 0 !important;
    }
    .input-box{
      height: 44px;
      border: 1px solid #DFE0E5;
    }
    .input-box-code{
      width: 100%;
      height: 44px;
      border: 1px solid #DFE0E5;
    }
  }
  ::v-deep .el-input__inner {
    border: 0px solid #eee !important;
  }
  .tip-container {
    margin-top: 30px;
    padding: 0px 30px 0 30px;
    color: #999999;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      color: #F57B15;
      font-size: 18px;
      padding-right: 10px;
    }
  }
  .clear-icon {
    cursor: pointer;
    font-size: 22px;
    margin-top: 3px;
  }
  .login-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .login-button {
    width: 82%;
    border-radius: 4px;
    cursor: pointer;
    height: 66px;
    background: #F57B15;
    color: white;
    font-size: 28px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-button:hover {
    background: $--color-theme;
  }
  .protocols {
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    line-height: 24px;
    padding: 10px 50px 0 50px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #B0B5C0;
    float:left;
    text-align: justify;
    word-break: break-all;
    a:hover{
      color: #faae6f;
    }
  }
  .protocol-name {
    color: #B0B5C0;
    word-break: break-all;
  }
  .other-login{
    margin-top: 90px;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: space-around;
    img{
      width: 36px;
      height: 36px;
      padding-bottom: 10px;
    }
    .login-type{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    ::v-deep .el-dialog__header{
      background: #E9E9E9;
      min-width: 300px;
      position: relative;
    }
    ::v-deep .el-dialog__body{
      background: #E9E9E9;
      min-width: 300px;
    }
  }
  .bind-info{
    ::v-deep .el-dialog__header{
      background: #ffffff;
      min-width: 300px;
      position: relative;
    }
    ::v-deep .el-dialog__body{
      background: #ffffff;
      min-width: 300px;
    }
  }
  .bind-word{
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .bind-btns{
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: 20px;
    margin-bottom: -30px;
    border-top: 2px solid #c7c7c7;
    display: flex;
    align-items: center;
    .bind-btn{
      width: 50%;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #666666;
      padding: 20px 0;
      cursor: pointer;
    }
    .confirm{
      color: #EC8541;
      border-left: 2px solid #c7c7c7;
    }
  }
</style>