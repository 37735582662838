<template>
  <div class="content">
    <div class="top">
      <div class="title">
        全部资讯
      </div>
      <div class="types">
        <div class="type" v-for="item in 6" :key="item" :class="{'active':typeCur === item}" @mouseenter="changeType(item)">
          全部
        </div>
      </div>
    </div>
    <div class="card" v-for="item in 2" :key="item">
      <div class="thumb">
        <Image :src="'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2023/11/18/1702877799600529?imageMogr2/thumbnail/!50p'"></Image>
      </div>
      <div class="infos">
        <div><a class="text-ol-ell a-title">4万字回顾｜2023电商复盘、2024经4万字回顾｜2023电商复盘、2024经4万字回顾｜2023电商复盘、2024经</a></div>
        <p class="context text-ol-ell">新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼新型RW-24智能巡飞弹机翼采用的是联翼</p>
        <div class="bottom">
          <p class="time">3小时前</p>
          <img src="@/assets/images/post-line/info/thumb.png" class="icon" />
          <p class="num text-ol-ell">322</p>
          <img src="@/assets/images/post-line/info/comment.png" class="icon" />
          <p class="num text-ol-ell">322</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return{
      typeCur: 0,
    }
  },
  methods:{
    changeType(type){
      this.typeCur = type
    }
  }
}

</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  padding-top: 60px;
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    .title{
      font-size: 26px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 26px;
    }
    .types{
      display: flex;
      align-items: center;
      .type{
        width: 36px;
        height: 25px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 25px;
        margin: 0 15px;
        cursor: pointer;
      }
      .active{
        color: #2280FF;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          width: 32px;
          height: 2px;
          background: #2280FF;
          left: 50%;
          margin-left: -16px;
          bottom: -5px;
        }
      }
    }
  }
  .card{
    display: flex;
    padding-bottom: 30px;
    .thumb{
      width: 216px;
      height: 144px;
      cursor: pointer;
      margin-right: 20px;
    }
    .infos{
      width: 775px;
      padding-top: 7px;
      .a-title{
        display: block;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 24px;
        height: 24px;
        cursor: pointer;
        &:hover{
          color: $--G60-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .context{
        margin-top: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        width: 100%;
        margin-bottom: 42px;
      }
      .bottom{
        width: 100%;
        display: flex;
        align-items: center;
        .time{
          font-size: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 12px;
        }
        .icon{
          width: 16px;
          height: 16px;
          margin-left: 30px;
        }
        .num{
          max-width: 100px;
          font-size: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 12px;
          margin-left: 1px;
        }
      }
    }
  }
}
</style>